import "./Modals.scss";
import React, { useState } from "react";
import {foldersApi} from "../../../api/folders";
import Button from "../../../components/buttons/Button";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import FormValidator from "./modalValidation";
import {toast} from "react-toastify";

const CreateFolder = ({isOpen, onClose, cameras, errors, setErrors, onFolderWasCreated}) => {
  const [form, setForm] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (e) => {
    const {name, value} = e.target;

    setForm({
      ...form,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const onSelectChange = (id) => {
    onSelectCamera(id);

    setErrors({
      ...errors,
      cameraIds: "",
    });
  };

  const onSelectCamera = (id) => {
    const cameraIds = form?.cameraIds || [];

    setForm({
      ...form,
      cameraIds: isSelected(id) ? cameraIds.filter((item) => item !== id) : [...cameraIds, id],
    });
  };

  const isSelected = (id) => {
    return form.cameraIds && form.cameraIds.includes(id);
  };

  const onConfirm = async () => {
    const isValid = FormValidator.validateForm(form, setErrors);

    if (!isValid) {
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await foldersApi.create({folder: form});
      setForm({});
      onFolderWasCreated(data);
      toast.success("Folder was successfully created");
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Internal server error.');
    }

    setIsLoading(false);
  };

  return (
    <BaseModal isOpen={isOpen} onClose={onClose}>
      <section className="modal-body create-folder-modal">
        <Header name={"Create folder"} onClose={onClose}/>
        <div className="modal-content">
          <form>
            <div className="form-group">
              <label>Name</label>
              <input
                type={"text"}
                placeholder={"Enter Name"}
                name="name"
                className={errors.name ? "inputError" : "input"}
                value={form?.name || ""}
                onChange={onChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className={"textarea"}
                value={form?.description || ""}
                rows={2}
                name="description"
                placeholder={"Enter Description"}
                onChange={onChange}
              />
              {errors.description && <span className="error">{errors.description}</span>}
            </div>
          </form>
          <div className="cameras-list">
            <p className="cameras-list-title">Select cameras:</p>
            <table>
              <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>MEID (ICCID)</th>
              </tr>
              </thead>
              <tbody>
              {cameras.length > 0 ? (
                cameras.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className="checkbox-table">
                          <input
                            onChange={() => onSelectChange(item.id)}
                            type="checkbox"
                            id={`camera-select-${item.id}`}
                            value={item.id}
                            checked={isSelected(item.id)}
                          />
                          <label htmlFor={`camera-select-${item.id}`}>
                            {item.name || <i>No name</i>}
                          </label>
                        </div>
                      </td>
                      <td>{item.provider}</td>
                      <td>{item.imei}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="table-empty">
                    You don't have any cameras
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            {errors.cameraIds && <span className="error">{errors.cameraIds}</span>}
          </div>
        </div>
        <div className="modal-buttons">
          <Button onClick={onClose} title={"Cancel"} color={"outline"}/>
          <Button
            onClick={onConfirm}
            loader={isLoading}
            disabled={isLoading}
            title={"Create folder"}
            color={"grey"}
          />
        </div>
      </section>
    </BaseModal>
  );
};

export default CreateFolder;
