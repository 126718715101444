import "./PhotoList.scss";
import {Link} from "react-router-dom";
import React, {useState} from "react";
import {CameraPhotosApi} from "../../api/account-camera-photos";
import {useConfirmationModalContext} from "../../context/confirmation";

export default function PhotoList({
      listByDays,
      showRecognition,
      selectedPhotos,
      searchParams,
      onPhotoSelect,
      afterDelete
}) {

    const days = Object.keys(listByDays);

    return days.map((day) => {
        const {date, list} = listByDays[day];

        return (
            <div className="photos-for-days" key={day}>
                <div className="photos-date">{date}</div>
                <div className="photos-list">
                    {list &&
                        list.map((photo) =>
                            <PhotoItem
                                key={photo.id}
                                initialPhoto={photo}
                                showRecognition={showRecognition}
                                onPhotoSelect={onPhotoSelect}
                                selected={selectedPhotos.includes(photo.id)}
                                searchParams={searchParams}
                                afterDelete={afterDelete}
                            />
                        )}
                </div>
            </div>
        );
    });
}

const PhotoItem = ({initialPhoto, showRecognition, searchParams, selected, onPhotoSelect, afterDelete}) => {

    const [action, setAction] = useState("");
    const [photo, setPhoto] = useState(initialPhoto);
    const { showConfirmation } = useConfirmationModalContext();

    const onLikeClick = async () => {
        setAction("toggle-like");

        const isLike = !photo.liked;

        try {
            if (!isLike) {
                await CameraPhotosApi.photoUnLike(photo.cameraId, photo.id);
            }

            if (isLike) {
                await CameraPhotosApi.photoLike(photo.cameraId, photo.id);
            }

            setPhoto({
                ...photo,
                liked: isLike
            });
        } catch (err) {
            console.log("Error: ", err);
        }

        setAction("");
    }

    const onDeleteClick = async () => {
        const confirm = await showConfirmation("Are you sure you want delete this photo?")
        if (confirm) {
            setAction("photo-delete");

            try {
                await CameraPhotosApi.photoDelete(photo.cameraId, photo.id);
                afterDelete(photo.id);
            } catch (err) {
                setAction("");
                console.log("Error: ", err);
            }
        }
    }

    const inDeleteAction = action === "photo-delete";
    const inToggleLikeAction = action === "toggle-like";

    return (
        <div
            className={`photo-item ${selected ? 'photo-item-selected' : ''}`}
            key={photo.id}
            onClick={() => {
                onPhotoSelect({target: {value: photo.id}});
            }}
        >
            <div className={`delete-photo-overlay ${inDeleteAction ? "visible" : ""} `}><i className={"delete-photo-item-spinner"}/></div>
            <img src={photo.thumb} alt="" />
            {
                !inDeleteAction && <div className="photo-item-overlay">
                    <div className="photo-delete">
                        <div className="icon icon-trash-white" onClick={onDeleteClick} />
                    </div>
                    <div className="photo-view-btn">
                        <Link
                            to={`${photo.id}${searchParams.toString().length > 0 ? "?" + searchParams.toString() : ""}`}
                            className="btn btn-red"
                        >
                            View
                        </Link>
                    </div>
                    {
                        !inToggleLikeAction &&
                            <div className="photo-like" onClick={onLikeClick}>
                                {photo.liked ? (
                                    <div className="icon icon-liked" />
                                ) : (
                                    <div className="icon icon-heart-outline-white" />
                                )}
                            </div>
                    }
                </div>
            }

            <div className="photo-item-select">
                <div className="custom-checkbox ">
                    <input
                        type="checkbox"
                        name="photoId"
                        value={photo.id}
                        checked={selected}
                        readOnly={true}
                    />
                </div>
            </div>
            {showRecognition && photo.recognition && Object.keys(photo.recognition || {}).length > 0 && (
                <RecognitionIconsList recognition={photo.recognition} />
            )}
            <LikeIcon isLiked={photo.liked} loader={inToggleLikeAction}/>
            {photo.type === "video-preview" && (
                <div className="video-preview">
                    <div className="icon icon-play" />
                </div>
            )}
            {photo.type === "video-preview" ? (
                <VideoState
                    videoResolution={photo.videoResolution}
                    stateDescription={photo.stateDescription}
                />
            ) : (
                <PhotoState stateDescription={photo.stateDescription} />
            )}
        </div>
    );
}

const LikeIcon = ({isLiked, loader}) => {
    if (isLiked && !loader) {
        return <div className="photo-liked"><i className="icon icon-liked"/></div>
    }

    if (loader) {
        return <div className="photo-liked"><div className="photo-like-loader"></div></div>
    }

    return null;
}

const PhotoState = ({stateDescription}) => {
    const {status, short} = stateDescription;

    if (status === "success") {
        return <div className="photo-state success">{short}</div>;
    }

    if (status === "wait") {
        return <div className="photo-state waiting">{short}</div>;
    }

    if (status === "error") {
        return <div className="photo-state error">{short}</div>;
    }

    return null;
};

const VideoState = ({videoResolution, stateDescription}) => {
    const {status, short} = stateDescription;
    if (status === "success") {
        return <div className="photo-state success">{videoResolution}</div>;
    }

    if (status === "wait") {
        return <div className="photo-state waiting">{short}</div>;
    }

    if (status === "error") {
        return <div className="photo-state error">{short}</div>;
    }

    return <div className="photo-state">{videoResolution}</div>;
};

const RecognitionIconsList = ({recognition}) => {
    const icons = Object.keys(recognition);

    return (
        <div className="recognition-icons">
            {icons.map((icon, index) => {
                return (
                    <div key={icon + "-" + index} className="recognition-info">
                        <div className="recognition-icon-count">{recognition[icon]} x </div>
                        <div className={`recognition-icon icon-${icon}`} />
                    </div>
                );
            })}
        </div>
    );
};
