import "./Profile.scss";
import React, {useState} from "react";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import {CameraBlock} from "./components/CameraBlock/CameraBlock";
import {CamerasAPI} from "../../api/cameras";
import ConfirmOnXIntegration from "./modals/ConfirmOnXIntegration";
import ConfirmOnXLogout from "./modals/ConfirmOnXLogout";
import {ProfileAPI} from "../../api/profile";
import {Button} from "../../components";
import {useOutletContext} from "react-router-dom";
import {camerasQK} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";

const Onx = () => {
  const outletContext = useOutletContext();
  const [profile, setProfile] = useState(outletContext.profile);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmLogout, setShowConfirmLogout] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const camerasQueryKey = camerasQK.list().queryKey;
  const {
    data = {data: []},
    refetch: refetchCameras,
    isLoading: camerasLoading
  } = fetcher(camerasQueryKey, CamerasAPI.listCamera);

  const cameras = data.data;

  const isLoading = camerasLoading || outletContext.isLoading;

  const onConfirmNoAuth = async () => {
    setShowConfirm(false);
    setInProgress(true);
    await ProfileAPI.updateOnXIntegration(true);
    setProfile({
      ...profile,
      onx: {
        ...profile.onx,
        enabled: true
      }
    })
    setInProgress(false);
  }

  const onToggleOnXSync = async () => {
    if (profile?.onx.enabled && !profile?.onx.authRequired) {
      setInProgress(true);
      await ProfileAPI.updateOnXIntegration(false);
      setProfile({
        ...profile,
        onx: {
          ...profile.onx,
          enabled: false
        }
      })
      setInProgress(false);
    } else {
      setShowConfirm(true);
    }
  };

  const onSuccessLogout = async () => {
    await refetchCameras();
    await outletContext.refetchProfile();
  }

  const {onx} = profile;

  const enabledButAuthRequired = onx?.enabled && onx?.authRequired;
  const accountEnabled = onx?.enabled && !onx?.authRequired;

  return (
    <>
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title ">onX Integration</div>
          <div className="onx-header-log"></div>
        </div>
        {isLoading ? (
          <LineWaveLoader/>
        ) : (
          <>
            <section className="profile-onx-header">
              <div className="profile-onx-swipers">
                {enabledButAuthRequired &&
                  <p className="onx-auth-warning">Your auth was revoked or expired. Please perform authorization the he
                    onX account.</p>}
                <div className="profile-onx-auth-section">
                  <div className="profile-onx-auth-toggle">
                    <p className="profile-onx--swipers-title">onX Integration</p>
                    <ToggleSwitch
                      checked={accountEnabled || false}
                      name={"onx"}
                      loader={inProgress}
                      onChange={onToggleOnXSync}
                    />
                  </div>
                  {accountEnabled && <Button icon={"logout"} color={"red"} title={"Logout"} onClick={() => {
                    setShowConfirmLogout(true)
                  }}/>}
                </div>
              </div>
            </section>
            <section className="profile-onx-list">
              <div className="profile-onx-camera-block">
                {cameras?.map((camera) => {
                  return <CameraBlock key={camera.id} camera={camera} accountEnabled={accountEnabled}/>;
                })}
              </div>
            </section>
          </>
        )}
      </section>
      {showConfirm && (
        <ConfirmOnXIntegration
          isOpen={showConfirm}
          onConfirmNoAuth={onConfirmNoAuth}
          authRequired={profile?.onx.authRequired || false}
          onClose={() => {
            setShowConfirm(false)
          }}
        />
      )}
      {showConfirmLogout && (
        <ConfirmOnXLogout
          isOpen={showConfirmLogout}
          onSuccessLogout={onSuccessLogout}
          onClose={() => {
            setShowConfirmLogout(false)
          }}
        />
      )}
    </>
  );
};

export default Onx;
