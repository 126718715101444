import "./GuestCameras.scss";
import React from "react";
import {Link, useParams} from "react-router-dom";
import {Outlet} from "react-router-dom";
import Header from "./components/Header/Header";
import GuestCameraPreview from "./components/GuestCameraPreview/GuestCameraPreview";
import {GuestAccountsCameraApi} from "../../api/guest-accounts-camera";
import {guestQK} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";

const GuestCameraIndex = () => {
  let {id, photoId} = useParams();
  const qk = guestQK.oneCamera(id).queryKey;
  const {
    data = {
      data: {}
    }, isLoading, isFetched
  } = fetcher(qk, () => GuestAccountsCameraApi.view(id));

  const camera = data.data || {};

  const pageName = camera?.name ? camera?.name + ' / ' + camera?.host?.username : "";

  return (
    <section className="page-wrapper">
      <div className="page-content">
        <Header name={pageName} loading={isLoading}/>
        <div className="camera-view">
          <div className="camera-view-short">
            <GuestCameraPreview loading={isLoading} camera={camera}/>
            <BackLink id={id} photoId={photoId}/>
          </div>
          <div className="camera-view-content">
            {isFetched && <Outlet context={[camera, isFetched]}/>}
          </div>
        </div>
      </div>
    </section>
  );
};

const BackLink = ({id, photoId}) => {
  if (!photoId) {
    return (
      <Link to={"/guest-cameras"} className="btn btn-grey lg w100">
        <span className="btn-icon-arrow"/>
        <span>Back to cameras</span>
      </Link>
    )
  }

  return (
    <Link to={`/guest-cameras/${id}/photos`} className="btn btn-grey lg w100">
      <span className="btn-icon-arrow"/>
      <span>Back to my photos list</span>
    </Link>
  )
}

export default GuestCameraIndex;
