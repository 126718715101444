import "./Modal.scss";
import React from "react";
import Modal from "react-modal";

/*
const contentDefault = {
    position: "absolute",
    right: null,
    bottom: null,
    border: "none",
    background: "#FFFFFF",
    overflow: "visible",
    WebkitOverflowScrolling: "touch",
    borderRadius: "8px",
    outline: "none",
    padding: "28px",
    width: "656px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)"
}*/

Modal.setAppElement("#root");

const defaultContent = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    border: "none",
    background: "#FFFFFF",
    WebkitOverflowScrolling: "touch",
    borderRadius: "8px",
    outline: "none",
    padding: "28px",
    width: "656px",
};

export default function BaseModal({children, onClose, overlayStyle = {}, isOpen = false, content = {}, onClick}) {
    return (
        <Modal
            isOpen={isOpen}
            closeTimeoutMS={200}
            ariaHideApp={true}
            preventScroll={true}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    backgroundColor: "rgba(15, 23, 42, 0.45)",
                    ...overlayStyle
                },
                content: {
                    ...defaultContent,
                    ...content,
                },
            }}
        >
            {children}
        </Modal>
    );
}
