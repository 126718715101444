import React from "react";
import "./ToggleSwitch.scss";

const ToggleSwitch = ({
  name,
  checked,
  onChange,
  disabled = false,
  readOnly = false,
  label = "",
  loader = false
}) => {
    return (
        <section className={`toggle-switch-section ${disabled ? "toggle-switch-disabled" : ""}`}>
            <label className={`toggle-switch ${checked ? "toggle-switch-checked" : ""}`}>
                {
                    loader && <div className="toggle-switch-spinner"/>
                }
                <input
                    type="checkbox"
                    readOnly={readOnly}
                    name={name}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    value={1}
                />
                <span className="switch" />
            </label>
            {label ? (
                <p className="toggle-switch-text">{label}</p>
            ) : (
                <p className="toggle-switch-text">{checked ? "Enabled" : "Disabled"}</p>
            )}
        </section>
    );
};
export default ToggleSwitch;
