import axios from "./http-api-client";

const TagsAPI = {
  get: async (name) => {
    return axios.get(`/account/tags${name ? `?name=${name}` : ""}`);
  },
  create: async (name) => {
    return axios.post(`/account/tags`, {
      tag: {
        name
      }
    });
  },
  update: async (id, name) => {
    return axios.put(`/account/tags/${id}`, {
      tag: {
        name
      }
    });
  },
  delete: async (id) => {
    return axios.delete(`/account/tags/${id}`);
  },
};

export default TagsAPI;
