import PhotoWeather from "../../components/Weather/photo-weather";
import React, {useMemo} from "react";
import moment from "moment/moment";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import {CamerasAPI} from "../../api/cameras";
import Spinner from "../../components/CameraPreview/Spinner";

const TodayWeather = ({camera}) => {
  const qk = camerasSettings.todayWeather(camera.id).queryKey;
  const { data = { data: { current: {}}}, isLoading} = fetcher(qk,  () => CamerasAPI.cameraCurrentWeather(camera.id));
  const today = moment().format("dddd, MMMM d");
  const weather = data?.data?.current || {};

  const rows = useMemo(() => {
    return [
      {label: "Condition", value: weather.weatherCode?.description},
      {label: "Wind Direction", value: weather.windDirection},
      {label: "Wind Speed", value: weather.windSpeed},
      {label: "Wind Gusts", value: weather.windGusts},
      {label: "Precipitation", value: weather.precipitationProbability},
      {label: "Rainfall", value: weather.rain},
      {label: "Moon Phase", value: weather.moonPhase?.name},
      {label: "Pressure", value: weather.surfacePressure},
      {label: "Humidity", value: weather.relativeHumidity},
    ]
  }, [weather]);

  return (
    <section className="content-section">
      <div className="content-header">
        <div className="page-sub-title ">{today}</div>
      </div>
      {isLoading
        ? <Spinner />
        : (
          <div>
            <PhotoWeather short weather={weather} />
            <div className="camera-weather-today">
              {rows.map((row, idx) => (
                <div className="camera-weather-today-row" key={idx}>
                  <div className="camera-weather-today-row-label">{row.label}</div>
                  <div className="camera-weather-today-row-value"><strong>{row.value || "N/A"}</strong></div>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </section>
  );
}

export default TodayWeather;
