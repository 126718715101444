import "./Profile.scss";
import React, {useState} from "react";
import Device from "./components/Device/Device";
import {SessionsAPI} from "../../api/sessions";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import ViewDevice from "./modals/ViewDevice";
import DeleteDevice from "./modals/DeleteDevice";
import fetcher from "../../helpers/fetcher";
import {profileQK} from "../../helpers/query-keys";

const Sessions = () => {
  const {data = {data: []}, isFetched, refetch, isLoading} = fetcher(profileQK.sessions.queryKey, SessionsAPI.all);
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [showDeleteDevice, setShowDeleteDevice] = useState(false);
  const [deviceId, setDeviceId] = useState(null);
  const list = data.data;

  const onDeviceClick = (id) => {
    setDeviceId(id);
    setShowDeviceInfo(true);
  }

  const onDeviceInfoClose = () => {
    setShowDeviceInfo(false);
  }

  const onDeviceDeleteClick = () => {
    setShowDeleteDevice(true);
  }

  const onDeviceDeleteClose = () => {
    setShowDeleteDevice(false);
  }

  const onSuccessDelete = async () => {
    setShowDeviceInfo(false);
    setShowDeleteDevice(false);

    await refetch()
  }

  return (
    <>
      <ViewDevice id={deviceId} isOpen={showDeviceInfo} onClose={onDeviceInfoClose}
                  onDeleteClick={onDeviceDeleteClick}/>
      <DeleteDevice id={deviceId} isOpen={showDeleteDevice} onClose={onDeviceDeleteClose}
                    onSuccessDelete={onSuccessDelete}/>
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Sessions</div>
        </div>
        {isLoading && <LineWaveLoader/>}
        <div className="device-list">
          {list.map((device) => <Device key={device.id} device={device} onClick={onDeviceClick}/>)}
          {list.length < 1 && isFetched ? <div>Sessions are not found</div> : null}
        </div>
      </section>
    </>
  );
};

export default Sessions;
