import "./Profile.scss";
import React, {useCallback, useState} from "react";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import {ProfileAPI} from "../../api/profile";
import {SubscriptionsAPI} from "../../api/subscriptions";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import NewAddon from "./modals/NewAddon";
import fetcher from "../../helpers/fetcher";
import {profileQK, subscriptionsQK} from "../../helpers/query-keys";

const Addons = () => {
  const {data: addonsData = {
    data: []
  }, refetch, isFetched: isAddonsFetched, isLoading} = fetcher(profileQK.addons.queryKey, ProfileAPI.addons);
  const {
    data: subscriptionGroups = {
      data: {
        list: []
      }
    },
    isLoading: subscriptionGroupsIsLoading,
    isFetched: subscriptionGroupsIsFetched,
  } = fetcher(subscriptionsQK.addons.queryKey, SubscriptionsAPI.addons);
  const [showAddAddon, setShowAddAddon] = useState(false);

  const handleCloseModal = useCallback(async (success) => {
      if (success) {
          await refetch();
      }
      setShowAddAddon(false);
  }, [refetch]);

  const addons = addonsData.data || [];

  const pictures = useCallback((item) => {
    if (item.billingPlan?.numbersOfPictures && Number(item.billingPlan?.numbersOfPictures) > 0) {
      return `${item.billingPlan.numbersOfPictures} Pictures `;
    }
    return ""
  }, []);

  return (
    <>
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Addons</div>
          <div className="content-header-controls">
            <ButtonIcon icon={"plus"} title={"Add"} color="btn-grey" onClick={() => setShowAddAddon(true)}/>
          </div>
        </div>
        {isLoading && <LineWaveLoader/>}
        {isAddonsFetched &&
            (<div className="profile-addons-list">
              {
                addons.map((item) => (
                  <div className="addon-item" key={item.id}>
                    <div className="addon-description">
                      <div className="addon-description-type">{item.billingPlan?.name}</div>
                      <div className="addon-price">{pictures(item)}{item.billingPlan?.price} {item.billingPlan?.period}</div>
                    </div>
                    <div className="addon-date">
                      <div className="addon-purchased">Purchased - {item.startDate}</div>
                      <div className="addon-expires">Expires - {item.endDate}</div>
                    </div>
                  </div>
                ))
              }
            </div>)
        }
        {isAddonsFetched && addons.length < 1 ? <div>This account does not have those types of subscriptions</div> : null}
      </section>
      { subscriptionGroupsIsFetched ? <NewAddon isOpen={showAddAddon} subscriptionGroups={subscriptionGroups.data.list} isLoading={subscriptionGroupsIsLoading} onClose={handleCloseModal}/> : null}
    </>
  );
};

export default Addons;
