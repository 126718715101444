import "./CameraView.scss";
import React, {useCallback, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import {camerasSettings} from "../../helpers/query-keys";
import {AccountCameraAPI} from "../../api/account-camera";
import fetcher from "../../helpers/fetcher";
import Nav from "./components/Nav";
import Spinner from "./components/Spinner";
import Button from "../../components/buttons/Button";
import NotificationEmail from "./components/NotificaitonEmail";
import NotificationPhone from "./components/NotificationPhone";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import NotificationModal from "./modals/NotificationModal";
import {deleteModalTextHandler, deleteModalTextToastHandler, modalTextHandler} from "./utils";
import AddPhoneModal from "./modals/AddPhoneModal";
import AddEmailModal from "./modals/AddEmailModal";
import {useQueryClient} from "@tanstack/react-query";
import {useConfirmationModalContext} from "../../context/confirmation";
import {toast} from "react-toastify";

const Notifications = ({data, camera, deleteModalHandler, refetch, onUpdate}) => {
  const {phones, emails} = data;
  const [isChecked, setIsChecked] = useState({
    push: data.isPush,
    email: data.isEmail,
    twilio: data.isTwillio,
  });

  const [isNotificationModal, setIsNotificationModal] = useState("");

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isAddPhoneModalOpen, setIsAddPhoneModalOpen] = useState(false);
  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);

  const [modalTouched, setModalTouched] = useState(false);

  const onPushHandler = () => {
    onUpdate({
      push: isChecked.push ? 0 : 1
    });
    setIsChecked((prev) => ({...prev, push: !prev.push}));
  };

  const onPhoneHandler = () => {
    setIsChecked((prev) => ({...prev, twilio: !prev.twilio}));
    if (!isChecked.twilio) {
      setIsNotificationModal("twilio");
      setIsConfirmModalOpen(true);
    } else {
      onUpdate({
        twilio: 0,
      });
      setIsNotificationModal("");
      setIsConfirmModalOpen(false);
    }

    setModalTouched(true);
  };

  const onEmailHandler = () => {
    setIsChecked((prev) => ({...prev, email: !prev.email}));
    if (!isChecked.email) {
      setIsConfirmModalOpen(true);
      setIsNotificationModal("email");
    } else {
      onUpdate({
        email: 0,
      });
      setIsNotificationModal("");
      setIsConfirmModalOpen(false);
    }
    setModalTouched(true);
  };

  //modals handlers
  const addPhoneModalHandler = () => setIsAddPhoneModalOpen(true);

  const addEmailModalHandler = () => setIsAddEmailModalOpen(true);

  const onCloseConfirmModal = () => {
    setIsChecked((prev) => ({
      ...prev,
      [isNotificationModal]: false,
    }));
    setIsNotificationModal("");
    setIsConfirmModalOpen(false);
    setIsAddPhoneModalOpen(false);
  };

  const onConfirmNotificationModal = () => {
    onUpdate({
      [isNotificationModal]: 1,
    });
    setIsNotificationModal("");
    setIsConfirmModalOpen(false);
  };

  const handleClosePhoneModal = async (success) => {
    setIsAddPhoneModalOpen(false);
    if (success) await refetch();
  }

  const handleCloseEmailModal = async (success) => {
    setIsAddEmailModalOpen(false);
    if (success) await refetch();
  }

  return (
    <>
      <section>
        <div className="camera-settings-notification-swipers">
          <div className="camera-settings-notification-swipers-block">
            <span className="setting-title">Push Messages</span>
            <ToggleSwitch checked={isChecked.push} onChange={onPushHandler}/>
          </div>
          <div className="camera-settings-notification-swipers-block">
            <span className="setting-title">Send Images via Text</span>
            <ToggleSwitch checked={isChecked.twilio} onChange={onPhoneHandler}/>
          </div>
          <div className="camera-settings-notification-swipers-block">
            <span className="setting-title">Send Images via Email</span>
            <ToggleSwitch checked={isChecked.email} onChange={onEmailHandler}/>
          </div>
        </div>
        <div className="camera-settings-notification-info">
          <form>
            <div className="camera-notification-phones">
              <div className="form-group">
                <label>Phone</label>
                <div style={{display: 'flex', flexDirection: 'column', gap: 16}}>
                  {phones?.map((phone) => {
                    return (
                      <NotificationPhone
                        key={phone?.id}
                        value={phone?.phone}
                        disabled={phone?.status === 0}
                        reason={phone?.comment}
                        onClick={() => deleteModalHandler(phone, "phone")}
                      />
                    );
                  })}
                </div>
              </div>
              <Button
                title={"Add phone"}
                onClick={addPhoneModalHandler}
                disabled={isChecked.twilio ? false : true}
              />
            </div>
          </form>
          <div className="camera-notification-emails">
            <div className="form-group">
              <label>Emails</label>
              {emails?.map((email) => {
                return (
                  <NotificationEmail
                    key={email?.id}
                    value={email?.email}
                    onClick={() => deleteModalHandler(email, "email")}
                  />
                );
              })}
            </div>
            <Button
              title={"Add email"}
              onClick={addEmailModalHandler}
              disabled={isChecked.email ? false : true}
            />
          </div>
        </div>
      </section>
      <NotificationModal
        isOpen={modalTouched && isConfirmModalOpen}
        onConfirmNotificationModal={onConfirmNotificationModal}
        onClose={onCloseConfirmModal}
        title={isNotificationModal === "twilio" ? "text" : isNotificationModal}
        modalText={modalTextHandler(isNotificationModal)}
      />

      <AddEmailModal id={camera.id} isOpen={isAddEmailModalOpen} onClose={handleCloseEmailModal}/>
      <AddPhoneModal
        id={camera.id}
        isOpen={isAddPhoneModalOpen}
        onClose={handleClosePhoneModal}
      />
    </>
  );
};

const NotificationsPage = () => {
  const {id} = useParams();
  const {showConfirmation} = useConfirmationModalContext();
  const queryClient = useQueryClient();
  const notificationsQK = camerasSettings.notifications(id).queryKey;
  const [camera] = useOutletContext();
  const {
    data = {
      isTwillio: false,
      isPush: false,
      isEmail: false,
      emails: [],
      phones: []
    }, isFetched, refetch
  } = fetcher(notificationsQK, () => AccountCameraAPI.notifications(id));

  const onUpdate = useCallback(async (newSettings) => {
    try {
      await AccountCameraAPI.updateNotifications(id, newSettings);
      await refetch();
    } catch (e) {

    }
  }, [queryClient, id, refetch]);


  const deleteModalHandler = async (value, type) => {
    const text = `Are you sure that you want delete ${deleteModalTextHandler(type) || ""} ${type === "phone" ? value.phone : value.email}?`;
    const func = type === "email" ? AccountCameraAPI.deleteCameraNotificationEmail : AccountCameraAPI.deleteCameraNotificationPhone;
    const confirm = await showConfirmation(text);
    if (confirm) {
      try {
        await func(id, value.id);
        await refetch();
        toast.info(`${deleteModalTextToastHandler(type) || ""} was successfully deleted`);
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <>
      <Nav camera={camera} current={"notifications"}/>
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Notifications</div>
        </div>
        {isFetched ?
          <Notifications deleteModalHandler={deleteModalHandler} refetch={refetch} camera={camera} data={data.data}
                         onUpdate={onUpdate}/> : <Spinner/>}
      </section>
    </>
  )
}

export default NotificationsPage;
