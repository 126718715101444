import "./../AddCamera.scss";
import cx from "classnames";
import React, {useCallback} from "react";

const AccountSubscriptionItem = ({item, onSelectSubscription, isSelected}) => {
  const billingPlan = item.billingPlan;
  const cameras = item.cameras.length;

  const handleClick = useCallback(() => {
    onSelectSubscription("account", billingPlan);
  }, [billingPlan, onSelectSubscription]);

  return (
    <div
      className={cx("subscriptions-select-item", {
        active: isSelected,
      })}
      role="button"
      onClick={handleClick}
    >
      <div className="subscription-select-name">{billingPlan.photos} Pictures</div>
      <div className="subscription-select-period">{billingPlan.price} {billingPlan.period}</div>
      <div className="subscription-select-cameras">Cameras: {cameras}</div>
    </div>
  );
};
export default AccountSubscriptionItem;
