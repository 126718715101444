import "./CameraView.scss";
import React, {useState} from "react";
import Nav from "./components/Nav";
import {useOutletContext} from "react-router-dom";
import SettingsLine, { checkIsVisible, RenderControlsItem } from "../../components/CameraSettings";
import {Button} from "../../components";
import {AccountCameraAPI} from "../../api/account-camera";
import {toast} from "react-toastify";

const Settings = () => {
    const [camera, _setCamera, _isLoading, suspended] = useOutletContext();
    const [inProgress, setInProgress] = useState(false);

    const {settings} = camera;
    const {schema, schemaValues} = settings;

    const {readOnly, settings: settingsMap} = schema;
    const settingsMapNames = settingsMap ? Object.keys(settingsMap) : [];

    const [form, setForm] = useState({...schemaValues});

    const onChange = (event) => {
        const {name, value} = event.target;
        let newForm = {
            [name]: value
        };
        if (name === "triggerIntervalUnit") {
            const pirIntervals = Object.values(settingsMap).filter((item) => item.alias === "pirInterval");
            const currentIntervals = pirIntervals.find((item) => (item.dependsOn?.triggerIntervalUnit || []).includes(value));
            if (currentIntervals && currentIntervals.options) {
                newForm = {
                    ...newForm,
                    pirInterval: currentIntervals.options[0]?.value
                }
            }
        }
        if (name === "timelapseUnit") {
            const timeLapseIntervals = Object.values(settingsMap).filter((item) => item.alias === "timeLapseInterval");
            const currentIntervals = timeLapseIntervals.find((item) => (item.dependsOn?.timelapseUnit || []).includes(value));
            if (currentIntervals && currentIntervals.options) {
                newForm = {
                    ...newForm,
                    timeLapseInterval: currentIntervals.options[0]?.value
                }
            }
        }
        setForm({
            ...form,
            ...newForm,
        });
    };

    const onToggleChange = (event) => {
        const {name, checked} = event.target;
        setForm({
            ...form,
            [name]: checked ? 1 : 0,
        });
    };

    const onSubmit = async (event) => {
        event.preventDefault();
        if (suspended) return;
        const data = new FormData(event.target);
        const formObject = Object.fromEntries(data.entries());
        try {
            setInProgress(true);
            await AccountCameraAPI.updateSettings(camera.id, {settings: {...form, formObject}});
            setInProgress(false);
            toast.success("Settings was successfully updated");
        } catch (error) {
            setInProgress(false);
            toast.error(error?.response?.data?.message || 'Internal server error.');
        }
    };

    const onCancel = () => {
        setForm({...schemaValues});
    };

    return (
        <>
            <Nav camera={camera} current={"settings"} />
            <section className="content-section">
                <div className="content-header">
                    <div className="page-sub-title">Settings</div>
                </div>
                <form onSubmit={onSubmit}>
                    <section className="camera-settings">
                        {settingsMapNames.map((name, index) => {
                            const option = settingsMap[name];
                            const fieldName = option.alias || name;
                            const currentValue = form[fieldName];
                            const isVisible = option["dependsOn"] ? checkIsVisible(option["dependsOn"], form) : true;

                            return (
                                <SettingsLine
                                    key={`${name}-${index}`}
                                    label={option.label}
                                    isVisible={isVisible}
                                    indent={option.indent}
                                >
                                    <RenderControlsItem
                                        option={option}
                                        fieldName={fieldName}
                                        currentValue={currentValue}
                                        onChange={onChange}
                                        onToggleChange={onToggleChange}
                                        disabled={readOnly || !isVisible}
                                    />
                                </SettingsLine>
                            );
                        })}
                    </section>
                    <div className="camera-view-footer">
                        <Button disabled={inProgress} size="lg" onClick={onCancel} color="outline" title="Cancel" />
                        <Button
                            type="submit"
                            disabled={inProgress || suspended}
                            loader={inProgress}
                            size="lg"
                            color="grey"
                            title="Save"
                        />
                    </div>
                </form>
            </section>
        </>
    );
};

export default Settings;
