import "./Profile.scss";
import React, {useState, useEffect, useCallback} from "react";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import ButtonStandard from "../../components/buttons/ButtonStandard";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import cx from "classnames";
import {ProfileAPI} from "../../api/profile";
import {Button, Select} from "../../components";
import {AuthApi} from "../../api/auth";
import {useOutletContext} from "react-router-dom";
import {useConfirmationModalContext} from "../../context/confirmation";

const ProfileInfo = () => {
  const outletContext = useOutletContext();
  const {showConfirmation} = useConfirmationModalContext();
  const {isLoading, balance, refetchProfile} = outletContext;
  const [isInProgress, setIsInProgress] = useState(false);
  const [countries, onChangeCountries] = useState([]);
  const [states, onChangeStates] = useState([]);
  const [profile, setProfile] = useState(outletContext.profile);
  const [form, setForm] = useState({
    notify: outletContext.profile.notify,
    recognitionEnabled: outletContext.profile.recognitionEnabled,
  });

  useEffect(() => {
    ProfileAPI.address().then(({data}) => {
      setProfile((oldData) => ({
        ...oldData,
        ...data,
      }));
      if (data.countryId) {
        AuthApi.states(data.countryId)
          .then(({data}) => {
            onChangeStates(data.list || []);
          })
      }
      AuthApi.countries().then(({data}) => {
        onChangeCountries(data.list || []);
      })
    });
  }, []);

  const onSwitchChange = (e) => {
    const {name} = e.target;

    setForm({
      ...form,
      [name]: !form[name],
    });
  };

  const onCancelClick = () => {
    setForm({
      notify: profile.notify,
      recognitionEnabled: profile.recognitionEnabled,
    });
  }

  const onSaveClick = async () => {
    setIsInProgress(true);

    if (form.notify !== profile.notify) {
      await ProfileAPI.updatePushNotificationSetting({enabled: form.notify});
    }

    if (form.recognitionEnabled !== profile.recognitionEnabled) {
      await ProfileAPI.updateRecognitionSetting({enabled: form.recognitionEnabled});
    }

    try {
      await ProfileAPI.updateProfile({
        firstName: profile.firstName,
        lastName: profile.lastName,
        address: profile.address,
        middleName: profile.middleName,
        city: profile.city,
        stateId: profile.stateId,
        zip: profile.zip,
        countryId: profile.countryId,
        primaryPhone: profile.primaryPhone,
        secondaryPhone: profile.secondaryPhone,
      });
    } catch (e) {
      console.log(e);
    }
    setIsInProgress(false);
  }

  const handleChangeProfile = useCallback((field = {}) => {
    setProfile({
      ...profile,
      ...field,
    })
  }, [profile]);

  const handleChangeZip = useCallback((zip = "") => {
    setProfile({
      ...profile,
      zip: zip.replace(/\D/ig, "")
    });
  }, [profile]);

  const handleChangePhone = useCallback((value = "", key) => {
    setProfile({
      ...profile,
      [key]: value.replace(/\D/ig, "")
    });
  }, [profile]);

  const countriesOptions = countries.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const statesOptions = states.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleChangeCountry = useCallback(async (countryId) => {
    try {
      const {data} = await AuthApi.states(countryId);
      handleChangeProfile({countryId, stateId: undefined});
      onChangeStates(data.list || []);
    } catch (e) {
    }
  }, [handleChangeProfile]);

  const payNow = useCallback(async () => {
    const confirm = await showConfirmation("Are you sure you want to pay now?");
    if (!confirm) return;
    try {
      await ProfileAPI.payNow();
      await refetchProfile();
    } catch (e) {

    }
  }, [handleChangeProfile]);

  const canPayNow = profile.status === "suspended" && balance.payNow;

  return (
    <>
      <section className="content-section status-section">
        <div className="profile-status-wrapper">
          <div className="profile-status-subtitle">
            Account number: <strong>{profile.accountNumber}</strong>
          </div>
          {profile.status && (
            <div className="profile-status-change">
              <div className={cx("profile-status", profile.status)}>{profile.status}</div>
              {canPayNow
                ? (
                  <div className="balance-wrapper">
                    Balance: <strong>{` ${balance.balance}`}</strong>
                    <Button title="Pay now" onClick={payNow} className="pay-now-btn" icon="credit-card" color="red"/>
                  </div>
                )
                : null
              }
            </div>
          )}
        </div>
      </section>
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title ">Profile Info</div>
        </div>
        {isLoading ? (
          <LineWaveLoader/>
        ) : (
          <>
            <form>
              <div className="threeInputs">
                <div className="form-group">
                  <label>First name</label>
                  <input
                    onChange={(event) => handleChangeProfile({firstName: event.target.value})}
                    className="input"
                    type={"text"}
                    placeholder={"First name"}
                    value={profile.firstName}
                  />
                </div>
                <div className="form-group">
                  <label>Middle name</label>
                  <input
                    onChange={(event) => handleChangeProfile({middleName: event.target.value})}
                    className="input"
                    type={"text"}
                    placeholder={"Middle name"}
                    value={profile.middleName}
                  />
                </div>
                <div className="form-group">
                  <label>Last name</label>
                  <input
                    className="input"
                    onChange={(event) => handleChangeProfile({lastName: event.target.value})}
                    type={"text"}
                    placeholder={"Last name"}
                    value={profile.lastName}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  className="input"
                  readOnly
                  type={"text"}
                  placeholder={"email"}
                  value={profile.email}
                />
              </div>

              <div className="twoInputs">
                <div className="form-group">
                  <label>Country</label>
                  <Select
                    value={profile.countryId}
                    onChange={(countryId) => handleChangeCountry(countryId)}
                    name="countryId"
                    options={countriesOptions}
                  />
                </div>
                <div className="form-group">
                  <label>State</label>
                  <Select
                    value={profile.stateId}
                    onChange={(stateId) => handleChangeProfile({stateId})}
                    name="countryId"
                    disabled={!profile.countryId}
                    options={statesOptions}
                  />
                </div>
              </div>

              <div className="twoInputs">
                <div className="form-group">
                  <label>City</label>
                  <input
                    className="input"
                    onChange={(event) => handleChangeProfile({city: event.target.value})}
                    type={"text"}
                    placeholder={"City"}
                    value={profile.city}
                  />
                </div>
                <div className="form-group">
                  <label>ZIP</label>
                  <input
                    className="input"
                    onChange={(event) => handleChangeZip(event.target.value)}
                    type={"text"}
                    placeholder={"ZIP"}
                    value={profile.zip}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>Address</label>
                <input
                  className="input"
                  onChange={(event) => handleChangeProfile({address: event.target.value})}
                  type={"text"}
                  placeholder={"Address"}
                  value={profile.address}
                />
              </div>

              <div className="twoInputs">
                <div className="form-group">
                  <label>Primary phone</label>
                  <input
                    className="input"
                    onChange={(event) => handleChangePhone(event.target.value, "primaryPhone")}
                    type={"text"}
                    placeholder={"Primary phone"}
                    value={profile.primaryPhone}
                  />
                </div>
                <div className="form-group">
                  <label>Secondary phone</label>
                  <input
                    className="input"
                    onChange={(event) => handleChangePhone(event.target.value, "secondaryPhone")}
                    type={"text"}
                    placeholder={"Secondary phone"}
                    value={profile.secondaryPhone}
                  />
                </div>
              </div>
            </form>

            <section className="profile-info-footer">
              <div className="profile-info-swipers">
                <div className="profile-info-swipers-block">
                  <p className="profile-info--swipers-title">Push Messages</p>
                  <ToggleSwitch
                    checked={form?.notify || false}
                    name={"notify"}
                    onChange={onSwitchChange}
                  />
                </div>
                <div className="profile-info-swipers-block">
                  <p className="profile-info-swipers-title">Image recognition feature</p>
                  <ToggleSwitch
                    checked={form?.recognitionEnabled || false}
                    name={"recognitionEnabled"}
                    onChange={onSwitchChange}
                  />
                </div>
              </div>
            </section>
            <div className="profile-info-buttons">
              <ButtonStandard color="btn-outline" onClick={onCancelClick} title={"Cancel"}/>
              <Button color={"grey"} title={"Save"} size={"lg"} loader={isInProgress} onClick={onSaveClick}/>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default ProfileInfo;
