import axios from "./http-api-client";

export const GuestAccountsApi = {
    list: async () => {
        return axios.get(
            `/account/guests/keys`
        );
    },

    create: async (data) => {
        return axios.post(
            `/account/guests/keys`,
            data);
    },

    update: async (id, data) => {
        return axios.put(`/account/guests/keys/${id}`, data);
    },

    view: async (id) => {
        return axios.get(`/account/guests/keys/${id}`);
    },

    delete: async (id) => {
        return axios.delete(`/account/guests/keys/${id}`);
    },
};
