import "./AddCamera.scss";
import React, {useMemo, useState} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import checkRed from "../../../images/iconSVG/elements/check_red.svg";
import pencil from "../../../images/iconSVG/elements/icon_pencil_red.svg";
import cx from "classnames";
import AddCamera from "./AddCamera";
import CameraInfo from "./CameraInfo";
import ChooseSubscription from "./ChooseSubscription";
import Review from "./Review";
import AddCameraSettings from "./Settings";
import {AddCameraAPI} from "../../../api/add-camera";

const AddCameraModal = ({isOpen, onClose}) => {

    const [prevStep, setPrevStep] = useState("");
    const [loading, onChangeLoading] = useState(false);
    const [step, setStep] = useState("init");
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [subscriptionGroups, setSubscriptionGroups] = useState([]);
    const [settings, setSettings] = useState({
        scheme: {},
        values: {}
    });

    // store progress of form
    const [info, setInfo] = useState({});

    const onBack = (step) => {
        setStep(step);
    }

    const onInit = async (imei, iccid, cameraInfo, services, defaultName, settings) => {
        setInfo({
            imei: imei,
            iccid: iccid,
            name: defaultName,
            cameraInfo: cameraInfo,
            services: services
        });

        setSettings(settings);

        setStep("info");
        const responseAccountSubscriptions = await AddCameraAPI.accountSubscriptionPlans();
        const responseSubscriptionGroups = await AddCameraAPI.subscriptionPlans(imei);

        setSubscriptionList(responseAccountSubscriptions.data.list);
        setSubscriptionGroups(responseSubscriptionGroups.data.list);
    }

    const onNext = (step, data, nextStep) => {
        setInfo({
            ...info,
            ...data,
        });

        setPrevStep(step);
        setStep(nextStep);
    }

    const onFinish = async () => {
        try {
            onChangeLoading(true);
            await AddCameraAPI.confirm({
                summary: {
                    imei: info.imei,
                    iccid: info.iccid,
                    serviceId: info.serviceId ?? null,
                    name: info.name,
                    description: info.description,
                    packageId: info.subscription?.packageId ?? null,
                    settings: info.settings || {},
                }
            });
            onClose();
            onChangeLoading(false);
        } catch (e) {
            onChangeLoading(false)
        }
    }

    const steps = useMemo(() => {
        return [
            {
                label: "General",
                step: "init",
            },
            {
                label: "Info",
                step: "info",
            },
            {
                label: "Camera settings",
                step: "general",
            },
            {
                label: "Subscription",
                step: "choose-subscription",
            },
            {
                label: "Review",
                step: "review",
            },
        ]
    }, []);

    const currentStepIdx = useMemo(() => {
        return steps.findIndex((item) => item.step === step)
    }, [step, steps]);

    return (
        <BaseModal isOpen={isOpen} onClose={onClose} content={{width: "900px"}}>
            <section className="modal-body">
                <Header name={"Add camera"} onClose={onClose}/>
                <div className="wizard-tabs-wrapper">
                    {steps.map((item, idx) => (
                      <div key={idx} className={cx("wizard-step-wrapper", {
                          activeStep: step === item.step,
                          savedStep: currentStepIdx > idx
                      })}>
                          <div className="wizard-step-dot">
                              {currentStepIdx >= idx
                                ? <img className="wizard-step-check" src={step === item.step ? pencil : checkRed} alt="+"/>
                                : idx + 1
                              }
                          </div>
                          <span className="wizard-step-label">
                              {item.label}
                          </span>
                      </div>
                    ))}
                </div>
                <DrawStep
                    onInit={onInit}
                    settings={settings}
                    prevStep={prevStep}
                    currentStep={step}
                    finishLoader={loading}
                    subscriptionList={subscriptionList}
                    subscriptionGroups={subscriptionGroups}
                    info={info}
                    onNext={onNext}
                    onBack={onBack}
                    onFinish={onFinish}
                    onClose={onClose}
                />
            </section>
        </BaseModal>
    );
};


const DrawStep = ({currentStep, info, onNext, finishLoader, subscriptionList,
                      subscriptionGroups, onBack, onInit, settings, onFinish, onClose}) => {

    if (currentStep === "review") {
        return <Review onBack={onBack} loading={finishLoader} info={info} onFinish={onFinish} />
    }

    if (currentStep === "choose-subscription") {
        return <ChooseSubscription
          info={info} subscriptionList={subscriptionList}
          subscriptionGroups={subscriptionGroups} onNext={onNext} onBack={onBack}
        />
    }

    if (currentStep === "general") {
        return <AddCameraSettings key="general" values={settings.values} info={info} settings={settings.schema} onNext={onNext} onBack={onBack}/>
    }

    if (currentStep === "info") {
        return <CameraInfo onNext={onNext} onBack={onBack} camera={info.cameraInfo} info={info}/>
    }

    return <AddCamera info={info} onInit={onInit} onClose={onClose}/>
}

export default AddCameraModal;
