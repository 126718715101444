import axios from "./http-api-client";

export const FoldersAPI = {
  pinFolderCamera: async (folder, data) => {
    return axios.post(`/account-folders/${folder}/cameras/pin`, data);
  },

  unPinFolderCamera: async (folder, data) => {
    return axios.post(`/account-folders/${folder}/cameras/unpin`, data);
  },

  reorderPinFolderCamera: async (folder, data) => {
    return axios.post(`/account-folders/${folder}/cameras/reorder`, data);
  },
};
