import "./CameraView.scss";
import React, {useCallback, useMemo, useState} from "react";
import GoogleMapReact from "google-map-react";
import Nav from "./components/Nav";
import {useOutletContext} from "react-router-dom";
import EmptyMap from "./EmptyMap";
import Button from "../../components/buttons/Button";
import CameraLocationForm, { EMPTY_FORM_DATA } from './components/CameraLocationForm';
import MapMarker from '../../components/MapMarker/MapMarker';
import {AccountCameraAPI} from "../../api/account-camera";

const Map = () => {
  const [camera, setCamera, _isFetched, _suspended, refetch] = useOutletContext();
  const [isLoading, onChangeIsLoading] = useState(false);

  let coordinates = {};
  if (camera.latitude && camera.longitude) {
    coordinates = {
      lat: parseFloat(camera?.latitude),
      lng: parseFloat(camera?.longitude),
    };
  }

  const [manualMode, onChangeManualMode] = useState(false);
  const [manualLocation, onChangeManualLocation] = useState({
    lat: parseFloat(camera?.cameraLocation?.latitude || "0"),
    lng: parseFloat(camera?.cameraLocation?.longitude || "0"),
  });

  const setCoords = useCallback(({lat, lng}) => {
    onChangeManualLocation({lat, lng});
  }, []);

  const coordsAreCorrupted = useMemo(() => {
    return !Number(camera.latitude) && !Number(camera.longitude);
  }, [camera]);

  const coordsAreManual = useMemo(() => {
    return Number(camera?.cameraLocation?.latitude) && Number(camera?.cameraLocation?.longitude);
  }, [camera, coordsAreCorrupted]);

  const tooltipData = useMemo(() => {
    const sdCardData = camera?.usage?.stats?.sdCard;
    const sdCardInfo = `${sdCardData?.freeSpace}${sdCardData?.uom}/${sdCardData?.capacity}${sdCardData?.uom}`;
    return {
      name: camera?.name,
      battery: camera?.usage?.stats?.battery || 0,
      signal: camera?.usage?.stats?.signal || 0,
      sdCard: sdCardInfo,
      image: camera?.specs?.photo,
    }
  }, [camera]);

  const handleSaveNewLocation = useCallback(async () => {
    try {
      onChangeIsLoading(true);
      await AccountCameraAPI.setLocation(camera.id, {
        ...(camera.cameraLocation || EMPTY_FORM_DATA),
        latitude: `${manualLocation.lat}`,
        longitude: `${manualLocation.lng}`,
      });
      onChangeIsLoading(false);
      await refetch();
      onChangeManualMode(false);
    } catch (e) {
      onChangeIsLoading(false);
    }
  }, [camera, manualLocation]);

  const handleChangeToAutomatically = useCallback(async () => {
    try {
      onChangeIsLoading(true);
      await AccountCameraAPI.setLocation(camera.id, {
        ...(camera.cameraLocation || EMPTY_FORM_DATA),
        latitude: "0",
        longitude: "0",
      });
      onChangeManualLocation({
        lat: 0,
        lng: 0,
      });
      onChangeIsLoading(false);
      await refetch();
    } catch (e) {
      onChangeIsLoading(false);
    }
  }, [camera, manualLocation]);

  return (
    <>
      <Nav camera={camera} current={'map'}/>
      {coordinates ? (
        <section className="content-section camera-map">
          <div style={{height: '515px', width: '100%', position: "relative"}}>
            <GoogleMapReact
              bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAP_KEY}}
              defaultCenter={manualMode || coordsAreManual ? manualLocation : coordinates}
              center={manualMode || coordsAreManual ? manualLocation : coordinates}
              onClick={manualMode ? setCoords : undefined}
              defaultZoom={11}
            >
              <MapMarker
                disabledClickOnMarker
                lat={manualMode || coordsAreManual ? manualLocation.lat : coordinates.lat}
                lng={manualMode || coordsAreManual ? manualLocation.lng : coordinates.lng}
                isTooltipOpen={true}
                onSave={manualMode ? handleSaveNewLocation : undefined}
                tooltipData={tooltipData}
              />
            </GoogleMapReact>
          </div>
          {coordsAreCorrupted && !coordsAreManual && (
            <div className="corruptedLocationPlaceholder">
              <p>Camera location looks incorrect. Do you want to set it manually?</p>
              <Button
                color="grey"
                loader={isLoading}
                title={manualMode ? "Cancel" : "Pin location"}
                onClick={() => onChangeManualMode(!manualMode)}
              />
            </div>
          )}
          {coordsAreManual ? (
            <div className="corruptedLocationPlaceholder">
              <p>Camera location is setted manual. Do you want to change it to automatically?</p>
              <Button
                color="grey"
                title="Change"
                loader={isLoading}
                onClick={handleChangeToAutomatically}
              />
            </div>
          ) : null}
          <CameraLocationForm
            cameraId={camera?.id}
            cameraLocation={camera.cameraLocation}
            onUpdate={(cameraLocation) => setCamera({...camera, cameraLocation})}
          />
        </section>
      ) : (
        <section className="content-section no-body">
          <EmptyMap/>
        </section>
      )}
    </>
  );
};
export default Map;
