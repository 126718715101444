import "./GuestCameras.scss";
import React, {useCallback, useState} from "react";

//api
import {GuestAccountsKeysApi} from "../../api/guest-accounts-keys";

//components
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import GuestCamera from "./components/GuestCamera";

//modals
import AddKey from "../GuestAccounts/modals/AddKey";
import Header from "./components/Header/Header";
import fetcher from "../../helpers/fetcher";
import {guestQK} from "../../helpers/query-keys";

const GuestCameras = () => {
  const [addGuestAccount, setAddGuestAccount] = useState(false);
  const {data = {data: []}, isLoading, refetch} = fetcher(guestQK.cameras.queryKey, GuestAccountsKeysApi.list);
  const guestAccounts = data.data || [];

  const onKeyAddedClose = useCallback(async (isAdded = false) => {
    setAddGuestAccount(false);
    if (isAdded) {
      await refetch();
    }
  }, [refetch]);

  return (
    <section className="page-wrapper">
      <AddKey isOpen={addGuestAccount} onClose={onKeyAddedClose}/>
      <div className="page-content">
        <div className="page-header">
          <Header setAddGuestAccount={setAddGuestAccount}/>
        </div>
        <div className="content-section no-body">
          {isLoading && <LineWaveLoader/>}
          {guestAccounts &&
            guestAccounts.map((account) => <GuestAccount key={account.id} account={account}/>)}
        </div>
      </div>
    </section>
  );
};

const GuestAccount = ({account}) => {
  const {name, host, cameras} = account;

  return (
    <section className="subscribed-account-section">
      <div className="subscribed-account-header">
        <div className="subscribed-account-icon"/>
        <span className="subscribed-account-name">
                    {name} / {host.username}
                </span>
      </div>
      <div className="guest-cameras-section">
        {cameras.map((camera) => (
          <GuestCamera key={camera.id} camera={camera}/>
        ))}
      </div>
    </section>
  );
};

export default GuestCameras;
