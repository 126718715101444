import {
  useQuery,
  useInfiniteQuery
} from '@tanstack/react-query';
import {lastId, lastPhotoId} from "../components/helpers";

const fetcher = (key, fetchFunction, options = {}) => {
  return useQuery({
    queryKey: key,
    queryFn: fetchFunction,
    ...options
  });
}

export const photosFetcher = (key, fetchFunction, options = {}) => {
  return useInfiniteQuery({
    queryKey: key,
    queryFn: fetchFunction,
    getNextPageParam: (data) => {
      return lastPhotoId(data.list);
    },
    initialPageParam: undefined,
    ...options
  });
}

export const infinityListFetcher = (key, fetchFunction, options = {}) => {
  return useInfiniteQuery({
    queryKey: key,
    queryFn: fetchFunction,
    getNextPageParam: (data) => {
      return lastId(data.list);
    },
    initialPageParam: undefined,
    ...options
  });
}

export default fetcher;
