import "./CameraView.scss";
import React from "react";
import Nav from "./components/Nav";
import {useOutletContext} from "react-router-dom"

const Specs = () => {
    const [camera] = useOutletContext();

    const {specs} = camera;

    return (
        <>
            <Nav camera={camera} current={"specs"}/>
            <section className="content-section camera-specs-features">
                <div className="content-header">
                    <div className="page-sub-title">Features</div>
                </div>
                <FeaturesList features={specs?.features}/>
            </section>
            <section className="content-section camera-specs-description">
                <div className="content-header">
                    <div className="page-sub-title">Description</div>
                </div>
                {specs?.description ? <p>{specs.description}</p> : <p>No description for this model</p> }
            </section>
        </>
    );
};

const FeaturesList = ({features}) => {
    if (!features || features.length === 0) {
        return <p>No features description for this model</p>
    }

    return <ul>
        {features.map((item, index) => {
            return <li key={index}>{item}</li>
        })}
    </ul>
}

export default Specs;
