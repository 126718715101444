import "../CameraView.scss";
import React from "react";

const NotificationPhone = ({value, disabled, reason, onClick}) => {
    return (
        <div style={{display: 'flex', flexDirection: 'column', gap: 6}}>
            <div style={{display: 'flex', alignItems: 'center', gap: 12}}>
                <input type="text" readOnly={true} className="input" value={value} disabled={disabled} />
                <div className="icon icon-btn icon-trash" onClick={onClick} />
            </div>
            {disabled && reason && (
                <span style={{color: '#e42f31', fontSize: 12}}>{reason}</span>
            )}
        </div>
    );
};

export default NotificationPhone;
