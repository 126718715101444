import React, {useState} from "react";
import {FoldersAPI} from "../../../api/account-folders";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import {useNavigate} from "react-router-dom";
import CameraStandard from "../../../components/DnD/DraggableCardCamera";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

const FolderItem = ({folder, getList, onChangePin, editFolder, deleteFolder}) => {
  const navigate = useNavigate();

  const [camerasPin, setCamerasPin] = useState([]);

  const viewFolderGallery = (folder) => {
    navigate(`folders/${folder.id}/photos`);
  };

  const pinCamera = async (camera) => {
    if (camera.pinned) {
      await postUnPinCamera(camera.id);
    } else {
      await postPinCamera(camera.id);
    }
  };

  const postPinCamera = async (camera) => {
    const data = {
      camera: camera,
    };
    try {
      await FoldersAPI.pinFolderCamera(folder.id, data);
      const cameras = folder.cameras.map((item) => {
        if (item.id === camera) {
          return {
            ...item,
            pinned: true
          }
        }
        return item;
      });
      onChangePin({
        ...folder,
        cameras
      });
    } catch (e) {

    }
  };

  const postUnPinCamera = async (camera) => {
    const data = {
      camera: camera,
    };
    try {
      await FoldersAPI.unPinFolderCamera(folder.id, data);
      const cameras = folder.cameras.map((item) => {
        if (item.id === camera) {
          return {
            ...item,
            pinned: false
          }
        }
        return item;
      });
      onChangePin({
        ...folder,
        cameras
      });
    } catch (e) {

    }
  };

  const onMove = (item, index) => {
    let newArr = camerasPin.filter((i) => i.id !== item.id);
    let newItem = camerasPin.filter((i) => i.id === item.id);
    newArr.splice(index, 0, newItem[0]);
    setCamerasPin(newArr);
    postOnMove(newArr);
  };

  const postOnMove = async (arr) => {
    const arrId = arr.map((i) => i.id);
    const data = {
      cameras: arrId,
    };
    await FoldersAPI.reorderPinFolderCamera(folder.id, data);
    await getList();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <section className="folder-cameras-list">
        <div className="folder-header">
          <div className="folder-header-section">
            <div className="folder-icon"/>
            <span className="folder-name" onClick={() => viewFolderGallery(folder)}>
                        {getFolderName(folder.name, folder.parents)}
                    </span>
          </div>
          <div className="folder-header-section">
            <ButtonIcon
              onClick={() => viewFolderGallery(folder)}
              icon={"view"}
              title={"View"}
              color="btn-none"
            />
            <ButtonIcon onClick={() => editFolder(folder)} icon={"edit"} title={"Edit"} color="btn-none"/>
            <ButtonIcon onClick={() => deleteFolder(folder)} icon={"del"} title={"Delete"} color="btn-none"/>
          </div>
        </div>
        <div className="camera-list-cameras">
          {camerasPin.map((camera) => {
            return <CameraStandard key={`${folder.id}-${camera.imei}`} camera={camera} onPin={pinCamera}/>;
          })}
          {folder.cameras && folder.cameras.map((camera) => {
            return <CameraStandard key={`${folder.id}-${camera.imei}`} camera={camera} onPin={pinCamera}/>;
          })}
        </div>
      </section>
    </DndProvider>
  );
};

const getFolderName = (name, parents) => {
  if (parents.length) {
    return parents.map((item) => item.name).join(" / ") + " / " + name;
  }

  return name;
};

export default FolderItem;
