import "./EmptySearch.scss";
import React from "react";
import Button from "../buttons/Button";
import {useNavigate} from "react-router-dom";

const EmptySearch = () => {
    const navigate = useNavigate();

    const onBackHandler = () => {
        navigate("/");
    };

    return (
        <div className="empty-search">
            <div className="empty-search-image"/>
            <p className="empty-search-title">Sorry, we didn't find any cameras matching your search</p>
            <p className="empty-search-comment">Check your request and try again</p>
            <div className="button-back">
                <Button color="grey" title={"My cameras"} icon={"arrow"} onClick={onBackHandler} />
            </div>
        </div>
    );
};

export default EmptySearch;
