import React from "react";
import ButtonLg from "../../../components/buttons/ButtonLg";

const Header = ({setCreateGuestAccount, setAddGuestAccount}) => {
    return (
        <div className="page-header">
            <div className="page-title">Guest Accounts</div>
            <div className="page-header-controls">
                <ButtonLg
                    onClick={() => setCreateGuestAccount(true)}
                    title={"Create guest account"}
                    color="grey"
                />
                <ButtonLg
                    onClick={() => setAddGuestAccount(true)}
                    title={"Add"}
                    icon={"key"}
                    color="grey"
                />
            </div>
        </div>
    );
};

export default Header;
