import "./Modals.scss";
import React, {useState} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import { SessionsAPI } from "../../../api/sessions";

const DeleteDevice = ({id, isOpen, onClose, onSuccessDelete}) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDelete = async () => {
        setIsLoading(true);
        const response = await SessionsAPI.delete(id);
        setIsLoading(false);

        if (response.status === 204) {
            onSuccessDelete();
        }
    }

    const closeModal = () => {
        onClose();
    }

    return (
        <BaseModal isOpen={isOpen} onClose={closeModal}>
            <section className="modal-body create-guest-account-modal">
                <Header name={"Remove device"} onClose={closeModal}/>
                <div className="modal-content">
                    <p className="modal-text">Are you sure that you want to remove this device? This action cannot be undone.</p>
                </div>
                <div className="modal-buttons">
                    <Button onClick={closeModal} title={"Cancel"} color={"outline"}/>
                    {
                        isLoading
                            ? <Button title={"Please wait..."} disabled={true} color={"grey"} />
                            : <Button title={"Remove"} color={"grey"} onClick={handleDelete} />
                    }
                </div>
            </section>
        </BaseModal>
    );
};
export default DeleteDevice;
