import "./App.scss";
import {Route, Routes, BrowserRouter} from "react-router-dom";
import CameraList from "./CameraList/CameraList";
import Header from "../components/Header/Header";
import React from "react";
import ProfileInfo from "./Profile/ProfileInfo";
import GuestCameras from "./GuestCameras/GuestCameras";
import GuestAccounts from "./GuestAccounts/GuestAccounts";
import Notifications from "./CameraSettings/Notifications";
import Info from "./CameraSettings/Info";
import Settings from "./CameraSettings/Settings";
import Usage from "./CameraSettings/Usage";
import Commands from "./CameraSettings/Commands";
import Map from "./CameraSettings/Map";
import Specs from "./CameraSettings/Specs";
import Billing from "./Profile/Billing";
import Subscriptions from "./Profile/Subscriptions";
import ProfileIndex from "./Profile/ProfileIndex";
import Addons from "./Profile/Addons";
import Sessions from "./Profile/Sessions";
import Footer from "../components/Footer/Footer";
import CameraIndex from "./CameraSettings/CameraIndex";
import GuestCameraPhotosList from "./GuestCameras/GuestCameraPhotosList";
import GuestCameraPhotoView from "./GuestCameras/GuestCameraPhotoView";
import GuestCameraIndex from "./GuestCameras/GuestCameraIndex";
import PhotosList from "./CameraPhotos/PhotosList";
import PhotoView from "./CameraPhotos/PhotoView";
import CameraPhotosIndex from "./CameraPhotos/CameraPhotosIndex";
import NotFoundError from "./ErrorPages/NotFoundError";
import ServerError from "./ErrorPages/ServerError";
import GalleryPhotosList from "./Gallery/GalleryPhotosList";
import GalleryPhotoView from "./Gallery/GalleryPhotoView";
import FolderPhotosIndex from "./FolderPhotos/FolderPhotosIndex";
import FolderPhotosList from "./FolderPhotos/FolderPhotosList";
import FolderPhotoView from "./FolderPhotos/FolderPhotoView";
import Onx from "./Profile/Onx";
import BillingStats from "./CameraSettings/BillingStats";
import MapPage from "./Map/Map";
import StartPage from "./Start";
import LoginPage from "./Start/login";
import 'mac-scrollbar/dist/mac-scrollbar.css';
import {GlobalScrollbar} from "mac-scrollbar";
import PrivateRoute, { AuthRoute } from "../components/private-route";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfirmationModalContextProvider from "../context/confirmation";
import RegisterPage from "./Start/register";
import SupportSignIn from "./Support/SignIn";
import PaymentInfo from "./Profile/PaymentInfo";
import PasswordChange from "./Profile/PasswordChange";
import CameraStream from "./CameraPhotos/Stream";
import CameraWeatherIndex from "./CameraWeather";
import CameraWeather from "./CameraWeather/Weather";
import LinkedCameras from "./CameraSettings/LinkedCameras";
import AuthContextProvider from "../context/auth";
import ErrorsContextProvider from "../context/errors";
import ReportsRecognitionIndex from "./Reports/recognition";
import ReportsGeneralIndex from "./Reports/general";
import CameraDebugLogs from "./CameraSettings/DebugLogs";
import RecognitionGraphReports from "./Reports/recognition/graph";
import GeneralGraphReports from "./Reports/general/graph";
import CameraReportsIndex from "./CameraSettings/Reports";
import CameraReport from "./CameraSettings/Reports/Reports";

function App() {
  return (
    <>
      <div className="container">
        <AuthContextProvider>
          <ConfirmationModalContextProvider>
            <ErrorsContextProvider>
              <BrowserRouter basename="app">
                <Header/>
                <Routes>
                  <Route path="/start" element={<AuthRoute><StartPage/></AuthRoute>}/>
                  <Route path="/login" element={<AuthRoute><LoginPage/></AuthRoute>}/>
                  <Route path="/register" element={<AuthRoute><RegisterPage/></AuthRoute>}/>
                  <Route path="/support/sign-in" element={<SupportSignIn/>}/>
                  <Route path="/" element={<PrivateRoute><CameraList/></PrivateRoute>}/>

                  {/* error pages */}
                  <Route path="/not-found-page" element={<NotFoundError/>}/>
                  <Route path="/server-error-page" element={<ServerError/>}/>

                  {/* camera page */}
                  <Route path="camera/:id" element={<PrivateRoute><CameraIndex/></PrivateRoute>}>
                    <Route index element={<Info/>}/>
                    <Route path="info" element={<Info/>}/>
                    {/* recognition report */}
                    <Route path="reports/recognition" element={<CameraReportsIndex route="recognition" key={"camera-stats-recognition"}/>}>
                      <Route path=":type" element={<CameraReport route="recognition"/>}/>
                    </Route>
                    {/* general report */}
                    <Route path="reports/general" element={<CameraReportsIndex route="general" key={"camera-stats-general"}/>}>
                      <Route path=":type" element={<CameraReport route="general"/>}/>
                    </Route>
                    <Route path="commands" element={<Commands/>}/>
                    <Route path="map" element={<Map/>}/>
                    <Route path="notifications" element={<Notifications/>}/>
                    <Route path="settings" element={<Settings/>}/>
                    <Route path="specs" element={<Specs/>}/>
                    <Route path="usage" element={<Usage/>}/>
                    <Route path="billing" element={<BillingStats/>}/>
                    <Route path="linked" element={<LinkedCameras/>}/>
                    <Route path="debug" element={<CameraDebugLogs/>}/>
                  </Route>

                  {/* All photos gallery */}
                  <Route path="gallery" element={<PrivateRoute><GalleryPhotosList/></PrivateRoute>}/>
                  <Route path="gallery/:id" element={<PrivateRoute><GalleryPhotoView/></PrivateRoute>}/>

                  <Route path="cameras/:id/stream" element={<PrivateRoute><CameraPhotosIndex streams/></PrivateRoute>}>
                    <Route index element={<CameraStream/>} />
                  </Route>
                  <Route path="cameras/:id/weather" element={<PrivateRoute><CameraWeatherIndex/></PrivateRoute>}>
                    <Route path=":weatherType" element={<CameraWeather/>} />
                  </Route>
                  <Route path="cameras/:id/photos" element={<PrivateRoute><CameraPhotosIndex/></PrivateRoute>}>
                    <Route index element={<PhotosList/>}/>
                    <Route path=":photoId" element={<PhotoView/>}/>
                  </Route>
                  {/* Folder photos gallery */}
                  <Route path="folders/:id/photos" element={<PrivateRoute><FolderPhotosIndex/></PrivateRoute>}>
                    <Route index element={<FolderPhotosList/>}/>
                    <Route path=":photoId" element={<FolderPhotoView/>}/>
                  </Route>

                  {/* profile */}
                  <Route path="profile" element={<PrivateRoute><ProfileIndex/></PrivateRoute>}>
                    <Route path="info" element={<ProfileInfo/>}/>
                    <Route path="billing" element={<Billing/>}/>
                    <Route path="payment" element={<PaymentInfo/>}/>
                    <Route path="password" element={<PasswordChange/>}/>
                    <Route path="subscriptions" element={<Subscriptions/>}/>
                    <Route path="addons" element={<Addons/>}/>
                    <Route path="sessions" element={<Sessions/>}/>
                    <Route path="onx" element={<Onx/>}/>
                  </Route>

                  {/* recognition report */}
                  <Route path="reports/recognition" element={<PrivateRoute><ReportsRecognitionIndex/></PrivateRoute>}>
                    <Route path=":type" element={<RecognitionGraphReports/>}/>
                  </Route>
                  {/* general report */}
                  <Route path="reports/general" element={<PrivateRoute><ReportsGeneralIndex/></PrivateRoute>}>
                    <Route path=":type" element={<GeneralGraphReports/>}/>
                  </Route>

                  {/* guestCameras */}
                  <Route path="guest-accounts" element={<PrivateRoute><GuestAccounts/></PrivateRoute>}/>
                  <Route path="guest-cameras" element={<PrivateRoute><GuestCameras/></PrivateRoute>}/>

                  <Route path="guest-cameras/:id/photos" element={<PrivateRoute><GuestCameraIndex/></PrivateRoute>}>
                    <Route index element={<PrivateRoute><GuestCameraPhotosList/></PrivateRoute>}/>
                    <Route path=":photoId" element={<PrivateRoute><GuestCameraPhotoView/></PrivateRoute>}/>
                  </Route>

                  <Route path="map" element={<PrivateRoute><MapPage/></PrivateRoute>}/>

                  <Route path="*" element={<NotFoundError/>}/>
                </Routes>
                <Footer/>
              </BrowserRouter>
            </ErrorsContextProvider>
          </ConfirmationModalContextProvider>
        </AuthContextProvider>
      </div>
      <ToastContainer/>
      <GlobalScrollbar/>
    </>
  );
}

export default App;
