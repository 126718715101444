import React from "react";
import cx from "classnames";
import moment from "moment";
import WeatherIcon from "../WeatherIcon";
import "./weather.scss";

const PhotoWeather = ({ className, short, weather = {} }) => {
  return (
    <div className={cx("photo-weather-data", className)}>
      <div className="photo-weather-col">
        <div className="photo-weather-row">
          <WeatherIcon code={weather?.weatherCode?.code} className="photo-weather-icon" />
          <strong>{weather.temperature}</strong>
        </div>
        <div className="photo-weather-range-temp">
          <span>Hi <strong>{weather?.temperatureMax || "N/A"}</strong></span>
          <span>Lo <strong>{weather?.temperatureMin || "N/A"}</strong></span>
        </div>
      </div>
      {short ? null : (
        <>
          <div className="photo-weather-col">
            <div className="photo-weather-row">
              <WeatherIcon icon="wind" className="photo-weather-icon" />
              <strong className="mr4">{weather?.windDirection}</strong> <strong>{weather?.windSpeed}</strong>
            </div>
            <div className="photo-weather-row">
              <WeatherIcon icon="precipitation" className="photo-weather-icon" />
              <strong>{weather?.precipitationProbability || "N/A"}</strong>
            </div>
          </div>

          <div className="photo-weather-col">
            <div className="photo-weather-row">
              <WeatherIcon icon="thermometer" className="photo-weather-icon" />
              <strong>{weather?.surfacePressure || "N/A"}</strong>
            </div>
            <div className="photo-weather-row">
              <WeatherIcon icon="humidity" className="photo-weather-icon" />
              <strong>{weather?.relativeHumidity || "N/A"}</strong>
            </div>
          </div>
        </>
      )}
      <div className="photo-weather-col">
        <div className="photo-weather-row">
          <WeatherIcon icon="sunrise" className="photo-weather-icon" />
          <div className="photo-weather-row-text">
            <small>Sunrise</small>
            <strong>{weather?.sunrise ? moment(weather?.sunrise).format("hh:mma") : "N/A"}</strong>
          </div>
        </div>
        <div className="photo-weather-row">
          <WeatherIcon icon="sunset" className="photo-weather-icon" />
          <div className="photo-weather-row-text">
            <small>Sunset</small>
            <strong>{weather?.sunset ? moment(weather?.sunset).format("hh:mma") : "N/A"}</strong>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoWeather;
