import React from "react";
import Nav from "../../../components/Nav/Nav";
import {Tooltip} from "react-tooltip";

const profileNav = [
    {name: "Profile Info", alias: "info"},
    {name: "Subscriptions", alias: "subscriptions"},
    {name: "Addons", alias: "addons"},
    {name: "Billing", alias: "billing"},
    {name: "Payment Information", alias: "payment"},
    {name: "Password Change", alias: "password"},
    {name: "Sessions", alias: "sessions"},
    {name: "onX", alias: "onx"},
];

const Header = ({current, setCurrent, profile}) => {

    const enabledButAuthRequired = profile.onx?.enabled && profile.onx?.authRequired;
    const {creditCardStatus} = profile;
    const creditCardIssue = creditCardStatus && creditCardStatus.status === 'failed';

    const list = profileNav.map(item => {
       if (item.alias === 'onx' && enabledButAuthRequired) {
           return {...item, ...{icon: "warning", tooltip: {
               id: "profile-nav-tooltip",
               content: "onX hunt authorization required"
           }}};
       }

       if (item.alias === "billing" && creditCardIssue) {
           return {...item, ...{icon: "warning", tooltip: {
               id: "profile-nav-tooltip",
               content: "Credit card issue"
           }}};
       }

       return item;
    });

    return (
        <>
            <div className="page-title">My profile</div>
            <Nav list={list} setCurrent={setCurrent} basePath={"/profile"} current={current} />
            <Tooltip id={"profile-nav-tooltip"} />
        </>
    );
};

export default Header;
