import React from "react";
import { Link } from "react-router-dom";
import logo from "./logo.svg";
import "./login.scss";

const StartPage = () => {
  return (
    <div className="wrapper start-page">
      <form className="form form-def login-form js-login-form show-form">
        <section className="signin-block">
          <div className="signin-logo">
            <img src={logo} alt="logo"/>
          </div>
          <div>
            <p className="signin-question">Have you signed up for data plan yet?</p>
            <Link to="/login">
              <button className="button--login-block">Yes, Log In</button>
            </Link>
            <p className="signin-question">OR</p>
            <a href='#' onClick={(e) => {
              e.preventDefault();
                window.location.href = `${window.location.protocol}//${window.location.host}/app-guest/start`
            }}>
              <button className="button--login-block bordered">Guest Login</button>
            </a>
            <p className="signin-question">OR</p>
            <Link to="/register">
              <button className="button--login-block bordered red">No, Sign Up now</button>
            </Link>
            <div className="signin-text-link-wrapper">
              Need a camera? <a className="signin-text-link" target="_blank" href="https://www.feradyne.com/brands/covert-scouting-cameras/">Visit our website</a>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}

export default StartPage;
