import React, {useMemo} from "react";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import WeatherTable from "./table";
import {CamerasAPI} from "../../api/cameras";
import Spinner from "../../components/CameraPreview/Spinner";

const HourlyWeather = ({camera}) => {
  const qk = camerasSettings.hourlyForecastWeather(camera.id).queryKey;
  const { data = { data: { hourly: {}}}, isLoading} = fetcher(qk,  () => CamerasAPI.cameraHourlyForecastWeather(camera.id));

  const list = useMemo(() => {
    return Object.values(data.data.hourly || {});
  }, [data]);

  return (
    <section className="content-section">
      <div className="content-header">
        <div className="page-sub-title">Hourly</div>
      </div>
      {isLoading ? <Spinner /> : <WeatherTable list={list}/>}
    </section>
  );
}

export default HourlyWeather;
