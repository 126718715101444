import axios from "./http-api-client";

export const AccountPhotosAPI = {
    listByDates: async (params = {}, fromId) => {
        return axios.get(`account/photos/by-dates${fromId ? `?fromId=${fromId}` : ""}`, {
            params
        });
    },

    view: async (photoId, params = {}, cameras) => {
        return axios.get(`account/photos/${photoId}${cameras ? `?${cameras}` : ""}`, {
            params: params
        });
    },

    remove: async (photoId) => {
        return axios.delete(`account/photos/${photoId}`);
    },

    removeBatch: async (photoIds = []) => {
        return axios.delete(`account/photos`, {
            data: {photos: photoIds}
        });
    },

    like: async (photoId) => {
        return axios.put(`account/photos/${photoId}/like`);
    },

    unLike: async (photoId) => {
        return axios.put(`account/photos/${photoId}/unlike`);
    },

    batchLike: async (photoIds) => {
        return axios.post(`account/photos/like`, {
            photos: photoIds
        });
    },

    batchUnLike: async (photoIds) => {
        return axios.post(`account/photos/unlike`, {
            photos: photoIds
        });
    },

    command: async (photoId, command) => {
        return axios.post(`account/photos/${photoId}/commands/${command}`);
    },

    zip: async (photoIds) => {
        return axios.post(`account/photos/zip`, {
            photos: photoIds
        });
    }
};
