import "./GuestCameraPreview.scss";
import LineWaveLoader from "../../../../components/Loader/LineWaveLoader";
import React from "react";

const GuestCameraPreview = ({camera, loading}) => {
  return (
    <section className="guest-camera-info-preview">
      {loading
        ? <LineWaveLoader/>
        : (
          <>
            {camera.onxEnabled && <div className="card-item-onx-logo"/>}
            <div className="camera-photos">
              <div className="camera-photo">
                <img src={camera?.photo} alt=""/>
              </div>
            </div>
            <div className="camera-name">{camera?.name}</div>
            <div className="camera-info-section">
              <div className="camera-info-provider">
                {camera?.model} {camera?.type}: {camera?.meid}
              </div>
            </div>
          </>
        )}
    </section>
  );
};

export default GuestCameraPreview;
