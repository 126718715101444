import React from "react";
import cx from "classnames";
import DatePicker from "react-datepicker";
import { times } from "lodash";
import "react-datepicker/dist/react-datepicker.min.css";
import calendarIcon from "../../images/iconSVG/calendar-icon.svg";
import rightIcon from "../../images/iconSVG/icon-right.svg";
import leftIcon from "../../images/iconSVG/icon-left.svg";
import "./datepicker.scss";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = times(3000, i => i + 1);

const getYear = (date) => {
  return date.getFullYear();
}

const getMonth = (date) => {
  return date.getMonth();
}

const getMonthAndYear = (date) => {
  const month = date.getMonth();
  const year = date.getFullYear();
  return `${months[month]} ${year}`;
}

const CustomHeader = (props) => {
  const {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  } = props;
  return (
    <div className="cov-datepicker-header">
      <div className="cov-datepicker-header-title">{getMonthAndYear(date)}</div>
      <div className="cov-datepicker-header-controls">
        <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
          <img src={leftIcon} alt="left" draggable={false} />
        </button>
        <div className="cov-datepicker-header-controls-selects">
          <select
            value={getYear(date)}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            value={months[getMonth(date)]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
          <img src={rightIcon} alt="right" draggable={false} />
        </button>
      </div>
    </div>
  );
}

const CovertDatePicker = ({ value, name, range, placeholder = "Select date range", className, onChange }) => {
  return (
    <div className={cx("cov-datepicker-wrapper", {isRange: range}, className)}>
      <DatePicker
        showYearDropdown
        showMonthDropdown
        name={name}
        renderCustomHeader={CustomHeader}
        selectsRange={range}
        startDate={range ? value[0] : null}
        endDate={range ? value[1] : null}
        placeholderText={placeholder}
        selected={range ? value[0] : value}
        className="cov-datepicker"
        onChange={onChange}
      />
      <img className="cov-datepicker-icon" src={calendarIcon} alt="datepicker" draggable={false}/>
    </div>
  )
}

export default CovertDatePicker;
