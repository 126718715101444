import "./cameraSettingsInput.scss";
import React from "react";

const Timer = ({name, value, onChange, disabled = false}) => {

  return (
    <input type="time" className="input" name={name} value={value} onChange={onChange} disabled={disabled}/>
  );
};

export default Timer;
