import "./CameraView.scss";
import React, {useCallback, useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import "./info.scss";
import Nav from "./components/Nav";
import Button from "../../components/buttons/Button";

import {AccountCameraAPI} from "../../api/account-camera";
import {useConfirmationModalContext} from "../../context/confirmation";
import status from "./../../helpers/status";

const Info = () => {
    const [camera, setCamera, _isLoading, suspended] = useOutletContext();
    const { showConfirmation } = useConfirmationModalContext();

    const onDeactivate = useCallback(async () => {
        const confirm = await showConfirmation("Are you sure you want to deactivate current camera?", true);
        if (!confirm) return;
        try {
            await AccountCameraAPI.deactivateCamera(camera.id);
            setCamera({
                ...camera,
                status: status.STATUS_NOT_ACTIVE
            });
        } catch {

        }
    }, [setCamera]);

    const onSuspend = useCallback(async () => {
        const confirm = await showConfirmation("Are you sure you want to suspend current camera?", true);
        if (!confirm) return;
        try {
            await AccountCameraAPI.suspendCamera(camera.id);
            setCamera({
                ...camera,
                status: status.STATUS_SUSPEND
            });
        } catch {

        }
    }, [camera, setCamera]);

    const onRestore = useCallback(async () => {
        const confirm = await showConfirmation("Are you sure you want to activate current camera?");
        if (!confirm) return;
        try {
            await AccountCameraAPI.restoreCamera(camera.id);
            setCamera({
                ...camera,
                status: status.STATUS_ACTIVE
            });
        } catch {

        }
    }, [camera, setCamera]);

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({
        name: "",
        description: "",
        serialNumber: "",
    });
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        setForm({
            name: camera?.name,
            description: camera?.description,
            serialNumber: camera?.serialNumber
        });
    }, [camera]);

    useEffect(() => {
        checkIfDataChanged();
    }, [form, camera]);

    const checkIfDataChanged = () => {
        setIsChanged(
          form.name !== camera?.name ||
          form.description !== camera?.description ||
          form.serialNumber !== camera?.serialNumber
        );
    };

    const handleCancel = () => {
        setForm({
            name: camera?.name,
            description: camera?.description,
            serialNumber: camera?.serialNumber
        });
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);
            const {data} = await AccountCameraAPI.infoUpdate(camera.id, {
                info: {
                    name: form.name,
                    description: form.description,
                    serial: form.serialNumber
                },
            });
            setCamera(data);
            setIsLoading(false);
            setIsChanged(false);
        } catch (error) {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Nav camera={camera} current={"info"} />
            <section className="content-section thin-row">
                <div className="camera-status-wrapper">
                    <div className="camera-status-subtitle">
                        Status: <span className={`camera-status ${camera.status}`}>{camera.status}</span>
                    </div>
                    <div className="camera-status-change">
                        {
                            camera.status !== status.STATUS_NOT_ACTIVE
                              ? <Button color="red" title="Deactivate" onClick={onDeactivate} />
                              : null
                        }
                        {
                            suspended || camera.status === status.STATUS_NOT_ACTIVE
                              ? <Button title="Activate" color="green" onClick={onRestore} />
                              : <Button title="Suspend" color="grey" onClick={onSuspend} />
                        }
                    </div>
                </div>
            </section>
            <section className="content-section">
                <div className="content-header">
                    <div className="page-sub-title">Camera Info</div>
                </div>
                <form>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            className="input"
                            type={"text"}
                            placeholder={"Name"}
                            value={form.name}
                            onChange={(evt) => setForm({...form, name: evt.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label>Short Description</label>
                        <textarea
                            rows={5}
                            className="textarea"
                            placeholder={"Description"}
                            value={form.description}
                            onChange={(evt) => setForm({...form, description: evt.target.value})}
                        />
                    </div>
                    <div className="form-group">
                        <label>Serial Number</label>
                        <input
                          className="input"
                          type={"text"}
                          placeholder={"Serial Number"}
                          value={form.serialNumber}
                          onChange={(evt) => setForm({...form, serialNumber: evt.target.value})}
                        />
                    </div>
                </form>

                <div className="camera-model-info">
                    <div className="camera-model-info-item">
                        <div className="label">Provider Type</div>
                        <div className="value">{camera.type}</div>
                    </div>
                    <div className="camera-model-info-item">
                        <div className="label">Model</div>
                        <div className="value">{camera.specs?.model}</div>
                    </div>
                    <div className="camera-model-info-item">
                        <div className="label">MEID/IMEI</div>
                        <div className="value">{camera.meid}</div>
                    </div>
                    <div className="camera-model-info-item">
                        <div className="label">ICCID</div>
                        <div className="value">{camera.iccid}</div>
                    </div>
                </div>
                <div className="camera-model-info camera-model-info-row">
                    <div className="camera-model-info-item">
                        <div className="label">Firmware</div>
                        <div className="value">{camera?.firmware || "Unknown"}</div>
                    </div>
                </div>
                <div className="camera-view-footer">
                    <Button color={"outline"} title={"Cancel"} disabled={!isChanged} onClick={handleCancel} />
                    <Button
                        color={"grey"}
                        title={"Save"}
                        loader={isLoading}
                        disabled={!isChanged || suspended}
                        onClick={handleSave}
                    />
                </div>
            </section>
        </>
    );
};

export default Info;
