import React from "react";

const NoAccounts = () => {
    return (
        <div className="no-content">
            <div className="no-content-image">
                <div className="no-accounts-image"/>
            </div>
            <div className="no-content-text">
                <div className="no-content-title">You haven't created any guest accounts yet</div>
                <div className="no-content-subtitle">To create guest account use the button above</div>
            </div>
        </div>
    );
};

export default NoAccounts;
