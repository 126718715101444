import React from "react";
import {useLocation, useOutletContext, useParams} from "react-router-dom";
import Photo from "../../containers/photo";

const PhotoView = () => {
    let {photoId, id} = useParams();
    const [folder] = useOutletContext();
    const search = useLocation().search;
    const camerasId = folder?.cameras?.map((id) => id.id);
    const camerasFilters = camerasId?.map((id) => `filters[cameras][]=${id}`).join("&");

    return <Photo search={search} folder={folder} camerasFilters={camerasFilters} photoId={photoId} id={id} key={id} />

};

export default PhotoView;
