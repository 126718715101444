import "./Modals.scss";
import React, {useState} from "react";
import {foldersApi} from "../../../api/folders";
import Button from "../../../components/buttons/Button";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";

const DeleteFolder = ({folder, isOpen, onClose}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onConfirm = async () => {
        setIsLoading(true);

        await foldersApi.delete(folder.id);
        setIsLoading(false);
        onClose(true);
    };

    return (
        <BaseModal isOpen={isOpen} onClose={onClose}>
            <section className="modal-body delete-folder-modal">
                <Header name={"Delete folder"} onClose={onClose} />
                <div className="modal-content">
                    <p className="modal-text">
                        Are you sure that you want to delete folder? This action cannot be undone.
                    </p>
                </div>
                <div className="modal-buttons">
                    <Button onClick={onClose} title={"Cancel"} color={"outline"} />
                    <Button
                        onClick={onConfirm}
                        disabled={isLoading}
                        loader={isLoading}
                        title={"Delete"}
                        color={"grey"}
                    />
                </div>
            </section>
        </BaseModal>
    );
};
export default DeleteFolder;
