import "./Graphic.scss";
import {
  LineChart,
  Line,
  XAxis,
  Bar,
  BarChart,
  AreaChart,
  Legend,
  Area,
  Brush,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import LineWaveLoader from "../Loader/LineWaveLoader";
import colors from "../../helpers/colors";

const LineGraphic = ({data, width = 1080, height = 500, yLabel}) => {
  const labelFormatter = (_name, value) => {
    if (value[0]) return value[0].payload?.label;
    return "";
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 20, right: 20, bottom: 20, left: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis dataKey="x"/>
        <YAxis label={{value: yLabel, offset: 20, angle: -90}}/>
        <Tooltip labelFormatter={labelFormatter}/>
        <Line connectNulls={false} type="monotone" dataKey="y" stroke="#E42F31"/>
      </LineChart>
    </ResponsiveContainer>
  );
};

export const AreaGraphic = (props) => {
  const {loading, labels = undefined, width = 1080, color = "#E42F31", data, x, yy = [], y, height = 500} = props;
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0] || {payload: {}};
      const {label, ...other} = data.payload;
      return (
        <div className="custom-graph-tooltip">
          <label><strong>{label}</strong></label>
          <ul>
            {Object.keys(other).map((key) => (
              <li key={key}><strong>{labels[key]}</strong>: {other[key]}</li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const legendFormatter = (value) => {
    return labels[value];
  }

  if (loading) {
    return (<div style={{height}} className="graphic-loader-wrapper"><LineWaveLoader/></div>);
  }

  return (
    <div className="bar-chart-wrapper">
      <AreaChart width={width} height={height} data={data}
                 margin={{top: 10, right: 30, left: 0, bottom: 0}}>
        <defs>
          {yy.length > 0 ? yy.map((item, idx) => (
              <linearGradient key={idx} id={`${idx}-color`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={colors[idx]} stopOpacity={1}/>
                <stop offset="95%" stopColor={colors[idx]} stopOpacity={0.3}/>
              </linearGradient>
            ))
            : (
              <linearGradient id={`${y}-color`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={color || colors[0]} stopOpacity={1}/>
                <stop offset="95%" stopColor={color || colors[0]} stopOpacity={0.3}/>
              </linearGradient>
            )
          }
        </defs>
        <XAxis dataKey={x}/>
        <YAxis/>
        <Legend verticalAlign="bottom" formatter={labels ? legendFormatter : undefined} height={36}/>
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip
          content={labels ? <CustomTooltip/> : undefined}
        />
        {yy.length > 0 ?
          yy.map((item, idx) => (
            <Area type="monotone" key={idx} dataKey={item.key} stroke={colors[idx]} fillOpacity={1}
                  fill={`url(#${idx}-color)`}/>
          ))
          : <Area type="monotone" dataKey={y} stroke={color || colors[0]} fillOpacity={1} fill={`url(#${y}-color)`}/>
        }
        <Brush dataKey={x} height={30} stroke="#E42F31" />
      </AreaChart>
    </div>
  );
}

export const BarGraphic = (props) => {
  const {loading, labels = undefined, width = 1080, color = "#E42F31", data, x, yy = [], y, height = 500} = props;

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0] || {payload: {}};
      const {label, ...other} = data.payload;
      return (
        <div className="custom-graph-tooltip">
          <label><strong>{label}</strong></label>
          <ul>
            {Object.keys(other).map((key) => (
              <li key={key}><strong>{labels[key]}</strong>: {other[key]}</li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const legendFormatter = (value) => {
    return labels[value];
  }

  if (loading) {
    return (<div style={{height}} className="graphic-loader-wrapper"><LineWaveLoader/></div>);
  }
  return (
    <div className="bar-chart-wrapper">
      <BarChart width={width} height={height} data={data} barWidth={30}
                margin={{top: 10, right: 30, left: 0, bottom: 0}}>
        <defs>
          {yy.length > 0 ? yy.map((item, idx) => (
              <linearGradient key={idx} id={`${idx}-color`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={colors[idx]} stopOpacity={1}/>
                <stop offset="95%" stopColor={colors[idx]} stopOpacity={0.3}/>
              </linearGradient>
            ))
            : (
              <linearGradient id={`${y}-color`} x1="0" y1="0" x2="0" y2="1">
                <stop offset="20%" stopColor={color || colors[0]} stopOpacity={1}/>
                <stop offset="95%" stopColor={color || colors[0]} stopOpacity={0.3}/>
              </linearGradient>
            )
          }
        </defs>
        <XAxis dataKey={x}/>
        <YAxis/>
        <Legend verticalAlign="bottom" formatter={labels ? legendFormatter : undefined} height={36}/>
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip
          content={labels ? <CustomTooltip/> : undefined}
        />
        {yy.length > 0 ?
          yy.map((item, idx) => (
            <Bar type="monotone" key={idx} barSize={30} dataKey={item.key} stroke={colors[idx]} fillOpacity={1}
                 fill={`url(#${idx}-color)`}/>
          ))
          : <Bar type="monotone" dataKey={y} stroke={color || colors[0]} fillOpacity={1} fill={`url(#${y}-color)`}/>
        }
        <Brush dataKey={x} height={30} stroke="#E42F31" />
      </BarChart>
    </div>
  );
}

export default LineGraphic;
