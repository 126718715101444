import axios from "./http-api-client";

export const GuestAccountsKeysApi = {
  list: async () => {
    return axios.get(
        `/account/keys`
    );
  },

  attach: async (data) => {
    return axios.post(
      `/account/keys`,
     data);
  },

  view: async (id) => {
    return axios.get(`/account/keys/${id}`);
  },

  delete: async (id) => {
    return axios.delete(`/account/keys/${id}`);
  },
};
