import React, { createElement } from "react";
import Nav from "../../components/Nav/Nav";
import Today from "./today";
import Hourly from "./hourly";
import Daily from "./daily";
import {useOutletContext, useParams} from "react-router-dom";

const CameraWeather = () => {
  const {camera} = useOutletContext();
  const {weatherType = "today"} = useParams();

  const tabs = [
    {name: "Today", alias: "today"},
    {name: "Hourly", alias: "hourly"},
    {name: "Daily", alias: "daily"},
  ];

  const components = {
    today: Today,
    hourly: Hourly,
    daily: Daily,
  };

  return (
    <>
      <Nav list={tabs} current={weatherType} basePath={`/cameras/${camera.id}/weather/`} />
      {components[weatherType] && createElement(components[weatherType], { camera })}
    </>
  );
}

export default CameraWeather;
