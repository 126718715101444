import "../CameraList.scss";
import React from "react";

const EmptyFolder = () => {
  return (
    <div className="empty-folder">
      <div className="empty-folder-image"></div>
      <p>You haven't created any folders yet</p>
      <span>
        To create a folder, use the button above, name the folder and add
        cameras to it
      </span>
    </div>
  );
};

export default EmptyFolder;
