import "./Search.scss";
import React, {useEffect, useState} from "react";
import SearchIcon from "../../../../images/iconSVG/elements/search.svg";
import debounce from "lodash/debounce";
import {useSearchParams} from "react-router-dom";

const Search = () => {
    const [value, setValue] = useState("");
    const [searchParams, setSearchParams] = useSearchParams();
    const search = searchParams.get("search") || "";

    useEffect(() => {
        setValue(search);
    }, [search]);

    const debouncedInputChange = React.useCallback(
        debounce((str) => {
            if (str.length >= 3) {
                setSearchParams("search=" + str);
            }

            if (str.length === 0) {
                setSearchParams("");
            }
        }, 1000),
        [setSearchParams],
    );

    const handleInputChange = (evt) => {
        setValue(evt.target.value);
        debouncedInputChange(evt.target.value);
    };

    return (
        <div className="search">
            <input
                value={value}
                onChange={handleInputChange}
                className="search-input"
                type="text"
                placeholder="Search..."
            />
            <img className="search-icon" src={SearchIcon} alt="settings" />
        </div>
    );
};

export default Search;
