import React from "react";

const Filters = ({view, onSetViewClick}) => {
    return (
        <section className="photo-filters">
                <div className="photo-filters-left"/>
                <div className="photo-filters-right">
                    <div className="photo-list-size">
                        <div className={`icon icon-cards ${view === "three" ? "active" : ""}`} onClick={() => {
                            onSetViewClick("three");
                        }}/>
                        <div className={`icon icon-rows ${view === "two" ? "active" : ""}`} onClick={() => {
                            onSetViewClick("two");
                        }}/>
                    </div>
                </div>
        </section>
    );
};

export default Filters;
