import "./cameraSettingsInput.scss";
import React  from "react";
import RadioButton from "../RadioButton/RadioButton";

const RadioSelect = ({ name, options, value, onChange, disabed= false }) => {
  if (Array.isArray(options)) {
    return options.map((option, index) => {
      return <RadioButton key={`${option}-${index}`} checked={index == value} name={name} title={option} value={index} handleChange={onChange} disabed={disabed} />
    });
  }

  return Object.keys(options).map(key => {
    const option = options[key];

    return <RadioButton key={`${key}-${option}`} checked={key == value} name={name} title={option} value={key} handleChange={onChange} disabled={disabed} />
  })
};

export default RadioSelect;
