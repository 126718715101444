import "../CameraList.scss";
import React, {useMemo} from "react";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import Dnd from "../../../components/DnD";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import CardCamera from "../../../components/DnD/DraggableCardCamera";

const CardList = ({cameras, onChangeOrder, pinCamera, isLoading}) => {
  const defaultCameras = useMemo(() => cameras.filter((camera) => !camera.pinned), [cameras]);
  const pinnedCameras = useMemo(() => cameras.filter((camera) => camera.pinned), [cameras]);

  const handleChangeOrder = (pinnedCamerasOrder) => {
    onChangeOrder([...pinnedCamerasOrder, ...defaultCameras]);
  }

  return (
    <section className="camera-list">
      <DndProvider backend={HTML5Backend}>
        {cameras?.length !== 0 ? (
          <div>
            <div className="camera-list-cameras">
              <Dnd onChangeOrder={handleChangeOrder} cameras={pinnedCameras} row={false} onPin={pinCamera}/>
              {defaultCameras?.map((camera) => {
                return <CardCamera key={camera.imei} camera={camera} onPin={pinCamera}/>;
              })}
            </div>
          </div>
        ) : (
          <EmptyBlock text={!isLoading ? "" : "No Cameras"}/>
        )}
      </DndProvider>
    </section>
  );
};

export default CardList;
