import "./Header.scss";
import React from "react";
import HeaderTop from "./components/HeaderTop";
import HeaderMenu from "./components/HeaderMenu";
import {useLocation} from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const authRoutes = ["/start", "/register", "/login"];
  const isSupportRoute = location.pathname.includes("support");

  if (authRoutes.includes((location.pathname)) || isSupportRoute) return null;

  return (
    <div className="header">
      <HeaderTop />
      <hr className="header-divider"/>
      <HeaderMenu />
    </div>
  );
};

export default Header;
