import "./CameraPreview.scss";
import {Link} from "react-router-dom";
import React, {useMemo} from "react";
import Stats from "../Stats/Stats";
import SubscriptionStatus from "./SubscriptionStatus";
import status from "../../helpers/status";
import Spinner from "./Spinner";

const CameraPreview = ({camera, isSettings, loading}) => {

    const {latestFirmware, usage} = camera;
    const suspended = camera.status === status.STATUS_SUSPEND;
    const {stats, subscriptions} = (usage || {});
    const isOutdatedFirmware = latestFirmware && latestFirmware.currentState === "outdated";

    if (loading) {
      return (
        <section className="camera-info-preview">
          <Spinner />
        </section>
      );
    }

    const link = useMemo(() => {
      if (isSettings) {
        return {
          to: `/cameras/${camera?.id}/photos`,
          icon: "camera-item-photos-icon"
        };
      }

      return {
        to: `/camera/${camera?.id}/settings`,
        icon: "camera-item-controls-setting"
      }
    }, [camera, isSettings]);

    return (
        <section className="camera-info-preview">
            {camera.onxEnabled && <div className="card-item-onx-logo" />}
            {isOutdatedFirmware &&
                <div className="card-item-firmware-icon" data-tooltip-id={"camera-preview-outdated-firmware"} data-tooltip-content={"New firmware available"}/>
            }
            <div className="camera-photos">
                <div className="camera-photo">
                    <img src={camera?.specs?.photo} alt="" />
                </div>
                {suspended ? <span className="suspended-label">Suspended</span> : null}
            </div>
            <div className="camera-item-controls">
                <Link to={link.to}>
                    <div className={link.icon} />
                </Link>
            </div>
            <SubscriptionStatus closeOverLimit={subscriptions?.closeOverLimit} overPhotosLimit={subscriptions?.overPhotosLimit}/>
            <div className="camera-name">{camera?.name}</div>
            <div className="camera-info-section">
                <div className="camera-info-provider">
                    {camera?.specs?.model} {camera?.type}: {camera?.meid}
                </div>
            </div>
            <Stats stats={stats} />
            <div className="camera-info-last-seen">
                <div className="icon icon-clock" />
                <div className="camera-info-last-seen-time">{camera?.lastSeen}</div>
            </div>
        </section>
    );
};

export default CameraPreview;
