import "./GuestAccounts.scss";
import React, {useState} from "react";
import Header from "./components/Header";
import NoAccounts from "./components/NoAccounts";
import {GuestAccountsApi} from "../../api/guest-accounts";
import {GuestAccountsKeysApi} from "../../api/guest-accounts-keys";
import GuestAccountsList from "./components/GuestAccountsList";
import GuestAccountsKeysList from "./components/GuestAccountsKeysList";
import AddKey from "./modals/AddKey";
import CreateGuestAccount from "./modals/CreateGuestAccount";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import EditGuestAccount from "./modals/EditGuestAccount";
import ViewKey from "./modals/ViewKey";
import {camerasQK, guestQK} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import {useConfirmationModalContext} from "../../context/confirmation";
import {useQueryClient} from "@tanstack/react-query";
import {CamerasAPI} from "../../api/cameras";

const GuestAccounts = () => {
  const queryClient = useQueryClient();
  const {showConfirmation} = useConfirmationModalContext();
  const [addGuestAccount, setAddGuestAccount] = useState(false);
  const [createGuestAccount, setCreateGuestAccount] = useState(false);

  const [selectedGuestAccountId, setSelectedGuestAccountId] = useState(null);
  const [showEditGuestAccount, setShowEditGuestAccount] = useState(false);

  const [selectedKeyId, setSelectedKeyId] = useState(null);
  const [showViewKey, setShowViewKey] = useState(false);

  const {
    data: camerasData = {data: []},
  } = fetcher(camerasQK.list("").queryKey, () => CamerasAPI.listCamera(""));

  const {
    data: guestAccountsData = {data: []},
    ...guestAccountsQuery
  } = fetcher(guestQK.accounts.queryKey, GuestAccountsApi.list)
  const {
    data: guestAccountsKeysData = {data: []},
    ...guestAccountsKeysQuery
  } = fetcher(guestQK.keys.queryKey, GuestAccountsKeysApi.list)
  const guestAccounts = guestAccountsData.data || [];
  const guestAccountsKeys = guestAccountsKeysData.data || [];
  const isLoading = guestAccountsQuery.isLoading || guestAccountsKeysQuery.isLoading;
  const isEmptyList = guestAccountsQuery.isFetched && !guestAccounts.length;

  const onGuestAccountClick = (id) => {
    setSelectedGuestAccountId(id);
    setShowEditGuestAccount(true);
  };

  const onKeyClick = (id) => {
    setSelectedKeyId(id);
    setShowViewKey(true);
  };

  const onGuestAccountCreateClose = async (isCreated = false) => {
    setCreateGuestAccount(false);

    if (isCreated) {
      await guestAccountsQuery.refetch();
    }
  };

  const onEditGuestAccountClose = async (isUpdated = false) => {
    setShowEditGuestAccount(false);
    setSelectedGuestAccountId(undefined);

    if (isUpdated) {
      await guestAccountsQuery.refetch();
    }
  };

  const onKeyAddedClose = async (isAdded = false) => {
    setAddGuestAccount(false);

    if (isAdded) {
      await guestAccountsKeysQuery.refetch()
    }
  };

  const handleDeleteAccount = async (id) => {
    try {
      const result = await showConfirmation("Are you sure you want delete this guest account?", true);
      if (result) {
        await GuestAccountsApi.delete(id);
        const newGuestAccounts = guestAccounts.filter(item => item.id !== id);
        queryClient.setQueryData(guestQK.accounts.queryKey, {
          data: newGuestAccounts
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleDeleteKey = async (id) => {
    try {
      const result = await showConfirmation("Are you sure you want delete this guest key?", true);
      if (result) {
        await GuestAccountsKeysApi.delete(id);
        const newGuestKeys = guestAccountsKeys.filter(item => item.id !== id);
        queryClient.setQueryData(guestQK.keys.queryKey, {
          data: newGuestKeys
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  const onCloseViewKey = () => {
    setShowViewKey(false);
    setSelectedKeyId(undefined);
  };

  return (
    <section className="page-wrapper guest-accounts-content">
      <AddKey isOpen={addGuestAccount} onClose={onKeyAddedClose}/>
      {selectedKeyId ? <ViewKey isOpen={showViewKey} id={selectedKeyId} onClose={onCloseViewKey}/> : null}

      <CreateGuestAccount cameras={camerasData.data || []} isOpen={createGuestAccount} onClose={onGuestAccountCreateClose}/>

      {selectedGuestAccountId ?
        <EditGuestAccount
          isOpen={showEditGuestAccount}
          cameras={camerasData.data || []}
          id={selectedGuestAccountId}
          onClose={onEditGuestAccountClose}
        />
        : null}

      <div className="page-content">
        <Header setCreateGuestAccount={setCreateGuestAccount} setAddGuestAccount={setAddGuestAccount}/>
        <section className="content-section no-body">
          {isLoading && <LineWaveLoader/>}
          {isEmptyList ? (
            <NoAccounts/>
          ) : (
            !isLoading && (
              <>
                <GuestAccountsList onDelete={handleDeleteAccount} list={guestAccounts}
                                   onGuestAccountClick={onGuestAccountClick}/>
                <GuestAccountsKeysList onDelete={handleDeleteKey} list={guestAccountsKeys} onKeyClick={onKeyClick}/>
              </>
            )
          )}
        </section>
      </div>
    </section>
  );
};

export default GuestAccounts;
