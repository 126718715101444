import "./GuestAccountsList.scss";
import React, {useState, useCallback} from "react";
import Button from "../../../components/buttons/Button";
import {toast} from "react-toastify";

const GuestAccountsList = ({list, onDelete, onGuestAccountClick}) => {
  return (
    <div className="guest-accounts-list-section">
      <h4>Your guest key</h4>
      <div className="guest-accounts-list">
        {list.map(account => {
          return <GuestAccount key={account.id} account={account} onDelete={() => onDelete(account.id)}
                               onGuestAccountClick={() => onGuestAccountClick(account.id)}/>
        })}
      </div>
    </div>
  );
};


export default GuestAccountsList;

const GuestAccount = ({account, onDelete, onGuestAccountClick}) => {
  const [deleteLoading, onChangeDeleteLoading] = useState(false);

  const onCopy = useCallback(async () => {
    try {
      await window.navigator.clipboard.write(account.key);
      toast.info("Key copied to your clipboard");
    } catch (e) {

    }
  }, [account]);

  const handleDelete = useCallback(async () => {
    onChangeDeleteLoading(true);
    await onDelete();
    onChangeDeleteLoading(false);
  }, []);

  return (
    <div className="guest-account-item">
      <div className="guest-account-name">{account.name}</div>
      <div className="guest-account-key">
        <div className="guest-account-icon"/>
        <div className="guest-account-key-text">{account.key}</div>
      </div>
      <div className="guest-account-btns">
        <Button size="sm" title="Edit" color="grey" onClick={onGuestAccountClick}/>
        <Button size="sm" title="Copy" color="none" onClick={onCopy}/>
        <Button size="sm" loader={deleteLoading} title="Delete" color="red" onClick={handleDelete}/>
      </div>
    </div>
  );
}
