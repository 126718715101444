import "./CameraPhotos.scss";
import React, { useEffect, useMemo } from "react";
import {Link, Navigate, useLocation, useParams, useSearchParams} from "react-router-dom";
import {CamerasAPI} from "../../api/cameras";
import {CameraPhotosApi} from "../../api/account-camera-photos";
import CameraPreview from "../../components/CameraPreview/CameraPreview";
import {Outlet} from "react-router-dom";
import {CameraHeader} from "../../components";
import {Tooltip} from "react-tooltip";
import { last } from "lodash";
import {
    mergePhotoPages,
    pushToVisitedCamerasList,
    getVisitedCamerasList
} from "../../components/helpers";
import qs from "qs";
import status from "../../helpers/status";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher, { photosFetcher } from "../../helpers/fetcher";

const CameraPhotosIndex = ({ streams }) => {
    let {id, photoId} = useParams();
    const cameraInfoQK = camerasSettings.one(id).queryKey;
    const {data = { data: {}}, isLoading, isFetched, failureReason} = fetcher(cameraInfoQK, () => CamerasAPI.cameraInfo(id), {
        throwOnError: false,
    });
    const camera = data.data || {};
    const [searchParams] = useSearchParams();
    const params = qs.parse(searchParams.toString());
    const cameraPhotosQK = camerasSettings.onePhotos(id, params).queryKey;
    const { data: photosQueryData, refetch, fetchNextPage, isFetching, isLoading: photosIsLoading } = photosFetcher(cameraPhotosQK, async ({ pageParam }) => {
        const { data } = await CameraPhotosApi.listByDatesWithFromId(id, params, pageParam);
        return data;
    });
    const search = useLocation().search;
    const visitedCameras = getVisitedCamerasList().filter((item) => item.id !== camera.id);


    useEffect(() => {
        if (camera.id) pushToVisitedCamerasList(camera);
    }, [camera]);

    const loadMorePhotos = async () => {
        await fetchNextPage();
    }

    const suspended = camera.status === status.STATUS_SUSPEND;

    if (failureReason && failureReason.response && failureReason.response.status) {
        if (failureReason.response.status === 404) {
            return <Navigate to="/not-found-page" />
        }
        if (failureReason.response.status === 500) {
            return <Navigate to="/server-error-page" />
        }
    }

    const list = mergePhotoPages(photosQueryData?.pages);

    const meta = useMemo(() => {
        if (photosQueryData?.pages) {
            const tail = last(photosQueryData.pages);
            return tail._meta || {};
        }
        return {};
    }, [photosQueryData])

    return (
        <section className="page-wrapper">
            <div className="page-content">
                <CameraHeader camera={camera} loading={isLoading} streamsAllowed={camera.supportLiveMode} stream={streams} suspended={suspended} actions={camera.actions || {}} />
                <div className="camera-view">
                    <div className="camera-view-short">
                        <CameraPreview camera={camera} loading={isLoading} />
                        <BackLink id={id} photoId={photoId} search={search} />
                        <div className="visited-cameras">
                            {visitedCameras.map((visitedCamera, idx) => {
                                return (
                                    <CameraPreview
                                        key={idx}
                                        isSettings
                                        camera={visitedCamera}
                                        link={`/cameras/${visitedCamera.id}/${streams ? "stream" : "photos"}`}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    <div className="camera-view-content">
                        {isFetched && <Outlet context={{
                            camera,
                            isLoaded: isFetched,
                            fetchPhotos: refetch,
                            loadMorePhotos,
                            photosList: list,
                            photosListFetching: isFetching,
                            photosListIsLoading: photosIsLoading,
                            photosListMeta: meta
                        }} />}
                    </div>
                </div>
            </div>
            <Tooltip id={"camera-preview-outdated-firmware"} />
        </section>
    );
};

const BackLink = ({id, photoId, search = ""}) => {
    if (!photoId) {
        return (
            <Link to={"/"} className="btn btn-grey lg w100">
                <span className="btn-icon-arrow" />
                <span>Back to my cameras</span>
            </Link>
        );
    }

    return (
        <Link to={`/cameras/${id}/photos${search}`} className="btn btn-grey lg w100">
            <span className="btn-icon-arrow" />
            <span>Back to my photos list</span>
        </Link>
    );
};

export default CameraPhotosIndex;
