import React, {useState} from "react";
import {Button} from "../../../components";
import ReplenishModal from "../modals/ReplenishModal";
import {CamerasAPI} from "../../../api/cameras";
import {useNavigate} from "react-router-dom";

// TODO: use react redux
const Replenish = ({id, subscription, setCamera}) => {
  const navigate = useNavigate();
    const [showReplenish, setShowReplenish] = useState(false);
    const successReplenished = async () => {
      try {
        const {data} = await CamerasAPI.cameraInfo(id);
        setCamera(data);
      } catch (error) {
        if (error.response.status === 404) {
          navigate("/not-found-page");
        }
        if (error.response.status === 500) {
          navigate("/server-error-page");
        }
      }
    }

    if (subscription.closeOverLimit) {
        return (
            <>
                <ReplenishModal id={id} isOpen={showReplenish} successReplenished={successReplenished} onClose={() => setShowReplenish(false)}/>
                <div className="subscription-status-container">
                    <div className="subscription-status-text"><strong>{subscription.photosLeft}</strong> photos left. Click on the "Replenish" button to replenish your subscription.</div>
                    <div><Button title={"Replenish"} color={"red"} onClick={() => { setShowReplenish(true) }}/></div>
                </div>
            </>
        )
    }

    if (subscription.overPhotosLimit) {
        return (
            <>
                <ReplenishModal id={id} isOpen={showReplenish} onClose={() => setShowReplenish(false)}/>
                <div className="subscription-status-container">
                    <div className="subscription-status-text">Camera reached photos limit. Click on the "Replenish" button to replenish your subscription.</div>
                    <div><Button title={"Replenish"} color={"red"} onClick={() => { setShowReplenish(true) }}/></div>
                </div>
            </>
        )
    }

    return <></>;
};

export default Replenish;
