import "./FolderPhotos.scss";
import React, {useState, useCallback, useMemo} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {Outlet} from "react-router-dom";

//components
import {CameraHeader} from "../../components";
import EditFolder from "./components/Modals/EditFolder";

//api
import {foldersApi} from "../../api/folders";
import {CamerasAPI} from "../../api/cameras";
import fetcher from "../../helpers/fetcher";
import {camerasQK, foldersQK} from "../../helpers/query-keys";
import CameraPreview from "../../components/CameraPreview/CameraPreview";
import {useQueryClient} from "@tanstack/react-query";
import {useConfirmationModalContext} from "../../context/confirmation";
import {toast} from "react-toastify";

const FolderPhotosIndex = () => {
  const {id, photoId} = useParams();
  const queryClient = useQueryClient();
  const {showConfirmation} = useConfirmationModalContext();
  const navigate = useNavigate();

  const [editModalFolder, setEditModalFolder] = useState(false);
  const [errors, setErrors] = useState({});
  const search = useLocation().search;
  const camerasQueryKey = camerasQK.list().queryKey;
  const folderQueryKey = foldersQK.one(id).queryKey;
  const camerasQuery = fetcher(camerasQueryKey, CamerasAPI.listCamera);
  const folderQuery = fetcher(folderQueryKey, () => foldersApi.folderInfo(id));
  const folder = folderQuery.data?.data || {};
  const cameras = camerasQuery.data?.data || [];

  const onEditFolderClose = useCallback( (data) => {
    if (data) {
      queryClient.setQueryData(folderQueryKey, {
        data
      });
    }
    setEditModalFolder(false);
  }, [folderQueryKey, queryClient, folderQuery]);

  const handleDelete = useCallback(async () => {
    const confirm = await showConfirmation("Are you sure that you want to delete folder? This action cannot be undone.");
    if (confirm) {
      try {
        await foldersApi.delete(id);
        navigate("/");
      } catch (error) {
        const message = error?.response?.data?.message || "Something went wrong";
        toast.error(message);
      }
    }
  }, [folder, id, navigate, showConfirmation]);

  const folderActions = useMemo(() => ({
    "edit-folder": {
      alias: "edit-folder",
      label: "Edit folder",
      isAvailable: true,
      onClick: () => setEditModalFolder(true),
    },
    "delete-folder": {
      alias: "delete-folder",
      label: "Delete folder",
      isAvailable: true,
      onClick: handleDelete,
    },
  }), [handleDelete]);

  return (
    <>
      <section className="page-wrapper">
        {
          folder.id && (
            <EditFolder
              cameras={cameras}
              folder={folder}
              isOpen={editModalFolder}
              errors={errors}
              setErrors={setErrors}
              onClose={onEditFolderClose}
            />
          )
        }
        <div className="page-content">
          <>
            <CameraHeader camera={folder} loading={folderQuery.isLoading} actions={folderActions || {}}/>
            <div className="camera-view">
              <div className="camera-view-short">
                {folderQuery.isLoading && !folderQuery.isFetched ? (
                    <CameraPreview camera={{}} loading/>
                  )
                  : null}
                {folderQuery.isFetched && (
                  (folder.cameras || []).map((camera) => (
                    <CameraPreview isSettings camera={camera} key={camera.id} />
                  ))
                )}
                <BackLink id={id} photoId={photoId} search={search}/>
              </div>
              <div className="camera-view-content">
                {folderQuery.isFetched && <Outlet context={[folder, folderQuery.isFetched, folderQuery.refetch]} />}
              </div>
            </div>
          </>
        </div>
      </section>
    </>
  );
};

const BackLink = ({id, photoId, search = ""}) => {
  const scrollTop = useCallback(() => {
    window.scrollTo({left: 0, top: 0, behavior: "smooth" });
  }, []);

  if (!photoId) {
    return (
      <Link to={"/"} onClick={scrollTop} className="btn btn-grey lg w100">
        <span className="btn-icon-arrow"/>
        <span>Back to my cameras</span>
      </Link>
    );
  }

  return (
    <Link onClick={scrollTop} to={`/folders/${id}/photos${search}`} className="btn btn-grey lg w100">
      <span className="btn-icon-arrow"/>
      <span>Back to folder photos</span>
    </Link>
  );
};

export default FolderPhotosIndex;
