import "./Gallery.scss";
import qs from "qs";
import {Link} from "react-router-dom";
import React, {useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Button from "../../components/buttons/Button";
import NoPhotos from "../../components/NoPhotos/NoPhotos";
import {mergePhotoPages} from "../../components/helpers";
import Filters from "../CameraPhotos/components/Filters/Filters";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import {isEmptyObject} from "../../components/helpers";
import FiltersModal from "../CameraPhotos/components/Modals/FiltersModal";
import DeletePhotos from "../CameraPhotos/components/Modals/DeletePhotos";
import {AccountPhotosAPI} from "../../api/account-photos";
import PhotoList from "../../components/PhotoList/PhotoList";
import {gallery} from "../../helpers/query-keys";
import {photosFetcher} from "../../helpers/fetcher";
import {last} from "lodash";

export default function GalleryPhotosList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const search = qs.parse(searchParams.toString());
  const [filters, setFilters] = useState(search);
  const [date, setDate] = useState("");

  const isCheckedHq = useMemo(() => {
    if (search.filters) {
      return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "video")
    }
    return false;
  }, [search]);
  const isCheckedVideo = useMemo(() => {
    if (search.filters) {
      return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "image")
    }
    return false;
  }, [search]);

  const [view, setView] = useState(localStorage.getItem("gallery-photos-view") || "three");
  const [showRecognition, setShowRecognition] = useState(true);
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [showDeletePhotos, setShowDeletePhotos] = useState(false);
  const galleryQK = gallery.list(searchParams.toString()).queryKey;
  const {
    data: photosQueryData,
    refetch,
    fetchNextPage,
    isFetching,
    isFetched,
    isLoading
  } = photosFetcher(galleryQK, async ({pageParam}) => {
    const {data} = await AccountPhotosAPI.listByDates(qs.parse(searchParams.toString()), pageParam);
    return data;
  });

  const [showFilters, setShowFilters] = useState(false);

  const listByDays = mergePhotoPages(photosQueryData?.pages);

  const meta = useMemo(() => {
    if (photosQueryData?.pages) {
      const tail = last(photosQueryData.pages);
      return tail._meta || {};
    }
    return {};
  }, [photosQueryData]);

  function applyFilters(filters) {
    if (Object.keys(filters).length === 0) {
      setSearchParams({});
      setShowFilters(false);
      return;
    }
    setShowFilters(false);
    setSearchParams(qs.stringify({filters}));
  }

  function hqFilter() {
    let type = "image";
    if (search.filters?.type === "video") type = undefined;
    applyFilters({
      ...(search.filters || {}),
      state: "uploaded",
      type
    });
  }

  function videoFilter() {
    let type = "video";
    if (search.filters?.type === "image") type = undefined;
    applyFilters({
      ...(search?.filters || {}),
      state: "uploaded",
      type
    });
  }

  const loadMore = async () => {
    await fetchNextPage()
  };

  function onSetViewClick(view) {
    localStorage.setItem("gallery-photos-view", view);
    setView(view);
  }

  function toggleShowRecognition() {
    setShowRecognition(!showRecognition);
  }

  function toggleSelectMode() {
    setSelectMode(!isSelectMode);
    setSelectedPhotos([]);
  }

  function toggleSelectedCamera(e) {
    const {value} = e.target;
    const photoId = Number(value);

    if (selectedPhotos.includes(photoId)) {
      setSelectedPhotos(selectedPhotos.filter((item) => item !== photoId));
    } else {
      setSelectedPhotos([...selectedPhotos, photoId]);
    }
  }

  function toggleFiltersShow() {
    setShowFilters(!showFilters);
  }

  function onSelectAllClick() {
    const photoIds = Object.keys(listByDays)
      .map((day) => {
        const {list} = listByDays[day];

        return list.map((item) => item.id);
      })
      .flat();

    if (selectedPhotos.length === photoIds.length) {
      setSelectedPhotos([]);
    } else {
      setSelectedPhotos(photoIds);
    }
  }

  const resetHandler = () => {
    setFilters({});
    setSearchParams({});
    setDate("");
  };
  const onBatchDeleteClick = () => {
    setShowDeletePhotos(true);
  };

  const batchDeletePhotos = async () => {
    await AccountPhotosAPI.removeBatch(selectedPhotos);
    setShowDeletePhotos(false);
    setSelectedPhotos([]);
    await refetch();
  };

  const onDownloadPhotosClick = async () => {
    await AccountPhotosAPI.zip(selectedPhotos);
  };

  const refreshPhotoList = async () => {
    await refetch();
  };

  const onPhotoDelete = async () => {
    await refetch();
  }

  return (
    <section className="page-wrapper">
      <DeletePhotos
        isOpen={showDeletePhotos}
        selectedPhotos={selectedPhotos}
        onConfirm={batchDeletePhotos}
        onClose={() => {
          setShowDeletePhotos(false);
        }}
      />
      <FiltersModal
        date={date}
        isOpen={showFilters}
        filters={filters}
        isLoading={isLoading}
        search={search?.filters || {}}
        setFilters={setFilters}
        setDate={setDate}
        resetHandler={resetHandler}
        onClose={toggleFiltersShow}
        applyFilters={applyFilters}
      />

      <div className="page-content">
        <div className="page-title">Gallery</div>
        <section className="content-section gallery-section">
          <div className="sticky-filters">
            <Filters
              view={view}
              totalPhotos={meta?.totalCount || 0}
              filters={filters}
              isLoading={isLoading}
              showRecognition={showRecognition}
              isSelectMode={isSelectMode}
              selectedPhotos={selectedPhotos}
              onSetViewClick={onSetViewClick}
              isCheckedHq={isCheckedHq}
              isCheckedVideo={isCheckedVideo}
              resetHandler={resetHandler}
              toggleShowRecognition={toggleShowRecognition}
              toggleSelectMode={toggleSelectMode}
              onSelectAllClick={onSelectAllClick}
              toggleFiltersShow={toggleFiltersShow}
              onBatchDeleteClick={onBatchDeleteClick}
              hqFilter={hqFilter}
              videoFilter={videoFilter}
              refreshPhotoList={refreshPhotoList}
              onDownloadPhotosClick={onDownloadPhotosClick}
            />
          </div>
          {meta.totalCount < 1 && isFetched ? (
            <NoPhotos text={isEmptyObject(search) ? "" : "Photos not found by specified filters"}/>
          ) : (
            <div
              className={`photos-list-container photo-gallery ${view} ${
                isSelectMode ? "select-mode" : ""
              }`}
            >
              <PhotoList
                listByDays={listByDays}
                showRecognition={showRecognition}
                selectedPhotos={selectedPhotos}
                onPhotoSelect={toggleSelectedCamera}
                searchParams={searchParams}
                isSelectMode={isSelectMode}
                deletePhotoFromCollection={onPhotoDelete}
              />
            </div>
          )}
          {isLoading && <LineWaveLoader/>}
          {meta.pageCount > meta.currentPage && (
            <div className="load-more-photos">
              <Button
                color={"red-outline"}
                disabled={isFetching}
                size={"lg w100"}
                title={"Load More"}
                onClick={loadMore}
              />
            </div>
          )}
        </section>
      </div>
    </section>
  );
}

const RenderPhotoList = ({listByDays, showRecognition, selectedPhotos, toggleSelectedCamera, searchParams}) => {
  const days = Object.keys(listByDays);

  return days.map((day) => {
    const {date, list} = listByDays[day];

    return (
      <div className="photos-for-days" key={day}>
        <div className="photos-date">{date}</div>
        <div className="photos-list">
          {list &&
            list.map((photo) => {
              return (
                <div className="photo-item" key={photo.id}>
                  <img src={photo.thumb} alt=""/>
                  <div className="photo-item-overlay">
                    <div className="photo-delete">
                      <div className="icon icon-trash-white"/>
                    </div>
                    <div className="photo-view-btn">
                      <Link
                        to={`${photo.id}${
                          searchParams.toString().length > 0
                            ? "?" + searchParams.toString()
                            : ""
                        }`}
                        className="btn btn-red"
                      >
                        View
                      </Link>
                    </div>
                    <div className="photo-like">
                      {photo.liked ? (
                        <div className="icon icon-liked"/>
                      ) : (
                        <div className="icon icon-heart-outline-white"/>
                      )}
                    </div>
                  </div>
                  <div className="photo-item-select">
                    <div className="custom-checkbox ">
                      <input
                        type="checkbox"
                        name="photoId"
                        value={photo.id}
                        checked={selectedPhotos.includes(photo.id)}
                        onChange={toggleSelectedCamera}
                      />
                    </div>
                  </div>
                  {showRecognition && photo.recognition && (
                    <RecognitionIconsList recognition={photo.recognition}/>
                  )}
                  {photo.liked && <div className="photo-liked icon icon-liked"/>}
                  {photo.type === "video-preview" && (
                    <div className="video-preview">
                      <div className="icon icon-play"/>
                    </div>
                  )}
                  {photo.type === "video-preview" ? (
                    <VideoState
                      videoResolution={photo.videoResolution}
                      stateDescription={photo.stateDescription}
                    />
                  ) : (
                    <PhotoState stateDescription={photo.stateDescription}/>
                  )}
                </div>
              );
            })}
        </div>
      </div>
    );
  });
};

const PhotoState = ({stateDescription}) => {
  const {status, short} = stateDescription;

  if (status === "success") {
    return <div className="photo-state success">{short}</div>;
  }

  if (status === "wait") {
    return <div className="photo-state waiting">{short}</div>;
  }

  if (status === "error") {
    return <div className="photo-state error">{short}</div>;
  }

  return null;
};

const VideoState = ({videoResolution, stateDescription}) => {
  const {status, short} = stateDescription;
  if (status === "success") {
    return <div className="photo-state success">{videoResolution}</div>;
  }

  if (status === "wait") {
    return <div className="photo-state waiting">{short}</div>;
  }

  if (status === "error") {
    return <div className="photo-state error">{short}</div>;
  }

  return <div className="photo-state">{videoResolution}</div>;
};

const RecognitionIconsList = ({recognition}) => {
  const icons = Object.keys(recognition);

  return (
    <div className="recognition-icons">
      {icons.map((icon, index) => {
        return (
          <div key={icon + "-" + index} className="recognition-info">
            <div className="recognition-icon-count">{recognition[icon]} x</div>
            <div className={`recognition-icon icon-${icon}`}/>
          </div>
        );
      })}
    </div>
  );
};
