import React, {useCallback, useMemo, useState} from "react";
import LineWaveLoader from "../../../components/Loader/LineWaveLoader";
import cx from "classnames";
import { chunk } from "lodash";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import {SubscriptionItem, SubscriptionPreview} from "../components/SubscriptionItem";
import Button from "../../../components/buttons/Button";
import "./subscriptions.scss";
import {ProfileAPI} from "../../../api/profile";
import Collapse from "../../../components/Collapse";

const NewAddon = ({isOpen, subscriptionGroups = [], isLoading, onClose}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [inProgress, setInProgress] = useState(false);

  const handleClose = useCallback((refetch) => {
    onClose(refetch);
    setSelectedGroup(null);
    setSelectedSubscription(null);
  }, [onClose]);

  const handleBack = useCallback(() => {
    setSelectedGroup(null);
    setSelectedSubscription(null);
  }, []);

  const onSelectGroup = (group) => {
    setSelectedGroup(group);
  }

  const onSelectSubscription = useCallback((subscription) => {
    setSelectedSubscription(selectedSubscription?.id === subscription.id ? null : subscription);
  }, [selectedSubscription])

  const onConfirm = async () => {
    setInProgress(true);
    try {
      await ProfileAPI.addSubscription({
        packageId: selectedSubscription.packageId
      });
      setInProgress(false);
      await handleClose(true);
    } catch (e) {
      setInProgress(false);
      await handleClose(false);
    }
  };

  if (isLoading) {
    return (
      <BaseModal isOpen={isOpen} onClose={() => handleClose(false)} content={{width: "500px"}}>
        <section className="modal-body">
          <Header name={"Add addon"} onClose={() => handleClose(false)}/>
          <div className="modal-content"><LineWaveLoader/></div>
        </section>
      </BaseModal>
    );
  }

  const plans = useMemo(() => {
    if (selectedGroup) {
      return chunk(selectedGroup.plans, 2);
    }
    return [];
  }, [selectedGroup]);

  const disabled = Boolean(selectedSubscription?.userHasPlan);

  return (
    <BaseModal isOpen={isOpen} onClose={() => handleClose(false)} content={{width: selectedGroup ? "600px" : "400px", transition: "width 0.3s ease"}}>
      <section className="modal-body">
        <Header name={"Add addon"} onClose={() => handleClose(false)}/>
        <div className="modal-content">
          <div className="modal-content">
            <div className={cx({list: !selectedGroup})}>
              {
                selectedGroup ? (
                  plans.map((subscriptions) => (
                    <div className="subscription-group">
                      <div className="subscription-group-plans">
                        {subscriptions.map((subscription) => (
                            <SubscriptionItem
                              key={subscription.id}
                              item={subscription}
                              addon
                              add
                              isSelected={selectedSubscription && selectedSubscription.id === subscription.id}
                              onSelectSubscription={() => onSelectSubscription(subscription)}/>
                        ))}
                      </div>
                      <Collapse show={selectedSubscription && subscriptions.map((item) => item.id).includes(selectedSubscription.id)}>
                        <SubscriptionPreview addon key={selectedSubscription?.id} item={selectedSubscription || {}}/>
                      </Collapse>
                    </div>
                  ))
                ) : (
                  subscriptionGroups.map((group, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`subscription-btn subscription-group-btn ${selectedGroup && selectedGroup.id === group.id ? "selected" : ""}`}
                        role="button"
                        onClick={() => onSelectGroup(group)}
                      >
                        <p className="subscription-btn-group-name">{group.name}</p>
                      </div>
                    );
                  })
                )
              }
            </div>
          </div>
        </div>
        <div className="modal-buttons">
          <Button
            onClick={() => selectedGroup ? handleBack() : handleClose(false)}
            title={selectedGroup ? "Back" : "Close"}
            color="outline"
          />
          <Button
            loader={inProgress}
            disabled={disabled || inProgress || !selectedSubscription}
            onClick={onConfirm}
            title={"Confirm"}
            color="grey"
          />
        </div>
      </section>
    </BaseModal>
  );
};

export default NewAddon;
