import {camerasSettings, foldersQK, gallery as galleryQueryKeys} from "../../../helpers/query-keys";
import {useQueryClient} from "@tanstack/react-query";
import fetcher from "../../../helpers/fetcher";
import {CameraPhotosApi} from "../../../api/account-camera-photos";
import qs from "qs";
import {useMemo} from "react";
import {AccountPhotosAPI} from "../../../api/account-photos";

const PreloadPhoto = ({id, photoId, gallery, cameras, search = ""}) => {

  const currentApi = useMemo(() => {
    const params = qs.parse(search.toString(), {ignoreQueryPrefix: true});
    if (gallery) {
      return () => AccountPhotosAPI.view(photoId, params)
    }
    if (cameras) {
      return () => AccountPhotosAPI.view(photoId, params, cameras)
    }
    return () => CameraPhotosApi.photoView(id, photoId, params)
  }, [id, photoId, search, cameras, gallery]);

  const photoQK = useMemo(() =>{
    if (gallery) {
      return galleryQueryKeys.one(`${photoId}`).queryKey;
    }
    if (cameras) {
      return foldersQK.photo(id, photoId).queryKey;
    }
    return camerasSettings.onePhoto(id, photoId).queryKey;
  }, [id, gallery, cameras, photoId]);

  const queryClient = useQueryClient();
  const isPreloadEnable = !(queryClient.getQueryData(photoQK));
  fetcher(
    photoQK,
    currentApi,
    {
      enabled: isPreloadEnable
    }
  );
  return null;
}

export default PreloadPhoto;
