import React, {useState} from "react";
import "./CameraBlock.scss";
import {CameraName} from "../CameraName/CameraName";
import {CameraImei} from "../CameraImei/CameraImei";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";
import {AccountCameraAPI} from "../../../../api/account-camera";

export const CameraBlock = ({camera, accountEnabled}) => {

    const [inProgress, setInProgress] = useState(false);
    const [isOnxEnabled, setIsOnxEnabled] = useState(camera.onxEnabled);

    const toggleCameraOnX = async () => {
        setInProgress(true);
        await AccountCameraAPI.updateOnXSetting(camera.id, !isOnxEnabled);
        setIsOnxEnabled(!isOnxEnabled);
        setInProgress(false);
    }

    return (
        <section className="camera-block camera-onx-row">
            <section className="camera-item-onx">
                <div className="camera-item-header-row">
                    <img className="camera-image-row" src={camera?.image} alt="" />
                </div>
                <div className="camera-item-row-onx">
                    <CameraName value={camera?.name || ""} />
                    <div className="camera-item-info-onx">
                        <p className="camera-item-info-txt">{camera?.model}</p>
                        <p className="camera-item-info-txt">IMEI:</p>
                        <CameraImei value={camera?.imei} />
                    </div>
                </div>
            </section>
            <ToggleSwitch
                checked={isOnxEnabled}
                disabled={!accountEnabled}
                name={"onx"}
                loader={inProgress}
                onChange={toggleCameraOnX}
            />
        </section>
    );
};
