import "./Modals.scss";
import React, {useState} from "react";
import {Button, ModalComponent} from "../../../components";
import {ProfileAPI} from "../../../api/profile";

const ConfirmOnxIntegration = ({isOpen, onClose, onConfirmNoAuth, authRequired}) => {

  const [inProgress, setInProgress] = useState(false);
  const [error, setError] = useState("");

  const onApproveIntegration = async () => {

    if (!authRequired) {
      onConfirmNoAuth();
    } else {
      setInProgress(true);
      try {
        const {data} = await ProfileAPI.onxAuth();
        if (data.authLink) {
          window.open(data.authLink, "_blank");
        }
      } catch (error) {
        setError(error);
      }

      setInProgress(false);
    }
  };

  return (
    <ModalComponent title={"Enable onX integration"} isOpen={isOpen} onClose={onClose} content={{width: "530px"}}>
      <div className="modal-content onx-integration-modal">
        <div className="onx-logo-container">
          <div className="onx-modal-logo"/>
        </div>
        <p className="modal-text">To link your cameras with onX Hunt, simply log into your onX account and choose the
          cameras you wish to connect. Do you want to continue?</p>
      </div>
      {error && (
        <div className="error-block">{<span className="modal-text-error">{error}</span>}</div>
      )}
      <div className="modal-buttons-block">
        <Button onClick={onClose} title={"No"} color={"outline"}/>
        <Button onClick={onApproveIntegration} loader={inProgress} title={"Yes"} color={"grey"}/>
      </div>
    </ModalComponent>
  );
};

export default ConfirmOnxIntegration;
