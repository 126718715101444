import "./CameraView.scss";
import React from "react";
import Nav from "./components/Nav";
import {useOutletContext, useParams, useNavigate} from "react-router-dom";
import {AccountCameraAPI} from "../../api/account-camera";
import Spinner from "./components/Spinner";
import fileImageIcon from "../../images/iconSVG/elements/file_image.svg";
import {getStatusClass} from "./utils";
import Button from "../../components/buttons/Button";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import {useConfirmationModalContext} from "../../context/confirmation";
import {toast} from "react-toastify";

const COMMAND_STATUS_PENDING = 0;

const Commands = () => {
    const [camera] = useOutletContext();
    const navigate = useNavigate();
    const {id} = useParams();
    const cameraCommandsQK = camerasSettings.commands(camera.id).queryKey;
    const { data = {
        data: {
            list: [],
            type: ""
        }
    }, isLoading, refetch } = fetcher(cameraCommandsQK, () => AccountCameraAPI.commands(id))

    const commands = data.data.list || [];
    const type = data.data.type || "";

    const cancelCommand = async (commandId) => {
        try {
            await AccountCameraAPI.cancelCommand(id, commandId);
            await refetch();
            toast.info("Command was successfully canceled");
        } catch (error) {
            let message = error?.response?.data?.message;
            if (!message) {
                message = 'Internal server error.'
            }
            toast.error(message);
        }
    }

    const navigationHandler = (itemId) => {
        navigate(`/cameras/${id}/photos/${itemId}`);
    };

    if (!camera.id) {
        return;
    }

    return (
        <>
            <Nav camera={camera} current={"commands"}/>
            <section className="content-section">
                <div className="content-header">
                    <div className="page-sub-title ">Commands</div>
                </div>
                {isLoading ? <Spinner/> : (
                    <>
                        <CommandsNote type={type}/>
                        <CommandsList list={commands} type={type} navigationHandler={navigationHandler} cancelCommand={cancelCommand}/>
                    </>
                )}

            </section>
        </>
    );
};

const CommandsNote = ({type}) => {
    if (type !== 'sms') {
        return <div/>
    }

    return (
        <div className="sms-type-commands-note">
            <span>Provider Status</span> - status of the SMS command from the SMS Provider AT&T or Verizon and indicates
            the state of the SMS message while it is being delivered to the camera.
        </div>
    )
}

const CommandsList = ({type, list, navigationHandler, cancelCommand}) => {
    const { showConfirmation } = useConfirmationModalContext();

    const onCancelCommand = async (command) => {
        const confirm = await showConfirmation(`Cancel command "${command.command?.name}"?`)
        if (confirm) {
            await cancelCommand(command.id);
        }
    }

    return (
        <>
            {type === 'api' && (
                <KeepwayList list={list} navigationHandler={navigationHandler} onCancelCommand={onCancelCommand}/>
            )}
            {type === 'sms' && (
                <SmsList list={list} navigationHandler={navigationHandler}/>
            )}
        </>
    )
}

const SmsList = ({list, navigationHandler}) => {

    return (
        <table className="commands-list-table">
            <thead>
            <tr>
                <th>Command</th>
                <th>Created</th>
                <th>Provider Status</th>
                <th>Delivered</th>
                <th>Completed At</th>
                <th>Status</th>
                <th>Result/Description</th>
            </tr>
            </thead>
            <tbody>
            {list.length > 0
                ? list.map(item =>
                    <tr key={item.id}>
                        <td>{item.command.name}</td>
                        <td><DateTime dateTime={item.createdAt}/></td>
                        <td>{item.providerStatus.name}</td>
                        <td><DateTime dateTime={item.deliveredAt}/></td>
                        <td><DateTime dateTime={item.completedAt}/></td>
                        <td>
                            <span className={`status-cell ${getStatusClass(item.status.name)}`}>
                                {item.status.name.toUpperCase()}
                            </span>
                        </td>
                        <td>
                            {item.photoId ? (
                                <img
                                    src={fileImageIcon}
                                    alt="Photo Icon"
                                    className={"photo-view-link"}
                                    onClick={() => navigationHandler(item?.photoId)}
                                />
                            ) : (
                                item.status?.failReason ?? "Deleted"
                            )}
                        </td>
                    </tr>
                )
                : <tr>
                    <td colSpan={7} className="table-empty">No recent commands found</td>
                </tr>
            }
            </tbody>
        </table>
    );
}

const KeepwayList = ({list, navigationHandler, onCancelCommand}) => {
    return (
        <table className="commands-list-table">
            <thead>
            <tr>
                <th>Command</th>
                <th>Created</th>
                <th>Completed</th>
                <th>Status</th>
                <th>Result/Description</th>
                <th width={60}></th>
            </tr>
            </thead>
            <tbody>
            {list.length > 0
                ? list.map(item =>
                    <tr key={item.id}>
                        <td>{item.command.name}</td>
                        <td><DateTime dateTime={item.createdAt}/></td>
                        <td><DateTime dateTime={item.completedAt}/></td>
                        <td>
                            <span className={`status-cell ${getStatusClass(item.status.name)}`}>
                                {item.status.name.toUpperCase()}
                            </span>
                        </td>
                        <td>
                            {item.photoId ? (
                                <img
                                    src={fileImageIcon}
                                    alt="Photo Icon"
                                    className={"photo-view-link"}
                                    onClick={() => navigationHandler(item?.photoId)}
                                />
                            ) : (
                              item.status?.failReason ?? "Deleted"
                            )}
                        </td>
                        <td>
                            {item.status.status === COMMAND_STATUS_PENDING && (
                                <Button
                                    title="Cancel"
                                    size="sm"
                                    color="grey-outline"
                                    onClick={() => onCancelCommand(item)}
                                />
                            )}
                        </td>
                    </tr>
                )
                : <tr>
                    <td colSpan={6} className="table-empty">
                        No recent commands found
                    </td>
                </tr>
            }
            </tbody>
        </table>
    );
}

const DateTime = ({dateTime}) => {
    if (!dateTime) {
        return <div/>
    }

    const [date, time] = dateTime.split(" ");

    return (
        <>
            <div>{date}</div>
            <div>{time}</div>
        </>
    )
}

export default Commands;
