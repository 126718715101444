import "./AddCamera.scss";
import React, {useMemo, useState} from "react";
import {AddCameraAPI} from "../../../api/add-camera.js";
import Button from "../../../components/buttons/Button";
import { isValidImei, isValidIccid } from "../../../components/helpers";
import {toast} from "react-toastify";

// 862515041738067
// 89148000008043021363

const AddCamera = ({info, onInit, onClose}) => {
    const [form, setForm] = useState({
        imei: info?.imei || "",
        iccid: info?.iccid || ""
    });
    const [loading, onChangeLoading] = useState(false);

    const onConfirm = async (event) => {
        event.preventDefault();
        try {
            onChangeLoading(true);
            const response = await AddCameraAPI.registration(form);
            const settingsResponse = await AddCameraAPI.getDefaultSettings(form.imei);
            const {data} = response;
            const {cameraInfo, defaultName, services} = data;
            onChangeLoading(false);
            await onInit(form.imei, form.iccid, cameraInfo, services, defaultName, settingsResponse.data);
        } catch (e) {
            onChangeLoading(false);
            toast.error("Something went wrong")
        }
    };

    const onInputChange = (e) => {
        const {name, value} = e.target;

        setForm({
            ...form,
            [name]: value
        });
    }

    const nextButtonDisabled = useMemo(() => {
        return !((form.imei && isValidImei(form.imei)) && (form.iccid && isValidIccid(form.iccid)));
    }, [form]);

    return (
        <form onSubmit={onConfirm}>
            <div className="modal-content">
                <div>
                    <div className="form-group">
                        <label>IMEI</label>
                        <input
                            name={"imei"}
                            type={"text"}
                            className="input"
                            placeholder={"Enter IMEI"}
                            value={form?.imei || ""}
                            onChange={onInputChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>ICCID</label>
                        <input
                            name={"iccid"}
                            type={"text"}
                            className="input"
                            placeholder={"Enter ICCID"}
                            value={form?.iccid || ""}
                            onChange={onInputChange}
                        />
                    </div>
                </div>
            </div>
            <div className="modal-buttons">
                <Button
                    onClick={onClose}
                    title={"Close"}
                    color="outline"
                    type="button"
                />
                <Button
                    title={"Next"}
                    type="submit"
                    loader={loading}
                    disabled={nextButtonDisabled}
                    color="grey"
                />
            </div>
        </form>
    );
};
export default AddCamera;
