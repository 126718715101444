import "./Profile.scss";
import React, {useMemo, useState} from "react";
import {ProfileAPI} from "../../api/profile";
import Button from "../../components/buttons/Button";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import ViewLine from "./modals/ViewLine";
import NewSubscription from "./modals/NewSubscription";
import fetcher from "../../helpers/fetcher";
import {accountSubscriptionsQK, subscriptionsQK} from "../../helpers/query-keys";
import {SubscriptionsAPI} from "../../api/subscriptions";

const Subscriptions = () => {
  const {
    data = {data: []},
    refetch,
    isLoading
  } = fetcher(accountSubscriptionsQK.list.queryKey, ProfileAPI.subscriptions);
  const availableSubscriptionsQuery = fetcher(subscriptionsQK.list.queryKey, SubscriptionsAPI.subscriptions);
  const [line, setLine] = useState({
    show: false,
    line: {},
    subscription: {
        billingPlan: {},
    }
  });

  const subscriptions = data.data;

  const [showAddSubscription, setShowAddSubscription] = useState(false);

    const onShowLineClick = ({line, subscription}) => {
        setLine({
            show: true,
            line: line,
            subscription
        })
    }
    const onCloseShowLineClick = () => {
        setLine({
            show: false,
            line: {},
            subscription: {
                billingPlan: {},
            },
        })
    }

  const onCloseAddSubscription = async () => {
    setShowAddSubscription(false);
    await refetch();
  }

  const handleSetLineAfterChangeStatus = (newLine) => {
      setLine({
        ...line,
        line: newLine
      });
  }

  const subscriptionGroups = useMemo(() => {
    return availableSubscriptionsQuery.data?.data?.list || [];
  }, [availableSubscriptionsQuery]);

  return (
    <>
      <ViewLine onClose={onCloseShowLineClick} setLine={handleSetLineAfterChangeStatus} isOpen={line.show} subscription={line.subscription} line={line.line}/>
      {availableSubscriptionsQuery.isFetched ? <NewSubscription
        isOpen={showAddSubscription}
        subscriptionGroups={subscriptionGroups}
        onClose={onCloseAddSubscription}
      /> : null}
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Subscriptions</div>
          <div className="content-header-controls">
            <Button title={"New Subscription"} size={"lg"} color="grey" onClick={() => {
              setShowAddSubscription(true)
            }}/>
          </div>
        </div>
        {isLoading && <LineWaveLoader/>}
        {
          (subscriptions.length > 0)
            ? <SubscriptionsList subscriptions={subscriptions} onShowLineClick={onShowLineClick}/>
            : (!isLoading && <div>This account does not have any subscriptions</div>)
        }

      </section>
    </>
  );
};

const SubscriptionsList = ({subscriptions, onShowLineClick}) => {

  return (
    <table className="commands-list-table">
      <thead>
      <tr>
        <th>Name</th>
        <th>Purchased</th>
        <th>Next Billing</th>
        <th>Qty</th>
        <th>Price</th>
        <th>Additional line price</th>
      </tr>
      </thead>
      <tbody>
      {subscriptions.map(subscription => {
        const countCameras = subscription.lines && subscription.lines.length > 0 ? subscription.lines.reduce((partialSum, item) => {
          return partialSum + item.cameras.length
        }, 0) : 0;

        return (
          <tr key={subscription.id}>
            <td>
              <div className="subscription-product">
                <span className="subscription-name">{subscription.billingPlan.numbersOfPictures} Pictures</span>
                <div className="subscription-lines">
                  {
                    subscription.lines.map(line => {
                      if (line.cameras.length > 0) {
                        return <span
                          key={`${subscription.id}-${line.name}`}
                          className="subscription-line"
                          onClick={() => {onShowLineClick({
                              line,
                              subscription
                          })}}>
                                                        {line.name} - {line.cameras.length} line(s)
                                                    </span>
                      }

                      return <span key={`${subscription.id}-${line.name}`}
                                   className="subscription-line">{line.name} - <span className="no-lines">(no lines present)</span></span>
                    })
                  }
                </div>
              </div>
            </td>
            <td>{subscription.startDate}</td>
            <td>{subscription.endDate}</td>
            <td>{countCameras}</td>
            <td>{subscription.billingPlan.price} {subscription.billingPlan.period}</td>
            <td>{subscription?.billingPlan?.additionLinePrice || ""}</td>
          </tr>
        )
      })}
      </tbody>
    </table>
  );
}

export default Subscriptions;
