import "./CameraView.scss";
import "react18-json-view/src/style.css";
import React, {useCallback, useMemo, useState} from "react";
import Nav from "./components/Nav";
import {useOutletContext, useParams} from "react-router-dom";
import {AccountCameraAPI} from "../../api/account-camera";
import Spinner from "./components/Spinner";
import _ from "lodash";
import Button from "../../components/buttons/Button";
import {camerasSettings} from "../../helpers/query-keys";
import {infinityListFetcher} from "../../helpers/fetcher";
import {toast} from "react-toastify";
import JsonView from "react18-json-view";
import ModalComponent from "../../components/Modal/ModalComponent";

const CameraDebugLogs = () => {
  const [camera] = useOutletContext();
  const {id} = useParams();
  const QK = camerasSettings.logs(id).queryKey;
  const { data = {
    pages: []
  }, isLoading, isFetching, fetchNextPage } = infinityListFetcher(QK, async ({ pageParam }) => {
    const { data } = await AccountCameraAPI.log(id, pageParam);
    return data;
  });
  const [downloadLoading, onChangeDownloadLoading] = useState(false);
  const [showModal, onChangeShowModal] = useState(false);
  const [modalLog, onChangeModalLog] = useState({});

  const logs = useMemo(() => {
    return _.concat(...data.pages.map((item) => item.list));
  }, [data]);

  const meta = useMemo(() => {
    const last = _.last(data.pages)
    if (last && last.meta) {
      return last.meta;
    }
    return {
      currentPage: 0,
      pageCount: 0,
      perPage: 0,
      totalCount: 0,
    };
  }, [data]);

  const onCopy = useCallback(async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success("Text was copied to clipboard");
    } catch {

    }
  }, []);

  const handleShowModal = useCallback((log) => {
    onChangeShowModal(true);
    onChangeModalLog(log);
  }, []);

  const handleDownload = useCallback((log) => {
    const link = document.createElement('a');
    link.download = `${log.id}_${log.created_at}.json`;
    const blob = new Blob([JSON.stringify(log)], {type: 'text/plain'});
    link.href = window.URL.createObjectURL(blob);
    link.click();
  }, []);

  const handleCloseModal = useCallback(() => {
    onChangeShowModal(false);
    onChangeModalLog({});
  }, []);

  const handleDownloadAll = useCallback(async() => {
    try {
      onChangeDownloadLoading(true);
      const response = await AccountCameraAPI.downloadAllLogs(id);
      const link = document.createElement('a');
      const blob = new Blob([response.data], {type: 'application/zip'});
      link.download = `${id}_logs.zip`;
      link.href = URL.createObjectURL(blob);
      link.click();
      onChangeDownloadLoading(false);
    } catch (e) {
      onChangeDownloadLoading(false);
    }
  }, [id]);

  if (!camera.id) {
    return;
  }

  return (
    <>
      <Nav camera={camera} current={"debug"}/>
      <section className="content-section">
        <div className="content-header with-actions">
          <div className="page-sub-title">Debug logs</div>
          <div className="page-sub-title-actions">
            <Button
              size="lg"
              loader={downloadLoading}
              icon="download-alt"
              color="grey"
              title="Download all"
              onClick={handleDownloadAll}
            />
          </div>
        </div>
        {isLoading ? <Spinner/> : (
          <>
            <table className="commands-list-table">
              <thead>
                <tr>
                  <th>IP</th>
                  <th>Created</th>
                  <th>API version</th>
                  <th>Url</th>
                  <th>Token</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {logs.length < 1 && (
                <tr>
                  <td colSpan={6} className="table-empty">
                    No recent logs found
                  </td>
                </tr>
              )}
              {logs.map((log) => (
                <tr key={log.id}>
                  <td className="cell-with-copy" onClick={() => onCopy(log.ip)} >{log.ip}</td>
                  <td>{log.created_at}</td>
                  <td>{log.api_version}</td>
                  <td
                    className="long-cell-with-copy"
                    title={log.url}
                    onClick={() => onCopy(log.url)}
                  >
                    {log.url}
                  </td>
                  <td
                    className="long-cell-with-copy"
                    title={log.token}
                    onClick={() => onCopy(log.token)}
                  >
                    {log.token}
                  </td>
                  <td>
                    <div className="logs-actions-btns">
                      <Button
                        icon="eye"
                        size="sm"
                        color="bordered"
                        onClick={() => handleShowModal(log)}
                      />
                      <Button
                        size="sm"
                        icon="download-alt"
                        color="green"
                        onClick={() => handleDownload(log)}
                      />
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
            {meta.totalCount > 0 && (
              <div className="load-more-logs">
                <Button
                  color={"red-outline"}
                  disabled={isFetching || meta.currentPage >= meta.pageCount}
                  loader={isFetching}
                  size={"lg w100"}
                  title={"Load More"}
                  onClick={fetchNextPage}
                />
              </div>
            )}
          </>
        )}
      </section>
      <ModalComponent title={"Log details"} isOpen={showModal} onClose={handleCloseModal}>
        <>
          <div className="modal-content">
            <div className="logs-details-json-wrapper">
              <JsonView src={modalLog}/>
            </div>
          </div>
          <div className="modal-buttons-block logs-modal-footer">
            <Button onClick={handleCloseModal} type="button" title="Close" color="outline" />
          </div>
        </>
      </ModalComponent>
    </>
  );
};

export default CameraDebugLogs;
