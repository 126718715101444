import "./CameraView.scss";
import React from "react";
import {useOutletContext, useParams} from "react-router-dom";
import Nav from "./components/Nav";
import Replenish from "./components/Replenish";
import Spinner from "./components/Spinner";
import {camerasSettings} from "../../helpers/query-keys";
import {AccountCameraAPI} from "../../api/account-camera";
import fetcher from "../../helpers/fetcher";

const BillingStats = () => {
  const { id } = useParams();
  const [camera, setCamera] = useOutletContext();
  const cameraBillingQK = camerasSettings.billingInfo(id).queryKey;
  const { data = {
    data: {}
  }, isLoading } = fetcher(cameraBillingQK, () => AccountCameraAPI.billingInfo(id))

  const billingData = Object.values(data.data);

  const subscription = camera?.usage?.subscriptions;
  const showReplenish = subscription && (subscription?.closeOverLimit || subscription?.overPhotosLimit);

  return (
    <>
      <Nav camera={camera} current={"billing"}/>
      {showReplenish && <Replenish id={camera.id} subscription={subscription} setCamera={setCamera}/>}
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Billing</div>
        </div>
        <div className="billing-blocks">
          {billingData?.map((info) => {
            return <BillingBlock name={info?.label} value={info?.value} key={info?.label}/>;
          })}
        </div>
        {isLoading && <Spinner/>}
      </section>
    </>
  );
};

export default BillingStats;

const BillingBlock = ({name, value}) => {
  return (
    <div className="billing-block">
      <div className="billing-name">{name}</div>
      <div className="billing-value">{value}</div>
    </div>
  );
};
