const colors = [
  "#E42F31",
  "#00adb5",
  "#393e46",
  "#222831",
  "#6a2c70",
  "#b83b5e",
  "#f08a5d",
  "#f9ed69",
  "#95e1d3",
  "#eaffd0",
  "#fce38a",
  "#f38181",
  "#eaeaea",
  "#ff2e63",
  "#252a34",
  "#08d9d6",
  "#fc5185",
  "#f5f5f5",
  "#3fc1c9",
  "#364f6b",
  "#ffffd2",
  "#fcbad3",
  "#aa96da",
  "#a8d8ea",
  "#71c9ce",
  "#a6e3e9",
  "#cbf1f5",
  "#e3fdfd",
  "#40514e",
  "#11999e",
  "#30e3ca",
  "#e4f9f5",
  "#8785a2",
  "#f6f6f6",
  "#ffe2e2",
  "#ffc7c7",
  "#abedd8",
  "#46cdcf",
  "#3d84a8",
  "#48466d",
  "#112d4e",
  "#3f72af",
  "#dbe2ef",
  "#f9f7f7",
  "#ffde7d",
  "#f6416c",
  "#f8f3d4",
  "#00b8a9",
  "#53354a",
  "#903749",
  "#e84545",
  "#2b2e4a",
  "#311d3f",
  "#522546",
  "#88304e",
  "#e23e57",
  "#14ffec",
  "#0d7377",
  "#323232",
  "#212121",
  "#a5dee5",
  "#e0f9b5",
  "#fefdca",
  "#ffcfdf",
  "#61c0bf",
  "#bbded6",
  "#fae3d9",
  "#ffb6b9",
  "#ffaaa5",
  "#ffd3b6",
  "#dcedc1",
  "#a8e6cf",
  "#cca8e9",
  "#c3bef0",
  "#cadefc",
  "#defcf9"
];

export default colors;
