import React, {useCallback, useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./login.scss";
import {AuthApi} from "../../api/auth";
import {Select} from "../../components";
import {AuthContext} from "../../context/auth";
import eyeCross from "../../images/iconSVG/icon_eye_cross.svg";
import eye from "../../images/iconSVG/icon_eye.svg";

const RegisterPage = () => {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [errors, onChangeErrors] = useState({});
  const [showPassword, onChangeShowPassword] = useState(false);
  const [showPasswordConfirmation, onChangeShowPasswordConfirmation] = useState(false);
  const [countries, onChangeCountries] = useState([]);
  const [states, onChangeStates] = useState([]);
  const [loading, onChangeLoading] = useState(false);
  const [data, onChangeData] = useState({
    email: "",
    password: "",
    passwordConfirmation: "",
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    stateId: "",
    zip: "",
    countryId: "",
  });

  const getCountries = async () => {
    try {
      const {data} = await AuthApi.countries();
      onChangeCountries(data.list || []);
    } catch (e) {

    }
  }

  useEffect(() => {
    getCountries();
  }, []);

  const handleChangeData = useCallback((field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (data.password !== data.passwordConfirmation) {
      onChangeErrors({
        passwordConfirmation: "Passwords doesn't match"
      });
      return;
    }
    onChangeErrors({});
    onChangeLoading(true);
    try {
      const response = await AuthApi.register({
        account: data
      });
      onChangeLoading(false);
      if (response.data && response.data.auth_token) {
        setToken(response.data.auth_token);
        navigate("/");
      }
    } catch (error) {
      if (error.response.status === 422) {
        const newErrors = (error.response.data || []).reduce((res, item) => {
          return {
            ...res,
            [item.field]: item.message
          }
        }, {});
        onChangeErrors(newErrors)
      }
      onChangeLoading(false);
    }
  }, [data, navigate, setToken]);

  const countriesOptions = countries.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const statesOptions = states.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  const handleChangeCountry = useCallback(async (countryId) => {
    try {
      const {data} = await AuthApi.states(countryId);
      handleChangeData({countryId, stateId: undefined});
      onChangeStates(data.list || []);
    } catch (e) {
    }
  }, [handleChangeData]);

  const handleChangeZip = useCallback((zip = "") => {
    handleChangeData({
      zip: zip.replace(/\D/ig, "")
    });
  }, [handleChangeData]);

  return (
    <div className="wrapper start-page">
      <form onSubmit={handleSubmit} className="form form-def login-form js-login-form show-form">
        <section className="signin-block">
          <div className="signin-wrapper">
            <div className="signin-block__title-container">
              <h2 className="signin-block__title">Sign Up</h2>
            </div>
            <div className="signin-block__form">
              <div className="input-block">
                <label className="input-block__label" htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  value={data.firstName}
                  onChange={(event) => handleChangeData({firstName: event.target.value})}
                  required name="firstName"
                  placeholder="Enter first name"
                  className="input-block__input"
                />
                {errors.firstName ? <span className="login-error-text">{errors.firstName}</span> : null}
              </div>
              <div className="input-block">
                <label className="input-block__label" htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  value={data.lastName}
                  onChange={(event) => handleChangeData({lastName: event.target.value})}
                  required name="lastName"
                  placeholder="Enter last name"
                  className="input-block__input"
                />
                {errors.lastName ? <span className="login-error-text">{errors.lastName}</span> : null}
              </div>
              <div className="input-block">
                <label className="input-block__label" htmlFor="email">Email</label>
                <input
                  type="email"
                  value={data.email}
                  onChange={(event) => handleChangeData({email: event.target.value})}
                  required name="email"
                  placeholder="Enter email"
                  className="input-block__input"
                />
                {errors.email ? <span className="login-error-text">{errors.email}</span> : null}
              </div>

              <div className="inputs-block">
                <div className="input-block">
                  <label className="input-block__label" htmlFor="countryId">Country</label>
                  <Select
                    value={data.countryId}
                    onChange={(countryId) => handleChangeCountry(countryId)}
                    required name="countryId"
                    options={countriesOptions}
                  />
                  {errors.countryId ? <span className="login-error-text">{errors.countryId}</span> : null}
                </div>
                <div className="input-block">
                  <label className="input-block__label" htmlFor="stateId">State</label>
                  <Select
                    value={data.stateId}
                    onChange={(stateId) => handleChangeData({stateId})}
                    required name="stateId"
                    disabled={!data.countryId}
                    options={statesOptions}
                  />
                  {errors.stateId ? <span className="login-error-text">{errors.stateId}</span> : null}
                </div>
              </div>

              <div className="inputs-block">
                <div className="input-block">
                  <label className="input-block__label" htmlFor="city">City</label>
                  <input
                    type="text"
                    value={data.city}
                    onChange={(event) => handleChangeData({city: event.target.value})}
                    placeholder="Enter city"
                    className="input-block__input"
                    required name="city"
                  />
                  {errors.city ? <span className="login-error-text">{errors.city}</span> : null}
                </div>
                <div className="input-block">
                  <label className="input-block__label" htmlFor="stateId">Zip</label>
                  <input
                    type="text"
                    value={data.zip}
                    onChange={(event) => handleChangeZip(event.target.value)}
                    placeholder="Enter zip"
                    className="input-block__input"
                    required name="zip"
                  />
                  {errors.zip ? <span className="login-error-text">{errors.zip}</span> : null}
                </div>
              </div>

              <div className="input-block">
                <label className="input-block__label" htmlFor="address">Address</label>
                <input
                  type="text"
                  value={data.address}
                  onChange={(event) => handleChangeData({address: event.target.value})}
                  placeholder="Enter address"
                  className="input-block__input"
                  required name="address"
                />
                {errors.address ? <span className="login-error-text">{errors.address}</span> : null}
              </div>

              <div className="input-block">
                <label className="input-block__label" htmlFor="password">Password</label>
                <span className="input-block__password">
                  <input
                    type={showPassword ? "text" : "password"}
                    value={data.password}
                    onChange={(event) => handleChangeData({password: event.target.value})}
                    placeholder="Enter password"
                    className="input-block__input"
                    required name="password"
                  />
                  <img
                    src={showPassword ? eyeCross : eye}
                    alt="pass_btn"
                    className="show_password_btn"
                    role="button"
                    onClick={() => onChangeShowPassword(!showPassword)}
                  />
                </span>
                {errors.password ? <span className="login-error-text">{errors.password}</span> : null}
              </div>

              <div className="input-block">
                <label className="input-block__label" htmlFor="passwordConfirmation">Repeat Password</label>
                <span className="input-block__password">
                  <input
                    type={showPasswordConfirmation ? "text" : "password"}
                    value={data.passwordConfirmation}
                    onChange={(event) => handleChangeData({passwordConfirmation: event.target.value})}
                    placeholder="Repeat password"
                    className="input-block__input"
                    required name="password"
                  />
                  <img
                    src={showPasswordConfirmation ? eyeCross : eye}
                    alt="pass_btn"
                    className="show_password_btn"
                    role="button"
                    onClick={() => onChangeShowPasswordConfirmation(!showPasswordConfirmation)}
                  />
                </span>
                {errors.passwordConfirmation ? <span className="login-error-text">{errors.passwordConfirmation}</span> : null}
              </div>
              <div className="signin-block__button submit">
                <button className="button--login-block" disabled={loading} type="submit">
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
              <div className="signin-block__info">
                <div className="signin-text-link-wrapper">
                  <Link to="/start" className="signin-black-link">Back</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}

export default RegisterPage;
