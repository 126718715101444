import React, {useCallback, useState} from "react";
import Header from "./components/Header";
import {Outlet, useLocation} from "react-router-dom";
import fetcher from "../../helpers/fetcher";
import {profileQK} from "../../helpers/query-keys";
import {ProfileAPI} from "../../api/profile";
import {useQueryClient} from "@tanstack/react-query";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";

const ProfileIndex = () => {
  const location = useLocation();
  const [currentRoute] = location.pathname.split("/").reverse();
  const [current, onChangeCurrent] = useState(currentRoute);

  const profileQueryKey = profileQK.info.queryKey;
  const {
    data = {
      data: {
        firstName: "",
        lastName: "",
        middleName: "",
        status: "",
        address: "",
        city: "",
        primaryPhone: null,
        secondaryPhone: null,
        stateId: null,
        zip: "",
        countryId: null,
        onx: {
          authRequired: false,
          enabled: false,
        }
      }
    },
    refetch,
    isFetched,
    isLoading,
  } = fetcher(profileQueryKey, ProfileAPI.profile);

  const balanceQueryKey = profileQK.balance.queryKey;


  const profile = data.data;
  const { data: balanceData = {
    data: {
      payNow: false,
      balance: "$0.00"
    }
  }} = fetcher(balanceQueryKey, ProfileAPI.balance, {
    enabled: profile.status === "suspended"
  });
  const balance = balanceData.data;
  const queryClient = useQueryClient();

  const setProfile = useCallback((newProfile) => {
    queryClient.setQueryData(profileQueryKey, newProfile)
  }, [queryClient]);

  return (
    <section className="page-wrapper">
      <div className="page-content">
        <Header current={current} setCurrent={onChangeCurrent} profile={profile} />
        {isFetched ?
          <Outlet context={{
            profile,
            setProfile,
            balance,
            isLoading,
            refetchProfile: refetch,
          }} />
        : (
            <section className="content-section">
              {isLoading ? <LineWaveLoader /> : null}
            </section>
          )}
      </div>
    </section>
  );
}

export default ProfileIndex;
