import {highlightSearch} from "../helpers";
import cx from "classnames";
import React from "react";

export const CameraName = ({value, search}) => {
  const name = value.length === 0 ? "No Name" : value;
  if (value.length > 0 && search.length > 0) {
    return <span title={name} className="camera-item-name-text">{highlightSearch(name, search)}</span>;
  }

  return <span title={name} className="camera-item-name-text">{name}</span>;
};

export const CameraImei = ({value, search}) => {
  if (search.length > 0) {
    return <span title={value} className="camera-item-info-txt">{highlightSearch(value, search)}</span>;
  }

  return <span title={value} className="camera-item-info-txt">{value}</span>;
};

export const PinCamera = ({isPinned, pinProcessing, onClick}) => {
  if (pinProcessing) {
    return <div className="pin-spinner"/>;
  }

  return <button className={cx("camera-item-controls-pin ", {pinned: isPinned})} onClick={onClick}/>;
};

export default {
  CameraName,
  CameraImei,
  PinCamera,
}
