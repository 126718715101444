import "./AddCamera.scss";
import React, { useState } from "react";
import NewSubscription from "./components/NewSubscription";
import ChooseAccountSubscription from "./components/ChooseAccountSubscription";

const ChooseSubscription = ({subscriptionGroups, subscriptionList, info, onBack, onNext}) => {
    const [selectedSubscription, setSelectedSubscription] = useState({
        type: info?.type || "",
        subscription: info?.subscription || null
    });

    const [showNewSubscription, setShowNewSubscription] = useState(info?.type === "new");

    const onSelectSubscription = (type, subscription) => {
        setSelectedSubscription({
            type: type,
            subscription: subscription
        });
    }

    const onConfirm = () => {
        onNext("general", {...selectedSubscription}, "review");
    };

    const onShowSubscriptions = () => {
        setSelectedSubscription({
            type: "",
            subscription: null
        });
        setShowNewSubscription(!showNewSubscription);
    }

    const isEmptyAccountSubscriptions = subscriptionList.length === 0;

    if (isEmptyAccountSubscriptions || showNewSubscription) {
        return <NewSubscription
            subscriptions={subscriptionGroups}
            selectedSubscription={selectedSubscription.subscription}
            isEmptyAccountSubscriptions={isEmptyAccountSubscriptions}
            onSelectSubscription={onSelectSubscription}
            onShowSubscriptions={onShowSubscriptions}
            onBack={onBack}
            onConfirm={onConfirm}
        />
    }

    return (
        <ChooseAccountSubscription
            list={subscriptionList}
            selectedSubscription={selectedSubscription.subscription}
            onSelectSubscription={onSelectSubscription}
            onShowSubscriptions={onShowSubscriptions}
            onBack={onBack}
            onConfirm={onConfirm}
        />
    );
};

export default ChooseSubscription;
