import React, {useState, createContext, useCallback} from "react";
import fetcher from "../helpers/fetcher";
import {useQueryClient} from "@tanstack/react-query";
import {profileQK} from "../helpers/query-keys";
import {ProfileAPI} from "../api/profile";

export const AuthContext = createContext({
  token: null,
  user: {},
  isLoading: false,
  clear: () => {},
  setToken: () => {},
});

const AuthContextProvider = (props) => {
  const qk = profileQK.info.queryKey;
  const queryClient = useQueryClient();
  const [token, onChangeToken] = useState(localStorage.getItem("AUTH_TOKEN") || null);
  const {data = { data: {} }, isLoading} = fetcher(qk, ProfileAPI.profile, {
    enabled: !!token
  });

  const user = data.data;

  const clear = useCallback(() => {
    localStorage.clear();
    queryClient.clear();
    onChangeToken(null);
  }, [queryClient]);

  const setToken = useCallback((token) => {
    localStorage.setItem("AUTH_TOKEN", token);
    onChangeToken(token);
  }, []);

  return (
    <AuthContext.Provider value={{
      token,
      user,
      isLoading,
      clear,
      setToken,
    }}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthContextProvider;
