import "./Modals.scss";
import React, {useState} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {guestQK} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";
import LineWaveLoader from "../../../components/Loader/LineWaveLoader";
import {GuestAccountsApi} from "../../../api/guest-accounts";

const EditGuestAccount = ({isOpen, cameras, isLoading, account, onClose}) => {
  const [inProgress, setInProgress] = useState(false);
  const [form, setForm] = useState({
    name: account.name || "",
    cameraIds: (account.cameras || []).map(camera => camera.id)
  });

  const onChange = (e) => {
    const {name, value} = e.target;

    setForm({
      ...form,
      [name]: value
    })
  }

  const onSelectCamera = (id) => {

    const cameraIds = form?.cameraIds || [];

    setForm({
      ...form,
      cameraIds: cameraIds.includes(id) ? cameraIds.filter(item => item !== id) : [...cameraIds, id]
    })
  };

  const handleChooseAllCameras = () => {
    const cameraIds = form.cameraIds.length === cameras.length ? [] : cameras.map((item) => item.id);
    setForm({
      ...form,
      cameraIds
    });
  }

  const onConfirm = async () => {
    setInProgress(true);
    await GuestAccountsApi.update(account.id, form);
    setInProgress(false);
    onClose(true);
  };

  return (
    <BaseModal isOpen={isOpen} onClose={() => onClose(false)}>
      <section className="modal-body create-guest-account-modal">
        <Header name={"Edit guest account"} onClose={() => onClose(false)}/>
        {isLoading
          ? <LineWaveLoader/>
          : <div className="modal-content">
            <form>
              <div className="form-group">
                <label>Name</label>
                <input
                  type={"text"}
                  placeholder={"Enter name"}
                  className="input"
                  name="name"
                  value={form?.name || ""}
                  onChange={onChange}
                />
              </div>
            </form>
            <div className="guest-account-camera-list">
              <p className="guest-account-camera-list-title">Please select cameras to share:</p>
              <table>
                <thead>
                <tr>
                  <th>
                    <div className="checkbox-table">
                      <input
                        onChange={handleChooseAllCameras}
                        type="checkbox"
                        checked={form.cameraIds.length === cameras.length}/>
                    </div>
                  </th>
                  <th>Name</th>
                  <th>Type</th>
                  <th>MEID (IMEI)</th>
                </tr>
                </thead>
                <tbody>
                {cameras.length > 0 ? (
                  cameras.map((item) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <div className="checkbox-table">
                            <input
                              onChange={() => onSelectCamera(item.id)}
                              type="checkbox"
                              value={item.id}
                              checked={form.cameraIds && form.cameraIds.includes(item.id)}/>
                          </div>
                        </td>
                        <td>
                          <label>{item.name || <i>No name</i>}</label>
                        </td>
                        <td>{item.provider}</td>
                        <td>{item.imei}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={3} className="table-empty">
                      You don't have any cameras
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
        }
        <div className="modal-buttons">
          <Button
            onClick={() => onClose()}
            title={"Cancel"}
            color={"outline"}
          />
          <Button
            onClick={onConfirm}
            title={"Update guest account"}
            loader={inProgress}
            disabled={inProgress}
            color={"grey"}
          />
        </div>
      </section>
    </BaseModal>
  );
};

const EditGuestAccountModal = ({id, isOpen, cameras, onClose}) => {
  const guestAccountQK = guestQK.oneAccount(id).queryKey;
  const {
    data: guestAccountData = {
      data: {}
    },
    isLoading,
    isFetched
  } = fetcher(guestAccountQK, () => GuestAccountsApi.view(id));


  return isFetched ? (
    <EditGuestAccount
      isOpen={isOpen}
      isLoading={isLoading}
      onClose={onClose}
      account={guestAccountData.data}
      cameras={cameras}
    />
  ) : null;

}
export default EditGuestAccountModal;
