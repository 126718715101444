import React from "react";
import { useLocation, useParams} from "react-router-dom";
import Photo from "../../containers/photo";

const PhotoView = () => {
  const {id} = useParams();
  const search = useLocation().search;

  return <Photo search={search} gallery photoId={id} key={id} />

};

export default PhotoView;
