import React, {useCallback, useMemo} from "react";
import CameraNav from "../components/Nav";
import Nav from "../../../components/Nav/Nav";
import {Outlet, useOutletContext, useSearchParams} from "react-router-dom";
import {glossaryQK, reports} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";
import {GlossaryApi} from "../../../api/glossary";
import moment from "moment/moment";
import ReportsApi from "../../../api/reports";
import qs from "qs";
import "./stats.scss";
import {get, isEmpty} from "lodash";
import RecognitionFilters from "../../Reports/recognition/components/filters";
import GeneralFilters from "../../Reports/general/components/filters";
import Spinner from "../components/Spinner";

const getStructure = (structureQuery = {}, key) => {
  if (structureQuery.data) {
    const str = get(structureQuery, ["data", "data", key], {});
    return Object.keys(str)
      .filter(key => key !== "by-cameras")
      .map((key) => ({
        alias: key,
        name: str[key]
      }))
  }
  return [];
}

const CameraStatsIndex = ({route}) => {
  const [camera] = useOutletContext();
  const recognitionsQK = glossaryQK.recognitions.queryKey;
  const {data, isFetched} = fetcher(recognitionsQK, GlossaryApi.recognitions);
  const recognitions = data?.data || [];
  const defaultParams = useMemo(() => {
    return {
      filter: {
        cameras: [camera.id],
        labels: ["1"],
        date_from: moment().subtract(1, "w").format("yyyy-MM-DD"),
        date_to: moment().format("yyyy-MM-DD")
      }
    }
  }, []);
  const structureQK = reports.structure.queryKey;
  const structureQuery = fetcher(structureQK, ReportsApi.reports);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsFromSearch = qs.parse(searchParams.toString());
  const params = isEmpty(paramsFromSearch) ? defaultParams : paramsFromSearch;

  const setFilter = useCallback((filter) => {
    setSearchParams(qs.stringify({
      ...params,
      filter
    }));
  }, [searchParams, setSearchParams]);

  const recognitionStructure = useMemo(() => getStructure(structureQuery, "recognition-label"), [structureQuery]);
  const generalStructure = useMemo(() => getStructure(structureQuery, "photos"), [structureQuery]);

  const list = [
    {name: "General", alias: "general", sub: "tod"},
    {name: "Recognition", alias: "recognition", sub: "by-dates"},
  ];

  return (
    <>
      <CameraNav camera={camera} current="reports"/>
      <section className="content-section">
        <div className="content-header with-actions">
          <div className="page-sub-title">Reports</div>
        </div>
        {
          isFetched ? (
              <div className="camera-stats-wrapper">
                <div>
                  <Nav className="stats-main-nav" list={list} basePath={`/camera/${camera.id}/reports/`} current={route}/>
                  {route === "general"
                    ? <GeneralFilters defaultFilter={defaultParams} filter={params.filter} setFilter={setFilter}/>
                    : <RecognitionFilters oneCamera defaultFilter={defaultParams} recognitions={recognitions}
                                          filter={params.filter} setFilter={setFilter}/>
                  }
                </div>
                <Outlet context={{
                  params,
                  camera,
                  recognitions,
                  structure: route === "general" ? generalStructure : recognitionStructure,
                }}/>
              </div>
            )
            : <Spinner/>
        }
      </section>
    </>
  );
}

export default CameraStatsIndex;
