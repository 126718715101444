import React, { useState } from "react";
import {ModalComponent as Modal} from "../../../components";
import "./subscriptions.scss";

export const SubscriptionItem = ({item, add, isSelected, onSelectSubscription}) => {
  const linesCount = (item.lines || []).reduce((count, line) => (count + line.cameras.length || 0), 0);
  return (
    <div className={`subscription-btn ${isSelected ? "selected" : ""} ${add ? "add" : ""}`} role="button"
         onClick={onSelectSubscription}>
      <p className="subscription-btn-name">
        {item.isRecommended ? <span className="subscription-btn-recommended">Recommended</span> : null}
        {item.pictures && Number(item.pictures) > 0 ?
          <div className="subscription-btn-name-pictures">{item.pictures} Pictures</div> : null}
        <div className="subscription-btn-name-price">{item.price} {item.period}</div>
        {item.userHasPlan ? <span className="subscription-btn-recommended subscription-gray">Already has</span> : null}
      </p>
      {item.lines ? <p className="subscription-btn-lines">{linesCount} {linesCount > 1 ? "lines" : "line"}</p> : null}
      {item.examples
        ? item.examples.map((src, idx) => <link rel="preload" key={idx} as="image" href={src}/>)
        : null
      }
    </div>
  )
}

export const SubscriptionPreview = ({item, addon}) => {
  const [examplePreview, onChangeExamplePreview] = useState(undefined);
  if (!item) return null;
  const lines = (item.supportedLineTypes || []).join(" / ");
  return (
    <>
      <div className="subscription-preview">
        {item.pictures && Number(item.pictures) > 0 ?
          <div className="subscription-preview-lines"><strong>Pictures:</strong> {item.pictures}</div> : null}
        <div className="subscription-preview-lines"><strong>Price:</strong> {item.price}</div>
        <div className="subscription-preview-lines"><strong>Period:</strong> {item.period}</div>
        {addon ? null : <div className="subscription-preview-lines"><strong>Additional Line
          Price:</strong> {item.additionalLinePrice || "Free"}</div>}
        <div className="subscription-preview-lines"><strong>Lines:</strong> {lines || "-"}</div>
        <div className="subscription-preview-lines"><strong>Description:</strong> <br/><br/> {item.description || ""}</div>
        {item.examples && item.examples.length > 0 ? (
          <div>
            <div className="subscription-preview-lines"><strong>Examples:</strong></div>
            <div className="subscription-preview-examples">
              {item.examples.map((src, idx) => (
                <img
                  src={src} role="button" draggable={false}
                  alt={`example-image-${idx}`} key={idx}
                  onClick={() => onChangeExamplePreview(src)}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
      {!!examplePreview && (
        <Modal
          isOpen={!!examplePreview}
          header={false}
          onClose={() => onChangeExamplePreview(undefined)}
          content={{padding: 0, width: "auto"}}
        >
          <img
            src={examplePreview}
            className="example-addon-image-in-modal"
            alt="addon-example-image-preview"
            onClick={() => onChangeExamplePreview(undefined)}
          />
        </Modal>
      )
      }
    </>
  )
}
