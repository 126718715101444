import React, {useContext} from "react";
import { Navigate } from "react-router-dom";
import {AuthContext} from "../context/auth";

const PrivateRoute = ({ children }) => {
  const { token } = useContext(AuthContext);

  if (!token) {
    return <Navigate to="/start" replace />
  }

  return children;
}

export const AuthRoute = ({ children }) => {
  const { token } = useContext(AuthContext);

  if (token) {
    return <Navigate to="/" replace />
  }

  return children;
}

export default PrivateRoute;
