import axios from "./http-api-client";

export const foldersApi = {
  create: async (data) => {
    return axios.post(`/account-folders`, data);
  },

  update: async (id, data) => {
    return axios.put(`account-folders/${id}`, data);
  },

  photosBatchDelete: async (photos) => {
    return axios.delete(`account/photos`, {
      data: {photos}
    })
  },

  photoView: async (photos) => {
    return axios.delete(`account/photos`, {
      data: {photos}
    })
  },

  zipPhotos: async (photos) => {
    return axios.post(`account/photos/zip`, {photos})
  },

  delete: async (id) => {
    return axios.delete(`account-folders/${id}`);
  },
  folderInfo: async (id) => {
    return axios.get(`account-folders/${id}`);
  },

  folderPhotosByDates: async (filters, params = {}) => {
    return axios.get(`account/photos/by-dates?${filters}`, {
      params: params,
    });
  },
};
