import "./navigation.scss";
import React from "react";
import cx from "classnames";
import {Link, useLocation} from "react-router-dom";

const Nav = ({list, basePath, withSearch, className, current, setCurrent = () => {}}) => {
    const location = useLocation();
    return (
        <section className={cx("nav", className)}>
            {list.map((item) => {
                return <NavItem key={item.alias} search={withSearch ? location.search : undefined} {...item} onClick={() => setCurrent(item.alias)} basePath={basePath} isActive={current === item.alias} />;
            })}
        </section>
    );
};

export const SimpleTabs = ({list, className, current, setCurrent = () => {}}) => {
    return (
        <section className={cx("nav", className)}>
            {list.map((item) => (
              <button className={cx("nav-item", {active: current === item.alias})} key={item.alias} type="button" onClick={() => setCurrent(item.alias)}>
                  <p>{item.name}</p>
              </button>
            ))}
        </section>
    );
};

const NavItem = ({name, alias, icon, sub, onClick, search, basePath, tooltip, isActive}) => {
    return (
        <Link className={`nav-item ${isActive ? "active" : ""}`} onClick={onClick} to={`${basePath}/${alias}${sub ? `/${sub}` : ""}${search || ""}`}>
            <p>{name}</p>
            {icon && <Notification icon={icon} tooltip={tooltip}/>}
        </Link>
    );
};

const Notification = ({icon, tooltip}) => {
    if (!tooltip) {
        return <div className="nav-item-icon-container"><span className={`icon icon-${icon}`}/></div>
    }

    const {id, content} = tooltip;

    return (
        <div className="nav-item-icon-container" data-tooltip-id={id} data-tooltip-content={content}>
            <span className={`icon icon-${icon}`}/>
        </div>
    )
}

export default Nav;
