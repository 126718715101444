export const FormValidator = {
    validateAddPhoneForm: (inputValue, setErrors) => {
        let isValid = true;
        const phoneRegex = /^\d{3}-\d{3}-\d{4}$/;

        const newErrors = {};

        if (!inputValue) {
            newErrors.inputValue = "phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(inputValue)) {
            newErrors.inputValue = "Invalid phone number format";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    },

    validateAddEmailForm: (inputValue, setErrors) => {
        let isValid = true;
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        const newErrors = {};

        if (!inputValue) {
            newErrors.inputValue = "email is required";
            isValid = false;
        } else if (!emailRegex.test(inputValue)) {
            newErrors.inputValue = "Invalid email format";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    },
};

export default FormValidator;
