import "./../AddCamera.scss";
import cx from "classnames";
import React, {useCallback, useMemo} from "react";

const SubscriptionItem = ({item, onSelectSubscription, isSelected}) => {

  const handleClick = useCallback(() => {
    if (!item.userHasPlan) onSelectSubscription("new", item)
  }, [onSelectSubscription, item]);

  const badge = useMemo(() => {
    if (item.userHasPlan) {
      return (<div className={`subscriptions-select-item-recommended`}>Used</div>)
    }
    if (item.isRecommended) {
      return (<div className={`subscriptions-select-item-recommended`}>Recommended</div>)
    }
    return null;
  }, [item]);

  return (
    <div
      className={cx("subscriptions-select-item", {
        active: isSelected,
        isDeactivated: item.userHasPlan
      })}
      role="button"
      onClick={handleClick}
    >
      {badge}
      <div className="subscription-select-name">{item.pictures} Pictures</div>
      <div className="subscription-select-period">{item.price} {item.period}</div>
    </div>
  );
};

export default SubscriptionItem;
