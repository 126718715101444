import React, {useCallback, useMemo, useState} from "react";
import {useOutletContext} from "react-router-dom";
import Nav from "./components/Nav";
import "./CameraView.scss";
import Button from "../../components/buttons/Button";
import {compact, isEqual} from "lodash";
import {AccountCameraAPI} from "../../api/account-camera";
import {toast} from "react-toastify";

const LinkedCameras = () => {
  const [camera, _setCamera, _isLoading, suspended, refetch] = useOutletContext();
  const [isLoading, onChangeIsLoading] = useState(false);
  const [linkedCameras, onChangeLinkedCameras] = useState(Object.values(camera.linkedCameras || {}));

  const handleChangeDescription = useCallback((id, value) => {
    const newLinked = linkedCameras.map((item, idx) => {
      if (idx === id) {
        return {
          ...item,
          description: value
        }
      }
      return item;
    });
    onChangeLinkedCameras(newLinked);
  }, [linkedCameras]);

  const isChanged = useMemo(() => {
    const originLinkedCamerasDescription = compact(Object.values(camera.linkedCameras || {})).map((item) => item.description);
    const compactedLinkedCamerasDescription = compact(linkedCameras).map((item) => item.description);
    return !isEqual(originLinkedCamerasDescription, compactedLinkedCamerasDescription);
  }, [camera, linkedCameras]);

  const handleCancel = useCallback(() => {
    onChangeLinkedCameras(Object.values(camera.linkedCameras || {}));
  }, [camera]);

  const handleSave = useCallback(async (event) => {
    event.preventDefault();
    try {
      onChangeIsLoading(true);
      const cameras = compact(linkedCameras).map((item) => ({
        id: item.id,
        description: item.description
      }));
      await AccountCameraAPI.updateLinkedCameras(camera.id, { cameras});
      onChangeIsLoading(false);
      await refetch();
      toast.success("Linked cameras description was successfully updated");
    } catch (e) {
      onChangeIsLoading(false);
    }
  }, [camera, linkedCameras]);

  return (
    <>
      <Nav camera={camera} current={"linked"} />
      <section className="content-section">
        <div className="content-header">
          <div className="page-sub-title">Linked cameras</div>
        </div>
        <form className="linked-cameras-table-wrapper" onSubmit={handleSave}>
          <table className="linked-cameras-list-table">
            <thead>
            <tr>
              <th>Slot</th>
              <th>Signal</th>
              <th>Battery</th>
              <th>SD Card</th>
              <th>Status</th>
              <th>Activated</th>
              <th>Description</th>
            </tr>
            </thead>
            <tbody>
            {
              linkedCameras.map((item, idx) => (
                item !== null
                ?
                  <tr key={idx}>
                    <td>#{idx + 1}</td>
                    <td>{item.signal || "N/A"}</td>
                    <td>{item.battery || "N/A"}</td>
                    <td>{item.sdCard || "N/A"}</td>
                    <td className={`linked-status-cell ${item.status}`}>{item.status || "N/A"}</td>
                    <td>{item.activated || "N/A"}</td>
                    <td className="input-cell">
                      <input
                        className="input"
                        type="text"
                        required
                        minLength={3}
                        name={`linkedCameras[${idx}].description`}
                        placeholder="Description..."
                        value={item.description}
                        onChange={(event) => handleChangeDescription(idx, event.target.value)}
                      />
                    </td>
                  </tr>
                :
                  <tr key={idx}>
                    <td>#{idx + 1}</td>
                    <td className="empty" colSpan={5}>Empty slot</td>
                    <td className="input-cell">
                      <input
                        className="input"
                        type="text"
                        name={`linkedCameras[${idx}].description`}
                        placeholder="Description..."
                        disabled
                      />
                    </td>
                  </tr>
              ))
            }
            </tbody>
          </table>
          <div className="camera-view-footer linked-cameras-footer">
            <Button color={"outline"} title={"Cancel"} disabled={!isChanged} onClick={handleCancel} />
            <Button
              color={"grey"}
              title={"Save"}
              loader={isLoading}
              type="submit"
              disabled={!isChanged || suspended}
            />
          </div>
        </form>
      </section>
    </>
  );
}

export default LinkedCameras;
