import React, {useState} from "react";
import FormValidator from "./modalValidation";
import "./AddModal.scss";
import {Button} from "../../../components";
import ModalComponent from "../../../components/Modal/ModalComponent";
import {AccountCameraAPI} from "../../../api/account-camera";

const AddEmailModal = ({isOpen, onClose, id}) => {
    const [errors, setErrors] = useState({});
    const [loading, onChangeLoading] = useState(false);

    const [inputValue, setInputValue] = useState("");

    const handleChange = (e) => {
        setInputValue(e.target.value);
        setErrors({...errors, inputValue: ""});
    };

    const handleConfirm = async (event) => {
        event.preventDefault();
        const isValid = FormValidator.validateAddEmailForm(inputValue, setErrors);

        if (isValid) {
            try {
                onChangeLoading(true);
                await AccountCameraAPI.addCameraNotificationEmail(id, inputValue);
                onChangeLoading(false);
                setErrors({});
                onClose(true);
                setInputValue("");
            } catch {
                onChangeLoading(false);
            }
        }
    };

    const onCloseAddEmailModal = () => {
        setErrors({});
        setInputValue("");
        onClose();
    };

    return (
        <ModalComponent title={"Add email"} isOpen={isOpen} onClose={onCloseAddEmailModal} content={{width: "530px"}}>
            <form onSubmit={handleConfirm}>
                <div className="form-group">
                    <label>Please enter your email</label>
                    <input
                        type="text"
                        placeholder="Email"
                        onChange={handleChange}
                        value={inputValue}
                        className={`input ${errors.inputValue ? "not-valid" : ""}`}
                    />

                    <div className="error-block">
                        {errors.inputValue && <span className="modal-text-error">{errors.inputValue}</span>}
                    </div>
                </div>
                <div className="modal-buttons-block">
                    <Button onClick={onCloseAddEmailModal} type="button" title={"Cancel"} color={"outline"} />
                    <Button loader={loading} disabled={loading} type="submit" title={"Confirm"} color={"grey"} />
                </div>
            </form>
        </ModalComponent>
    );
};

export default AddEmailModal;
