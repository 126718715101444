import "./CameraPhotos.scss";
import qs from "qs";
import {useSearchParams} from "react-router-dom";
import {useOutletContext} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import Button from "../../components/buttons/Button";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import NoPhotos from "../../components/NoPhotos/NoPhotos";
import FiltersModal from "./components/Modals/FiltersModal";
import DeletePhotos from "./components/Modals/DeletePhotos";
import Filters from "./components/Filters/Filters";
import {isEmptyObject} from "../../components/helpers";
import PhotoList from "../../components/PhotoList/PhotoList";

import {CameraPhotosApi} from "../../api/account-camera-photos";

export default function PhotosList() {
  const {
    camera,
    fetchPhotos,
    loadMorePhotos,
    photosListFetching,
    photosList,
    photosListIsLoading,
    photosListMeta
  } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = qs.parse(searchParams.toString());
  const {id} = camera;

  const [filters, setFilters] = useState(search);

  const isCheckedHq = useMemo(() => {
   if (search.filters) {
     return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "video")
   }
   return false;
  }, [search]);
  const isCheckedVideo = useMemo(() => {
    if (search.filters) {
      return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "image")
    }
    return false;
  }, [search]);

  const [date, setDate] = useState("");

  const [view, setView] = useState(localStorage.getItem("photos-view") || "three");
  const [showRecognition, setShowRecognition] = useState(true);
  const [isSelectMode, setSelectMode] = useState(false);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [showDeletePhotos, setShowDeletePhotos] = useState(false);

  const [showFilters, setShowFilters] = useState(false);
  const [selectedAllOnPage, setSelectedAllOnPage] = useState(false);
  const [selectedAllOnCamera, setSelectedAllOnCamera] = useState(false);

  function onSetViewClick(view) {
    localStorage.setItem("photos-view", view);
    setView(view);
  }

  function toggleShowRecognition() {
    setShowRecognition(!showRecognition);
  }

  function toggleSelectMode() {
    setSelectMode(!isSelectMode);
    setSelectedPhotos([]);
    setSelectedAllOnPage(false);
    setSelectedAllOnCamera(false);
  }

  function toggleSelectedCamera(e) {
    if (!isSelectMode) {
      return false;
    }

    const {value} = e.target;
    const photoId = Number(value);

    if (selectedPhotos.includes(photoId)) {
      setSelectedPhotos(selectedPhotos.filter((item) => item !== photoId));
    } else {
      setSelectedPhotos([...selectedPhotos, photoId]);
    }
  }

  function toggleFiltersShow() {
    setShowFilters(!showFilters);
  }

  function onSelectAllClick() {
    const photoIds = Object.keys(photosList)
      .map((day) => {
        const {list} = photosList[day];

        return list.map((item) => item.id);
      })
      .flat();

    if (selectedPhotos.length === photoIds.length) {
      setSelectedPhotos([]);
      setSelectedAllOnPage(false);
      setSelectedAllOnCamera(false);
    } else {
      setSelectedPhotos(photoIds);
      setSelectedAllOnPage(true);
    }
  }

  function onSelectAllOnCamera() {
    setSelectedAllOnCamera(true);
  }

  const resetHandler = () => {
    setFilters({});
    setSearchParams({});
    setDate("");
  };

  function applyFilters(filters) {
    if (Object.keys(filters).length === 0) {
      setSearchParams({});
      setShowFilters(false);
      return;
    }
    setShowFilters(false);
    setSearchParams(qs.stringify({ filters }));
  }

  function hqFilter() {
    let type = "image";
    if (search.filters?.type === "video") type = undefined;
    applyFilters({
      ...(search.filters || {}),
      state: "uploaded",
      type
    });
  }

  function videoFilter() {
    let type = "video";
    if (search.filters?.type === "image") type = undefined;
    applyFilters({
      ...(search?.filters || {}),
      state: "uploaded",
      type
    });
  }

  const onBatchDeleteClick = () => setShowDeletePhotos(true);

  const batchDeletePhotos = async () => {
    if (selectedAllOnCamera) {
      await CameraPhotosApi.deleteAllPhotos(camera.id);
      setSelectMode(false);
    } else {
      await CameraPhotosApi.photosBatchDelete(camera.id, selectedPhotos);
    }
    setShowDeletePhotos(false);
    setSelectedAllOnPage(false);
    setSelectedAllOnCamera(false);
    setSelectedPhotos([]);

    await fetchPhotos();
  };

  const onDownloadPhotosClick = async () => {
    await CameraPhotosApi.zipPhotos(id, selectedPhotos);
  };

  const refreshPhotoList = async () => {
    await fetchPhotos();
  };

  const onPhotoDelete = async () => {
    await fetchPhotos();
  }

  if (!camera.id) {
    return <div/>;
  }

  return (
    <section className="content-section">
      <DeletePhotos
        isOpen={showDeletePhotos}
        selectedPhotos={selectedPhotos}
        totalPhotos={photosListMeta?.totalCount || 0}
        onConfirm={batchDeletePhotos}
        selectedAllOnCamera={selectedAllOnCamera}
        onClose={() => {
          setShowDeletePhotos(false);
        }}
      />
      <FiltersModal
        isOpen={showFilters}
        date={date}
        filters={filters}
        isLoading={photosListIsLoading}
        search={search?.filters || {}}
        setFilters={setFilters}
        setDate={setDate}
        resetHandler={resetHandler}
        onClose={toggleFiltersShow}
        applyFilters={applyFilters}
        setIsCheckedHq={hqFilter}
        setIsCheckedVideo={videoFilter}
      />
      <Filters
        view={view}
        filters={search.filters}
        isLoading={photosListIsLoading}
        showRecognition={showRecognition}
        isSelectMode={isSelectMode}
        selectedPhotos={selectedPhotos}
        onSetViewClick={onSetViewClick}
        resetHandler={resetHandler}
        toggleShowRecognition={toggleShowRecognition}
        toggleSelectMode={toggleSelectMode}
        onSelectAllClick={onSelectAllClick}
        toggleFiltersShow={toggleFiltersShow}
        onBatchDeleteClick={onBatchDeleteClick}
        hqFilter={hqFilter}
        isCheckedHq={isCheckedHq}
        isCheckedVideo={isCheckedVideo}
        videoFilter={videoFilter}
        onDownloadPhotosClick={onDownloadPhotosClick}
        refreshPhotoList={refreshPhotoList}
        totalPhotos={photosListMeta?.totalCount || 0}
      />
      {Object.keys(photosList).length === 0 && !photosListIsLoading ? (
        <NoPhotos text={isEmptyObject(search) ? "" : "Photos not found by specified filters"}/>
      ) : (
        <div>
          {selectedAllOnPage && !selectedAllOnCamera && (
            <div className="camera-photos-select-all">
              All {selectedPhotos.length} item(s) on this page are selected.{" "}
              <span className="select-all-on-camera" onClick={onSelectAllOnCamera}>
                                <b>Click here</b> to select {photosListMeta?.totalCount || 0} item(s) on this camera.
                            </span>
            </div>
          )}

          {selectedAllOnCamera && (
            <div className="camera-photos-select-all">
              Selected {photosListMeta?.totalCount || 0} item(s) on this camera.{" "}
              <span className="select-all-on-camera" onClick={toggleSelectMode}>
                                <b>Clear selection</b>
                            </span>
            </div>
          )}

          <div className={`photos-list-container ${view} ${isSelectMode ? "select-mode" : ""}`}>
            {!photosListIsLoading && (
              <PhotoList
                listByDays={photosList}
                showRecognition={showRecognition}
                selectedPhotos={selectedPhotos}
                onPhotoSelect={toggleSelectedCamera}
                searchParams={searchParams}
                isSelectMode={isSelectMode}
                afterDelete={onPhotoDelete}
              />
            )}
          </div>
        </div>
      )}
      {photosListIsLoading && <LineWaveLoader/>}
      {photosListMeta.pageCount > photosListMeta.currentPage && (
        <div className="load-more-photos">
          <Button
            color={"red-outline"}
            disabled={photosListFetching}
            loader={photosListFetching}
            size={"lg w100"}
            title={"Load More"}
            onClick={loadMorePhotos}
          />
        </div>
      )}
    </section>
  );
}
