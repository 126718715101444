const STATUS_SUSPEND = "suspend";
const STATUS_NOT_ACTIVE = "not-active";
const STATUS_PHOTO_LIMIT = "photo-limit-reached";
const STATUS_ACTIVE = "active";

export default {
  STATUS_SUSPEND,
  STATUS_NOT_ACTIVE,
  STATUS_PHOTO_LIMIT,
  STATUS_ACTIVE,
}
