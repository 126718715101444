import React, {useCallback, useMemo, useRef, useState} from "react";
import "./components.scss";
import {format} from "date-fns";
import { isEqual } from "lodash";
import {MultiSelect} from "../../../../components";
import Button from "../../../../components/buttons/Button";
import DatePicker from "../../../../components/DatePicker";

const Filters = ({setFilter, cameras = [], defaultFilter, filter = {}}) => {
  const [localFilters, onChangeLocalFilters] = useState(filter);

  const date = useMemo(() => {
    return [localFilters.date_from ? new Date(localFilters.date_from) : null, localFilters.date_to ? new Date(localFilters.date_to) : null]
  }, [localFilters]);

  const onChangeCalendarHandler = useCallback((date) => {
    onChangeLocalFilters({
      ...localFilters,
      date_from: date[0] ? format(date[0], "yyyy-MM-dd") : null,
      date_to: date[1] ? format(date[1], "yyyy-MM-dd") : null,
    });
  }, [localFilters]);

  const onCamerasChange = useCallback((_name, values) => {
    const filterCameras = values.map((item) => item.value);
    onChangeLocalFilters({
      ...localFilters,
      cameras: filterCameras,
    });
  }, [localFilters]);

  const camerasValue = useMemo(() => {
    if (localFilters.cameras) {
      return cameras.filter((item) => localFilters.cameras.includes(item.value));
    }
    return [];
  }, [cameras, localFilters]);

  const handleApplyFilter = useCallback(() => {
    setFilter(localFilters);
  }, [setFilter, localFilters]);

  const resetFilter = useCallback(() => {
    setFilter(defaultFilter.filter);
    onChangeLocalFilters(defaultFilter.filter);
  }, [setFilter, defaultFilter]);

  const disabledReset = useMemo(() => {
    return isEqual(defaultFilter.filter, localFilters);
  }, [defaultFilter, localFilters]);

  return (
    <div className="general-filters-wrapper">
      <div className="general-filters-line">
        <div className="form-row form-row-horizontal recognition-dates-filter">
          <div className="form-group">
            <DatePicker range value={date} className="reports-datepicker" onChange={onChangeCalendarHandler} />
          </div>
        </div>
        <div className="general-filters-btns">
          <Button color="outline" size="lg" disabled={disabledReset} title="Reset" onClick={resetFilter} />
          <Button color="grey" size="lg" title="Apply filters" onClick={handleApplyFilter} />
        </div>
      </div>
    </div>
  )
}

export default Filters;
