import "./Modals.scss";
import React, {useState} from "react";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Button from "../../../../components/buttons/Button";

const DeletePhotos = ({selectedPhotos, isOpen, onClose, onConfirm, selectedAllOnCamera, totalPhotos}) => {
    const [isLoading, setIsLoading] = useState(false);

    const onConfirmClick = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsLoading(false);
    };

    return (
        <ModalComponent isOpen={isOpen} onClose={onClose} title={"Delete photos"}>
            <div className="delete-folder-modal">
                <div className="modal-content">
                    {
                        selectedAllOnCamera
                            ? <p className="modal-text">Are you sure that you want delete {totalPhotos} photos? This action cannot be undone.</p>
                            : <p className="modal-text">Are you sure that you want delete {selectedPhotos.length} photos? This action cannot be undone.</p>
                    }
                </div>
                <div className="modal-buttons">
                    <Button onClick={onClose} title={"Cancel"} color={"outline"}/>
                    <Button onClick={onConfirmClick} disabled={isLoading} loader={isLoading} title={"Delete"} color={"grey"}/>
                </div>
            </div>
        </ModalComponent>
    );
};

export default DeletePhotos;
