import React, {useMemo} from "react";
import cx from "classnames";
import "./weather-icon.scss";

import clear from "../../images/iconSVG/weather/clear.svg";
import clearNight from "../../images/iconSVG/weather/clear-night.svg";
import cloudy from "../../images/iconSVG/weather/cloudy.svg";
import dust from "../../images/iconSVG/weather/dust.svg";
import fog from "../../images/iconSVG/weather/fog.svg";
import hail from "../../images/iconSVG/weather/hail.svg";
import haze from "../../images/iconSVG/weather/haze.svg";
import hurricane from "../../images/iconSVG/weather/hurricane.svg";
import rainy from "../../images/iconSVG/weather/rain.svg";
import snow from "../../images/iconSVG/weather/snow.svg";
import sunrise from "../../images/iconSVG/weather/sunrise.svg";
import sunset from "../../images/iconSVG/weather/sunset.svg";
import thunderstorm from "../../images/iconSVG/weather/thunderstorm.svg";
import wind from "../../images/iconSVG/weather/wind.svg";
import thermometer from "../../images/iconSVG/weather/thermometer.svg";
import tornado from "../../images/iconSVG/weather/tornado.svg";
import precipitation from "../../images/iconSVG/weather/precipitation.svg";
import humidity from "../../images/iconSVG/weather/humidity.svg";
import moon0 from "../../images/iconSVG/weather/moon_0.svg";
import moon1 from "../../images/iconSVG/weather/moon_1.svg";
import moon2 from "../../images/iconSVG/weather/moon_2.svg";
import moon3 from "../../images/iconSVG/weather/moon_3.svg";
import moon4 from "../../images/iconSVG/weather/moon_4.svg";
import moon5 from "../../images/iconSVG/weather/moon_5.svg";
import moon6 from "../../images/iconSVG/weather/moon_6.svg";
import moon7 from "../../images/iconSVG/weather/moon_7.svg";

const icons = {
  fog,
  clear,
  dust,
  rainy,
  tornado,
  thermometer,
  humidity,
  precipitation,
  haze,
  snow,
  hail,
  hurricane,
  thunderstorm,
  cloudy,
  wind,
  sunrise,
  sunset,
}

// 0	Clear sky
// 1, 2, 3	Mainly clear, partly cloudy, and overcast
// 45, 48	Fog and depositing rime fog
// 51, 53, 55	Drizzle: Light, moderate, and dense intensity
// 56, 57	Freezing Drizzle: Light and dense intensity
// 61, 63, 65	Rain: Slight, moderate and heavy intensity
// 66, 67	Freezing Rain: Light and heavy intensity

// 71, 73, 75	Snow fall: Slight, moderate, and heavy intensity
// 77	Snow grains
// 80, 81, 82	Rain showers: Slight, moderate, and violent
// 85, 86	Snow showers slight and heavy
// 95 *	Thunderstorm: Slight or moderate
// 96, 99 *	Thunderstorm with slight and heavy hail

const weatherCodes = {
  0: clear,
  1: cloudy,
  2: cloudy,
  3: cloudy,
  45: fog,
  48: fog,
  51: hail,
  53: hail,
  55: hail,
  56: hail,
  57: hail,
  61: rainy,
  63: rainy,
  65: rainy,
  66: rainy,
  67: rainy,
  71: snow,
  73: snow,
  75: snow,
  77: snow,
  80: rainy,
  81: rainy,
  82: rainy,
  85: snow,
  86: snow,

  95: thunderstorm,
  96: thunderstorm,
  99: thunderstorm,
};

const moonCodes = {
  0: moon0,
  1: moon1,
  2: moon2,
  3: moon3,
  4: moon4,
  5: moon5,
  6: moon6,
  7: moon7,
};

const WeatherIcon = ({icon = "", night, moon, code = null, width, className}) => {
  const iconFromCode = useMemo(() => {
    if (code === null) return clear;
    const resource = moon ? moonCodes : weatherCodes;
    const iconExist = resource[code];
    if (iconExist) {
      if (code === 0 && night && !moon) {
        return clearNight;
      }
      return iconExist;
    }
    return clear;
  }, [code, night]);

  const current = icons[icon];
  return (
    <img src={current || iconFromCode} alt={icon} style={{width}} className={cx("weather-icon", className)}/>
  )
}

export default WeatherIcon;
