import React, {useState, createContext, useCallback} from "react";

export const ErrorsContext = createContext({
  error: null,
  setError: () => {},
});

const ErrorsContextProvider = (props) => {
  const [error, onChangeError] = useState(null);

  const setError = useCallback((newError) => {
    onChangeError(newError)
  }, []);

  return (
    <ErrorsContext.Provider value={{
      error,
      setError
    }}>
      {props.children}
    </ErrorsContext.Provider>
  )
}

export default ErrorsContextProvider;
