import "./Modals.scss";
import React from "react";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import Button from "../../../../components/buttons/Button";

const PlayVideo = ({isOpen, onClose, video, photo}) => {
  const {download} = photo;

  const showMediaDropdown = download?.links.length > 1;
  const options = download?.links;
  const url = download?.links[0].url;
  const label = download?.links[0].label;

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={"Play Video"}>
      <div className="modal-content">
        <video className="modal-video-player" controls="controls">
          <source src={`${video?.src}`}/>
        </video>
      </div>
      <div className="modal-buttons">
        <Button onClick={onClose} title={"Close"} color={"outline"}/>
        {showMediaDropdown ? (
          <>
            {
              options.map((el) => (
                <a key={el.label} className="btn btn-green" href={el?.url} download>
                  <span className="btn-icon icon-download-alt"/>
                  <span>{el?.label}</span>
                </a>
              ))
            }
          </>
        ) : (
          <a className="btn btn-green lg modal" href={url} download>
            <span className="btn-icon icon-download-alt"/>
            <span>{label}</span>
          </a>
        )}
      </div>
    </ModalComponent>
  );
};
export default PlayVideo;
