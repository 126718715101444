import React, {useCallback, useEffect, useRef, useState} from "react";
import {
  EmailShareButton,
  EmailIcon,

  FacebookShareButton,
  FacebookIcon,

  TwitterShareButton,
  XIcon,
} from "react-share";
import "./sharelist.scss";
import Button from "../buttons/Button";
import ModalComponent from "../Modal/ModalComponent";

const ShareList = ({url}) => {
  const [isOpen, setIsOpen] = useState(false);
  const defaultText = "Check photo from my covert camera";
  const [text, onChangeText] = useState(defaultText)

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onChangeText(defaultText);
  }, []);

  return (
    <>
      <Button title={"Share"} icon={"share"} onClick={() => setIsOpen(true)} color={"grey"} size={"lg"} />
      <ModalComponent isOpen={isOpen} onClose={handleClose} title="Share photo" content={{width: 400}}>
        <div>
          <textarea
            className="textarea share-modal-textarea"
            placeholder={"Enter Description"}
            value={text}
            name="description"
            onChange={(event) => onChangeText(event.target.value)}
          />
          <h4 className="share-subtitle">Share via</h4>
          <div className="share-modal-socials">
            <div className="shareModalBtn">
              <FacebookShareButton url={url}>
                <FacebookIcon size={32} round />
              </FacebookShareButton>
            </div>

            <div className="shareModalBtn">
              <EmailShareButton
                url={url}
                subject={text}
                body={text + "\n" + url}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>


            <div className="shareModalBtn">
              <TwitterShareButton
                url={url}
                title={text}
              >
                <XIcon size={32} round />
              </TwitterShareButton>

            </div>
          </div>
        </div>
      </ModalComponent>
    </>
  );
}

export default ShareList;
