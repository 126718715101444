import "../CameraView.scss";
import React from "react";

const NotificationEmail = ({value, onClick}) => {
    return (
        <div className="input-group">
            <input type="text" readOnly={true} className="input" value={value} />
            <div className="icon icon-btn icon-trash" onClick={onClick} />
        </div>
    );
};

export default NotificationEmail;
