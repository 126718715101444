import "../CameraView.scss";
import cx from "classnames";
import React from "react";

const UsageItem = ({ title, head, info, alone }) => {
  return (
    <div className={cx("camera-settings-usage-input", {head: head, alone})}>
      <div className="camera-settings-usage-title">{title}</div>
      {info && (<div className="camera-settings-usage-txt">{info}</div>)}
    </div>
  );
};

export default UsageItem;
