import "./GuestAccountsList.scss";
import React, {useCallback, useState} from "react";
import Button from "../../../components/buttons/Button";

const GuestAccountsKeysList = ({list, onDelete, onKeyClick}) => {
  return (
    <div className="guest-accounts-list-section">
      <h4>Guest keys for others cameras</h4>
      <div className="guest-accounts-list">
        {list.map(account => {
          return <GuestAccountKey key={account.id} onDelete={() => onDelete(account.id)} account={account} onKeyClick={() => onKeyClick(account.id)}/>
        })}
      </div>
    </div>
  );
};


export default GuestAccountsKeysList;

const GuestAccountKey = ({account, onDelete, onKeyClick}) => {
  const [deleteLoading, onChangeDeleteLoading] = useState(false);

  const handleDelete = useCallback(async () => {
    onChangeDeleteLoading(true);
    await onDelete();
    onChangeDeleteLoading(false);
  }, []);

  return (
    <div className="guest-account-item">
      <div className="guest-account-name">{account.name}</div>
      <div className="guest-account-key">
        <div className="guest-account-key-text">{account.host.username}</div>
      </div>
      <div className="guest-account-btns">
        <Button size="sm" title="View" color="grey" onClick={onKeyClick}/>
        <Button size="sm" loader={deleteLoading} title="Delete" color="red" onClick={handleDelete}/>
      </div>
    </div>
  )
}
