import axios from "./auth-http-client";

export const AuthApi = {
  login: async (data) => {
    return axios.post(`/auth/login`, data);
  },
  register: async (data) => {
    return axios.post(`/account/register`, data);
  },
  signInAsUser: async (token) => {
    return axios.post(`/auth/sing-in-as`, { token });
  },
  countries: async () => axios.get("/glossary/countries"),
  states: async (countryId) => axios.get(`/glossary/states?countryId=${countryId}`),
};
