import "./Footer.scss";
import React from "react";
import {Link, useLocation} from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  const authRoutes = ["/start", "/support", "/register", "/login"];
  const isSupportRoute = location.pathname.includes("support");

  if (authRoutes.includes((location.pathname)) || isSupportRoute) return null;

  return (
    <section className="footer">
      <div className="footer-body">
        <div className="footer-body-info">
          <div className="footer-brand-logos">
            <Link className="footer-logo" to="/"/>
            <a href="https://www.onxmaps.com/hunt/app" target={"_blank"} className="onx-footer-logo"/>
          </div>
          <a
            target="_blank"
            href="https://www.covertscoutingcameras.com/"
            className="footer-site"
            rel="noreferrer"
          >
            Need a camera?
            <b className="footer-cite-page"> Visit our website</b>
          </a>
          <div className="footer-nav-list">
            <Link className="footer-nav-list-item" to="/">
              My Cameras
            </Link>
            <Link className="footer-nav-list-item" to="/guest-accounts">
              Guest Accounts
            </Link>
            <Link className="footer-nav-list-item" to="/guest-cameras">
              Guest Cameras
            </Link>
            <Link className="footer-nav-list-item" to="/report">
              Report
            </Link>
          </div>
        </div>
        <div className="footer-body-icons">
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.cando.covertwireless"
            className="footer-market_google"
            rel="noreferrer"
          ></a>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/covert-wireless/id1069038891?ls=1"
            className="footer-market_apple"
            rel="noreferrer"
          ></a>
        </div>
      </div>
      <hr className="header-divider"/>
      <div className="footer-line">
        <p className="footer-line-txt">© 2023 Covert. All rights reserved.</p>
      </div>
    </section>
  );
};

export default Footer;
