import React from "react";
import "./GraphicLoader.scss";

const GraphicLoader = ({color}) => {
    return (
        <div className="loader-container">
            <div className="loader" style={{borderTopColor: color}} />
        </div>
    );
};

export default GraphicLoader;
