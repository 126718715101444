import React, {useCallback, useMemo} from "react";
import {Outlet, useSearchParams} from "react-router-dom";
import fetcher from "../../../helpers/fetcher";
import {reports} from "../../../helpers/query-keys";
import GeneralTabs from "../tabs";
import {get, isEmpty} from "lodash";
import moment from "moment";
import qs from "qs";
import LineWaveLoader from "../../../components/Loader/LineWaveLoader";
import Filters from "./components/filters";
import ReportsApi from "../../../api/reports";

const GeneralReportsIndex = () => {
  const defaultParams = useMemo(() => {
    return {
      filter: {
        date_from: moment().subtract(1, "w").format("yyyy-MM-DD"),
        date_to: moment().format("yyyy-MM-DD")
      }
    }
  }, []);
  const structureQK = reports.structure.queryKey;
  const structureQuery = fetcher(structureQK, ReportsApi.reports);
  const [searchParams, setSearchParams] = useSearchParams();
  const paramsFromSearch = qs.parse(searchParams.toString());
  const params = isEmpty(paramsFromSearch) ? defaultParams : paramsFromSearch;


  const setFilter = useCallback((filter) => {
    setSearchParams(qs.stringify({
      ...params,
      filter
    }));
  }, [searchParams, setSearchParams]);

  const structure = useMemo(() => {
    if (structureQuery.data) {
      const str = get(structureQuery, ["data", "data", "photos"], {});
      return Object.keys(str)
        .map((key) => ({
          alias: key,
          name: str[key]
        }))
    }
    return [];
  }, [structureQuery]);

  return (
    <section className="page-wrapper">
      <div className="page-content">
        <GeneralTabs basePath="/reports" current="general"/>
        <div className="content-section">
          <div className="page-sub-title">General reports</div>
          {structureQuery.isFetched ? (
            <>
              <Filters cameras={[]} defaultFilter={defaultParams} filter={params.filter} setFilter={setFilter} />
              <Outlet context={{
                params,
                structure
              }}/>
            </>
          ) : <div className="loader-wrapper"><LineWaveLoader/></div>}
        </div>
      </div>
    </section>
  );
}

export default GeneralReportsIndex;
