import "./CameraList.scss";
import React, {useState, useCallback} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

//components
import CardList from "./components/CardList";
import FolderList from "./components/FolderList";
import RowList from "./components/RowList";
import AddCameraModal from "./AddCamera/AddCameraModal";
import {EmptySearch, Button} from "../../components/index";
import LineWaveLoader from '../../components/Loader/LineWaveLoader';

//modals
import CreateFolder from "./modals/CreateFolder";
import EditFolder from "./modals/EditFolder";
import DeleteFolder from "./modals/DeleteFolder";

import {CamerasAPI} from "../../api/cameras";
import {Tooltip} from "react-tooltip";
import fetcher from "../../helpers/fetcher";
import {camerasQK, foldersQK} from "../../helpers/query-keys";
import {useQueryClient} from "@tanstack/react-query";

const viewTypes = [
  {alias: "card", icon: "icon-cameras-card-list"},
  {alias: "row", icon: "icon-cameras-row-list"},
  {alias: "folder", icon: "icon-cameras-folder-list"},
];

export default function CameraList() {
  const queryClient = useQueryClient();
  const [list, setList] = useState(localStorage.getItem("listView") || "card");
  const [createFolder, setCreateFolder] = useState(false);
  const [editModalFolder, setEditModalFolder] = useState(false);
  const [deleteModalFolder, setDeleteModalFolder] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({});
  const [errors, setErrors] = useState({});
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showAddCameraModal, setShowAddCameraModal] = useState(false);

  const search = searchParams.get("search") || "";

  const camerasQueryKey = camerasQK.list(search).queryKey;
  const {
    data = {data: []},
    refetch: refetchCameras,
    isLoading: camerasLoading
  } = fetcher(camerasQueryKey, () => CamerasAPI.listCamera(search));

  const foldersQueryKey = foldersQK.list(search).queryKey;
  const {
    data: foldersData = {data: {folders: []}},
    refetch: refetchFolders,
    isLoading: foldersLoading
  } = fetcher(foldersQueryKey, () => CamerasAPI.listFolders(search));

  const cameras = data.data;
  const folders = foldersData.data.folders;
  const isLoading = foldersLoading || camerasLoading;

  const getList = useCallback(async () => {
    await refetchCameras();
    await refetchFolders();
  }, [refetchCameras, refetchFolders]);

  const pinCamera = useCallback(async (camera) => {
    if (camera.pinned) {
      await postUnPinCamera(camera.id);
    } else {
      await postPinCamera(camera.id);
    }
  }, []);

  const postPinCamera = useCallback(async (id) => {
    await CamerasAPI.pinCamera(id);
    await getList();
  }, [getList]);

  const postUnPinCamera = useCallback(async (id) => {
    await CamerasAPI.unPinCamera(id);
    await getList();
  }, [getList]);

  const editFolder = useCallback((folder) => {
    setCurrentFolder(folder);
    setEditModalFolder(true);
  }, []);

  const deleteFolder = useCallback((folder) => {
    setCurrentFolder(folder);
    setDeleteModalFolder(true);
  }, []);

  const handleViewSwitch = useCallback((viewType) => {
    localStorage.setItem("listView", viewType);
    setList(viewType);
  }, []);

  const onChangePinInFolder = useCallback((folder) => {
    const newFolders = folders.map((item) => {
      if (item.id === folder.id) return folder;
      return item;
    });
    queryClient.setQueryData(foldersQueryKey, {
      data: {
        folders: newFolders
      }
    });
  }, [queryClient, folders]);

  const onChangeCamerasOrder = useCallback((newCameras) => {
    queryClient.setQueryData(camerasQueryKey, {
      data: newCameras
    });
  }, [queryClient]);

  const CamerasListSwitch = useCallback(() => {
    switch (list) {
      case "folder":
        return (
          <FolderList
            folders={folders}
            search={search}
            onChangePin={onChangePinInFolder}
            getList={getList}
            editFolder={editFolder}
            deleteFolder={deleteFolder}
          />
        );

      case "row":
        return <RowList cameras={cameras} onChangeOrder={onChangeCamerasOrder} pinCamera={pinCamera} isLoading={isLoading}/>;

      case "card":
      default:
        return <CardList cameras={cameras} onChangeOrder={onChangeCamerasOrder} pinCamera={pinCamera} isLoading={isLoading}/>;
    }
  }, [list, folders, onChangeCamerasOrder, search, onChangePinInFolder, getList, editFolder, deleteFolder, pinCamera, cameras, isLoading]);

  const onFolderCreateClose = useCallback(() => {
    setCreateFolder(false);
  }, []);

  const onFolderWasCreated = useCallback((data) => {
    setCreateFolder(false);
    setErrors({});
    handleViewSwitch("folder");
    navigate(`/folders/${data.id}/photos`);
  }, [navigate]);

  const onDeleteFolderClose = useCallback(async (isDeleted = false) => {
    setDeleteModalFolder(false);
    setErrors({});
    if (isDeleted) {
      handleViewSwitch("folder");
      await getList();
    }
  }, [getList]);

  const onEditFolderClose = useCallback(async () => {
    setEditModalFolder(false);
    setErrors({});
    await getList();
  }, [getList]);

  return (
    <div className="page-wrapper">
      <CreateFolder
        setErrors={setErrors}
        onClose={onFolderCreateClose}
        onFolderWasCreated={onFolderWasCreated}
        refetchFolders={refetchFolders}
        cameras={cameras}
        isOpen={createFolder}
        errors={errors}
      />

      <EditFolder
        cameras={cameras}
        onClose={onEditFolderClose}
        folder={currentFolder}
        isOpen={editModalFolder}
        errors={errors}
        setErrors={setErrors}
      />

      <DeleteFolder onClose={onDeleteFolderClose} folder={currentFolder} isOpen={deleteModalFolder}/>

      {showAddCameraModal && (
          <AddCameraModal
            isOpen={showAddCameraModal}
            onClose={() => {
              setShowAddCameraModal(false);
            }}
          />
      )}

      <section className="camera-list-section">
        <div className="camera-list-header">
          <div className="camera-list-header-row">
            <h1 className="camera-list-header-title">My cameras</h1>
            <Button
              onClick={() => setShowAddCameraModal(true)}
              icon={"plus"}
              title={"Add camera"}
              color="grey"
            />
          </div>
          <div className="camera-list-header-row">
            <Button
              onClick={() => setCreateFolder(true)}
              title={"Create folder"}
              color="none"
              disabled={cameras.length === 0}
            />
            <div className="camera-list-header-list-style">
              {viewTypes.map((viewType) => {
                return (
                  <div
                    key={viewType.alias}
                    className={`camera-list-view-switcher ${viewType.icon} 
                                            ${viewType.alias === list ? "active" : ""}`}
                    onClick={() => handleViewSwitch(viewType.alias)}
                  />
                );
              })}
            </div>
          </div>
        </div>
        {isLoading ? <LineWaveLoader/> : cameras.length > 0 ? CamerasListSwitch() : <EmptySearch/>}
      </section>
      <Tooltip id={`camera-outdated-firmware-list`}/>
    </div>
  );
}
