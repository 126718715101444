import "./GuestCameras.scss";
import React, {useCallback, useMemo, useState} from "react";
import {Link, useOutletContext} from "react-router-dom";
import {GuestCameraPhotos} from "../../api/guest-camera-photos";
import Button from "../../components/buttons/Button";
import {mergePhotoPages} from "../../components/helpers";
import Filters from "./components/Filters";
import NoPhotos from "../../components/NoPhotos/NoPhotos";
import {photosFetcher} from "../../helpers/fetcher";
import {guestQK} from "../../helpers/query-keys";
import {last} from "lodash";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";

const GuestCameraPhotosList = () => {
  const [camera] = useOutletContext();
  const qk = guestQK.oneCameraPhotos(camera.id);
  const [view, setView] = useState(localStorage.getItem("guest-photos-view") || "three");
  const {data: photosQueryData, fetchNextPage, isFetched, isLoading, isFetching} = photosFetcher(qk,
    async ({pageParam}) => {
      if (pageParam) {
        const {data} = await GuestCameraPhotos.loadMorePhotos(camera.id, pageParam);
        return data;
      }
      const {data} = await GuestCameraPhotos.listByDates(camera.id);
      return data;
    });

  const meta = useMemo(() => {
    if (photosQueryData?.pages) {
      const tail = last(photosQueryData.pages);
      return tail._meta || {};
    }
    return {};
  }, [photosQueryData])

  const noPhotos = meta.totalCount < 1;

  const loadMore = useCallback(async () => {
    await fetchNextPage();
  }, [fetchNextPage]);

  const listByDays = mergePhotoPages(photosQueryData?.pages);

  function onSetViewClick(view) {
    localStorage.setItem("guest-photos-view", view);
    setView(view);
  }

  if (noPhotos && isFetched) {
    return (
      <section className="content-section no-body">
        <NoPhotos/>
      </section>
    );
  }

  return (
    <section className="content-section">
      <Filters
        view={view}
        onSetViewClick={onSetViewClick}
      />
      {isLoading && <LineWaveLoader/>}
      <div className={`photos-list-container guest-camera-photos-list ${view}`}>
        {Object.keys(listByDays).length > 0 ? <RenderPhotoList listByDays={listByDays}/> : null}
      </div>
      {
        meta.pageCount > meta.currentPage &&
        <div className="load-more-photos">
          <Button color={"red-outline"} disabled={isFetching} size={"lg w100"} title={"Load More"}
                  onClick={loadMore}/>
        </div>
      }

    </section>
  );
};

const RenderPhotoList = ({listByDays}) => {
  const days = Object.keys(listByDays);

  return days.map(day => {
    const {date, list} = listByDays[day];

    return (
      <div className="photos-for-days" key={day}>
        <div className="photos-date">
          {date}
        </div>
        <div className="photos-list">
          {
            list && list.map(photo => {
              return (
                <Link to={`${photo.id}`} className="photo-item" key={photo.id}>
                  <img src={photo.thumb} alt=""/>
                </Link>
              );
            })
          }
        </div>
      </div>
    );
  });
}

export default GuestCameraPhotosList;
