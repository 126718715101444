import React, {useCallback} from "react";
import { useQueryClient } from "@tanstack/react-query";
import {CameraHeader} from "../../components";
import {Link, Navigate, useParams} from "react-router-dom";
import {CamerasAPI} from "../../api/cameras";
import CameraPreview from "../../components/CameraPreview/CameraPreview";
import {Outlet} from "react-router-dom";
import {Tooltip} from "react-tooltip";
import status from "../../helpers/status";
import fetcher from "../../helpers/fetcher";
import {camerasSettings} from "../../helpers/query-keys";

const CameraIndex = () => {
    let {id} = useParams();
    const queryClient = useQueryClient();
    const cameraSettingsQK = camerasSettings.one(id).queryKey;
    const {data = { data: {}}, isLoading, refetch, isFetched, failureReason} = fetcher(cameraSettingsQK, () => CamerasAPI.cameraInfo(id), {
        throwOnError: false
    });
    const camera = data.data || {};
    const suspended = camera.status === status.STATUS_SUSPEND;

    const setCamera = useCallback((newCameraData) => {
        queryClient.setQueryData(cameraSettingsQK, {
            ...data,
            data: newCameraData
        });
    }, [queryClient]);

    if (failureReason && failureReason.response && failureReason.response.status) {
        if (failureReason.response.status === 404) {
            return <Navigate to="/not-found-page" />
        }
        if (failureReason.response.status === 500) {
            return <Navigate to="/server-error-page" />
        }
    }

    return (
        <section className="page-wrapper">
            <div className="page-content">
                <CameraHeader isSettings camera={camera} loading={isLoading} suspended={suspended} actions={camera.actions || {}}/>
                <div className="camera-view">
                    <div className="camera-view-short">
                        <CameraPreview isSettings loading={isLoading} camera={camera} />
                        <Link to={"/"} className="btn btn-grey lg w100">
                            <span className="btn-icon-arrow" />
                            <span>Back to my cameras</span>
                        </Link>
                    </div>
                    <div className="camera-view-content">
                        {isFetched && <Outlet context={[camera, setCamera, isFetched, suspended, refetch]}/>}
                    </div>
                </div>
            </div>
            <Tooltip id={"camera-preview-outdated-firmware"} />
        </section>
    );
};

export default CameraIndex;
