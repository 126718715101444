import React from "react";
import "../GuestCameras.scss";
import {Link} from "react-router-dom";

const GuestCamera = ({camera}) => {
  return (
      <Link to={`${camera?.id}/photos`} className="guest-camera-item">
        <div className="guest-camera-photo">
          <img src={camera?.photo} alt=""/>
        </div>
        <div className="guest-camera-name">{camera.name}</div>
        <div className="guest-camera-model">{camera.model} {camera.type} IMEI: {camera.meid}</div>
      </Link>
  );
};

export default GuestCamera;
