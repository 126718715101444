import React from "react";
import {Link} from "react-router-dom";
import Search from "./Search/Search";

const HeaderTop = () => {
    return (
        <section className="header-top">
            <div className="website-version">alpha</div>
            <Link className="header_section_logo" to="/"/>
            <Search/>
            <a target="_blank" href="https://covert-wireless.com/privacy-policy" rel="noreferrer" className="header_section_link">
                Privacy Policy
            </a>
            <a target="_blank" href="https://www.covertscoutingcameras.com/" rel="noreferrer" className="header_section_link visit-our-website">
                Visit our website
            </a>
            <div className="header-download-apps">
                <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.cando.covertwireless" className="header_section_market_google"/>
                <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/covert-wireless/id1069038891?ls=1" className="header_section_market_apple"/>
            </div>
        </section>
    );
};

export default HeaderTop;
