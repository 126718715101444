import "./AddCamera.scss";
import React, {useMemo, useState} from "react";
import Button from "../../../components/buttons/Button";

const CameraInfo = ({info, onNext, onBack}) => {
  const [form, setForm] = useState({
    name: info?.name || "",
    description: info?.description || "",
  });

  const onChange = (e) => {
    const {name, value} = e.target;

    setForm({
      ...form,
      [name]: value
    })
  }

  const onNextClick = (event) => {
    event.preventDefault();
    onNext("init", form, "general");
  };

  const onBackClick = () => {
    onBack("init");
  }

  const nextButtonDisabled = useMemo(() => {
    return !(form.name);
  }, [form])

  const {cameraInfo, imei, iccid} = info;

  return (
    <form onSubmit={onNextClick}>
      <div className="modal-content">
        <div className="camera-add-info">
          <div className="camera-add-info-photo">
            <img src={cameraInfo.photo} alt=""/>
          </div>
          <div className="camera-add-info-line">
            <div className="camera-add-info-name">Model/Year</div>
            <div className="camera-add-info-value">{cameraInfo.name} / {cameraInfo.year}</div>
          </div>
          <div className="camera-add-info-line">
            <div className="camera-add-info-name">Provider</div>
            <div className="camera-add-info-value">{cameraInfo.wirelessType}</div>
          </div>
          <div className="camera-add-info-line">
            <div className="camera-add-info-name">IMEI</div>
            <div className="camera-add-info-value">{imei}</div>
          </div>
          <div className="camera-add-info-line">
            <div className="camera-add-info-name">ICCID</div>
            <div className="camera-add-info-value">{iccid}</div>
          </div>
        </div>
        <div>
          <div className="form-group">
            <label>Name</label>
            <input
              type={"text"}
              placeholder={"Enter Name"}
              className="input"
              name="name"
              required
              value={form?.name || ""}
              onChange={onChange}
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea
              className="textarea"
              placeholder={"Enter Description"}
              value={form?.description || ""}
              name="description"
              onChange={onChange}
            />
          </div>
        </div>
      </div>
      <div className="modal-buttons">
        <Button onClick={onBackClick} type="button" title={"Back"} color="outline"/>
        <Button type="submit" title={"Next"} disabled={nextButtonDisabled} color="grey"/>
      </div>
    </form>
  );
};

export default CameraInfo;
