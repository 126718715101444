import "./Modals.scss";
import React, {useEffect, useState} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import LineWaveLoader from "../../../components/Loader/LineWaveLoader";
import { SessionsAPI } from "../../../api/sessions";

const ViewDevice = ({id, isOpen, onClose, onDeleteClick}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [device, setDevice] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setDevice({});
            const responseDevice = await SessionsAPI.view(id);
            setDevice(responseDevice.data);
            setIsLoading(false);
        };

        if (id) {
            fetchData();
        }
    }, [id]);

    const closeModal = () => {
        onClose();
    }

    return (
        <BaseModal isOpen={isOpen} onClose={closeModal}>
            <section className="modal-body create-guest-account-modal">
                <Header name={"View device"} onClose={closeModal}/>
                {isLoading
                    ? <LineWaveLoader/>
                    : <div className="modal-content">
                        <div className="account-device-info">
                            <div className="account-device-info-row">
                                <div className="info-category">App Version</div>
                                <div className="info-value">{device.appVersion || "0.0.0"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">Create date</div>
                                <div className="info-value">{device.createdAt || "Unknown"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">Platform</div>
                                <div className="info-value">{device.platform || "Unknown"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">OS</div>
                                <div className="info-value">{device.os || "Unknown"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">Manufacturer</div>
                                <div className="info-value">{device.manufacturer || "Unknown"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">Model</div>
                                <div className="info-value">{device.model || "Unknown"}</div>
                            </div>
                            <div className="account-device-info-row">
                                <div className="info-category">OS Version</div>
                                <div className="info-value">{device.version || "Unknown"}</div>
                            </div>
                        </div>
                    </div>
                }
                <div className="modal-buttons">
                    <Button
                        onClick={closeModal}
                        title={"Close"}
                        color={"outline"}
                    />
                    <Button
                        onClick={onDeleteClick}
                        icon={"trash-alt"}
                        title={"Remove device"}
                        color={"grey"}
                    />
                </div>
            </section>
        </BaseModal>
    );
};
export default ViewDevice;
