import React from "react";
import Tabs from "../innerTabs";
import {reports} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";
import ReportsApi from "../../../api/reports";
import { omit } from "lodash";
import { useOutletContext, useParams } from "react-router-dom";
import "./recognition.scss";
import {prepareDataForGraph} from "../helper";
import {AreaGraphic, BarGraphic} from "../../../components/Graphic/Graphic";
import cx from "classnames";

export const RecognitionGraphReport = ({type, width = 1080, recognitions, id, params}) => {
  const qk = reports.recognition(type, id, params).queryKey;
  const { data = {
    data: {
      label: "",
      datasets: {}
    }
  }, isLoading } = fetcher(qk, () => ReportsApi.recognitionLabel(type, id, params));

  const { graphData, graphType = "AreaGraphic", labels, y, yy } = prepareDataForGraph(data.data);

  const props = {
    height: 500,
    width,
    loading: isLoading,
    data: graphData,
    x: "label",
    y,
    labels,
    yy,
  };

  const graphs = {
    AreaGraphic,
    BarGraphic,
  };

  const recognition = recognitions.find((item) => item.id == id);

  return (
    <div className="recognition-graph-wrapper">
      <h4 className="recognition-graph-title">
        <span className={cx("recognition-icon dark-icon active-recognition-icon", `icon-${recognition?.label}`)}/>
        {recognition?.name || data.data.label}
      </h4>
      {React.createElement(graphs[graphType], props)}
    </div>
  )
}

const RecognitionGraphReports = () => {
  const { type } = useParams();
  const { params, recognitions, structure } = useOutletContext();

  const labels = params?.filter?.labels || [];

  const correctParams = {
    filter: omit(params.filter, "labels")
  };

  return (
    <>
      <Tabs list={structure} route="recognition" current={type}/>
      {labels.map((id) => (
        <RecognitionGraphReport recognitions={recognitions} key={id} id={id} params={correctParams} type={type} />
      ))}
    </>
  )
}

export default RecognitionGraphReports;
