import "./AddCamera.scss";
import React, {useEffect, useRef, useState} from "react";
import cx from "classnames";
import checkGreen from "../../../images/iconSVG/elements/check_green.svg";
import Button from "../../../components/buttons/Button";
import SettingsLine, { checkIsVisible, RenderControlsItem } from "../../../components/CameraSettings";

const SettingsSection = ({ section, checked, show, title, form, onChange, onToggleChange }) => {
  const settingsMapNames = Object.keys(section);
  const content = useRef();
  const [height, onChangeHeight] = useState(40);

  useEffect(() => {
    if (content.current) {
      const contentSize = content.current?.getBoundingClientRect();
      if (show) {
        onChangeHeight((contentSize?.height || 0) + 40 + 36);
        return;
      }
      onChangeHeight(40);
    }
  }, [content, form, show]);

  return (
    <div className={cx("add-camera-settings-wrapper", {contentShown: show})} style={{ height: show ? height : 40 }}>
      <div className={cx("add-camera-settings-header", {
        sectionChecked: checked
      })}>
        <span>{title}</span>
        <span className="add-camera-settings-header-icon">
          {checked
            ? <img src={checkGreen} alt="ch"/>
            : null
          }
        </span>
      </div>
      <div className="add-camera-settings-content" ref={content}>
        {settingsMapNames.map((name, index) => {
          const option = section[name];
          const fieldName = option.alias || name;
          const currentValue = form[fieldName];
          const isVisible = option["dependsOn"] ? checkIsVisible(option["dependsOn"], form) : true;

          return (
            <SettingsLine
              key={`${name}-${index}`}
              label={option.label}
              isVisible={isVisible}
              indent={option.indent}
            >
              <RenderControlsItem
                option={option}
                fieldName={fieldName}
                currentValue={currentValue}
                onChange={onChange}
                onToggleChange={onToggleChange}
                disabled={!isVisible}
              />
            </SettingsLine>
          );
        })}
      </div>
    </div>
  )
}

const AddCameraSettings = ({settings = {}, values = {}, info = {}, onNext, onBack}) => {
  const [form, setForm] = useState(values);
  const sectionsKeys = Object.keys(settings);
  const [show, onChangeShow] = useState(0);

  const onChange = (event) => {
    const {name, value} = event.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const onToggleChange = (event) => {
    const {name, checked} = event.target;
    setForm({
      ...form,
      [name]: checked ? 1 : 0,
    });
  };

  const onNextClick = (event) => {
    event.preventDefault();
    if (show >= sectionsKeys.length - 1) {
      onNext("info", {
        settings: {
          ...(info.settings || {}),
          ...form
        },
      }, "choose-subscription");
      return;
    }
    onChangeShow(show + 1);
  };

  const onBackClick = () => {
    if (show < 1) {
      onBack("info");
      return;
    }
    onChangeShow(show - 1);
  }

  return (
    <form onSubmit={onNextClick}>
      <div className="modal-content">
        {
          sectionsKeys.map((sectionName, idx) => (
            <SettingsSection
              title={sectionName}
              section={settings[sectionName]}
              key={sectionName}
              show={show === idx}
              idx={idx + 1}
              checked={show > idx}
              onChange={onChange}
              onToggleChange={onToggleChange}
              form={form}
            />
          ))
        }
      </div>
      <div className="modal-buttons">
        <Button onClick={onBackClick} type="button" title={"Back"} color="outline"/>
        <Button type="submit" title="Next" color="grey"/>
      </div>
    </form>
  );
};

export default AddCameraSettings;
