import React, {useCallback, useMemo, useState} from "react";
import Button from "../buttons/Button";
import ModalComponent from "../Modal/ModalComponent";
import TagsInput from "../TagsInput";
import TagsAPI from "../../api/tags";

const renderTag =  ({tag, key, disabled, onRemove, classNameRemove, getTagDisplayValue, ...other}) => {
  return (
    <span key={key} {...other} className="react-tagsinput-tag recognition-tag">
      <span>
        {tag?.name || tag}
      </span>
      {!disabled &&
        <a className={classNameRemove} onClick={() => onRemove(key)} />
      }
    </span>
  )
}

const EditTagsModal = ({tags = [], label = "Tags for photo", title = "Edit tags", onSubmit, onClose}) => {
  const [localTags, onChangeLocalTags] = useState(tags.map((item) => ({...item, id: item.tag_id})));
  const [loading, onChangeLoading] = useState(false);
  const [suggestions, onChangeSuggestions] = useState([]);

  const onLoad = useCallback( async (value) => {
    if (value !== "") {
      try {
        const { data } = await TagsAPI.get(value);
        onChangeSuggestions(data.list || []);
      } catch (e) {
        console.log(e)
      }
    }
  }, []);

  const handleChangeLocalTags = useCallback(async (tags) => {
    const [last] = [...tags].reverse();
    if (last?.id) {
      onChangeLocalTags(tags);
      return;
    }
    if (last) {
      const lastPresentInSuggestions = suggestions.find((item) => item.name === last);
      if (lastPresentInSuggestions) {
        onChangeLocalTags([...localTags, lastPresentInSuggestions]);
        return;
      }
      try {
        const {data} = await TagsAPI.create(last);
        onChangeLocalTags([...localTags, data]);
      } catch (e) {
        console.log(e);
      }
    } else {
      onChangeLocalTags([]);
    }
  }, [localTags, suggestions])

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    try {
      onChangeLoading(true);
      const localIds = localTags.map((item) => item.id);
      await onSubmit(localIds);
      onChangeLoading(false);
    } catch (e) {
      console.log(e);
      onChangeLoading(false);
    }
  }, [localTags, onSubmit]);

  const renderedSuggestions = useMemo(() => {
    if (suggestions.length > 0 && localTags.length > 0) {
      const localIds = localTags.map((item) => item.id);
      return suggestions.filter((item) => !localIds.includes(item.id));
    }
    return suggestions;
  }, [localTags, suggestions]);

  return (
    <ModalComponent isOpen onClose={onClose} title={title} content={{width: 400}}>
      <form onSubmit={handleSubmit}>
        <div className="modal-content">
          <TagsInput
            name="tags"
            label={label}
            overflow
            renderTag={renderTag}
            suggestions={renderedSuggestions}
            onLoad={onLoad}
            tags={localTags}
            onChange={handleChangeLocalTags}
          />
        </div>
        <div className="modal-buttons">
          <Button
            onClick={onClose}
            title={"Close"}
            color="outline"
            type="button"
          />
          <Button
            title="Save"
            type="submit"
            loader={loading}
            color="grey"
          />
        </div>
      </form>
    </ModalComponent>
  )
}

const RecognitionTags = ({ item, index, onSubmit }) => {
  const defaultTags = item.tags.map((item) => ({...item, id: item.tag_id}));
  const [localTags, onChangeLocalTags] = useState(defaultTags);
  const [loading, onChangeLoading] = useState(false);
  const [edit, onChangeEdit] = useState(false);
  const [suggestions, onChangeSuggestions] = useState([]);

  const onLoad = useCallback( async (value) => {
    if (value !== "") {
      try {
        const { data } = await TagsAPI.get(value);
        onChangeSuggestions(data.list || []);
      } catch (e) {
        console.log(e)
      }
    }
  }, []);

  const handleChangeLocalTags = useCallback(async (tags) => {
    const [last] = [...tags].reverse();
    if (last?.id) {
      onChangeLocalTags(tags);
      return;
    }
    if (last) {
      const lastPresentInSuggestions = suggestions.find((item) => item.name === last);
      if (lastPresentInSuggestions) {
        onChangeLocalTags([...localTags, lastPresentInSuggestions]);
        return;
      }
      try {
        const {data} = await TagsAPI.create(last);
        onChangeLocalTags([...localTags, data]);
      } catch (e) {
        console.log(e);
      }
    } else {
      onChangeLocalTags([]);
    }
  }, [localTags, suggestions]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    try {
      onChangeLoading(true);
      const localIds = localTags.map((item) => item.id);
      await onSubmit(localIds, item);
      onChangeLoading(false);
      onChangeEdit(false);
    } catch (e) {
      console.log(e);
      onChangeLoading(false);
    }
  }, [localTags, item, onSubmit]);

  const renderedSuggestions = useMemo(() => {
    if (suggestions.length > 0 && localTags.length > 0) {
      const localIds = localTags.map((item) => item.id);
      return suggestions.filter((item) => !localIds.includes(item.id));
    }
    return suggestions;
  }, [localTags, suggestions]);

  const handleCancelEdit = useCallback(() => {
    onChangeEdit(false);
    onChangeLocalTags(defaultTags);
  }, [item, defaultTags]);

  if (!edit) {
    return (
      <div className="recognition-tags-view-wrapper">
        <div className="recognition-tags-view">
          <div className="recognition-tags-view-label">
            <span className={`recognition-icon dark-icon icon-${item.label}`}/>
            <span className={`recognition-text color-${index}`}>
            {item.name} {parseFloat(item.confidence * 100).toFixed(2)}%
          </span>
          </div>
          <div className="recognition-tags-view-tags">
            {(item.tags || []).map((tag) => (
              <span className="tag" key={tag.id}>{tag.name}</span>
            ))}
          </div>
        </div>
        <div className="recognition-tags-view-tags-edit">
          <Button
            color="grey"
            onClick={() => onChangeEdit(true)}
            title="Edit"
          />
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit} className="recognition-tags-view-wrapper editMode">
      <div className="recognition-tags-view">
        <div className="recognition-tags-view-label">
          <span className={`recognition-icon dark-icon icon-${item.label}`}/>
          <span className={`recognition-text color-${index}`}>
            {item.name} {parseFloat(item.confidence * 100).toFixed(2)}%
          </span>
        </div>
        <div className="recognition-tags-view-tags">
          <TagsInput
            name="tags"
            overflow
            renderTag={renderTag}
            suggestions={renderedSuggestions}
            onLoad={onLoad}
            tags={localTags}
            onChange={handleChangeLocalTags}
          />
        </div>
      </div>
      <div className="recognition-tags-view-tags-edit-ctrls">
        <Button
          onClick={handleCancelEdit}
          title={"Cancel"}
          size="lg"
          color="outline"
          type="button"
        />
        <Button
          title="Save"
          size="lg"
          type="submit"
          loader={loading}
          color="grey"
        />
      </div>
    </form>
  )
}


const EditRecognitionTagsModal = ({recognitions, title = "Edit tags", onSubmit, onClose}) => {

  return (
    <ModalComponent isOpen onClose={onClose} title={title} content={{width: recognitions ? 600 : 400}}>
      <div>
        {recognitions.map((item, index) => (
          <RecognitionTags item={item} index={index} key={item.id} onSubmit={onSubmit}/>
        ))}
        <div className="modal-buttons">
          <Button
            onClick={onClose}
            title={"Close"}
            color="outline"
            type="button"
          />
        </div>
      </div>
    </ModalComponent>
  );
}

const EditTags = ({ onClose, btnText = "Edit tags", size = "lg", color = "grey", className, recognitions, onSubmit, label, title, onOpen, tags = [], open }) => {
  const EditTagsComponent = recognitions ? EditRecognitionTagsModal : EditTagsModal;
  return (
    <>
      <Button
        onClick={onOpen}
        className={className}
        title={btnText}
        icon={color === "grey" ? "white-tags" : "tags"}
        color={color}
        size={size}
      />
      {open && <EditTagsComponent recognitions={recognitions} title={title} label={label} onSubmit={onSubmit} tags={tags} onClose={onClose} />}
    </>
  )
}

export default EditTags;
