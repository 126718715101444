import React, {useCallback, useEffect, useMemo, useState} from "react";
import cx from "classnames";
import ActionsDropdown from "../ActionsDropdown/ActionsDropdown";
import {useConfirmationModalContext} from "../../context/confirmation";
import {AccountCameraAPI} from "../../api/account-camera";
import {toast} from "react-toastify";
import {compact} from "lodash";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import Button from "../buttons/Button";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import qs from "qs";

const commandText = {
  "realtime-photo": "Capture real time photo?",
  "request-location": "Send location request?",
  "request-firmware-update": "Send firmware update request?"
};

const Header = ({camera, isSettings, isWeather, streamsAllowed, stream, loading, suspended, actions = {}}) => {
  const navigate = useNavigate();
  const {showConfirmation} = useConfirmationModalContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const [showLinked, onChangeShowLinked] = useState(false);
  const params = qs.parse(searchParams.toString());
  const cameraCommandsQK = camerasSettings.commands(camera.id).queryKey;
  const {refetch} = fetcher(cameraCommandsQK, () => AccountCameraAPI.commands(camera.id), {
    enabled: false
  });

  const onOptionClick = async (alias) => {
    const command = actions[alias] || {};
    const text = commandText[command.alias] || `${command.label}?`;
    if (["delete-folder", "edit-folder"].includes(command.alias) && command.onClick) {
      command.onClick();
      return;
    }
    const confirm = await showConfirmation(text);
    if (!confirm || !command.alias) return;
    try {
      const response = await AccountCameraAPI.sendCommand(camera.id, command.alias);
      const {data} = response;
      toast.info(data.message);
    } catch (error) {
      toast.error(`Unable to send command. Reason: ${(error?.response?.data?.message || "Unknown server error")}`);
    } finally {
      await refetch();
    }
  };

  const goToStreamOrPhotos = () => {
    navigate(`/cameras/${camera.id}/${stream ? "photos" : "stream"}`)
  }

  const linkedCameras = useMemo(() => {
    return compact(Object.values(camera.linkedCameras || {}));
  });

  const handleChangeCheckbox = useCallback((id) => {
    const linked = params.filters?.linked || [];
    const newLinked = linked.includes(id) ? linked.filter((item) => item !== id) : [...linked, id];
    setSearchParams(qs.stringify({...params, filters: {...(params.filters || {}), linked: newLinked}}));
  }, [params, setSearchParams]);


  const handleOutsideClick = (event) => {
    const inElem = event.target?.closest(`.linked-cameras-dropdown`);
    if (showLinked && !inElem) {
      onChangeShowLinked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <>
      <section className="header-settings">
        <p className="header-settings-name">{loading ? "Loading..." : camera.name}</p>
        <div className="header-settings-right-side">
          {streamsAllowed
            ? <Button
                title={stream ? "Show photos" : "Live"}
                color="green"
                size="lg"
                onClick={goToStreamOrPhotos}
              />
            : null
          }
          <Link to={`/cameras/${camera.id}/${isWeather ? "photos" : "weather/today"}`}>
            <Button
              title={isWeather ? "Show photos" : "Weather"}
              color="grey"
              size="lg"
            />
          </Link>
          {linkedCameras.length && !isSettings > 0
            ? <div className={cx("linked-cameras-dropdown", {showLinked})}>
              <Button
                title={`Linked cameras: ${linkedCameras.length}`}
                color="grey"
                onClick={() => onChangeShowLinked(!showLinked)}
                icon={params.filters?.linked ? "camera-red" : "camera"}
                size="lg"
              />
              <div className="camera-linked-list-wrapper">
                <ul>
                  {linkedCameras.map((linkedCamera) => (
                    <li key={linkedCamera.id} role="button" onClick={() => handleChangeCheckbox(`${linkedCamera.id}`)}>
                      <input
                        type="checkbox"
                        value={`${linkedCamera.id}`}
                        onChange={() => handleChangeCheckbox(`${linkedCamera.id}`)}
                        checked={params.filters?.linked ? params.filters.linked.includes(`${linkedCamera.id}`) : false}
                      />
                      {linkedCamera.slot}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            : null
          }
          {Object.keys(actions).length > 0 &&
            <ActionsDropdown disabled={suspended} options={actions} onOptionClick={onOptionClick}/>}
        </div>
      </section>
    </>
  );
};

export default Header;
