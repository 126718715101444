import "./Button.scss";
import React from "react";

const ButtonStandard = ({ title, color, onClick, icon, disabled }) => {
  return (
    <button onClick={onClick} className={`btn ${color} lg`} disabled={disabled}>
      <span>{title}</span>
      {icon && <div className={`btn-icon-down`}></div>}
    </button>
  );
};

export default ButtonStandard;
