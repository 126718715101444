import React from "react";
import _ from "lodash";
import isDeepEqual from 'fast-deep-equal/react';

export function mergePhotosLists (target, source) {
    return [].concat(Object.keys(target), Object.keys(source)).reduce((result, key) => {
        if (target[key] && source[key]) {
            result[key] = Object.assign({}, { date: target[key].date, list: target[key].list.concat(source[key].list) })
        } else {
            result[key] = target[key] || source[key]
        }
        return result
    }, {})
}

export function mergePhotoPages(pages = []) {
    return pages
      .reduce((res, page) => (
        [...res, page.list]
      ), [])
      .reduce((res, list) => (
        Object.keys(list)
          .reduce((result, key) => {
              if (result[key] && list[key]) {
                  return {
                      ...result,
                      [key]: {
                          ...result[key],
                          list: [
                              ...result[key].list,
                              ...list[key].list
                          ]
                      }
                  }
              }
              return {
                  ...result,
                  [key]: list[key]
              }
          }, res)
      ), {});
}

export function fromPhotoId(listByDates) {
    const days = Object.keys(listByDates);
    const lastDay = days[days.length - 1];

    const {list} = listByDates[lastDay];

    const {id} = list[list.length - 1];

    return id;
}

export function lastPhotoId(listByDates = {}) {
    const days = Object.keys(listByDates);
    const lastDay = _.last(days);
    if (lastDay) {
        const {list} = listByDates[lastDay];

        const {id} = list[list.length - 1];

        return id;
    }
    return undefined;
}

export function lastId(list = []) {
    const last = _.last(list);
    if (last) {
        return last.id;
    }
    return undefined;
}

export function transformUrlSearchParams(urlSearchParams) {

    if (!urlSearchParams) {
        return {};
    }

    return Object.fromEntries(urlSearchParams.entries());
}

export function isEmptyObject(obj) {
    return Object.keys(obj).length === 0;
}

export function isValidImei(imei) {
    return imei.length === 15 && /^\d+$/.test(imei);
}

export function isValidIccid(iccid) {
    return iccid.length >= 15 && iccid.length <= 24 && /^\d+$/.test(iccid);
}

export function dec2bin(dec) {
    return (dec >>> 0).toString(2);
}

export function highlightSearch(value, search) {
    const parts = value.toString().split(new RegExp(`(${search})`, "gi"));

    return parts.map((item, index) => {
        if (item.toLowerCase() === search.toString().toLowerCase()) {
            return <mark key={index}>{item}</mark>;
        }

        return <span key={index}>{item}</span>
    })
}

export function isEqual(a, b) {
    return isDeepEqual(a, b);
}

const VISITED_CAMERAS_KEY = 'visitedCameras';

export function pushToVisitedCamerasList(camera) {
    const cameras = JSON.parse(localStorage.getItem(VISITED_CAMERAS_KEY)) || [];
    localStorage.setItem(
        VISITED_CAMERAS_KEY,
        JSON.stringify([
            camera,
            ...cameras.filter((item) => item.id !== camera.id).slice(0, 4)
        ])
    );
}

export function getVisitedCamerasList() {
    return JSON.parse(localStorage.getItem(VISITED_CAMERAS_KEY)) || [];
}


