import React from "react";
import Nav from "../../components/Nav/Nav";
import "./reports.scss";

const ReportsInnerTabs = ({list = [], route, current}) => {
  return (
    <Nav list={list} withSearch className="reports-inner-nav" basePath={`/reports/${route}`} current={current} />
  );
}

export default ReportsInnerTabs;
