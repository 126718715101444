import axios from "./http-api-client";

export const ProfileAPI = {
  profile: async () => {
    return axios.get(`/profile`);
  },
  updateProfile: async (data) => {
    return axios.post(`/profile`, {
      data
    });
  },

  address: async () => {
    return axios.get(`/profile/address`);
  },

  balance: async () => {
    return axios.get(`/account/payments/balance`);
  },

  payNow: async () => {
    return axios.post(`/account/payments/pay`);
  },

  subscriptions: async () => {
    return axios.get(`/account/subscriptions`);
  },

  addons: async () => {
    return axios.get(`/account/subscriptions/addons`);
  },

  addSubscription: async (data) => {
    return axios.post(`/account/subscriptions`, data)
  },

  updateOnXIntegration: async (enabled) => {
    return axios.post(`/account/settings/on-x`, {enabled: enabled})
  },

  onXIntegrationLogout: async () => {
    return axios.post(`/account/settings/on-x-logout`)
  },

  onxAuth: async () => {
    return axios.get(`gateway/on-x`)
  },

  billing: async () => {
    return axios.post(`/profile/billing`);
  },

  paymentWidget: async () => {
    return axios.post(`/profile/payment-widget`);
  },

  passwordWidget: async () => {
    return axios.post(`/profile/password-widget`);
  },

  updatePushNotificationSetting: async (data) => {
    return axios.post(`account/settings/notifications`, data);
  },

  updateRecognitionSetting: async (data) => {
    return axios.post(`account/settings/recognition`, data);
  }
};
