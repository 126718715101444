import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import "./MultiSelect.scss";

const animatedComponents = makeAnimated();

const MultiSelect = ({name, value, placeholder, options, onChange}) => {
  return (
    <Select
      closeMenuOnSelect={false}
      components={animatedComponents}
      unstyled={true}
      placeholder={placeholder}
      value={value}
      isMulti
      onChange={(values) => {
        onChange(name, values);
      }}
      menuPortalTarget={document.body}
      options={options}
      className={"multi-custom-select-container"}
      classNamePrefix={"multi-custom-select"}
    />
  );
};

export default MultiSelect;
