import React from "react";
import WeatherIcon from "../../components/WeatherIcon";

const WeatherTable = ({list}) => {
  return (
    <table className="camera-weather-table">
      <thead>
        <tr>
          <th>Time</th>
          <th>Temperature</th>
          <th>Condition</th>
          <th>Precipitation</th>
          <th>Humidity</th>
          <th>Wind</th>
          <th>Moon</th>
        </tr>
      </thead>
      <tbody>
      {list.map((item, idx) => (
        <tr key={idx}>
          <td className="nowrap">{item.time}</td>
          <td>
            <strong>{item.temperature || "N/A"}</strong>
          </td>
          <td>
              <span className="cell-with-icon">
                <WeatherIcon night={!item.isDay} width={30} className="weather-table-icon" code={item.weatherCode?.code}/><strong>{item.weatherCode?.description || "N/A"}</strong>
              </span>
          </td>
          <td>
              <span className="cell-with-icon">
                <WeatherIcon width={30} className="weather-table-icon" icon="precipitation"/><strong>{item.precipitationProbability || "N/A"}</strong>
              </span>
          </td>
          <td>
              <span className="cell-with-icon">
                <WeatherIcon width={30} className="weather-table-icon" icon="humidity"/><strong>{item.relativeHumidity || "N/A"}</strong>
              </span>
          </td>
          <td>
              <span className="cell-with-icon nowrap">
                <WeatherIcon icon="wind" width={30} className="weather-table-icon" />
                <strong className="mr4">{item.windDirection}</strong> <strong>{item.windSpeed || "N/A"}</strong>
              </span>
          </td>
          <td>
              <span className="cell-with-icon">
                <WeatherIcon moon width={30} code={item.moonPhase?.phase} className="weather-table-icon" />
                <strong>{item.moonPhase?.name || "N/A"}</strong>
              </span>
          </td>
        </tr>
      ))}
      {list.length < 1 && (
        <tr>
          <td colSpan={7} className="cell-placeholder">No data</td>
        </tr>
      )}
      </tbody>
    </table>
  )
}

export default WeatherTable;
