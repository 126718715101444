import "./NoPhotos.scss";
import React from "react";

const NoPhotos = ({text = ""}) => {
  return (
      <div className="no-photos-section">
        <div className="no-photos-image"/>
        <div className="no-photos-title">{text.length > 0 ? text : "There are no photos on this camera yet"}</div>
      </div>
  );
};

export default NoPhotos;
