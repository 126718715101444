import {subWeeks, startOfMonth, addDays, isSameMonth} from "date-fns";
//utils
import {emailText, smsText} from "../assets/mocks";
//icons
import {
    noSignal,
    lowSignal,
    mediumSignal,
    goodSignal,
    emptyBattery,
    lowBattery,
    mediumBattery,
    goodBattery,
    perfectBattery,
} from "../../../images/iconSVG/stats";

export const generateLabels = (value) => {
    const today = new Date();
    if (value === "w") {
        return ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    } else if (value === "2w") {
        const labels = [];
        const twoWeeksAgo = subWeeks(today, 2);
        for (let i = 0; i < 14; i++) {
            const date = addDays(twoWeeksAgo, i);
            labels.push(date.getDate());
        }
        return labels;
    } else if (value === "1m") {
        const labels = [];
        const firstDayOfMonth = startOfMonth(today);
        let currentDay = firstDayOfMonth;
        while (isSameMonth(currentDay, today)) {
            labels.push(currentDay.getDate());
            currentDay = addDays(currentDay, 1);
        }
        return labels;
    }
    return [];
};

export const getStatusClass = (statusName) => {
    switch (statusName) {
        case "Success":
            return "success";
        case "Failed":
            return "failed";
        case "Pending":
            return "pending";
        case "Expired":
            return "expired";
        default:
            return "";
    }
};

export const modalTextHandler = (modalType) => {
    switch (modalType) {
        case "twilio":
            return smsText;
        case "email":
            return emailText;
        default:
            return "";
    }
};

export const deleteModalTextHandler = (modalType) => {
    switch (modalType) {
        case "phone":
            return "phone number";
        case "email":
            return "email";
        default:
            break;
    }
};

export const deleteModalTextToastHandler = (modalType) => {
    switch (modalType) {
        case "phone":
            return "Phone number";
        case "email":
            return "Email";
        default:
            break;
    }
};

export const getSignalIcon = (signal) => {
    if (signal !== undefined && signal !== null) {
        switch (true) {
            case signal < 20:
                return lowSignal;
            case signal < 80:
                return mediumSignal;
            default:
                return goodSignal;
        }
    } else {
        return noSignal;
    }
};

export const getBatteryIcon = (battery) => {
    if (battery !== undefined && battery !== null) {
        switch (true) {
            case battery === 0:
                return emptyBattery;
            case battery <= 25:
                return lowBattery;
            case battery <= 50:
                return mediumBattery;
            case battery <= 75:
                return goodBattery;
            default:
                return perfectBattery;
        }
    } else {
        return emptyBattery;
    }
};
