import React, {useState, useEffect, useRef} from "react";
import "./Dropdown.scss";

const Dropdown = ({options, value, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectOptionsRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (selectOptionsRef.current && !selectOptionsRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleOutsideClick);
        }
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    const handleDropdown = () => setIsOpen(!isOpen);

    const handleSelect = (option) => {
        onChange(option);
        setIsOpen(false);
    };

    return (
        <div className={`custom-dropdown ${isOpen ? "open" : ""}`}>
            <div className="dropdown-trigger" onClick={handleDropdown}>
                <span className="general-block-subtitle">{value}</span>
                <span className="option-arrow" />
            </div>
            {isOpen && (
                <ul className="dropdown-options" ref={selectOptionsRef}>
                    {options.map((option) => (
                        <div className={`options-block ${value === option.label ? "selected" : ""}`} key={option.value}>
                            <li
                                className={`option`}
                                onClick={() => handleSelect(option)}
                            >
                                {option.label}
                            </li>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Dropdown;
