import React, {useState} from "react";
import "./AddModal.scss";
import {Button} from "../../../components";
import ModalComponent from "../../../components/Modal/ModalComponent";
import {AccountCameraAPI} from "../../../api/account-camera";

const ReplenishModal = ({id, isOpen, onClose, successReplenished}) => {

    const defaultText = 'Are you sure that you want replenish subscription?'
    const [loading, setIsLoading] = useState(false);
    const [text, setText] = useState(defaultText);
    const [disabled, setDisabled] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        const {data} = await AccountCameraAPI.replenish(id);
        setText(data.message);

        if (data.status === 'success') {
            successReplenished();
        }

        setIsLoading(false);
        setDisabled(true);
    }

    const closeModal = () => {
        onClose();
        setDisabled(false);
        setText(defaultText);
    }

    return (
        <ModalComponent title={"Replenish subscription"} isOpen={isOpen} onClose={closeModal} content={{width: "530px"}}>
            <form>
                <div className="modal-content">
                    <p className="modal-text">{text}</p>
                </div>
            </form>
            <div className="modal-buttons-block">
                <Button onClick={closeModal} title={"Close"} color={"outline"} />
                <Button loader={loading} disabled={loading || disabled} onClick={handleConfirm} title={"Replenish"} color={"grey"} />
            </div>
        </ModalComponent>
    );
};

export default ReplenishModal;
