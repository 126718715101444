import axios from "./http-api-client";

export const ReportsApi = {
  photos: async (tab, params = {}) => axios.get(`account/reports/photos/${tab}`, { params }),
  recognition: async (tab, params = {}) => axios.get(`/account/reports/recognition/${tab}`, { params }),
  recognitionLabel: async (tab, labelId, params = {}) => axios.get(`/account/reports/recognition-label/${labelId}/${tab}`, { params }),
  reports: async () => axios.get(`/account/reports`),
}

export default ReportsApi;
