import { createQueryKeys } from "@lukemorales/query-key-factory";

export const camerasQK = createQueryKeys("cameras", {
  list: (search = "") => [search],
  one: (id) => [id],
});

export const foldersQK = createQueryKeys("folders", {
  list: (search) => [search],
  one: (id) => [id],
  photos: (params = {}) => [params],
  cameras: (search = "") => [search],
  photo: (id, photoId) => [id, "photos", photoId],
});

export const profileQK = createQueryKeys("profile", {
  info: null,
  addons: null,
  billing: null,
  balance: null,
  payment: null,
  sessions: null,
  subscriptions: null,
});

export const mapQK = createQueryKeys("map", {
  list: null,
});

export const reports = createQueryKeys("reports", {
  recognition: (tab, id, filters = {}) => [tab, id, filters],
  structure: null,
  photos: (tab, filters = {}) => [tab, filters],
});

export const gallery = createQueryKeys("gallery", {
  list: (params = "") => [params],
  one: (id) => [id]
});
export const camerasSettings = createQueryKeys("cameras", {
  one: (id) => [id],
  onePhotos: (id, params = {}) => [id, "photos", params],
  onePhoto: (id, photoId) => [id, "photos", photoId],
  commands: (id) => [id],
  logs: (id) => [id],
  notifications: (id) => [id],
  usage: (id) => [id],
  summary: (id) => [id],
  photos: (id, period) => [id, period],
  battery: (id, period) => [id, period],
  sdCard: (id, period) => [id, period],
  billingInfo: (id) => [id],
  todayWeather: (id) => [id],
  hourlyForecastWeather: (id) => [id],
  dailyForecastWeather: (id) => [id],
});

export const guestQK = createQueryKeys("guest", {
  accounts: null,
  oneAccount: (id) => [id],
  oneKey: (id) => [id],
  keys: null,
  cameras: null,
  oneCameraPhotos: (id) => [id, "photos"],
  oneCameraPhoto: (id, photoId) => [id, "photos", photoId],
  oneCamera: (id) => [id],
});

export const glossaryQK = createQueryKeys("glossary", {
  periods: null,
  recognitions: null,
  timezones: null,
  filters: null,
  states: null,
});

export const accountSubscriptionsQK = createQueryKeys("accountSubscriptions", {
  addons: null,
  list: null
});
export const subscriptionsQK = createQueryKeys("subscriptions", {
  addons: null,
  list: null
});
