import "./EmptyBlock.scss";
import React from "react";

const EmptyBlock = ({ text }) => {
  return (
    <div className="empty-block">
      <p className="empty-block-txt">{text}</p>
    </div>
  );
};

export default EmptyBlock;
