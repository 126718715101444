import React from "react";
import {Bars} from "react-loader-spinner";

const Spinner = ({className}) => {
  return (
    <div
      className={className}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Bars
        height="80"
        width="100"
        color="#E42F31"
        ariaLabel="line-wave"
        visible={true}
      />
    </div>
  );
}

export default Spinner;
