import axios from "./http-api-client";
import simpleAxios from "./http-api-simple-client";

export const AccountCameraAPI = {
    info: async (id) => {
        return simpleAxios.get(`account-cameras/${id}`);
    },
    billingInfo: async (id) => {
        return axios.get(`/account-cameras/${id}/billing`);
    },
    notifications: async (id) => {
        return axios.get(`/account/cameras/${id}/notifications/settings`);
    },
    updateLinkedCameras: async (id, data) => {
        return axios.post(`/account/cameras/${id}/linked`, data);
    },
    camerasStatsPhotos: async (id, period) => {
        return axios.get(`account/cameras/${id}/stats/photos?period=${period}`);
    },
    camerasStatsBattery: async (id, period) => {
        return axios.get(`account/cameras/${id}/stats/battery?period=${period}`);
    },
    camerasStatsStorage: async (id, period) => {
        return axios.get(`account/cameras/${id}/stats/storage?period=${period}`);
    },
    cameraSummary: async (id) => {
        return axios.get(`account/cameras/${id}/reports/photos`);
    },
    updateNotifications: async (id, settings) => {
        return axios.post(`/account/cameras/${id}/notifications/settings`, { settings });
    },
    addCameraNotificationPhone: async (id, phone) => {
        return axios.post(`/account/cameras/${id}/notifications/phones`, { phone });
    },
    addCameraNotificationEmail: async (id, email) => {
        return axios.post(`/account/cameras/${id}/notifications/emails`, { email });
    },
    deleteCameraNotificationPhone: async (id, phoneId) => {
        return axios.delete(`/account/cameras/${id}/notifications/phones/${phoneId}`);
    },
    deleteCameraNotificationEmail: async (id, emailId) => {
        return axios.delete(`/account/cameras/${id}/notifications/emails/${emailId}`);
    },

    commands: async (id) => {
        return axios.get(`account/cameras/${id}/commands`);
    },

    log: async (id, fromId) => {
        return axios.get(`account/camera/${id}/log${fromId ? `?fromId=${fromId}` : ""}`);
    },

    downloadAllLogs: async (id) => {
        return axios.get(`account/camera/${id}/log/download-all`, { responseType: "blob"});
    },

    usage: async (id) => {
        return axios.get(`account-cameras/${id}/usage`);
    },

    map: async () => {
        return axios.get(`account-cameras/map`);
    },

    startStream: async (id) => {
      return axios.post(`/account/cameras/${id}/stream/start`)
    },

    stopStream: async (id) => {
        return axios.post(`/account/cameras/${id}/stream/stop`)
    },

    updateSettings: async (id, settings) => {
        return axios.post(`account/cameras/${id}/settings`, settings);
    },

    sendCommand: async (id, command) => {
        return axios.post(`account/cameras/${id}/command/${command}`);
    },

    deactivateCamera: async (id) => {
        return axios.delete(`/account/cameras/${id}`);
    },

    suspendCamera: async (id) => {
        return axios.post(`/account/cameras/${id}/suspend`);
    },

    restoreCamera: async (id) => {
        return axios.post(`/account/cameras/${id}/restore`);
    },

    cancelCommand: async (id, command) => {
        return axios.post(`account/cameras/${id}/commands/${command}`);
    },

    infoUpdate: async (id, info) => {
        return axios.put(`account/cameras/${id}`, info);
    },

    updateOnXSetting: async (id, enabled) => {
        return axios.post(`/account/cameras/${id}/settings/on-x`, {enabled: enabled});
    },

    replenish: async (id) => {
        return axios.post(`/account/cameras/${id}/replenish`);
    },

    setLocation: async(id, location) => {
        return axios.post(`/account/cameras/${id}/location`, {location});
    }
};
