import React, { useState } from "react";
import {GlossaryApi} from '../../../api/glossary';
import Button from '../../../components/buttons/Button';
import {isEqual} from '../../../components/helpers';
import {AccountCameraAPI} from '../../../api/account-camera';
import {Loader} from '../../../components';
import GoogleMapReact from "google-map-react";
import MapMarker from '../../../components/MapMarker/MapMarker';
import fetcher from "../../../helpers/fetcher";
import {glossaryQK} from "../../../helpers/query-keys";
import {toast} from "react-toastify";

export const EMPTY_FORM_DATA = {
  name: '',
  state: '',
  city: '',
  zip: '',
  timezone: '',
  latitude: '',
  longitude: '',
};


const CameraLocationForm = ({cameraId, cameraLocation, loading, timezones, states, withCoordinates, onUpdate}) => {
  const [isLoading, setIsLoading] = useState(false);
  const currentTimezone = timezones.find((zone) => zone.label === cameraLocation?.timezone_name)?.value;
  let initialForm = EMPTY_FORM_DATA;
  if (cameraLocation) {
    initialForm = {
      name: cameraLocation.name,
      state: cameraLocation.state,
      city: cameraLocation.city,
      zip: cameraLocation.zip,
      timezone: currentTimezone,
      latitude: cameraLocation.latitude || '',
      longitude: cameraLocation.longitude || '',
    }
  }

  const [initialFormData, setInitialFormData] = useState(initialForm);
  const [formData, setFormData] = useState(initialForm);
  const [errors, setErrors] = useState({});


  const onChange = (event) => {
    const {name, value} = event.currentTarget;

    setFormData({
      ...formData,
      [name]: value
    });

    setErrors({
      ...errors,
      [name]: ""
    });
  }

  const handleCancel = () => {
    setFormData(initialFormData);
    setErrors({});
  }

  const handleSave = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await AccountCameraAPI.setLocation(cameraId, formData);
      setInitialFormData(formData);
      onUpdate(formData);
      toast.success("Location successfully updated");
    } catch (error) {
      const message = error?.response?.data?.message;
      if (message) {
        const errors = JSON.parse(message);
        if (errors) {
          setErrors(
            Object.keys(errors).reduce((acc, field) => {
              return {...acc, [field]: errors[field][0]}
            }, {})
          );
        }
      }
    }
    setIsLoading(false);
  }

  const setCoordinates = (latitude, longitude) => {
    setFormData({
      ...formData,
      latitude,
      longitude,
    });

    setErrors({
      ...errors,
      latitude: '',
      longitude: '',
    });
  }

  return (
    <>
      {withCoordinates && (
        <>
          <div style={{paddingBottom: 12}}>
            Click on map to set camera coordinates.
          </div>
          <div style={{height: '515px', width: '100%'}}>
            <GoogleMapReact
              bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAP_KEY}}
              defaultZoom={4}
              defaultCenter={{
                lat: 39,
                lng: -100,
              }}
              onClick={({x, y, lat, lng}) => {
                setCoordinates(lat, lng);
              }}
            >
              {formData.latitude && formData.longitude && (
                <MapMarker
                  lat={formData.latitude}
                  lng={formData.longitude}
                  onClick={() => {
                    setCoordinates('', '');
                  }}
                />
              )}
            </GoogleMapReact>
          </div>
        </>
      )}
      <form className="camera-location-form">
        {loading && <Loader/>}
        {withCoordinates && (
          <div style={{display: 'flex', gap: 24}}>
            <div className="form-group">
              <label>Latitude</label>
              <input
                name="latitude"
                className="input"
                type={'number'}
                placeholder={'Enter Latitude'}
                value={formData.latitude}
                onChange={onChange}
              />
              {errors.latitude &&
                <span
                  className="error">{errors.latitude}</span>}
            </div>
            <div className="form-group">
              <label>Longitude</label>
              <input
                name="longitude"
                className="input"
                type={'number'}
                placeholder={'Enter Longitude'}
                value={formData.longitude}
                onChange={onChange}
              />
              {errors.longitude &&
                <span
                  className="error">{errors.longitude}</span>}
            </div>
          </div>
        )}
        <div className="form-group">
          <label>Name</label>
          <input
            name="name"
            className="input"
            type={'text'}
            placeholder={'Enter Name'}
            value={formData.name}
            onChange={onChange}
          />
          {errors.name &&
            <span className="error">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label>State</label>
          <select
            name="state"
            className="select"
            style={{width: '100%'}}
            value={formData.state}
            onChange={onChange}
          >
            {states.map(
              (state, index) =>
                <option key={index} value={state.value}>
                  {state.label}
                </option>,
            )}
          </select>
          {errors.state &&
            <span className="error">{errors.state}</span>}
        </div>

        <div className="form-group">
          <label>City</label>
          <input
            name="city"
            className="input"
            type={'text'}
            placeholder={'Enter City'}
            value={formData.city}
            onChange={onChange}
          />
          {errors.city &&
            <span className="error">{errors.city}</span>}
        </div>
        <div className="form-group">
          <label>Zip</label>
          <input
            name="zip"
            className="input"
            type={'number'}
            placeholder={'Enter Zip'}
            value={formData.zip}
            onChange={onChange}
          />
          {errors.zip && <span className="error">{errors.zip}</span>}
        </div>
        <div className="form-group">
          <label>Timezone</label>
          <select
            name="timezone"
            className="select"
            style={{width: '100%'}}
            value={formData.timezone}
            onChange={onChange}
          >
            {timezones.map(
              (timezone, index) =>
                <option key={index} value={timezone.value}>
                  {timezone.label}
                </option>,
            )}
          </select>
          {errors.timezone &&
            <span className="error">{errors.timezone}</span>}
        </div>
        <div className="camera-location-buttons">
          <Button
            color="outline"
            title={'Cancel'}
            disabled={isEqual(initialFormData, formData)}
            onClick={handleCancel}

          />
          <Button
            color="grey"
            loader={isLoading}
            title={'Save'}
            disabled={isEqual(initialFormData, formData)}
            onClick={handleSave}
          />
        </div>
      </form>
    </>
  );
}

const CameraLocationFormWrapper = (props) => {
  const timezonesQuery = fetcher(glossaryQK.timezones.queryKey, GlossaryApi.timezones);
  const statesQuery = fetcher(glossaryQK.states.queryKey, GlossaryApi.states);
  if (timezonesQuery.isFetched && statesQuery.isFetched) {
    const timezones = timezonesQuery.data?.data || {}
    const timezonesOptions = [
      {label: 'No timezone', value: ''},
      ...Object.keys(timezones).map(
        (value) => ({value, label: timezones[value]})
      )
    ];
    const states = statesQuery.data?.data?.list || []
    const statesOptions = [
      {label: 'No state', value: ''},
      ...states.map(
        (state) => ({value: state.id, label: state.name})
      )
    ];
    return (
      <CameraLocationForm {...props} timezones={timezonesOptions} states={statesOptions}/>
    );
  }
  return null;
}

export default CameraLocationFormWrapper;
