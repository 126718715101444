import React from "react";
import { Bars } from "react-loader-spinner";

const LineWaveLoader = ({ height = 80, width = 100 }) => {
  return (
      <div className="content-loader">
          <Bars
              height={height}
              width={width}
              color="#E42F31"
              ariaLabel="line-wave"
              visible={true}
          />
      </div>
  );
};

export default LineWaveLoader;
