import "./Modals.scss";
import React, {useCallback} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {useConfirmationModalContext} from "../../../context/confirmation";
import {AccountCameraAPI} from "../../../api/account-camera";
import status from "../../../helpers/status";
import iconDeactivate from "../../../images/iconSVG/elements/close-red.svg";

const ViewLine = ({line, isOpen, setLine, subscription, onClose}) => {
    const { showConfirmation } = useConfirmationModalContext();


    const onDeactivate = useCallback(async (camera) => {
        const confirm = await showConfirmation("Are you sure you want to deactivate current camera?", true);
        if (!confirm) return;
        try {
            await AccountCameraAPI.deactivateCamera(camera.id);
            setLine({
                ...line,
                cameras: line.cameras.map((item) => {
                    if (item.id === camera.id) {
                        return {
                            ...item,
                            status: status.STATUS_NOT_ACTIVE
                        }
                    }
                    return item
                })
            })
        } catch {

        }
    }, [showConfirmation, setLine]);

    const onSuspend = useCallback(async (camera) => {
        const confirm = await showConfirmation("Are you sure you want to suspend current camera?", true);
        if (!confirm) return;
        try {
            await AccountCameraAPI.suspendCamera(camera.id);
            setLine({
                ...line,
                cameras: line.cameras.map((item) => {
                    if (item.id === camera.id) {
                        return {
                            ...item,
                            status: status.STATUS_SUSPEND
                        }
                    }
                    return item
                })
            })
        } catch {

        }
    }, [showConfirmation, setLine]);

    const onRestore = useCallback(async (camera) => {
        const confirm = await showConfirmation("Are you sure you want to activate current camera?");
        if (!confirm) return;
        try {
            await AccountCameraAPI.restoreCamera(camera.id);
            setLine({
                ...line,
                cameras: line.cameras.map((item) => {
                    if (item.id === camera.id) {
                        return {
                            ...item,
                            status: status.STATUS_ACTIVE
                        }
                    }
                    return item
                })
            })
        } catch {

        }
    }, [showConfirmation, setLine]);

    const cameras = line?.cameras || [];
    const lineName = `${line?.name || ""}(${cameras.length})`;

    return (
        <BaseModal content={{ width: 800 }} isOpen={isOpen} onClose={onClose}>
            <section className="modal-body">
                <Header name={lineName} onClose={onClose}/>
                <div className="line-subscription-description">{subscription.billingPlan.numbersOfPictures} pictures, {subscription.billingPlan.price}/{subscription.billingPlan.period}</div>
                <div className="modal-content">
                    <table className="line-cameras-list">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>IMEI</th>
                                <th>ICCID</th>
                                <th>Status</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {cameras.length > 0
                                ? cameras.map((item) =>
                                        <tr key={item.id}>
                                            <td>
                                                <div className="line-camera-name">
                                                    {
                                                        item.status !== status.STATUS_NOT_ACTIVE
                                                          ? <span
                                                                className="remove-line-icon"
                                                                role="button"
                                                                onClick={() => onDeactivate(item)}
                                                              >
                                                                <img src={iconDeactivate} draggable="false" alt="deactivate"/>
                                                            </span>
                                                          : null
                                                    }
                                                    <div className="line-camera-photo">
                                                        <img src={item.photo} alt=""/>
                                                    </div>
                                                    <div className="line-camera-name">{item.name}</div>
                                                </div>
                                            </td>
                                            <td>{item.imei}</td>
                                            <td>{item.iccid}</td>
                                            <td>
                                                <span className={`line-camera-status ${item.status}`}>
                                                    {item.status}
                                                </span>
                                            </td>
                                            <td>
                                                <div className="line-camera-btns">
                                                    {
                                                        item.status === status.STATUS_SUSPEND || item.status === status.STATUS_NOT_ACTIVE
                                                          ? <Button title="Activate" color="green" onClick={() => onRestore(item)} />
                                                          : <Button title="Suspend" color="grey" onClick={() => onSuspend(item)} />
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                : <tr><td colSpan={3}>Cameras not found</td></tr>}
                        </tbody>
                    </table>
                </div>
                <div className="modal-buttons">
                    <Button onClick={onClose} title={"Close"} color={"outline"} />
                </div>
            </section>
        </BaseModal>
    );
};

export default ViewLine;
