import React, {useCallback, useContext, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import "./login.scss";
import {AuthApi} from "../../api/auth";
import eye from "../../images/iconSVG/icon_eye.svg";
import eyeCross from "../../images/iconSVG/icon_eye_cross.svg";
import {AuthContext} from "../../context/auth";

const LoginPage = () => {
  const { setToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, onChangeError] = useState(false);
  const [loading, onChangeLoading] = useState(false);
  const [showPassword, onChangeShowPassword] = useState(false);
  const [data, onChangeData] = useState({
    email: "",
    password: "",
  });

  const handleChangeData = useCallback((field) => {
    onChangeData({
      ...data,
      ...field
    });
  }, [data]);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    onChangeError(false);
    onChangeLoading(true);
    try {
      const response = await AuthApi.login(data);
      onChangeLoading(false);
      if (response.data && response.data.auth_token) {
        setToken(response.data.auth_token);
        navigate("/");
      }
    } catch (e) {
      onChangeLoading(false);
      onChangeError(true);
    }
  }, [data, navigate, setToken]);

  return (
    <div className="wrapper start-page">
      <form onSubmit={handleSubmit} className="form form-def login-form js-login-form show-form">
        <section className="signin-block">
          <div className="signin-wrapper">
            <div className="signin-block__title-container">
              <h2 className="signin-block__title">Log In</h2>
            </div>
            <div className="signin-block__form">
              <div className="input-block">
                <label className="input-block__label" htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={data.email}
                  onChange={(event) => handleChangeData({email: event.target.value})}
                  required name="email"
                  placeholder="Enter email"
                  className="input-block__input"
                />
                {error ? <span className="login-error-text" id="email-error-text">Wrong email or password</span> : null}
              </div>
              <div className="input-block">
                <label className="input-block__label" htmlFor="password">Password</label>
                <span className="input-block__password">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    value={data.password}
                    onChange={(event) => handleChangeData({password: event.target.value})}
                    placeholder="Enter password"
                    className="input-block__input"
                    required name="password"
                    data-rule-minlength="4"
                  />
                  <img
                    src={showPassword ? eyeCross : eye}
                    alt="pass_btn"
                    className="show_password_btn"
                    role="button"
                    onClick={() => onChangeShowPassword(!showPassword)}
                  />
                </span>
              </div>
            </div>
            <div className="signin-block__button submit">
              <button className="button--login-block" disabled={loading} type="submit">
                {loading ? "Loading..." : "Submit"}
              </button>
            </div>
            <div className="signin-block__info">
              <div className="forgot-block">
                <a className="forgot-block__link" href="https://secure.covert-wireless.com/" target="_blank">
                  Forgot password?
                </a>
              </div>
              <div className="signup-block--login">
                <span className="signup-block__text">Don’t have an account? </span>
                <Link to="/register" className="signup-block__link">Sign
                  Up </Link>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  );
}

export default LoginPage;
