import {useOutletContext, useParams} from "react-router-dom";
import {omit} from "lodash";
import React from "react";
import {RecognitionGraphReport} from "../../Reports/recognition/graph";
import Nav from "../../../components/Nav/Nav";
import {GeneralGraphReport} from "../../Reports/general/graph";

const CameraStatsReport = ({route}) => {
  const {type} = useParams();
  const {params, recognitions, camera, structure} = useOutletContext();

  const labels = params?.filter?.labels || [];

  const correctParams = {
    filter: omit(params.filter, "labels")
  };

  return (
    <>
      <Nav list={structure} withSearch className="stats-inner-nav" basePath={`/camera/${camera.id}/reports/${route}`}
           current={type}/>
      {
        route === "general"
          ? <GeneralGraphReport width={780} type={type} params={params}/>
          : (<>
            {labels.map((id) => (
              <RecognitionGraphReport width={780} recognitions={recognitions} key={id} id={id} params={correctParams} type={type}/>
            ))}
          </>)
      }
    </>
  );
}

export default CameraStatsReport;
