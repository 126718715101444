import axios from "./http-api-client";

export const AddCameraAPI = {
    registration: async (data) => {
        return axios.post(`/cameras/registration`, data);
    },
    subscriptionPlans: async (imei) => {
        return axios.get(`/cameras/registration/subscription-plans?imei=${imei}`);
    },
    getDefaultSettings: async (imei) => {
        return axios.get(`/cameras/registration/default-settings?imei=${imei}`);
    },
    accountSubscriptionPlans: async () => {
        return axios.get(`/cameras/registration/account-subscription-plans`);
    },

    confirm: async (data) => {
        return axios.post(`/cameras/registration/confirm`, data);
    },
};
