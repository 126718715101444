import "./Filters.scss";
import React, { useState } from "react";
import ToggleSwitch from "../../../../components/ToggleSwitch/ToggleSwitch";
import Button from "../../../../components/buttons/Button";

const Filters = (props) => {
    const {
        view,
        photosCount,
        onSetViewClick,
        showRecognition,
        toggleShowRecognition,
        selectedPhotos,
        isSelectMode,
        toggleSelectMode,
        onSelectAllClick,
        toggleFiltersShow,
        onBatchDeleteClick,
        onDownloadPhotosClick,
        resetHandler,
        filters = {},
        hqFilter,
        videoFilter,
        isLoading,
        isCheckedHq,
        isCheckedVideo,
        refreshPhotoList,
        totalPhotos,
    } = props;
    const noPhotosSelected = selectedPhotos.length === 0;

    const isShowResetButton = Object.keys(filters).length > 0;
    const [isRefreshing, setIsRefreshing] = useState(false);

    const onRefreshPhotosListClick = async () => {
        setIsRefreshing(true);
        await refreshPhotoList();
        setIsRefreshing(false);
    };

    return (
        <section className="photo-filters">
            {!isSelectMode ? (
                <>
                    <div className="photo-filters-left">
                        <ToggleSwitch
                            name={"recognition"}
                            checked={showRecognition}
                            onChange={toggleShowRecognition}
                            label={"Show recognition"}
                        />
                        <div className="select-files" onClick={toggleSelectMode}>
                            Select
                        </div>
                    </div>
                    <div className="photo-filters-right">
                        <div className="photo-list-size">
                            <div
                                className={`icon icon-cards ${view === "three" ? "active" : ""}`}
                                onClick={() => {
                                    onSetViewClick("three");
                                }}
                            />
                            <div
                                className={`icon icon-rows ${view === "two" ? "active" : ""}`}
                                onClick={() => {
                                    onSetViewClick("two");
                                }}
                            />
                        </div>
                        <Button
                            title={`HQ`}
                            color={`${isCheckedHq ? "green" : "green-outline"}`}
                            onClick={hqFilter}
                            loader={isLoading ? isLoading : null}
                        />
                        <Button
                            title={`Video`}
                            color={`${isCheckedVideo ? "green" : "green-outline"}`}
                            onClick={videoFilter}
                            loader={isLoading ? isLoading : null}
                        />
                        {isShowResetButton && (
                            <Button onClick={() => resetHandler()} title={`Reset`} color={"outline"} />
                        )}
                        <Button title={"Filters"} icon={"funnel"} color={"grey"} onClick={toggleFiltersShow} />
                        <Button
                            icon={"refresh"}
                            loader={isRefreshing}
                            color={"outline"}
                            onClick={onRefreshPhotosListClick}
                        />
                        <div className="total-photos-count">
                            <span>Total: {totalPhotos}</span>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="photo-select-mode-left">
                        <Button
                            icon={"download-alt"}
                            onClick={onDownloadPhotosClick}
                            disabled={noPhotosSelected}
                            title={"Download"}
                            color={"green"}
                        />
                        <Button
                            icon={"trash-alt"}
                            disabled={noPhotosSelected}
                            onClick={onBatchDeleteClick}
                            title={"Delete"}
                            color={"red"}
                        />
                        <div className="select-mode-text" onClick={onSelectAllClick}>
                            Select All
                        </div>
                        <div className="select-mode-text" onClick={toggleSelectMode}>
                            Cancel
                        </div>
                    </div>
                    <div className="photo-select-mode-right">
                        {selectedPhotos.length > 0 && `${selectedPhotos.length} item(s) selected on page`}
                    </div>
                </>
            )}
        </section>
    );
};

export default Filters;
