import React, {useCallback, useContext} from "react";
import {NavLink, Link, useNavigate} from "react-router-dom";
import {ProfileAPI} from "../../../api/profile";
import {Tooltip} from "react-tooltip";
import {useConfirmationModalContext} from "../../../context/confirmation";
import {profileQK} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";
import LineWaveLoader from "../../Loader/LineWaveLoader";
import {AuthContext} from "../../../context/auth";

const HeaderMenu = () => {
    const { data =  {data: {}}, isLoading, isFetched } = fetcher(profileQK.info.queryKey, ProfileAPI.profile, { refetchInterval: 30000});
    const { showConfirmation } = useConfirmationModalContext();
    const { clear } = useContext(AuthContext);
    const navigate = useNavigate();
    const profile = data.data;

    const profileName = profile?.name || "";
    const {creditCardStatus} = profile;
    const enabledButAuthRequired = profile.onx?.enabled && profile.onx?.authRequired;
    const creditCardIssue = creditCardStatus && creditCardStatus.status === 'failed';

    let tooltipMessage = [];
    if (enabledButAuthRequired) {
        tooltipMessage = tooltipMessage.concat(['onX hunt authorization required']);
    }

    if (creditCardIssue) {
        tooltipMessage = tooltipMessage.concat(['Credit card issue']);
    }

    const logout = useCallback(async () => {
        const confirm = await showConfirmation("Are you sure that you want Logout?");
        if (confirm) {
            clear();
            navigate("/start");
        }
    }, [clear, isFetched, showConfirmation, navigate]);

    return (
        <section className="header-menu">
            <div className="header-nav-list">
                <NavLink activeclassname="active" className="header-nav-list-item" to="/">
                    <span>My Cameras</span>
                </NavLink>
                <NavLink activeclassname="active" className="header-nav-list-item" to="/guest-accounts">
                    <span>Guest Accounts</span>
                </NavLink>
                <NavLink activeclassname="active" className="header-nav-list-item" to="/guest-cameras">
                    <span>Guest Cameras</span>
                </NavLink>
                <NavLink activeclassname="active" className="header-nav-list-item" to="/gallery">
                    <span>Gallery</span>
                </NavLink>
                <NavLink activeclassname="active" className="header-nav-list-item" to="/map">
                    <span>Map</span>
                </NavLink>
                <NavLink activeclassname="active" className="header-nav-list-item" to="/reports/general/tod">
                    <span>Reports</span>
                </NavLink>
            </div>
            <div className="header-menu-account">
                <Link to="/profile/info" className="header-right-link header-account-item">
                    {isLoading
                      ? <span className="header-user-loader"><LineWaveLoader height={25} width={25}/></span>
                      : <div className="header-menu-account-icon_user"/>
                    }
                    <span className="header-menu-text">
                            {isLoading ? "User Loading..." : profileName}
                        {enabledButAuthRequired &&
                          <div className="warning-icon-account-container"
                               data-tooltip-id={"profile-header-menu-tooltip"}
                               data-tooltip-html={tooltipMessage.join('<br/>')}>
                              <span className="icon icon-warning "/>
                          </div>
                        }
                        </span>
                </Link>
                <div className="header-right-link header-logout" role="button" onClick={logout}>
                    <div className="icon icon-logout"/>
                </div>
            </div>
            <Tooltip id="profile-header-menu-tooltip" />
        </section>
    );
};

export default HeaderMenu;
