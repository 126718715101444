import "./Modals.scss";
import React, {useState} from "react";
import {Button, ModalComponent} from "../../../components";
import {ProfileAPI} from "../../../api/profile";

const ConfirmOnXLogout = ({isOpen, onSuccessLogout, onClose}) => {

    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState("");

    const onConfirm = async (event) => {
        event.preventDefault();
        setInProgress(true);
        try {
            await ProfileAPI.onXIntegrationLogout();
            onClose();
            onSuccessLogout();
        } catch (error) {
            setError(error);
        }
        setInProgress(false);
    };

    return (
        <ModalComponent title={"Logout from OnX"} isOpen={isOpen} onClose={onClose} content={{width: "530px"}}>
            <div className="modal-content onx-integration-modal">
                <div className="onx-logo-container">
                    <div className="onx-modal-logo"/>
                </div>
                <p className="modal-text">By logging out from OnX you will loose your cameras synchronization. Do you want to continue?</p>
            </div>
            {error && (
                <div className="error-block">{<span className="modal-text-error">{error}</span>}</div>
            )}
            <div className="modal-buttons-block">
                <Button onClick={onClose} title={"No"} color={"outline"} />
                <Button onClick={onConfirm} loader={inProgress} title={"Yes"} color={"grey"} />
            </div>
        </ModalComponent>
    );
};

export default ConfirmOnXLogout;
