import "./Profile.scss";
import React, {useEffect, useRef, useState} from "react";
import {ProfileAPI} from "../../api/profile";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import fetcher from "../../helpers/fetcher";
import {profileQK} from "../../helpers/query-keys";
import Cookies from "js-cookie";

const Billing = () => {
    const queryKey = profileQK.billing.queryKey;
    const { data = {data: {}}, isFetched, isLoading } = fetcher(queryKey, ProfileAPI.billing);
    const sessionInfo = data.data;
    const revXContainer = useRef(null);

    useEffect(() => {
        if (!sessionInfo.error && isFetched) {
            if (!Cookies.remove('PHPSESSID')) {
                Cookies.remove('PHPSESSID', {
                    'path': '/'
                });

                Cookies.remove('PHPSESSID', {
                    'path': ''
                });
            }

            Cookies.set('PHPSESSID', sessionInfo.session_id, {
                'path': '/',
                'domain': 'covert-wireless.com'
            });

            revXContainer.current.innerHTML = '<object type="text/html" data="https://secure.covert-wireless.com/myaccount.php?lnf=revxCovertWebsite" style="width: 100%; height: 800px;">';
        }
    }, [sessionInfo, isFetched]);

    const {error} = sessionInfo;

    return (
      <section className="content-section">
          <div className="page-sub-title">Billing</div>
          {isLoading ? (<LineWaveLoader/>) : (
            <div>
                {error && (<p>Error occurred during authorization to billing system.</p>)}
            </div>
          )}
          <div id={"revxContent"} ref={revXContainer}/>
      </section>
    );
};

export default Billing;
