import "./CameraView.scss";
import React, {useState} from "react";
import Button from "../../components/buttons/Button";
import {useOutletContext} from "react-router-dom";
import CameraLocationForm from './components/CameraLocationForm';

export default function EmptyMap() {
    const [camera, setCamera] = useOutletContext();
    const [showLocationForm, setShowLocationForm] = useState(!!camera.cameraLocation);

    return (
        <>
            {!showLocationForm && (
                <div className="empty-map">
                    <div className="empty-map-image"/>
                    <h2 className="empty-map-title">Sorry, we didn't find location
                        of
                        this camera</h2>
                    <span className="empty-map-text">You can set camera’s location manually using the button below</span>
                    <Button
                        color="grey"
                        size={"lg"}
                        title={"Set location"}
                        onClick={() => setShowLocationForm(true)}
                    />
                </div>
            )}
            {showLocationForm && (
                <section className="content-section">
                    <CameraLocationForm
                        cameraId={camera?.id}
                        cameraLocation={camera.cameraLocation}
                        onUpdate={(cameraLocation) => setCamera({ ...camera, cameraLocation})}
                        withCoordinates={true}
                    />
                </section>
            )}

        </>
    );
};