import "../CameraList.scss";
import React, {useMemo} from "react";
import EmptyBlock from "../../../components/EmptyBlock/EmptyBlock";
import Dnd from "../../../components/DnD";
import {DndProvider} from "react-dnd";
import RowCamera from "../../../components/DnD/DraggableRowCamera";
import {HTML5Backend} from "react-dnd-html5-backend";

const RowList = ({cameras, pinCamera, onChangeOrder, search, isLoading}) => {
  const defaultCameras = useMemo(() => cameras.filter((camera) => !camera.pinned), [cameras]);
  const pinnedCameras = useMemo(() => cameras.filter((camera) => camera.pinned), [cameras]);

  const handleChangeOrder = (pinnedCamerasOrder) => {
    onChangeOrder([...pinnedCamerasOrder, ...defaultCameras]);
  }

  return (
    <section className="camera-list-row">
      <DndProvider backend={HTML5Backend}>
        {cameras?.length !== 0 ? (
          <div>
            <Dnd cameras={pinnedCameras} onChangeOrder={handleChangeOrder} row={true} onPin={pinCamera}
                 search={search}/>
            <div className="camera-list-cameras-row">
              {defaultCameras?.map((camera) => {
                return (
                  <RowCamera
                    key={camera.imei}
                    camera={camera}
                    onPin={pinCamera}
                  />
                )
              })}
            </div>
          </div>
        ) : (
          <EmptyBlock text={!isLoading ? "" : "No Cameras"}/>
        )}
      </DndProvider>
    </section>
  );
};

export default RowList;
