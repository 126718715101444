import "./cameraSettingsInput.scss";
import React from "react";
import RadioSelect from "./RadioSelect";
import Toggle from "../ToggleSwitch/ToggleSwitch";
import Select from "./Select";
import Timer from "./Timer";

const CameraSettingsInput = ({children, label, isVisible = true, indent = 0}) => {
  return (
    <div className={`settings-line indent-${indent} ${isVisible ? "" : "line-hidden"}`}>
      <div className="settings-line-name">
        <p>{label}</p>
      </div>
      <div className="settings-line-value">
        {children}
      </div>
    </div>
  );
};

export const checkIsVisible = (depends, currentValues) => {
  const keys = Object.keys(depends);

  return keys.every((key) => {
    if (key.includes("useWorkTime")) {
      return currentValues[key] > 0;
    }

    if (Array.isArray(depends[key])) {
      // eslint-disable-next-line
      return depends[key].some((value) => value == currentValues[key]);
    }

    // eslint-disable-next-line
    return depends[key] == currentValues[key];
  });
};

export const RenderControlsItem = ({option, fieldName, currentValue, onChange, onToggleChange, disabled}) => {
  switch (option.type) {
    case "toggle":
      return (
        <RadioSelect
          name={fieldName}
          label={option.label}
          options={option.options}
          value={currentValue}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case "on-off":
      return (
        <Toggle
          name={fieldName}
          label={option.label}
          checked={currentValue}
          onChange={onToggleChange}
          disabled={disabled}
        />
      );
    case "select":
      return (
        <Select
          name={fieldName}
          label={option.label}
          options={option.options}
          rangeOptions={option.rangeOptions}
          value={currentValue}
          onChange={onChange}
          disabled={disabled}
        />
      );
    case "time":
    case "timer":
      return <Timer name={fieldName} value={currentValue} onChange={onChange} disabled={disabled} />;
    default:
      return <div />;
  }
};

export default CameraSettingsInput;
