import "./Modals.scss";
import React, {useState} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {GuestAccountsApi} from "../../../api/guest-accounts";

const CreateGuestAccount = ({isOpen, cameras, onClose}) => {
  const [inProgress, setInProgress] = useState(false);
  const [form, setForm] = useState({
    name: "",
    cameraIds: []
  });

  const onChange = (e) => {
    const {name, value} = e.target;

    setForm({
      ...form,
      [name]: value
    })
  }

  const onSelectCamera = (id) => {

    const cameraIds = form?.cameraIds || [];

    setForm({
      ...form,
      cameraIds: cameraIds.includes(id) ? cameraIds.filter(item => item !== id) : [...cameraIds, id]
    })
  };

  const handleChooseAllCameras = () => {
    const cameraIds = form.cameraIds.length === cameras.length ? [] : cameras.map((item) => item.id);
    setForm({
      ...form,
      cameraIds
    });
  }

  const onConfirm = async () => {
    try {
      setInProgress(true);
      await GuestAccountsApi.create(form);
      setInProgress(false);
      onClose(true);
      setForm({
        name: "",
        cameraIds: []
      });
    } catch (e) {
      setInProgress(false);
    }
  };

  const handleCloseModal = () => {
    setForm({
      name: "",
      cameraIds: []
    });
    onClose(false)
  }

  return (
    <BaseModal isOpen={isOpen} onClose={handleCloseModal}>
      <section className="modal-body create-guest-account-modal">
        <Header name={"Create guest account"} onClose={handleCloseModal}/>
        <div className="modal-content">
          <form>
            <div className="form-group">
              <label>Name</label>
              <input
                type={"text"}
                placeholder={"Enter name"}
                className="input"
                name="name"
                value={form?.name || ""}
                onChange={onChange}
              />
            </div>
          </form>
          <div className="guest-account-camera-list">
            <p className="guest-account-camera-list-title">Please select cameras to share:</p>
            <table>
              <thead>
              <tr>
                <th>
                  <div className="checkbox-table">
                    <input
                      onChange={handleChooseAllCameras}
                      type="checkbox"
                      checked={form.cameraIds.length === cameras.length}/>
                  </div>
                </th>
                <th>Name</th>
                <th>Type</th>
                <th>MEID (IMEI)</th>
              </tr>
              </thead>
              <tbody>
              {cameras.length > 0 ? (
                cameras.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className="checkbox-table">
                          <input
                            onChange={() => onSelectCamera(item.id)}
                            type="checkbox"
                            name="cameraIds[]"
                            value={item.id}
                            checked={form.cameraIds && form.cameraIds.includes(item.id)}/>
                        </div>
                      </td>
                      <td>
                        <label>{item.name || <i>No name</i>}</label>
                      </td>
                      <td>{item.provider}</td>
                      <td>{item.imei}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="table-empty">
                    You don't have any cameras
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

        </div>

        <div className="modal-buttons">
          <Button
            onClick={handleCloseModal}
            title={"Cancel"}
            color={"outline"}
          />
          <Button
            onClick={onConfirm}
            loader={inProgress}
            disabled={inProgress}
            title={"Create guest account"}
            color={"grey"}
          />
        </div>
      </section>
    </BaseModal>
  );
};
export default CreateGuestAccount;
