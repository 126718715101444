import React, {useCallback, useState} from "react";
import cx from "classnames";
import EditTags from "../../../components/EditTags";

function getRectangleParams(coordinates = {}) {
  const {maxx, minx, maxy, miny} = coordinates;

  let top = miny;
  let left = minx;
  let width = parseFloat(maxx) - parseFloat(minx);
  let height = parseFloat(maxy) - parseFloat(miny);

  return {top: top, left: left, width: width, height: height};
}

const RecognitionFrames = ({imgContainer, image, onSubmitTags, recognition, showRecognition = true}) => {
  const [showRecognitionTags, onChangeShowRecognitionTags] = useState();
  const container = imgContainer.current;
  const img = image.current;

  const handleSubmitTags = useCallback(async (tags, recognition) => {
    await onSubmitTags(tags, recognition);
    onChangeShowRecognitionTags();
  }, [onSubmitTags]);

  return recognition.map((item, index) => {
    if (item.coordinates.length === 0) {
      return null;
    }
    const params = getRectangleParams(item.coordinates);

    const tagsTexts = item.tags.map(item => item.name).join("#");

    return [
      <div
        key={`detection-${item.id}`}
        className={cx("recognition-detection smallTagsBtn", `color-${index}`,
          { hidden: !showRecognition }
        )}
        style={{
          top: params.top + "px",
          left: params.left + "px",
          width: params.width + "px",
          height: params.height + "px",
        }}
      >
        {recognition.length < 2 && (<EditTags
          onOpen={() => onChangeShowRecognitionTags(item.id)}
          open={showRecognitionTags === item.id}
          tags={item.tags || []}
          label="Edit recognition tags"
          title={`Edit tags for ${item.name}`}
          onSubmit={(tags) => handleSubmitTags(tags, item)}
          onClose={() => onChangeShowRecognitionTags()}
        />)}
      </div>,
      <div
        key={`text-${item.id}`}
        className={`recognition-text color-${index} ${showRecognition ? "" : "hidden"}`}
        style={{
          top: params.top - 7 + "px",
          left: params.left + "px",
        }}
      >
        {parseFloat(item.confidence * 100).toFixed(2)}% {item.name} {tagsTexts ? `#${tagsTexts}` : ""}
      </div>,
    ];
  });
};

export default RecognitionFrames;
