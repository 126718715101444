import "./FolderPhotos.scss";
import qs from "qs";
import {useSearchParams} from "react-router-dom";
import {useOutletContext} from "react-router-dom";
import React, {useCallback, useMemo, useState} from "react";
import Button from "../../components/buttons/Button";
import {mergePhotoPages} from "../../components/helpers";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import NoPhotos from "../../components/NoPhotos/NoPhotos";
import {isEmptyObject} from "../../components/helpers";
import {foldersApi} from "../../api/folders";
import PhotoList from "../../components/PhotoList/PhotoList";
import {foldersQK} from "../../helpers/query-keys";
import {photosFetcher} from "../../helpers/fetcher";
import {last} from "lodash";
import FiltersModal from "../CameraPhotos/components/Modals/FiltersModal";
import Filters from "../CameraPhotos/components/Filters/Filters";
import {useConfirmationModalContext} from "../../context/confirmation";
import {toast} from "react-toastify";

export default function FolderPhotosList() {
  const [folder] = useOutletContext();
  const { showConfirmation } = useConfirmationModalContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const search = qs.parse(searchParams.toString());

  const camerasId = folder?.cameras?.map((id) => id.id);
  const params = qs.parse(searchParams.toString());
  const camerasFilters = camerasId?.map((id) => `filters[cameras][]=${id}`).join("&");
  const photosQK = foldersQK.photos(params).queryKey;

  const isCheckedHq = useMemo(() => {
    if (search.filters) {
      return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "video")
    }
    return false;
  }, [search]);
  const isCheckedVideo = useMemo(() => {
    if (search.filters) {
      return search.filters?.state === "uploaded" && (!search.filters?.type || search.filters?.type !== "image")
    }
    return false;
  }, [search]);

  const {
    data: photosQueryData,
    refetch,
    fetchNextPage,
    isFetching,
    isLoading
  } = photosFetcher(photosQK, async ({pageParam}) => {
    const {data} = await foldersApi.folderPhotosByDates(camerasFilters, {
      fromId: pageParam || undefined,
      ...params
    });
    return data;
  });
  const photosList = mergePhotoPages(photosQueryData?.pages);

  const meta = useMemo(() => {
    if (photosQueryData?.pages) {
      const tail = last(photosQueryData.pages);
      return tail._meta || {};
    }
    return {};
  }, [photosQueryData])

  const noPhotos = meta.totalCount < 1;

  const [view, setView] = useState(localStorage.getItem("photos-view") || "three");
  const [showRecognition, setShowRecognition] = useState(true);
  const [isSelectMode, setSelectMode] = useState(false);
  const [filters, setFilters] = useState(search);
  const [date, setDate] = useState("");
  const [selectedPhotos, setSelectedPhotos] = useState([]);

  const [showFilters, setShowFilters] = useState(false);

  const loadMore = async () => {
    await fetchNextPage();
  }

  function onSetViewClick(view) {
    localStorage.setItem("photos-view", view);
    setView(view);
  }

  function toggleShowRecognition() {
    setShowRecognition(!showRecognition);
  }

  function toggleSelectMode() {
    setSelectMode(!isSelectMode);
    setSelectedPhotos([]);
  }

  function toggleSelectedCamera(e) {
    if (!isSelectMode) {
      return false;
    }

    const {value} = e.target;
    const photoId = Number(value);

    if (selectedPhotos.includes(photoId)) {
      setSelectedPhotos(selectedPhotos.filter((item) => item !== photoId));
    } else {
      setSelectedPhotos([...selectedPhotos, photoId]);
    }
  }

  function toggleFiltersShow() {
    setShowFilters(!showFilters);
  }

  function onSelectAllClick() {
    const photoIds = Object.keys(photosList)
      .map((day) => {
        const {list} = photosList[day];

        return list.map((item) => item.id);
      })
      .flat();

    if (selectedPhotos.length === photoIds.length) {
      setSelectedPhotos([]);
    } else {
      setSelectedPhotos(photoIds);
    }
  }

  const resetHandler = () => {
    setFilters({});
    setSearchParams({});
    setDate("");
  };

  function applyFilters(filters) {
    if (Object.keys(filters).length === 0) {
      setSearchParams({});
      setShowFilters(false);
      return;
    }
    setShowFilters(false);
    setSearchParams(qs.stringify({filters}));
  }

  const onBatchDeleteClick = useCallback(async () => {
    const confirm = await showConfirmation(`Are you sure that you want delete ${selectedPhotos.length} photos? This action cannot be undone.`)
    if (confirm) {
      try {
        await foldersApi.photosBatchDelete(selectedPhotos);
        await refetch();
      } catch {
        toast.error("Something went wrong");
      }
    }
  }, [selectedPhotos, showConfirmation]);

  const onDownloadPhotosClick = useCallback(async () => {
    await foldersApi.zipPhotos(selectedPhotos);
  }, [selectedPhotos]);

  function hqFilter() {
    let type = "image";
    if (search.filters?.type === "video") type = undefined;
    applyFilters({
      ...(search.filters || {}),
      state: "uploaded",
      type
    });
  }

  function videoFilter() {
    let type = "video";
    if (search.filters?.type === "image") type = undefined;
    applyFilters({
      ...(search?.filters || {}),
      state: "uploaded",
      type
    });
  }

  const onPhotoDelete = useCallback(async () => {
    await refetch();
  }, [refetch]);

  if (!folder.id) {
    return <div/>;
  }

  return (
    <section className="content-section">
      <FiltersModal
        isOpen={showFilters}
        date={date}
        filters={filters}
        isLoading={isLoading}
        search={search?.filters || {}}
        setFilters={setFilters}
        setDate={setDate}
        resetHandler={resetHandler}
        onClose={toggleFiltersShow}
        applyFilters={applyFilters}
        setIsCheckedHq={hqFilter}
        setIsCheckedVideo={videoFilter}
      />
      <Filters
        view={view}
        filters={search.filters}
        isLoading={isLoading}
        showRecognition={showRecognition}
        isSelectMode={isSelectMode}
        selectedPhotos={selectedPhotos}
        onSetViewClick={onSetViewClick}
        resetHandler={resetHandler}
        toggleShowRecognition={toggleShowRecognition}
        toggleSelectMode={toggleSelectMode}
        onSelectAllClick={onSelectAllClick}
        toggleFiltersShow={toggleFiltersShow}
        onBatchDeleteClick={onBatchDeleteClick}
        hqFilter={hqFilter}
        isCheckedHq={isCheckedHq}
        isCheckedVideo={isCheckedVideo}
        videoFilter={videoFilter}
        onDownloadPhotosClick={onDownloadPhotosClick}
        refreshPhotoList={refetch}
        totalPhotos={meta?.totalCount || 0}
      />
      {noPhotos ? (
        <NoPhotos text={isEmptyObject(search) ? "" : "Photos not found by specified filters"}/>
      ) : (
        <div className={`photos-list-container ${view} ${isSelectMode ? "select-mode" : ""}`}>
          <PhotoList
            listByDays={photosList}
            showRecognition={showRecognition}
            selectedPhotos={selectedPhotos}
            onPhotoSelect={toggleSelectedCamera}
            searchParams={searchParams}
            isSelectMode={isSelectMode}
            afterDelete={onPhotoDelete}
          />
        </div>
      )}
      {isLoading && <LineWaveLoader/>}
      {meta.pageCount > meta.currentPage && (
        <div className="load-more-photos">
          <Button
            color={"red-outline"}
            disabled={isFetching}
            size={"lg w100"}
            loader={isFetching}
            title={"Load More"}
            onClick={loadMore}
          />
        </div>
      )}
    </section>
  );
}

