import "./Button.scss";
import React from "react";

const ButtonIcon = ({title, color, onClick, icon, disabled = false}) => {
    const switchElements = (elem) => {
        switch (elem) {
            case "plus":
                return "btn-icon-plus";
            case "edit":
                return "btn-icon-edit";
            case "view":
                return "btn-icon-view";
            case "del":
                return "btn-icon-del";
            case "arrow":
                return "btn-icon-arrow";
            case "trash":
                return "btn-icon-trash";
            case "key":
                return "btn-icon-key";
            default:
                break;
        }
    };

    return (
        <button onClick={onClick} className={`btn ${color}`} disabled={disabled}>
            <div className={`${switchElements(icon)}`}></div>
            <span>{title}</span>
        </button>
    );
};

export default ButtonIcon;
