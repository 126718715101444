import React from "react";
import Nav from "../../components/Nav/Nav";

const ReportsTabs = ({ current, basePath = "/reports" }) => {
  const list = [
    {name: "General", alias: "general", sub: "tod"},
    {name: "Recognition", alias: "recognition", sub: "by-dates"},
  ]

  return (
    <Nav list={list} basePath={basePath} current={current} />
  );
}

export default ReportsTabs;
