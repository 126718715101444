import React from "react";
import "./ServerError.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../components/buttons/Button";

const ServerError = () => {
  const navigate = useNavigate();

  const onHomeHandler = () => {
    navigate("/");
  };

  return (
    <div className="wrapper">
      <p className="error-text">Interval Server Error.</p>
      <Button onClick={onHomeHandler} title={"My Cameras"} icon="arrow" />
    </div>
  );
};

export default ServerError;
