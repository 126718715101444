import "./GuestCameras.scss";
import React from "react";
import {Link, Navigate, useParams} from "react-router-dom";
import {GuestCameraPhotos} from "../../api/guest-camera-photos";
import {guestQK} from "../../helpers/query-keys";
import {useQueryClient} from "@tanstack/react-query";
import fetcher from "../../helpers/fetcher";


const PreloadPhoto = ({id, photoId}) => {
  const photoQK = guestQK.oneCameraPhoto(id, `${photoId}`).queryKey;
  const queryClient = useQueryClient();
  const isPreloadEnable = !(queryClient.getQueryData(photoQK));
  fetcher(
    photoQK,
    () => GuestCameraPhotos.photoView(id, photoId),
    {
      enabled: isPreloadEnable
    }
  );
  return null;
}

const GuestCameraPhotoView = () => {
  let {id, photoId} = useParams();
  const photoQK = guestQK.oneCameraPhoto(id, photoId).queryKey;
  const {
    data = {
      data: {
        next: null,
        prev: null,
        photo: {}
      }
    }, isFetched, failureReason
  } = fetcher(photoQK, () => GuestCameraPhotos.photoView(id, photoId), {
    throwOnError: false,
  });

  const nav = {
    prev: data.data.prev,
    next: data.data.next
  };
  const photo = data.data.photo || {};

  if (failureReason && failureReason.response && failureReason.response.status) {
    if (failureReason.response.status === 404) {
      return <Navigate to="/not-found-page"/>
    }
    if (failureReason.response.status === 500) {
      return <Navigate to="/server-error-page"/>
    }
  }

  const {next, prev} = nav;

  return (
    <section className="content-section">
      <div className="guest-photo-view">
        <div className="guest-photo-header">
          <div className="guest-photo-date">{photo.date}</div>
          <div className="guest-photo-time">{photo.time}</div>
        </div>
        <div className="guest-photo">
          {isFetched && <img src={photo.original} alt=""/>}
          {next && (
            <Link to={`/guest-cameras/${id}/photos/${next}`} className="guest-photo-next">
              <div className="icon icon-arrow-left"/>
              <PreloadPhoto id={id} photoId={next}/>
            </Link>
          )}
          {prev && (
            <Link to={`/guest-cameras/${id}/photos/${prev}`} className="guest-photo-prev">
              <div className="icon icon-arrow-right"/>
              <PreloadPhoto id={id} photoId={prev}/>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default GuestCameraPhotoView;
