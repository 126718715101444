import React from "react";

const State = ({type, stateDescription, videoResolution}) => {
  if (type === "video-preview") {
    return <VideoState videoResolution={videoResolution} stateDescription={stateDescription}/>;
  }

  return <PhotoState stateDescription={stateDescription}/>;
};

const PhotoState = ({stateDescription}) => {
  const {status, short, description} = stateDescription;

  if (status === "success") {
    return (
      <div className="photo-view-state success" title={description}>
        {short}
      </div>
    );
  }

  if (status === "wait") {
    return (
      <div className="photo-view-state waiting" title={description}>
        {short}
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="photo-view-state error" title={description}>
        {short}
      </div>
    );
  }

  return null;
};

const VideoState = ({videoResolution, stateDescription}) => {
  const {status, short, description} = stateDescription;
  if (status === "success") {
    return (
      <div className="photo-view-state success" title={description}>
        {videoResolution}
      </div>
    );
  }

  if (status === "wait") {
    return (
      <div className="photo-view-state waiting" title={description}>
        {short}
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="photo-view-state error" title={description}>
        {short}
      </div>
    );
  }

  return <div className="photo-view-state">{videoResolution}</div>;
};

export default State;
