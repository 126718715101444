import React from "react";
import Tabs from "../innerTabs";
import {reports} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";
import ReportsApi from "../../../api/reports";
import { useOutletContext, useParams } from "react-router-dom";
import {prepareDataForGraph} from "../helper";
import {AreaGraphic, BarGraphic} from "../../../components/Graphic/Graphic";

export const GeneralGraphReport = ({type, width = 1080, params}) => {
  const qk = reports.photos(type, params).queryKey;
  const { data = {
    data: {
      label: "",
      datasets: {}
    }
  }, isLoading } = fetcher(qk, () => ReportsApi.photos(type, params));

  const { graphData, graphType = "BarGraphic", labels, y, yy } = prepareDataForGraph(data.data);

  const props = {
    height: 500,
    width,
    loading: isLoading,
    data: graphData,
    x: "label",
    y,
    labels,
    yy,
  };

  const graphs = {
    AreaGraphic,
    BarGraphic,
  }

  return (
    React.createElement(graphs[graphType], props)
  );
}

const GeneralGraphReports = () => {
  const { type } = useParams();
  const { params, structure } = useOutletContext();

  return (
    <>
      <Tabs list={structure} route="general" current={type}/>
      <GeneralGraphReport type={type} params={params} />
    </>
  );
}

export default GeneralGraphReports;
