import React, {useState, useEffect, useRef} from "react";
import "./Select.scss";

const Select = ({options, disabled, value, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectOptionsRef = useRef(null);
    const selectTriggerRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                selectOptionsRef.current &&
                !selectOptionsRef.current.contains(event.target) &&
                !selectTriggerRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOpen = () => {
        if (disabled) return;
        setIsOpen(!isOpen);
    }

    const handleSelect = (option) => {
        onChange(!option ? null : option);
        setIsOpen(false);
    };

    const selectedOption = options.find(option => option.value === value);

    return (
        <div className={`custom-select ${isOpen ? "open" : ""}, ${disabled ? "disabled-select" : ""}`}>
            <div className="select-trigger" onClick={handleOpen} ref={selectTriggerRef}>
                {selectedOption ? (
                    <div className="selected-value">
                        <span>{selectedOption.label}</span>
                    </div>
                ) : (
                    <span className="placeholder">Select an option</span>
                )}
                <span className="option-arrow" />
            </div>
            {isOpen && (
                <ul className="select-options" ref={selectOptionsRef}>
                    <div className="option-container">
                        <li className={`option ${!value ? "selected" : ""}`} onClick={() => handleSelect(null)}>
                            All
                        </li>
                    </div>
                    {options?.map((option, idx) => (
                        <div key={`${option.value}-${idx}`} className="option-container">
                            <li
                                className={`option ${value === option.value ? "selected" : ""}`}
                                onClick={() => handleSelect(option.value)}
                            >
                                {option.label}
                            </li>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Select;
