import axios from "./http-api-client";
import simpleAxios from "./http-api-simple-client";

export const CamerasAPI = {
    search: async (search) => {
        return axios.get(`account-cameras?search=${search}`);
    },

    listCamera: async (search = "") => {
        if (search && search.length > 0) {
            return axios.get(`account-cameras?search=${search}`);
        }

        return axios.get(`account-cameras`);
    },

    listOnxCameras: async () => {
        return axios.get(`account-cameras`);
    },

    listFolders: async (search = "") => {
        if (search && search.length > 0) {
            return axios.get(`account-cameras/folders?search=${search}`);
        }

        return axios.get(`account-cameras/folders`);
    },

    pinCamera: async (id) => {
        return axios.post(`/account-cameras/${id}/pin`);
    },

    unPinCamera: async (id) => {
        return axios.post(`/account-cameras/${id}/unpin`);
    },

    reorderPinCamera: async (data) => {
        return axios.post(`/account-cameras/reorder`, data);
    },

    cameraCurrentWeather: async (id) => {
        return axios.get(`/account/cameras/${id}/weather/current`);
    },

    cameraHourlyForecastWeather: async (id) => {
        return axios.get(`/account/cameras/${id}/weather/hourly`);
    },

    cameraDailyForecastWeather: async (id) => {
        return axios.get(`/account/cameras/${id}/weather/daily`);
    },

    cameraInfo: async (id) => {
        return simpleAxios.get(`account-cameras/${id}`);
    },
};
