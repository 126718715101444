import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import cx from "classnames";
import {useNavigate, Link, useSearchParams} from "react-router-dom";
import {
  CameraName,
  CameraImei,
  PinCamera
} from "./common";
import "./cameras.scss";

import {ItemTypes} from "./ItemTypes";

//components
import {RowStats} from "../StatsRow/StatsRow";
import status from "../../helpers/status";
import Weather from "../Weather";

export const DraggableRowCamera = ({index, onDrop, gallery, camera, onPin, moveCamera}) => {
  const ref = useRef(null);

  const [showLinked, onChangeShowLinked] = useState(false);
  const [pinProcessing, setPinProcessing] = useState(false);

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";

  const navigate = useNavigate();

  const [, drop] = useDrop(
    {
      accept: ItemTypes.CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }
        if (moveCamera) moveCamera(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop: async (item, monitor) => {
        if (!ref.current) {
          return;
        }
        if (onDrop) await onDrop();
      }
    },
    [index, onDrop, moveCamera],
  );

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: {id: camera?.id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const onPinClick = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPinProcessing(true);
    await onPin(camera);
    setPinProcessing(false);
  };

  const onSetting = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/camera/${camera.id}/info`);
  };

  const handleClickShowLinked = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onChangeShowLinked(!showLinked);
  }, [showLinked]);

  const handleOutsideClick = (event) => {
    const inElem = event.target?.closest(`.camera-item-row-${camera.id}`);
    if (showLinked && !inElem) {
      onChangeShowLinked(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const suspended = camera.status === status.STATUS_SUSPEND;

  return (
    <Link to={`/cameras/${camera.id}/photos`}
          className={cx(`camera-item-row camera-item-row-${camera.id}`, { isDragging, suspended, "in-gallery": gallery })}
          draggable={Boolean(onDrop)}
          ref={Boolean(onDrop) ? ref : null}
    >
      {!isDragging && (
        <>
          <div className="camera-item-header-row">
            <div className="camera-image-row-wrapper">
              <img className="camera-image-row" src={gallery ? camera?.specs?.photo : camera?.image} alt=""/>
              {suspended ? <span className="suspended-label">Suspended</span> : null}
            </div>
            {camera.onxEnabled && <div className="row-item-onx-logo"/>}
            <PinCamera isPinned={camera.pinned} pinProcessing={pinProcessing} onClick={onPinClick}/>
          </div>
          <div className="camera-item-row-info">
            <CameraName value={camera?.name || ""} search={search}/>
            <div className="camera-item-info">
              <p className="camera-item-info-txt">{camera?.model}</p>
              <p className="camera-item-info-txt">IMEI:</p>
              <CameraImei value={gallery ? camera?.meid : camera?.imei} search={search}/>
            </div>
          </div>
          <div className="camera-item-info-row-with-weather">
            <div>
              <RowStats stats={camera?.usage?.stats}/>
              <div className={`camera-item-time ${camera?.lastSeen ? "" : "hidden-last-seen"}`}>
                <div className="camera-item-time-icon"/>
                <p className="camera-item-stats-txt">{camera?.lastSeen}</p>
              </div>
            </div>
            <Weather className="camera-line-weather" weather={camera?.weather} row />
          </div>
          <div className="camera-item-row-controls">
            {camera.linkedCameras && camera.linkedCameras.length > 0 && (
              <div className={cx("camera-item-linked-list", {showLinked: showLinked})}>
                <div role="button" className="camera-item-cameras-icon trigger" onClick={handleClickShowLinked}>
                  <span>{camera.linkedCameras.length}</span>
                </div>
                <div className="camera-item-linked-list-wrapper">
                  <ul>
                    {camera.linkedCameras.map((linkedCamera) => (
                      <li key={linkedCamera.id}>
                        <Link to={`/cameras/${camera.id}/photos?filters[linked][0]=${linkedCamera.id}`}>
                          {linkedCamera.slot}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <button className="camera-item-controls-setting" onClick={onSetting}/>
          </div>
        </>
      )}
    </Link>
  );
};

export default DraggableRowCamera;
