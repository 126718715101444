import React, {useCallback} from "react";
import update from "immutability-helper";

//components
import {DraggableRowCamera} from "./DraggableRowCamera";
import {DraggableCardCamera} from "./DraggableCardCamera";

//api
import {CamerasAPI} from "../../api/cameras";

export const Container = ({onPin, onChangeOrder, camerasPin, row}) => {

  const moveCamera = useCallback(
    (dragIndex, hoverIndex) => {
      const updatedCamerasPin = update(camerasPin, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, camerasPin[dragIndex]],
        ],
      });

      onChangeOrder(updatedCamerasPin)
    },
    [camerasPin],
  );

  const onDrop = async () => {
    const arrId = camerasPin?.map((el) => el?.id);

    const data = {
      cameras: arrId,
    };
    await CamerasAPI.reorderPinCamera(data);
  };

  return (
    <>
      {camerasPin?.map((camera, index) =>
        row ? (
          <DraggableRowCamera
            key={camera.id}
            index={index}
            camera={camera}
            pin={true}
            onDrop={onDrop}
            onPin={onPin}
            moveCamera={moveCamera}
          />
        ) : (
          <DraggableCardCamera
            key={camera.id}
            index={index}
            camera={camera}
            pin={true}
            onDrop={onDrop}
            onPin={onPin}
            moveCamera={moveCamera}
          />
        ),
      )}
    </>
  );
};
