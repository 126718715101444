import "./Button.scss";
import React from "react";
import Button from "./Button";

const ButtonLg = (props) => {
    return (
       <Button
           {...props}
           size={"lg"}
       />
    );
};

export default ButtonLg;
