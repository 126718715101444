import React from "react";
import cx from "classnames";
import moment from "moment";
import WeatherIcon from "../WeatherIcon";
import "./weather.scss";

const temp = {
  temp: 24,
  highTemp: 28,
  lowTemp: 12,
  wind: {
    direction: "N",
    speed: 4,
  },
  humidity: 0
}

const a = {
  "time": "03/27/2025 08:00",
  "temperature": "48°F",
  "apparentTemperature": "48°F",
  "precipitation": "0 in",
  "weatherCode": {
    "code": 3,
    "description": "Overcast"
  },
  "windSpeed": "25 mph",
  "windDirection": "E",
  "cloudCover": "100%",
  "rain": "0 in",
  "relativeHumidity": "57%",
  "showers": "0 in",
  "isDay": true,
  "snowfall": "0 in",
  "windGusts": "51 mph",
  "moonPhase": "New moon",
  "precipitationProbability": null,
  "surfacePressure": null,
  "dewPoint": null,
  "temperatureMax": "73°F",
  "temperatureMin": "42°F",
  "sunset": "03/27/2025 07:05",
  "sunrise": "03/27/2025 06:39",
  "apparentTemperatureMax": "66°F",
  "apparentTemperatureMin": "36°F"
};

const CameraPreviewWeather = ({ className, weather, row }) => {
  if (!weather) return null;

  return (
    <div className={cx("camera-weather-info-line", {rowView: row}, className)}>
      <div className="camera-weather-info-line-temp">
        <div className="current-temp">
          <WeatherIcon code={weather.weatherCode?.code} className="current-temp-icon" />
          {weather.temperature}
        </div>
        <div className="range-temp">
          <span>Hi {weather.temperatureMax}</span>
          <span>Lo {weather.temperatureMin}</span>
        </div>
      </div>
      <div className="camera-weather-info-line-sun">
        <div className="sun-field">
          <WeatherIcon icon="sunrise" className="sun-field-icon" />
          <div className="sun-field-text">
            <span>Sunrise</span>
            <span><strong>{moment(weather.sunrise).format("hh:mma")}</strong></span>
          </div>
        </div>
        <div className="sun-field">
          <WeatherIcon icon="sunset" className="sun-field-icon" />
          <div className="sun-field-text">
            <span>Sunset</span>
            <span><strong>{moment(weather.sunset).format("hh:mma")}</strong></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CameraPreviewWeather;
