import "./Modals.scss";
import React, {useCallback, useState} from "react";
import {BaseModal, Button, Header} from "../../../../components";
import FormValidator from "../../../CameraList/modals/modalValidation";
import {foldersApi} from "../../../../api/folders";

const EditFolder = ({folder, isOpen, cameras, onClose, errors, setErrors}) => {
  const defaultValue = {
    name: folder.name || "",
    description: folder.description || "",
    cameraIds: folder.cameras && folder.cameras.length > 0 ? folder.cameras.map((camera) => camera.id) : [],
  };
  const [form, setForm] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = useCallback((e) => {
    const {name, value} = e.target;

    setForm({
      ...form,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  }, [form, errors, setErrors]);

  const isSelected = useCallback((id) => {
    return form.cameraIds && form.cameraIds.includes(id);
  }, [form]);

  const onSelectCamera = useCallback((id) => {
    const cameraIds = form?.cameraIds || [];
    setForm({
      ...form,
      cameraIds: isSelected(id) ? cameraIds.filter((item) => item !== id) : [...cameraIds, id],
    });
    setErrors({
      ...errors,
      cameraIds: "",
    });
  }, [setErrors, errors, form, isSelected]);

  const handleClose = useCallback(() => {
    onClose(false);
    setForm(defaultValue);
  }, [onClose, defaultValue]);

  const onSubmit = useCallback(async () => {
    const isValid = FormValidator.validateForm(form, setErrors);
    if (!isValid) return;
    setIsLoading(true);
    const { data } = await foldersApi.update(folder.id, {folder: {...form, parentId: null}});
    setIsLoading(false);
    setForm({
      name: data.name || "",
      description: data.description || "",
      cameraIds: data.cameras && data.cameras.length > 0 ? data.cameras.map((camera) => camera.id) : [],
    })
    onClose(data);
  }, [form, setErrors, onClose, folder]);

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose}>
      <section className="modal-body create-folder-modal">
        <Header name={"Edit folder"} onClose={handleClose}/>
        <div className="modal-content">
          <form>
            <div className="form-group">
              <label>Name</label>
              <input
                type={"text"}
                placeholder={"Enter Name"}
                className={errors.name ? "inputError" : "input"}
                name="name"
                value={form?.name || ""}
                onChange={onChange}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
            <div className="form-group">
              <label>Description</label>
              <textarea
                className={errors.description ? "textareaError" : "textarea"}
                value={form?.description || ""}
                rows={2}
                name="description"
                placeholder={"Enter Description"}
                onChange={onChange}
              />
              {errors.description && <span className="error">{errors.description}</span>}
            </div>
          </form>
          <div className="cameras-list">
            <p className="cameras-list-title">Select cameras:</p>
            <table>
              <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>MEID (ICCID)</th>
              </tr>
              </thead>
              <tbody>
              {cameras.length > 0 ? (
                cameras.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <div className="checkbox-table">
                          <input
                            onChange={() => onSelectCamera(item.id)}
                            type="checkbox"
                            id={`camera-select-${item.id}`}
                            value={item.id}
                            checked={isSelected(item.id)}
                          />
                          <label htmlFor={`camera-select-${item.id}`}>
                            {item.name || <i>No name</i>}
                          </label>
                        </div>
                      </td>
                      <td>{item.provider}</td>
                      <td>{item.imei}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={3} className="table-empty">
                    You don't have any cameras
                  </td>
                </tr>
              )}
              </tbody>
            </table>
            {errors.cameraIds && <span className="error">{errors.cameraIds}</span>}
          </div>
        </div>
        <div className="modal-buttons">
          <Button onClick={handleClose} title={"Cancel"} color={"outline"}/>
          <Button
            onClick={onSubmit}
            disabled={isLoading}
            loader={isLoading}
            title={"Update"}
            color={"grey"}
          />
        </div>
      </section>
    </BaseModal>
  );
};
export default EditFolder;
