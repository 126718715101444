import "./Device.scss";
import React from "react";
import moment from "moment";

const Device = ({onClick, device}) => {

    return (
        <section onClick={() => {
            onClick(device.id)
        }} className="device-list-item">
            <div className="device-item-description">
                <span>{device?.model || "Unknown"},</span>
                <span>{device?.version || "Unknown"},</span>
                <span>{device?.os || "Unknown"},</span>
                <span>{device?.appVersion || "0.0.0"}</span>
            </div>
            <div className="device-controls">
                <LastSeen lastSeen={device?.lastSeen || ""}/>
                <div className="icon icon-arrow-right"/>
            </div>
        </section>
    );
};

export default Device;


const LastSeen = ({lastSeen}) => {

    if (!lastSeen) {
        return <div className="device-last-seen">{"Never"}</div>
    }


    const dateTime = moment(lastSeen, "MM/DD/YYYY hh:mm");

    return <div className="device-last-seen" title={lastSeen}>{dateTime.fromNow()}</div>
}