import "./../AddCamera.scss";
import React from "react";
import Button from "../../../../components/buttons/Button";
import AccountSubscriptionItem from "./AccountSubscriptionItem";

const ChooseAccountSubscription = ({
   list,
   selectedSubscription,
   onSelectSubscription,
   onShowSubscriptions,
   onBack,
   onConfirm
}) => {

    return (
        <>
            <div className="modal-content">
                <p className="subscription-select-title">Please choose subscription: </p>
                <div className="subscriptions-select-list">
                    {list.map((subscription) => {
                        return (
                            <AccountSubscriptionItem
                                key={subscription.id}
                                item={subscription}
                                isSelected={selectedSubscription && selectedSubscription.id === subscription.billingPlan.id}
                                onSelectSubscription={onSelectSubscription}/>
                        );
                    })}
                </div>
                <Button onClick={onShowSubscriptions} color={"red-outline"} size={"w100"} title="New subscription"/>
            </div>
            <div className="modal-buttons">
                <Button
                    onClick={() => onBack("general")}
                    title={"Back"}
                    color="outline"
                />
                <Button
                    disabled={!selectedSubscription}
                    onClick={onConfirm}
                    title={"Next"}
                    color="grey"
                />
            </div>
        </>
    )
};

export default ChooseAccountSubscription;
