import "./RadioButton.scss";
import React from "react";

const RadioButton = ({name, title, value, checked, handleChange, readOnly = false, disabled = false}) => {
    return (
        <label className="radio-input">
            <input
                readOnly={readOnly}
                onChange={handleChange}
                type="radio"
                value={value}
                checked={checked}
                className="radio-button-input"
                name={name}
                disabled={disabled}
            />
            <span className="checkmark"/>
            <span className="label-text">{title}</span>
        </label>
    );
};

export default RadioButton;
