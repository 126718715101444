import React, {useContext} from "react";
import "./NotFoundError.scss";
import {useNavigate} from "react-router-dom";
import Button from "../../components/buttons/Button";
import not_found_icon from "../../images/iconSVG/errorPages/not-found.svg";
import {ErrorsContext} from "../../context/errors";

const NotFoundError = () => {
  const { error } = useContext(ErrorsContext);
  const navigate = useNavigate();

  const onHomeHandler = () => {
    navigate("/");
  };

  return (
    <div className="error-page-wrapper">
      <div className="img-container">
        <img src={not_found_icon} alt="not-found"/>
      </div>
      <div className="error-page-description">
        <p className="error-page-title">{error ? error : "Oooops! This page doesn’t exist. "}</p>
        <p className="error-text-comment">The above error occurred while the Web server was processing your request.</p>
        <p className="error-text-comment">Please contact us if you think this is a server error. Thank you.</p>
      </div>
      <Button onClick={onHomeHandler} title={"My Cameras"} size={"lg"} icon="arrow"/>
    </div>
  );
};

export default NotFoundError;
