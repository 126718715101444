import "./AddCamera.scss";
import React from "react";
import Button from "../../../components/buttons/Button";

const Review = ({info, onBack, loading, onFinish}) => {
    const onConfirm = async () => {
        await onFinish();
    };

    const {imei, iccid, cameraInfo, name, description, subscription} = info;

    return (
        <>
            <div className="modal-content">
                <div className="camera-add-info">
                    <div className="camera-add-info-photo">
                        <img src={cameraInfo.photo} alt="" />
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">Model/Year</div>
                        <div className="camera-add-info-value">
                            {cameraInfo.name} / {cameraInfo.year}
                        </div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">Provider</div>
                        <div className="camera-add-info-value">{cameraInfo.wirelessType}</div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">IMEI</div>
                        <div className="camera-add-info-value">{imei}</div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">ICCID</div>
                        <div className="camera-add-info-value">{iccid}</div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">Name</div>
                        <div className="camera-add-info-value">{name || ""}</div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">Description</div>
                        <div className="camera-add-info-value">{description}</div>
                    </div>
                    <div className="camera-add-info-line">
                        <div className="camera-add-info-name">Subscription</div>
                        <div className="camera-add-info-value">{subscription.name}</div>
                    </div>
                </div>
            </div>
            <div className="modal-buttons">
                <Button
                    onClick={() => onBack("choose-subscription")}
                    title={"Back"}
                    color="outline"
                />
                <Button onClick={onConfirm} loader={loading} title={"Confirm"} color="grey" />
            </div>
        </>
    );
};

export default Review;
