import "./Modals.scss";
import React from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {GuestAccountsKeysApi} from "../../../api/guest-accounts-keys";
import {guestQK} from "../../../helpers/query-keys";
import fetcher from "../../../helpers/fetcher";

const ViewKey = ({ isOpen, account, onClose }) => {
    return (
        <BaseModal isOpen={isOpen} onClose={onClose}>
            <section className="modal-body create-guest-account-modal">
                <Header name={"View key"} onClose={onClose}/>
                <div className="modal-content">
                    <form>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                              type={"text"}
                              placeholder={"Enter name"}
                              className="input"
                              value={account.name}
                              readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <label>Key</label>
                            <input
                              type={"text"}
                              placeholder={"Enter key"}
                              className="input"
                              value={account.key}
                              readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <label>Username</label>
                            <input
                              type={"text"}
                              placeholder={"Enter username"}
                              className="input"
                              value={account.host.username}
                              readOnly={true}
                            />
                        </div>
                    </form>
                    <div className="guest-account-camera-list">
                        <p className="guest-account-camera-list-title">Please select cameras to share:</p>
                        <table>
                            <thead>
                            <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>MEID (IMEI)</th>
                            </tr>
                            </thead>
                            <tbody>
                            {account.cameras && account.cameras.length > 0 ? (
                              account.cameras.map((item) => {
                                  return (
                                    <tr key={item.id}>
                                        <td>
                                            <div className="checkbox-table">
                                                <label>{item.name || <i>No name</i>}</label>
                                            </div>
                                        </td>
                                        <td>{item.type}</td>
                                        <td>{item.meid}</td>
                                    </tr>
                                  );
                              })
                            ) : (
                              <tr>
                                  <td colSpan={3} className="table-empty">
                                      You don't have any cameras
                                  </td>
                              </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="modal-buttons">
                    <Button
                        onClick={onClose}
                        title={"Close"}
                        color={"outline"}
                    />
                </div>
            </section>
        </BaseModal>
    );
};

const ViewKeyModal = ({id, isOpen, onClose}) => {
    const guestKeyQK = guestQK.oneKey(id).queryKey;
    const {
        data: guestAccountData = {
            data: {}
        },
        isFetched
    } = fetcher(guestKeyQK, () => GuestAccountsKeysApi.view(id));

    return isFetched ? <ViewKey account={guestAccountData.data} isOpen={isOpen} onClose={onClose} /> : null;

}
export default ViewKeyModal;
