import "./Modals.scss";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";

import { Button, MultiSelect, ModalComponent } from "../../../../components";
import { GlossaryApi } from "../../../../api/glossary";
import CustomSelect from "../../../../components/CustomSelect/CustomSelect";
import DatePicker from "../../../../components/DatePicker";

const extraFilters = false;

const FiltersModal = (props) => {
  const {
    isOpen,
    onClose,
    applyFilters,
    date = [null, null],
    setDate,
    filters,
    setFilters,
    resetHandler,
    isLoading
  } = props;
  const [glossary, setGlossary] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const responseFilters = await GlossaryApi.filters();

      setGlossary(responseFilters.data);
    };

    fetchData();
  }, []);

  const onChangeCalendarHandler = (date) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      date: {
        from: date[0] ? format(date[0], "yyyy-MM-dd") : "",
        to: date[1] ? format(date[1], "yyyy-MM-dd") : "",
      },
    }));

    setDate(date);
  };

  const onChangeFilter = (e) => {
    const {name, value} = e.target;

    setFilters(getFilterValues(filters, name, value));
  };

  const getFilterValues = (source, name, value) => {
    if (value === "" || (Array.isArray(value) && value.length === 0)) {
      const {[name]: _, ...rest} = source;

      return rest;
    }

    return {
      ...source,
      ...{[name]: value},
    };
  };

  const onFromToChange = (name, type, value) => {
    const filtered = getFilterValues(filters[name] ?? {}, type, value);

    setFilters(getFilterValues(filters, name, Object.keys(filtered).length > 0 ? filtered : ""));
  };

  const onMultiSelectChange = (name, values) => {
    const icons = values.map((item) => item.value);

    setFilters(getFilterValues(filters, name, icons));
  };

  const multiSelectValues = (name, selected) => {
    if (!selected || Object.keys(glossary).length === 0) {
      return [];
    }

    if (name === "icons") {
      return selected.map((item) => ({value: item, label: glossary[name][item].name || "Unknown"}));
    }

    return selected.map((item) => ({value: item, label: glossary[name][item] || "Unknown"}));
  };

  const findSelectedOption = (type, value) => {
    if (!value || !glossary[type]) {
      return {value: "", label: "All"};
    }

    return {value: value, label: glossary[type][value]};
  };

  const {icons, type, moon_phase, tod, wind_dir, state} = glossary;

  return (
    <ModalComponent isOpen={isOpen} onClose={onClose} title={"Filters"}>
      <div className="modal-content">
        <form>
          <div className="form-row form-row-horizontal">
            <div className="form-group">
              <label>Content type</label>
              <CustomSelect
                name={"type"}
                onChange={onChangeFilter}
                value={findSelectedOption("type", filters?.type || "")}
                options={
                  type &&
                  Object.keys(type).map((key) => ({
                    value: key,
                    label: type[key],
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>HQ/Video Status</label>
              <CustomSelect
                name={"state"}
                onChange={onChangeFilter}
                value={findSelectedOption("state", filters?.state || "")}
                options={
                  state &&
                  Object.keys(state).map((key) => ({
                    value: key,
                    label: state[key],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-row form-row-horizontal">
            <div className="form-group">
              <label>Time of day</label>
              <CustomSelect
                name={"tod"}
                onChange={onChangeFilter}
                value={findSelectedOption("tod", filters?.tod || "")}
                options={
                  tod &&
                  Object.keys(tod).map((key) => ({
                    value: key,
                    label: tod[key],
                    key: key,
                  }))
                }
              />
            </div>
            <div className="form-group">
              <label>Recognition</label>
              <MultiSelect
                name={"icons"}
                onChange={onMultiSelectChange}
                value={multiSelectValues("icons", filters?.icons || [])}
                options={
                  icons && Object.keys(icons).length > 0
                    ? Object.keys(icons).map((key) => ({value: key, label: icons[key].name}))
                    : []
                }
              />
            </div>
          </div>
          <div className="form-row form-row-horizontal">
            <div className="form-group">
              <label>Moon Phase</label>
              <MultiSelect
                name={"moon_phase"}
                onChange={onMultiSelectChange}
                value={multiSelectValues("moon_phase", filters?.moon_phase || [])}
                options={
                  moon_phase &&
                  Object.keys(moon_phase).map((key) => ({
                    value: key,
                    label: moon_phase[key],
                  }))
                }
              />
            </div>
          </div>
          <div className="form-row form-row-horizontal">
            <div className="form-row form-row-horizontal">
              <div className="form-group">
                <label>Date</label>
                <div className="form-group-datepicker">
                  <DatePicker name="date" range value={date} onChange={onChangeCalendarHandler} />
                </div>
              </div>
            </div>
            <div className="form-row form-row-horizontal">
              <div className="form-group">
                <label>Temperature, F</label>
                <input
                  type="number"
                  placeholder="From"
                  name={"temp[from]"}
                  className="input"
                  value={filters?.temp && filters?.temp.from ? filters?.temp.from : ""}
                  onChange={(e) => {
                    onFromToChange("temp", "from", e.target.value);
                  }}
                />
              </div>
              <div className="form-group">
                <label>&nbsp;</label>
                <input
                  type="number"
                  placeholder="To"
                  className="input"
                  name={"temp[to]"}
                  value={filters?.temp && filters?.temp?.to ? filters?.temp?.to : ""}
                  onChange={(e) => {
                    onFromToChange("temp", "to", e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          {extraFilters && (
            <div className="form-row form-row-horizontal">
              <div className="form-row form-row-horizontal">
                <div className="form-group">
                  <label>Date</label>
                  <div className="date-picker-wrapper">
                    <div className="form-group-datepicker">
                      <DatePicker name="date" range value={date} onChange={onChangeCalendarHandler} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row form-row-horizontal">
                <div className="form-group">
                  <label>Wind Speed, MPH</label>
                  <input type="text" placeholder="From" className="input"/>
                </div>

                <div className="form-group">
                  <label>&nbsp;</label>
                  <input type="text" placeholder="To" className="input"/>
                </div>
              </div>
            </div>
          )}
          {extraFilters && (
            <div className="form-row form-row-horizontal">
              <div className="form-group">
                <label>Wind direction</label>
                <select className="select" value={""}>
                  <option value={""}>All</option>
                  {wind_dir &&
                    Object.keys(wind_dir).map((key) => (
                      <option key={key} value={key}>
                        {wind_dir[key]}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
        </form>
      </div>
      <div className="modal-footer">
        <div className="modal-footer-left">
          <Button onClick={onClose} title={"Close"} color={"outline"}/>
        </div>
        <div className="modal-footer-right">
          <Button onClick={() => resetHandler()} title={"Reset"} color={"outline"}/>
          <Button
            onClick={() => {
              applyFilters(filters);
            }}
            title={"Apply"}
            color={"grey"}
            loader={isLoading}
          />
        </div>
      </div>
    </ModalComponent>
  );
};
export default FiltersModal;
