import React from "react";
import GoogleMapReact from "google-map-react";
import {AccountCameraAPI} from '../../api/account-camera';
import MapMarker from '../../components/MapMarker/MapMarker';
import LineWaveLoader from '../../components/Loader/LineWaveLoader';
import {mapQK} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import "./map.scss";

const Map = () => {
  const defaultData = {
    data: {
      list: []
    }
  };
  const {data = defaultData, isFetched, isLoading} = fetcher(mapQK.list.queryKey, AccountCameraAPI.map);
  const cameras = data.data.list;

  const onMapLoaded = ({map, maps}) => {
    const bounds = new maps.LatLngBounds();
    cameras.forEach((camera) => {
      bounds.extend({
        lat: parseFloat(camera?.coordinates?.latitude),
        lng: parseFloat(camera?.coordinates?.longitude)
      });
    });
    map.fitBounds(bounds);
  };

  const camerasAbsent = (cameras?.length || 0) < 1;

  return (
    <section className="page-wrapper">
      <div className="map-wrapper">
        {(!isFetched || camerasAbsent) && (
          <div className="page-content map-page-content">
            <div className="page-header">
              <div className="page-title">Cameras Map</div>
            </div>
            {isLoading && <LineWaveLoader/>}
          </div>
        )}
        {isFetched && camerasAbsent && (
          <div className="page-content map-page-content">
            <div className="empty-search">
              <div className="empty-search-image"/>
              <p className="empty-search-title">Sorry, we didn't find any cameras to show on map</p>
            </div>
          </div>
        )}
        {isFetched && cameras.length > 0 && (
          <div className="map">
            <GoogleMapReact
              bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAP_KEY}}
              center={{lat: 0, lng: 0}}
              defaultZoom={0}
              onGoogleApiLoaded={onMapLoaded}
            >
              {cameras.map((camera) => {
                const sdCardData = camera?.usage?.stats?.sdCard;
                const sdCardInfo = `${sdCardData?.freeSpace}${sdCardData?.uom}/${sdCardData?.capacity}${sdCardData?.uom}`;

                return (
                  <MapMarker
                    key={camera.id}
                    lat={parseFloat(camera?.coordinates?.latitude)}
                    lng={parseFloat(camera?.coordinates?.longitude)}
                    isTooltipOpen={false}
                    tooltipData={{
                      name: camera?.name,
                      battery: camera?.usage?.stats?.battery || 0,
                      signal: camera?.usage?.stats?.signal || 0,
                      sdCard: sdCardInfo,
                      image: camera?.specs?.photo,
                    }}
                  />
                )
              })}
            </GoogleMapReact>
            <div className="page-content map-title map-page-content">
              <div className="page-header">
                <div className="page-title">Cameras Map</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Map;
