import React, {useContext, useEffect} from "react";
import {AuthApi} from "../../api/auth";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import qs from "qs";
import {AuthContext} from "../../context/auth";


const SupportSignIn = () => {
  const navigate = useNavigate();
  const { setToken } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const search = qs.parse(searchParams.toString());


  const authWithToken = async () => {
    if (search && search.token) {
      try {
        const { data } = await AuthApi.signInAsUser(search.token);
        if (data.auth_token) setToken(data.auth_token);
        navigate("/");
      } catch (e) {
        navigate("/login");
      }
    }
  }


  useEffect(() => {
    authWithToken();
  }, []);


  if (!(search && search.token)) {
    return (<Navigate to="/login"/>);
  }

  return null;
}

export default SupportSignIn;
