import axios from "./http-api-client";

export const GlossaryApi = {
    filters: async () => {
        return axios.get(`/glossary/photos/list-filter`);
    },

    pricing: async () => {
        return axios.get(`/glossary/pricing`);
    },

    recognitions: async () => {
        return axios.get(`/glossary/recognition/labels`);
    },

    states: async () => {
        return axios.get(`/glossary/states`);
    },

    statsPeriods: async () => {
        return axios.get(`/glossary/camera/stats-periods`);
    },

    timezones: async () => {
        return axios.get(`/glossary/timezones`);
    }
};
