import axios from "./http-api-client";
import simpleAxios from "./http-api-simple-client";

export const CameraPhotosApi = {

    listByDates: async (id, params = {}) => {
        return axios.get(`/account/cameras/${id}/photos-by-dates`, {
            params: params
        });
    },

    addTags: async (id, data) => {
        return axios.post(`/account/photos/${id}/tags`, data);
    },

    removeTag: async (photoId, tags) => {
        return axios.delete(`/account/photos/${photoId}/tags`, {
            data: {
                tags
            }
        });
    },

    listByDatesWithFromId: async (id, params = {}, fromId) => {
        return axios.get(`/account/cameras/${id}/photos-by-dates${fromId ? `?fromId=${fromId}` : ""}`, {
            params: params
        });
    },

    loadMorePhotos: async (id, fromId, params = {}) => {
        return axios.get(`/account/cameras/${id}/photos-by-dates?fromId=${fromId}`, {
            params: params
        });
    },

    photoView: async (id, photoId, params = {}) => {
        return simpleAxios.get(`/account/cameras/${id}/photos/${photoId}`, {
            params: params
        });
    },

    photoLike: async (id, photoId) => {
        return axios.put(`account/cameras/${id}/photos/${photoId}/like`);
    },

    photoUnLike: async (id, photoId) => {
        return axios.put(`account/cameras/${id}/photos/${photoId}/unlike`);
    },

    photoDelete: async (id, photoId) => {
        return axios.delete(`account/cameras/${id}/photos/${photoId}`);
    },

    photosBatchDelete: async (id, photoIds) => {
        return axios.delete(`account/cameras/${id}/photos`, {
            data: {photoIds: photoIds}
        })
    },

    photosBatchLike: async (id, photoIds) => {
        return axios.post(`account/cameras/${id}/photos/like`, {
            photoIds: photoIds
        })
    },

    photosBatchUnLike: async (id, photoIds) => {
        return axios.post(`account/cameras/${id}/photos/unlike`, {
            photoIds: photoIds
        })
    },

    zipPhotos: async (id, photoIds) => {
        return axios.post(`account/cameras/${id}/photos/zip`, {
            photos: photoIds
        })
    },

    requestHq: async (id, photoId, forced = false) => {

        if (forced) {
            return axios.post(`/account/cameras/${id}/photos/${photoId}/commands/request-hq?forced=1`);
        }

        return axios.post(`/account/cameras/${id}/photos/${photoId}/commands/request-hq`);
    },

    requestVideo: async (id, photoId, forced = false) => {

        if (forced) {
            return axios.post(`/account/cameras/${id}/photos/${photoId}/commands/request-video?forced=1`);
        }

        return axios.post(`/account/cameras/${id}/photos/${photoId}/commands/request-video`);
    },

    deleteAllPhotos: async (id) => {
        return axios.delete(`account/cameras/${id}/photos/all`);
    }
};
