import "./../AddCamera.scss";
import cx from "classnames";
import React, {useState} from "react";
import SubscriptionItem from "./SubscriptionItem";
import Button from "../../../../components/buttons/Button";

const NewSubscription = ({
     subscriptions,
     selectedSubscription,
     isEmptyAccountSubscriptions,
     onSelectSubscription,
     onShowSubscriptions,
     onBack,
     onConfirm
}) => {

    const [subscriptionGroup, onSubscriptionGroupSelect] = useState(selectedSubscription !== null ? subscriptions.find(item => {
        return item.plans.some(plan => plan.id === selectedSubscription.id);
    }) : null);

    const onEmptyAccountSubscriptionsBack = () => {
        onBack("general");
    }

    const onConfirmClick = () => {
        onConfirm();
    }

    return (
        <>
            <div className="modal-content">
                <p className="subscription-select-title">{subscriptionGroup !== null ? subscriptionGroup.name : "Please choose plan type"}:</p>
                <div className={cx("new-subscriptions-select-list", {"subscriptions-grid-list": subscriptionGroup !== null})}>
                    {
                        subscriptionGroup ?
                            subscriptionGroup.plans.map((subscription) => {
                                return (
                                    <SubscriptionItem
                                        key={subscription.id}
                                        item={subscription}
                                        isSelected={selectedSubscription && selectedSubscription.id === subscription.id}
                                        onSelectSubscription={onSelectSubscription}/>
                                );
                            })
                            : subscriptions.map(group => {
                                return (
                                    <div key={group.id} className="subscription-plans-group" onClick={() => {
                                        onSubscriptionGroupSelect(group);
                                    }}>
                                        <div className="subscription-plans-group-name">{group.name}</div>
                                    </div>
                                );
                            })
                    }
                </div>
                {
                    (selectedSubscription && selectedSubscription.description.length > 0) &&
                    <p style={{fontSize: '16px'}}>{selectedSubscription.description}</p>
                }
            </div>
            <ModalButtons
                isSelectedSubscription={selectedSubscription !== null}
                isGroupSelected={subscriptionGroup !== null}
                isEmptyAccountSubscriptions={isEmptyAccountSubscriptions}
                onSubscriptionGroupSelect={onSubscriptionGroupSelect}
                onShowSubscriptions={onShowSubscriptions}
                onBack={onEmptyAccountSubscriptionsBack}
                onConfirm={onConfirmClick}
            />
        </>
    )
};

export default NewSubscription;

const ModalButtons = ({isSelectedSubscription, isGroupSelected, isEmptyAccountSubscriptions, onSubscriptionGroupSelect, onShowSubscriptions, onBack, onConfirm}) => {

    if (isGroupSelected) {
        return (
            <div className="modal-buttons">
                <Button
                    onClick={() => onSubscriptionGroupSelect(null)}
                    title={"Back"}
                    color="outline"
                />
                <Button
                    disabled={!isSelectedSubscription}
                    onClick={onConfirm}
                    title={"Next"}
                    color="grey"
                />
            </div>
        )
    }

    if (isEmptyAccountSubscriptions) {
        return (
            <div className="modal-buttons">
                <Button
                    onClick={onBack}
                    title={"Back"}
                    color="outline"
                />
            </div>
        )
    }

    return (
        <div className="modal-buttons">
            <Button
                onClick={onShowSubscriptions}
                title={"Back"}
                color="outline"
            />
        </div>
    )
}
