import "./CameraView.scss";
import React, {useMemo, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";

//components
import Nav from "./components/Nav";
import UsageItem from "./components/UsageItem";
import Dropdown from "../../components/Dropdown/Dropdown";
import GraphicLoader from "../../components/GraphicLoader/GraphicLoader";
import Graphic from "../../components/Graphic/Graphic";
import Spinner from "./components/Spinner";

import {AccountCameraAPI} from "../../api/account-camera";
import {camerasSettings, glossaryQK} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import {GlossaryApi} from "../../api/glossary";
import {dropdownOptions} from "../../helpers/options";

const Usage = () => {
  const [camera] = useOutletContext();
  const {id} = useParams();
  const [summaryPeriod, setSummaryPeriod] = useState({period: "1 week ago"});
  const [photosPeriod, setPhotoPeriod] = useState({period: "1 week ago"});
  const [batteryPeriod, setBatteryPeriod] = useState({period: {label: "1 week ago"}});
  const [sdUsagePeriod, setSdUsagePeriod] = useState({period: {label: "1 week ago"}});

  const [summaryPeriodKey, setSummaryPeriodKey] = useState("w");
  const [photosPeriodKey, setPhotoPeriodKey] = useState("w");
  const [batteryPeriodKey, setBatteryPeriodKey] = useState("w");
  const [sdUsagePeriodKey, setSdUsagePeriodKey] = useState("w");
  const usageQueryKey = camerasSettings.usage(id).queryKey;
  const summaryQueryKey = camerasSettings.summary(id).queryKey;
  const photosQueryKey = camerasSettings.photos(id, photosPeriodKey).queryKey;
  const batteryQueryKey = camerasSettings.battery(id, batteryPeriodKey).queryKey;
  const sdCardQueryKey = camerasSettings.sdCard(id, sdUsagePeriodKey).queryKey;

  const {
    data: camerasStatsPhotosData,
    ...camerasStatsPhotosQuery
  } = fetcher(photosQueryKey, () => AccountCameraAPI.camerasStatsPhotos(id, photosPeriodKey))
  const {
    data: camerasStatsBatteryData,
    ...camerasStatsBatteryQuery
  } = fetcher(batteryQueryKey, () => AccountCameraAPI.camerasStatsBattery(id, batteryPeriodKey))
  const {
    data: camerasStatsStorageData,
    ...camerasStatsStorageQuery
  } = fetcher(sdCardQueryKey, () => AccountCameraAPI.camerasStatsStorage(id, sdUsagePeriodKey))
  const summaryQuery = fetcher(summaryQueryKey, () => AccountCameraAPI.cameraSummary(id))
  const {data: periodsData} = fetcher(glossaryQK.periods.queryKey, GlossaryApi.statsPeriods);
  const cameraStats = useMemo(() => {
    return {
      photos: camerasStatsPhotosData?.data ? camerasStatsPhotosData.data.datasets : {},
      battery: camerasStatsBatteryData?.data ? camerasStatsBatteryData.data.datasets : {},
      sdCard: camerasStatsStorageData?.data ? camerasStatsStorageData.data.datasets : {},
    }
  }, [camerasStatsPhotosData, camerasStatsBatteryData, camerasStatsStorageData]);

  const summaryData = summaryQuery.data?.data?.summary || [];

  const {
    data = {
      data: {
        firmware: "",
        id: "",
        stats: {},
        weather: {},
        photo: {},
      }
    }, ...usageQuery
  } = fetcher(usageQueryKey, () => AccountCameraAPI.usage(id));

  const statsData = useMemo(() => {
    if (data.data) {
      return {
        firmware: data.data.firmware,
        id: data.data.id,
        stats: data.data.stats,
        sdCard: camerasStatsStorageQuery.data?.datasets ?? {},
        battery: camerasStatsBatteryQuery.data ?? {},
        weather: data.data.weather,
        photo: data.data.photo,
      };
    }
    return {
      firmware: "",
      id: "",
      stats: {},
      weather: {},
      sdCard: {},
      battery: {},
      photo: {},
    };
  }, [data, camerasStatsStorageQuery, camerasStatsBatteryQuery]);

  if (!camera.id) {
    return;
  }

  const onChangeFilterPhotos = (value) => {
    setPhotoPeriodKey(value.key);
    setPhotoPeriod({period: value.label});
  };

  const onChangeSummary = (value) => {
    setSummaryPeriodKey(value.key);
    setSummaryPeriod({period: value.label});
  };
  const onChangeFilterBattery = (value) => {
    setBatteryPeriodKey(value.key);
    setBatteryPeriod({period: value});
  };
  const onChangeFilterSdUsage = (value) => {
    setSdUsagePeriodKey(value.key);
    setSdUsagePeriod({period: value});
  };

  let photosGraphicLData = cameraStats?.photos
    ? Object.values(cameraStats?.photos).map((photos, index) => {
      const x = Object.keys(cameraStats?.photos)[index];
      return {
        x,
        y: parseInt(photos),
        label: (
          <p style={{margin: 0}}>
            <p style={{margin: 0}}>Date: {x}</p>
            <p style={{margin: 0}}>Photos: {photos}</p>
          </p>
        )
      }
    })
    : [];

  let batteryGraphicData = cameraStats?.battery
    ? Object.values(cameraStats?.battery)?.map((level, index) => {
      const x = Object.keys(cameraStats?.battery)[index];
      return {
        x,
        y: parseInt(level),
        label: (
          <p style={{margin: 0}}>
            <p style={{margin: 0}}>Date: {x}</p>
            <p style={{margin: 0}}>Battery level: {level}</p>
          </p>
        )
      }
    })
    : [];

  let sdCardGraphicData = cameraStats?.sdCard
    ? Object.values(cameraStats?.sdCard)?.map((capacity, index) => {
      const x = Object.keys(cameraStats?.sdCard)[index];
      return {
        x,
        y: parseFloat(capacity).toFixed(2),
        label: (
          <p style={{margin: 0}}>
            <p style={{margin: 0}}>Date: {x}</p>
            <p style={{margin: 0}}>Capacity: {parseFloat(capacity).toFixed(2)}</p>
          </p>
        )
      }
    })
    : [];

  const {weather, stats, photo} = statsData;
  const {battery, sdCard} = stats;

  const periodsOptions = useMemo(() => {
    if (periodsData && periodsData.data) {
      return Object.keys(periodsData.data).map((key) => ({
        value: key,
        label: periodsData.data[key],
        key: key,
      }))
    }
    return [];
  }, [periodsData]);

  const summary = useMemo(() => {
    if (summaryData.length > 0) {
      const key = summaryPeriodKey === "w" ? "1w" : summaryPeriodKey;
      const current = summaryData.find((item) => item.period === key);
      if (current) {
        return {
          photos: current.photos,
          tags: Object.keys(current.tags).map((tag) => ({
            label: tag,
            value: current.tags[tag],
          })),
          recognitions: Object.keys(current.recognitions).map((item) => ({
            label: item,
            name: item.replace("_", " "),
            value: current.recognitions[item],
          })),
          recognitionsTotal: Object.values(current.recognitions).reduce((res, item) => res + item, 0),
          tagsTotal: Object.values(current.tags).reduce((res, item) => res + item, 0),
        }
      }
    }
    return {
      tags: [],
      recognitions: [],
      recognitionsTotal: 0,
      photos: 0,
      tagsTotal: 0
    };
  }, [summaryPeriodKey, summaryData]);

  return (
    <>
      <Nav camera={camera} current={"usage"}/>
      <section className="content-section camera-usage-section">
        <div className="content-header">
          <div className="page-sub-title">Camera Info</div>
        </div>
        {usageQuery.isLoading ? <Spinner/> :
          <div className="camera-settings-usage-section">
            <div className="camera-settings-usage-block">
              <UsageItem title={"Data usage"} info={"N/A"}/>
              <UsageItem title={"Total photos and video taken"} info={"N/A"}/>
              <UsageItem
                title={"Available SD card space"}
                info={sdCard ? `${sdCard.freeSpace} ${sdCard.uom}` : "N/A"}
              />
              <UsageItem title={"Battery level"} info={battery ? battery + "%" : "N/A"}/>
              <UsageItem title={"Firmware"} info={camera?.firmware || "N/A"}/>
            </div>
            <div className="camera-settings-usage-block">
              <UsageItem title={"Latest weather conditions"} info={"N/A"}/>
              <UsageItem title={"Temperature"} info={weather?.temperature || "N/A"}/>
              <UsageItem title={"Wind speed"} info={weather?.windSpeed || "N/A"}/>
              <UsageItem title={"Wind direction"} info={weather?.windDirection || "N/A"}/>
              <UsageItem title={"Last update"} info={camera.lastSeen || "N/A"}/>
            </div>
          </div>
        }
      </section>

      <div className="camera-usage-chart">
        <div className="usage-chart-header">
          <div className="usage-chart-title">Camera Summary</div>
          <div className="usage-dropdown">
            <Dropdown
              value={summaryPeriod.period}
              options={periodsOptions}
              onChange={(option) => onChangeSummary(option)}
            />
          </div>
        </div>
        {summaryQuery.isLoading ? <Spinner className="summary-loader"/> :
          (<>
            <hr className="usage-chart-divider"/>
            <div className="camera-settings-usage-section with-paddings for-photos">
              <div className="camera-settings-usage-block">
                <UsageItem alone head title="Photos" info={summary.photos ?? "N/A"}/>
              </div>
            </div>
            <hr className="summary-divider"/>
            <div className="camera-settings-usage-section with-paddings">
              <div className="camera-settings-usage-block">
                <UsageItem head title="Recognitions"/>
                {summary.recognitions.map((item, idx) => (
                  <UsageItem
                    title={
                      <span className="summary-recognition">
                        <div className={`recognition-icon dark-icon icon-${item.label}`}/>{item.name}
                      </span>
                    }
                    key={idx}
                    info={item.value ?? "N/A"}
                  />
                ))}
              </div>
              <div className="camera-settings-usage-block">
                <UsageItem head title="Tags"/>
                {summary.tags.map((item, idx) => (
                  <UsageItem title={item.label} key={idx} info={item.value ?? "N/A"}/>
                ))}
              </div>
            </div>
          </>)
        }
      </div>

      <div className="camera-usage-chart">
        <div className="usage-chart-header">
          <div className="usage-chart-title">Photos by days</div>
          <div className="usage-photos-count">{photo?.totalPhotos}</div>
          <div className="usage-dropdown">
            <Dropdown
              value={photosPeriod.period}
              options={periodsOptions}
              onChange={(value) => onChangeFilterPhotos(value)}
              key={dropdownOptions.id}
            />
          </div>
        </div>
        <hr className="usage-chart-divider"/>
        <div className="usage-chart-content">
          <div className="chart-tbd">
            {camerasStatsPhotosQuery.isLoading ? (
              <GraphicLoader color="#E42F31"/>
            ) : photosGraphicLData.length > 0 ? (
              <Graphic data={photosGraphicLData} width={500} height={300} yLabel="Photos"/>
            ) : (
              <p className="not-found-text">No data found for selected period</p>
            )}
          </div>
        </div>
      </div>
      <div className="camera-usage-chart">
        <div className="usage-chart-header">
          <div className="usage-chart-title">Battery level</div>
          <div className="usage-dropdown">
            <Dropdown
              value={batteryPeriod.period.label}
              options={periodsOptions}
              onChange={(value) => onChangeFilterBattery(value)}
              key={dropdownOptions.id}
            />
          </div>
        </div>
        <hr className="usage-chart-divider"/>
        <div className="usage-chart-content">
          <div className="chart-tbd">
            {camerasStatsBatteryQuery.isLoading ? (
              <GraphicLoader color="#E42F31"/>
            ) : batteryGraphicData.length > 0 ? (
              <Graphic yLabel="Battery %" width={500} height={300} data={batteryGraphicData}/>
            ) : (
              <p className="not-found-text">No data found for selected period</p>
            )}
          </div>
        </div>
      </div>
      <div className="camera-usage-chart">
        <div className="usage-chart-header">
          <div className="usage-chart-title">SD card space usage</div>
          <div className="usage-dropdown">
            <Dropdown
              value={sdUsagePeriod.period.label}
              options={periodsOptions}
              onChange={(value) => onChangeFilterSdUsage(value)}
              key={dropdownOptions.id}
            />
          </div>
        </div>
        <hr className="usage-chart-divider"/>
        <div className="usage-chart-content">
          <div className="chart-tbd">
            {camerasStatsStorageQuery.isLoading ? (
              <GraphicLoader color="#CBD5E1"/>
            ) : sdCardGraphicData.length > 0 ? (
              <Graphic yLabel="SD Card GB" width={500} height={300} data={sdCardGraphicData}/>
            ) : (
              <p className="not-found-text">No data found for selected period</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Usage;
