import "../CameraList.scss";
import React from "react";
import FolderItem from "./FolderItem";
import EmptyFolder from "./EmptyFolder";

const FolderList = ({folders, onChangePin, getList, editFolder, deleteFolder, isLoaded}) => {
    return (
        <section className="camera-list">
            {folders?.length !== 0 ? (
                <div>
                    {folders?.map((folder) => {
                        return (
                            <FolderItem
                                key={folder.id}
                                folder={folder}
                                onChangePin={onChangePin}
                                getList={getList}
                                editFolder={editFolder}
                                deleteFolder={deleteFolder}
                            />
                        );
                    })}
                </div>
            ) : (
                <EmptyFolder />
            )}
        </section>
    );
};

export default FolderList;
