import axios from "./http-api-client";

export const SessionsAPI = {
    all: async () => {
        return axios.get(`/account/sessions`);
    },

    view: async (id) => {
        return axios.get(`/account/sessions/${id}`);
    },

    delete: async (id) => {
        return axios.delete(`/account/sessions/${id}`);
    }
};
