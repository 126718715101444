import "./photo.scss";
import {Navigate, useNavigate} from "react-router-dom";
import React, {useState, useRef, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import cx from "classnames";
import qs from "qs";
import PhotoWeather from "../../components/Weather/photo-weather";
import {CameraPhotosApi} from "../../api/account-camera-photos";

//components
import {isEmptyObject} from "../../components/helpers";
import ToggleSwitch from "../../components/ToggleSwitch/ToggleSwitch";
import PlayVideo from "./components/Modals/PlayVideo";
import {useConfirmationModalContext} from "../../context/confirmation";
import status from "../../helpers/status";
import fetcher from "../../helpers/fetcher";

import {useQueryClient} from "@tanstack/react-query";
import {camerasSettings, foldersQK, gallery, gallery as galleryQueryKeys} from "../../helpers/query-keys";
import EditTags from "../../components/EditTags";
import Tags from "../../components/tags";
import Controls from "./components/controls";
import RecognitionFrames from "./components/recognition"
import PreloadPhoto from "./components/preload";
import State from "./components/state";
import {CamerasAPI} from "../../api/cameras";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from "react-dnd-html5-backend";
import CameraRow from "../../components/DnD/DraggableRowCamera";
import {AccountPhotosAPI} from "../../api/account-photos";

const GalleryPhotoCamera = ({ id }) => {
  const cameraInfoQK = camerasSettings.one(id).queryKey;
  const {data = { data: {}}} = fetcher(cameraInfoQK, () => CamerasAPI.cameraInfo(id));
  const camera = data.data || {};
  if (camera.id) {
    return (
      <section className="content-section camera-photo-preview">
        <DndProvider backend={HTML5Backend}>
          <CameraRow search="" key={camera.id} gallery camera={camera} />
        </DndProvider>
      </section>
    )
  }
  return null;
}

const Wrapper = ({children, gallery}) => {
  if (gallery) {
    return (
      <section className="page-wrapper">
        <div className="page-content">
          <div className="page-title">Gallery</div>
          {children}
        </div>
      </section>
    )
  }
  return children;
}

const PhotoView = ({ camera = {}, folder, camerasFilters, id, gallery, photoId, search }) => {
  const imgContainer = useRef(null);
  const image = useRef(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {showConfirmation} = useConfirmationModalContext();

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showRecognition, setShowRecognition] = useState(true);
  const [showPlayVideo, setShowPlayVideo] = useState(false);

  const suspended = camera.status === status.STATUS_SUSPEND;

  const qKey = useMemo(() =>{
    if (gallery) {
      return galleryQueryKeys.one(`${photoId}`).queryKey;
    }
    if (folder) {
      return foldersQK.photo(id, photoId).queryKey;
    }
    return camerasSettings.onePhoto(id, photoId).queryKey;
  }, [id, gallery, photoId]);

  const currentApi = useMemo(() => {
    if (gallery) {
      return () => AccountPhotosAPI.view(photoId, qs.parse(search.toString(), {ignoreQueryPrefix: true}))
    }
    if (folder) {
      return () => AccountPhotosAPI.view(photoId, qs.parse(search.toString(), {ignoreQueryPrefix: true}), camerasFilters)
    }
    return () => CameraPhotosApi.photoView(id, photoId, qs.parse(search.toString(), {ignoreQueryPrefix: true}))
  }, [id, photoId, camerasFilters, search, gallery]);

  const photoQK = qKey;
  const {
    data = {
      data: {
        next: null,
        prev: null,
        photo: {}
      }
    },
    isLoading, failureReason, refetch
  } = fetcher(photoQK, currentApi, {
    throwOnError: false,
  });
  const photo = data.data.photo;
  const prev = data.data.prev;
  const next = data.data.next;

  const hasVideoFile = photo.type === "video-preview" && !isEmptyObject(photo?.video || {});
  const cameraLocation = [camera?.cameraLocation?.city, camera?.cameraLocation?.name].filter((item) => !!item).join(', ');

  const setPhoto = useCallback((photo) => {
    queryClient.setQueryData(photoQK, {
      ...data,
      data: {
        ...data.data,
        photo
      }
    });
  }, [queryClient, data]);

  function onImageLoad() {
    setIsImageLoading(false);
  }

  const onLikeClick = useCallback(async () => {
    if (gallery || folder) {
      const func =  photo.liked ? AccountPhotosAPI.unLike : AccountPhotosAPI.like;
      try {
        await func(photoId);
        setPhoto({
          ...photo,
          liked: !photo.liked,
        });
      } catch (e) {

      }
    }
    else {
      const func =  photo.liked ? CameraPhotosApi.photoUnLike : CameraPhotosApi.photoLike;
      try {
        await func(id || photo.cameraId, photoId);
        setPhoto({
          ...photo,
          liked: !photo.liked,
        });
      } catch (e) {

      }
    }
  }, [photo, setPhoto, folder, gallery, id, photoId]);

  const navigateLink = useMemo(() => {
    if (gallery) return `/gallery`;
    if (folder) return `/folders/${id}/photos`;
    return `/cameras/${id}/photos`;
  }, [id, gallery]);

  const onImageDelete = useCallback(async () => {
    const confirm = await showConfirmation("Are you sure want delete this photo?")
    if (confirm) {
      try {

        setIsDeleting(true);
        if (gallery || folder) {
          await AccountPhotosAPI.remove(photoId);
        } else {
          await CameraPhotosApi.photoDelete(id, photoId);
        }
        navigate(navigateLink);
      } catch (e) {
        console.log(e);
      } finally {
        setIsDeleting(false);
      }
    }
  }, [showConfirmation, gallery, id, folder, photoId, navigate, navigateLink]);

  const onRequestHqClick = useCallback(async () => {
    if (suspended) return;
    const confirm = await showConfirmation("This will result in a $0.03 service charge to your account. Are you sure you want to request a hi-res version of this image?")
    if (confirm) {
     try {
       const response = await CameraPhotosApi.requestHq(photo.cameraId || id, photo.id, true);
       const data = response.data;
       setPhoto({
         ...photo,
         ...{stateDescription: data.photo?.stateDescription, state: data.photo?.state},
       });
     } catch (e) {

     }
    }
  }, [showConfirmation, id, photo, setPhoto, suspended]);

  const onRequestVideoClick = useCallback(async () => {
    if (suspended) return;
    const confirm = await showConfirmation("This will result in a $0.07 service charge to your account. Are you sure you want to request a video of this image?")
    if (confirm) {
      try {
        const response = await CameraPhotosApi.requestVideo(photo.cameraId || id, photo.id, true);
        const data = response.data;
        setPhoto({
          ...photo,
          ...{stateDescription: data.photo?.stateDescription, state: data.photo?.state},
        });
      } catch (e) {

      }
    }
  }, [showConfirmation, id, photo, setPhoto, suspended]);

  const onSubmitTags = useCallback(async (tags) => {
    try {
      const photoTagIds = photo.tags.map((item) => item.tag_id);
      const deletedTagIds = photoTagIds.filter((item) => !tags.includes(item));
      if (deletedTagIds.length > 0) await CameraPhotosApi.removeTag(photo.id, deletedTagIds);
      await CameraPhotosApi.addTags(photo.id, { tags });
      await refetch();
    } catch (e) {

    }
  }, [refetch, photo]);

  const onSubmitRecognitionTag = useCallback(async (tags, recognition) => {
    try {
      const recognitionTagIds = recognition.tags.map((item) => item.tag_id);
      const deletedTagIds = recognitionTagIds.filter((item) => !tags.includes(item));
      if (deletedTagIds.length > 0) await CameraPhotosApi.removeTag(photo.id, deletedTagIds);
      await CameraPhotosApi.addTags(photo.id, { tags, labelId: recognition.id });
      await refetch();
    } catch (e) {

    }
  }, [refetch, photo]);

  if (failureReason && failureReason?.response && failureReason.response?.status) {
    if (failureReason.response.status === 404) {
      return <Navigate to="/not-found-page"/>
    }
    if (failureReason.response.status === 500) {
      return <Navigate to="/server-error-page"/>
    }
  }
  const [showRecognitionTags, onChangeShowRecognitionTags] = useState(false);

  const handleShowRecognitionTags = useCallback(() => {
    onChangeShowRecognitionTags(true);
  }, []);

  const nextLink = useMemo(() => {
    if (gallery) {
      return `/gallery/${next}${search}`;
    }
    if (folder) {
      return `/folders/${id}/photos/${next}${search}`;
    }
    return `/cameras/${id}/photos/${next}${search}`;
  }, [id, next, folder, gallery, search]);

  const prevLink = useMemo(() => {
    if (gallery) {
      return `/gallery/${prev}${search}`;
    }
    if (folder) {
      return `/folders/${id}/photos/${prev}${search}`;
    }
    return `/cameras/${id}/photos/${prev}${search}`;
  }, [id, prev, folder, gallery, search]);

  return (
    <Wrapper gallery={gallery}>
      <>
        <section className="content-section">
          {hasVideoFile && (
            <PlayVideo
              isOpen={showPlayVideo}
              video={photo.video}
              photo={photo}
              onClose={() => {
                setShowPlayVideo(false);
              }}
            />
          )}
          <div className={cx("camera-photo-view", {"gallery-photo-view": gallery || folder})}>
            <div className="camera-photo-header">
              <div className="camera-photo-date">{photo.date || "..."}</div>
              <div className="camera-photo-time">{photo.time || "..."}</div>
            </div>
            <div className="camera-photo-recognition-row">
              <div className="camera-photo-controls-left camera-photo-location">
                {cameraLocation && `Location: ${cameraLocation}`}
              </div>
              <div className="camera-photo-controls-right">
                <ToggleSwitch
                  name={"recognition"}
                  checked={showRecognition}
                  onChange={() => {
                    setShowRecognition(!showRecognition);
                  }}
                  label={"Show recognition"}
                />
                {photo.recognition && (
                  <EditTags
                    onOpen={handleShowRecognitionTags}
                    recognitions={photo.recognition}
                    open={showRecognitionTags}
                    color="outline"
                    btnText="Tags"
                    onSubmit={onSubmitRecognitionTag}
                    title="View tags for recognitions"
                    className="all-recognitions-tags-btn"
                    onClose={() => onChangeShowRecognitionTags(false)}
                  />
                )}
              </div>
            </div>
            <div className={cx({"gallery-camera-photo": gallery})}>
              <div className="camera-photo" ref={imgContainer}>
                <div style={{position: 'relative', width: '100%'}}>
                  <img
                    ref={image}
                    src={photo.original}
                    alt=""
                    onLoad={onImageLoad}
                    className={`${isLoading || isImageLoading ? "image-loading" : ""}`}
                  />
                  {next && (
                    <Link to={nextLink} className="camera-photo-next">
                      <div className="icon icon-arrow-left"/>
                      <PreloadPhoto id={id} gallery={gallery} photoId={next} cameras={camerasFilters} search={search}/>
                    </Link>
                  )}
                  {prev && (
                    <Link to={prevLink} className="camera-photo-prev">
                      <div className="icon icon-arrow-right"/>
                      <PreloadPhoto id={id} gallery={gallery} photoId={prev} cameras={camerasFilters} search={search}/>
                    </Link>
                  )}
                  {!isLoading && (
                    <State
                      type={photo.type}
                      videoResolution={photo.videoResolution}
                      stateDescription={photo.stateDescription || {}}
                    />
                  )}
                  {!isImageLoading && photo.recognition && (
                    <RecognitionFrames
                      recognition={photo.recognition}
                      image={image}
                      onSubmitTags={onSubmitRecognitionTag}
                      imgContainer={imgContainer}
                      showRecognition={showRecognition}
                    />
                  )}
                </div>
              </div>
            </div>
            <Controls
              photo={photo}
              onSubmitTags={onSubmitTags}
              onLikeClick={onLikeClick}
              suspended={suspended}
              onRequestHqClick={onRequestHqClick}
              onRequestVideoClick={onRequestVideoClick}
              showPlayVideoModal={() => {
                setShowPlayVideo(!showPlayVideo);
              }}
              isDeleting={isDeleting}
              onImageDelete={onImageDelete}
            />
            <Tags tags={photo.tags} className="photo-tags" />
            {/*<div className="photo-weather">*/}
            {/*  <h3>Weather conditions</h3>*/}
            {/*  <PhotoWeather weather={photo.weather || {}}/>*/}
            {/*</div>*/}
          </div>
        </section>
        {photo.cameraId && gallery && <GalleryPhotoCamera id={photo.cameraId}/>}
      </>
    </Wrapper>
  );
};


export default PhotoView;
