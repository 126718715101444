import React, {useCallback, useState} from "react";
import Button from "../buttons/Button";

const Tooltip = ({style, onClose, onSave, tooltipData}) => {
    const {name, battery, signal, sdCard, image} = tooltipData;

    const handleClickSave = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        if (onSave) onSave();
    }, [onSave]);

    return (
        <div
            className="camera-marker-tooltip"
            style={style}
        >
            <div className="camera-marker-tooltip-body">
                <div className="camera-photo">
                    <img src={image} alt=""/>
                </div>
                <div className="camera-info">
                    <div className="camera-name">
                        <strong>{name}</strong>
                    </div>
                    <div>Signal: {signal}%</div>
                    <div>Battery: {battery}%</div>
                    <div>SD: {sdCard}</div>
                </div>
                <div className="tooltip-close">
                    <div
                      className="icon icon-close"
                      onClick={onClose}
                    />
                </div>
            </div>
            {onSave && (
              <div className="tooltip-save">
                  <Button
                    size="sm"
                    color="grey"
                    title="Save"
                    onClick={onSave ? handleClickSave : undefined}
                  />
              </div>
            )}
        </div>
    );
}

const Marker = ({tooltipData, onClick, onSave, disabledClickOnMarker, isTooltipOpen}) => {
    const MARKER_HEIGHT = 28;
    const MARKER_WIDTH = 28;
    const HINT_HEIGHT = onSave ? 130 : 100;
    const HINT_WIDTH = 210;
    const HINT_PADDING = 8;
    const HINT_MARGIN_BOTTOM = 10;

    const [showTooltip, setShowTooltip] = useState(isTooltipOpen);

    const onMarkerClick = onClick || (() => {
        if (disabledClickOnMarker) return;
        setShowTooltip(!showTooltip);
    });

    const onTooltipCloseClick = () => {
        setShowTooltip(false);
    };

    return (
        <div>
            <svg
                style={{
                    cursor: 'pointer',
                    position: 'absolute',
                    top: (MARKER_HEIGHT / 2) * -1,
                    left: (MARKER_WIDTH / 2) * -1,
                }}
                fill="#750000"
                height={MARKER_HEIGHT}
                width={MARKER_WIDTH}
                viewBox="0 0 487 487"
                stroke="#750000"
                onClick={onMarkerClick}
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round"
                   strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <g>
                        <g>
                        <path
                                d="M308.1,277.95c0,35.7-28.9,64.6-64.6,64.6s-64.6-28.9-64.6-64.6s28.9-64.6,64.6-64.6S308.1,242.25,308.1,277.95z M440.3,116.05c25.8,0,46.7,20.9,46.7,46.7v122.4v103.8c0,27.5-22.3,49.8-49.8,49.8H49.8c-27.5,0-49.8-22.3-49.8-49.8v-103.9 v-122.3l0,0c0-25.8,20.9-46.7,46.7-46.7h93.4l4.4-18.6c6.7-28.8,32.4-49.2,62-49.2h74.1c29.6,0,55.3,20.4,62,49.2l4.3,18.6H440.3z M97.4,183.45c0-12.9-10.5-23.4-23.4-23.4c-13,0-23.5,10.5-23.5,23.4s10.5,23.4,23.4,23.4C86.9,206.95,97.4,196.45,97.4,183.45z M358.7,277.95c0-63.6-51.6-115.2-115.2-115.2s-115.2,51.6-115.2,115.2s51.6,115.2,115.2,115.2S358.7,341.55,358.7,277.95z"></path>
                        </g>
                    </g>
                </g>
            </svg>
            {showTooltip && tooltipData && (
                <Tooltip
                    style={{
                        padding: HINT_PADDING,
                        height: HINT_HEIGHT,
                        width: HINT_WIDTH,
                        top: (HINT_HEIGHT + HINT_MARGIN_BOTTOM + HINT_PADDING *
                            2) * -1 - (MARKER_HEIGHT / 2),
                        left: ((HINT_WIDTH + HINT_PADDING * 2) / 2) * -1,
                        zIndex: 999,
                    }}
                    onSave={onSave}
                    tooltipData={tooltipData}
                    onClose={onTooltipCloseClick}
                />
            )}
        </div>
    );
}

export default Marker;
