import React from "react";
import { useLocation, useParams, useOutletContext} from "react-router-dom";
import Photo from "../../containers/photo";

const PhotoView = () => {
  const {id, photoId} = useParams();
  const search = useLocation().search;
  const {camera} = useOutletContext();

  return <Photo camera={camera} search={search} photoId={photoId} key={photoId} id={id} />

};

export default PhotoView;
