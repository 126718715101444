import "../Notifications.scss";
import React from "react";
import ModalComponent from "../../../components/Modal/ModalComponent";
import {Button} from "../../../components";

const NotificationModal = ({isOpen, onClose, modalText, onConfirmNotificationModal, title}) => {
  const updateSettings = null; // todo ???

  return (
    <ModalComponent title={`Enable ${title} messages`} isOpen={isOpen} onClose={onClose} content={{width: "530px"}}>
      <div className="modal-content">
        <p className="modal-text">{modalText}</p>
      </div>
      {updateSettings && (
        <div className="error-block">{<span className="modal-text-error">{updateSettings}</span>}</div>
      )}
      <div className="modal-buttons-block">
        <Button onClick={onClose} title={"No"} color={"outline"}/>
        <Button onClick={onConfirmNotificationModal} title={"Yes"} color={"grey"}/>
      </div>
    </ModalComponent>
  );
};

export default NotificationModal;
