import "./Modal.scss";
import React from "react";

export default function Header({name, onClose}) {
    return (
        <div className="modal-header">
            <h2>{name}</h2>
          {onClose ? (
            <button onClick={onClose} className="modal-close-btn">
              <span className="icon icon-close"/>
            </button>
          ) : null}
        </div>
    );
};
