import React, {useState} from "react";
import InputMask from "react-input-mask";
import FormValidator from "./modalValidation";
import "./AddModal.scss";
import {Button} from "../../../components";
import ModalComponent from "../../../components/Modal/ModalComponent";
import {AccountCameraAPI} from "../../../api/account-camera";

const AddPhoneModal = ({isOpen, id, onClose}) => {
    const [loading, onChangeLoading] = useState(false);

    const [errors, setErrors] = useState({});

    const [inputValue, setInputValue] = useState("");
    const handleChange = (e) => {
        setInputValue(e.target.value);
        setErrors({...errors, inputValue: ""});
    };

    const handleConfirm = async (event) => {
        event.preventDefault();
        const isValid = FormValidator.validateAddPhoneForm(inputValue, setErrors);

        if (isValid) {
            try {
                onChangeLoading(true);
                await AccountCameraAPI.addCameraNotificationPhone(id, inputValue);
                onChangeLoading(false);
                setErrors({});
                onClose(true);
                setInputValue("");
            } catch {
                onChangeLoading(false);
            }
        }
    };

    const onCloseAddPhoneModal = () => {
        setErrors({});
        setInputValue("");
        onClose();
    };


    return (
        <ModalComponent title={"Add phone"} isOpen={isOpen} onClose={onCloseAddPhoneModal} content={{width: "530px"}}>
            <form onSubmit={handleConfirm}>
                <div className="form-group">
                    <label>Please enter your phone number</label>
                    <InputMask
                        mask="999-999-9999"
                        type="text"
                        className={`input ${errors.inputValue ? "not-valid" : ""}`}
                        onChange={handleChange}
                        value={inputValue}
                        placeholder={"___-___-___"}
                    />
                    <div className="error-block">
                        {errors.inputValue && <span className="modal-text-error">{errors.inputValue}</span>}
                    </div>
                </div>
                <div className="modal-buttons-block">
                    <Button onClick={onCloseAddPhoneModal} type="button" title={"Cancel"} color={"outline"} />
                    <Button disabled={loading} loader={loading} type="submit" title={"Confirm"} color={"grey"} />
                </div>
            </form>
        </ModalComponent>
    );
};

export default AddPhoneModal;
