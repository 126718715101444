import React, {useCallback, useState} from "react";
import {isEmptyObject} from "../../../components/helpers";
import {MediaDropdown} from "../../../components";
import ShareList from "../../../components/ShareList";
import Button from "../../../components/buttons/Button";
import EditTags from "../../../components/EditTags";

const Controls = (props) => {
  const {
    photo,
    onLikeClick,
    onRequestHqClick,
    onRequestVideoClick,
    suspended,
    showPlayVideoModal,
    onSubmitTags,
    isDeleting,
    onImageDelete,
  } = props;
  const {liked, canRequestHq, canRequestVideo, type, hq, video, stateDescription, videoResolution, download} = photo;
  const hasVideoFile = type === "video-preview" && !isEmptyObject(video || {});
  const showRequestHq = type === "photo" && canRequestHq;
  const showRequestVideo = type === "video-preview" && canRequestVideo;
  const [showTags, onChangeShowTags] = useState(false);

  const handleShowTags = useCallback(() => {
    onChangeShowTags(true);
  }, []);

  const handleSubmitTags = useCallback(async(tags) => {
    await onSubmitTags(tags);
    onChangeShowTags(false);
  }, [onSubmitTags]);

  const showMediaDropdown = download?.links.length > 1;
  const options = download?.links;
  const url = download?.links[0].url;
  const label = download?.links[0].label;

  return (
    <div className="camera-photo-controls-row">
      {showMediaDropdown ? (
        <MediaDropdown options={options}/>
      ) : (
        <a className="btn btn-green lg" href={url} download>
          <span className="btn-icon icon-download-alt"/>
          <span>{label || "Download"}</span>
        </a>
      )}

      <LikeButton isLiked={liked} onLikeClick={onLikeClick}/>
      <ShareList url={photo.original}/>
      {hasVideoFile && (
        <Button title={"Play"} color={"green"} icon={"play-alt"} size={"lg"} onClick={showPlayVideoModal}/>
      )}
      {showRequestHq && (
        <Hq hq={hq || {}} suspended={suspended} stateDescription={stateDescription || {}}
            onRequestHqClick={onRequestHqClick}/>
      )}
      {showRequestVideo && (
        <Video
          video={video || {}} suspended={suspended}
          onRequestVideoClick={onRequestVideoClick}
          stateDescription={stateDescription || {}}
          videoResolution={videoResolution}
        />
      )}
      <EditTags
        onOpen={handleShowTags}
        open={showTags}
        tags={photo.tags || []}
        onSubmit={handleSubmitTags}
        onClose={() => onChangeShowTags(false)}
      />
      <Button
        onClick={onImageDelete}
        title={"Delete"}
        loader={isDeleting}
        disabled={isDeleting}
        icon={"trash-alt"}
        color={"red"}
        size={"lg"}
      />

    </div>
  );
};


const Hq = ({hq, stateDescription, suspended, onRequestHqClick}) => {
  if (!isEmptyObject(hq)) {
    return (
      <>
        <Button
          title={"Request HQ"}
          disabled={!(stateDescription?.status === "none" || stateDescription?.status === "success") || suspended}
          icon={"download-alt"}
          color={"grey"}
          onClick={onRequestHqClick}
          size={"lg"}
        />
      </>
    );
  }

  // TODO: Temp change for testing failed HQ uploads
  return (
    <Button
      title={"Request HQ"}
      //disabled={stateDescription?.status === "success"}
      disabled={false}
      icon={"download-alt"}
      color={"grey"}
      onClick={onRequestHqClick}
      size={"lg"}
    />
  );
};

const Video = ({video, videoResolution, stateDescription, onRequestVideoClick, suspended}) => {
  return (
    <Button
      title={`Request ${videoResolution}`}
      onClick={onRequestVideoClick}
      //disabled={stateDescription?.status !== "none" || suspended}
      disabled={false}
      icon={"download-alt"}
      color={"grey"}
      size={"lg"}
    />
  );
};

const LikeButton = ({isLiked, onLikeClick}) => {
  const [isInProgress, setIsInProgress] = useState(false);

  const onClick = async () => {
    setIsInProgress(true);
    onLikeClick();
    setIsInProgress(false);
  };

  if (isLiked) {
    return (
      <Button
        title={"Saved"}
        loader={isInProgress}
        disabled={isInProgress}
        icon={"heart-alt"}
        color={"red"}
        size={"lg"}
        onClick={onClick}
      />
    );
  }

  return (
    <Button
      title={"Save"}
      loader={isInProgress}
      disabled={isInProgress}
      icon={"heart-outline-red"}
      color={"red-outline"}
      size={"lg"}
      onClick={onClick}
    />
  );
};

export default Controls;
