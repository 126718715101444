import React, {useCallback, useEffect, useRef, useMemo, useState} from "react";
import {debounce} from "lodash";
import cx from "classnames";
import ReactTagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import "./tags.scss";
import {createPortal} from "react-dom";

const TagsInput = ({tags = [], overflow, name, suggestions = [], onLoad, renderTag, label, onChange}) => {
  const [value, onChangeValue] = useState("");
  const [focused, onChangeFocused] = useState(false);
  const [highlighted, onChangeHighlighted] = useState(0);
  const wrapper = useRef();

  const debouncedOnLoad = useMemo(() => {
    if (onLoad) {
      return debounce(onLoad, 300);
    }
  }, []);

  const debouncedBlur = useMemo(() => {
    return debounce(() => onChangeFocused(false), 300);
  }, []);

  useEffect(() => () => {
    if (debouncedOnLoad?.cancel) debouncedOnLoad.cancel();
  }, [debouncedOnLoad]);

  const onAddTagFromSuggestion = useCallback((tag) => {
    onChange([...tags, tag]);
  }, [onChange, tags]);

  const onInputKeyUp = useCallback((event) => {
    const key = event.keyCode;
    if (suggestions.length < 1) return;
    if (key === 40) {
      const nextHighlighted = highlighted + 1 >= suggestions.length ? 0 : highlighted + 1;
      onChangeHighlighted(nextHighlighted);
      const suggestion = suggestions[nextHighlighted];
      if (suggestion) {
        onChangeValue(suggestion.name);
      }
    }
    if (key === 38) {
      const prevHighlighted = highlighted - 1 < 0 ? suggestions.length - 1 : highlighted - 1;
      onChangeHighlighted(prevHighlighted);
      const suggestion = suggestions[prevHighlighted];
      if (suggestion) {
        onChangeValue(suggestion.name);
      }
    }
  }, [highlighted, value, onAddTagFromSuggestion, suggestions]);

  const renderInput = useCallback(({onChange, value, addTag, ...other}) => {
    return (
      <input
        type="text"
        onChange={onChange}
        value={value}
        {...other}
        onKeyUp={onInputKeyUp}
        onBlur={debouncedBlur}
        onFocus={() => onChangeFocused(true)}
      />
    );
  }, [onInputKeyUp])

  const onChangeInput = useCallback(async (value) => {
    onChangeValue(value);
    onChangeHighlighted(0);
    if (debouncedOnLoad) {
      await debouncedOnLoad(value);
    }
  }, [debouncedOnLoad, onLoad]);

  const overflowStyles = useMemo(() => {
    if (wrapper.current && overflow) {
      const coords = wrapper.current.getBoundingClientRect();
      return {
        position: "fixed",
        left: coords.left,
        width: coords.width,
        zIndex: 1000,
        top: coords.top + coords.height
      }
    }
    return {};
  }, [overflow, wrapper, focused, value]);

  const suggestionsElement = focused && suggestions.length > 0 ? (
    <div className="tags-input-suggestions" style={overflowStyles}>
      <ul>
        {suggestions.map((suggest, idx) => (
          <li role="button"
              onClick={() => onAddTagFromSuggestion(suggest)}
              key={idx}
              className={cx({highlightedSuggestion: idx === highlighted})}
          >
            {suggest.name}
          </li>
        ))}
      </ul>
    </div>
  ) : null;

  return (
    <div className="covert-tags-input-wrapper" ref={wrapper}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <ReactTagsInput
        className="covert-tags-input"
        renderInput={renderInput}
        inputValue={value}
        onlyUnique
        onChangeInput={onChangeInput}
        renderTag={renderTag}
        name={name}
        value={tags}
        onChange={onChange}
      />
      {overflow ? createPortal(suggestionsElement, document.body) : suggestionsElement}
    </div>
  );
}

export default TagsInput;
