import "./CustomSelect.scss";
import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();

const CustomSelect = ({name, value, options = [], onChange, hasAll = true}) => {

    const computedOptions = hasAll ? [{value: "", label: "All"}, ...options] : options;

    return (
        <Select
            closeMenuOnSelect={true}
            components={animatedComponents}
            unstyled={true}
            value={value}
            onChange={(selected) => {
                onChange({target: {name: name, value: selected.value}});
            }}
            menuPortalTarget={document.body}
            options={computedOptions}
            className={"single-custom-select-container"}
            classNamePrefix={"single-custom-select"}
        />
    );
};

export default CustomSelect;
