import "./Modals.scss";
import React, {useState} from "react";
import {GuestAccountsKeysApi} from "../../../api/guest-accounts-keys";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {toast} from "react-toastify";

const AddKey = ({isOpen, onClose}) => {
  const [key, setKey] = useState("");
  const [inProgress, setInProgress] = useState(false);

  const onConfirm = async () => {
    try {
      setInProgress(true);
      await GuestAccountsKeysApi.attach({key: key});
      setInProgress(false);
      setKey("");
      onClose(true);
    } catch (e) {
      if (e.response.data && e.response.data.message) toast.error(e.response.data.message || "Something went wrong");
      setInProgress(false);
    }
  };

  return (
    <BaseModal isOpen={isOpen} onClose={() => onClose(false)}>
      <section className="modal-body">
        <Header name={"Add key"} onClose={() => onClose(false)}/>
        <div className="modal-content">
          <form>
            <div className="form-group">
              <label>Key</label>
              <input
                type={"text"}
                placeholder={"Enter key"}
                className="input"
                value={key}
                onChange={(evt) => setKey(evt.target.value)}
              />
            </div>
          </form>
        </div>
        <div className="modal-buttons">
          <Button onClick={() => onClose(false)} title={"Cancel"} color={"outline"}/>
          <Button onClick={onConfirm} disabled={inProgress || !key.length} loader={inProgress} title={"Save"}
                  color={"grey"}/>
        </div>
      </section>
    </BaseModal>
  );
};
export default AddKey;
