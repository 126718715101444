import React, { useEffect } from "react";
import {Link, Navigate, useParams, useSearchParams} from "react-router-dom";
import {CamerasAPI} from "../../api/cameras";
import CameraPreview from "../../components/CameraPreview/CameraPreview";
import {Outlet} from "react-router-dom";
import {CameraHeader} from "../../components";
import {Tooltip} from "react-tooltip";
import {
  pushToVisitedCamerasList,
  getVisitedCamerasList
} from "../../components/helpers";
import status from "../../helpers/status";
import {camerasSettings} from "../../helpers/query-keys";
import fetcher from "../../helpers/fetcher";
import "./camera-weather.scss";

const CameraWeatherIndex = () => {
  const {id} = useParams();
  const cameraInfoQK = camerasSettings.one(id).queryKey;
  const {data = { data: {}}, isLoading, isFetched, failureReason} = fetcher(cameraInfoQK, () => CamerasAPI.cameraInfo(id), {
    throwOnError: false,
  });
  const camera = data.data || {};
  const [searchParams] = useSearchParams();
  const visitedCameras = getVisitedCamerasList().filter((item) => item.id !== camera.id);


  useEffect(() => {
    if (camera.id) pushToVisitedCamerasList(camera);
  }, [camera]);

  const suspended = camera.status === status.STATUS_SUSPEND;

  if (failureReason && failureReason.response && failureReason.response.status) {
    if (failureReason.response.status === 404) {
      return <Navigate to="/not-found-page" />
    }
    if (failureReason.response.status === 500) {
      return <Navigate to="/server-error-page" />
    }
  }

  return (
    <section className="page-wrapper">
      <div className="page-content">
        <CameraHeader camera={camera} loading={isLoading} isWeather streamsAllowed={camera.supportLiveMode} suspended={suspended} actions={camera.actions || {}} />
        <div className="camera-view">
          <div className="camera-view-short">
            <CameraPreview camera={camera} loading={isLoading} />
            <Link to={`/cameras/${id}/photos`} className="btn btn-grey lg w100">
              <span className="btn-icon-arrow" />
              <span>Back to my photos list</span>
            </Link>
            <div className="visited-cameras">
              {visitedCameras.map((visitedCamera, idx) => {
                return (
                  <CameraPreview
                    key={idx}
                    isSettings
                    camera={visitedCamera}
                    link={`/cameras/${visitedCamera.id}/photos`}
                  />
                );
              })}
            </div>
          </div>
          <div className="camera-view-content">
            {isFetched && <Outlet context={{camera}} />}
          </div>
        </div>
      </div>
      <Tooltip id={"camera-preview-outdated-firmware"} />
    </section>
  );
};

export default CameraWeatherIndex;
