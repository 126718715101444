export const FormValidator = {
  validateForm: (form, setErrors) => {
    let isValid = true;
    const newErrors = {};

    if (!form.name) {
      newErrors.name = "Name is required";
      isValid = false;
    }

    if (!form.cameraIds || form.cameraIds.length === 0) {
      newErrors.cameraIds = "At least one camera must be selected";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  },
};

export default FormValidator;
