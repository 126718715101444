import axios from "./http-api-client";

export const GuestCameraPhotos = {

    listByDates: async (id) => {
        return axios.get(`/account/guests/cameras/${id}/photos`);
    },

    loadMorePhotos: async (id, fromId) => {
        return axios.get(`/account/guests/cameras/${id}/photos?fromId=${fromId}`);
    },

    photoView: async (id, photoId) => {
        return axios.get(`/account/guests/cameras/${id}/photos/${photoId}`);
    }
};