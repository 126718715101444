import React from "react";
import "./CameraPreview.scss";

const SubscriptionStatus = ({closeOverLimit, overPhotosLimit}) => {

  if (closeOverLimit) {
    return <div className="camera-subscription-status close-to-limit">Close to photo limit</div>
  }

  if (overPhotosLimit) {
    return <div className="camera-subscription-status over-limit">Photo limit reached</div>
  }

  return <div className="camera-subscription-status holder">&nbsp;</div>
};


export default SubscriptionStatus;
