import React, {useState, useCallback, useMemo} from "react";
import BaseModal from "../../../components/Modal/BaseModal";
import Header from "../../../components/Modal/Header";
import Button from "../../../components/buttons/Button";
import {ProfileAPI} from "../../../api/profile";
import { SubscriptionItem, SubscriptionPreview } from "../components/SubscriptionItem";
import "./subscriptions.scss";
import cx from "classnames";
import Collapse from "../../../components/Collapse";
import {chunk} from "lodash";

const NewSubscription = ({isOpen, subscriptionGroups = [], onClose}) => {
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);
  const [inProgress, setInProgress] = useState(false);


  const onSelectSubscription = (subscription) => {
    setSelectedSubscription(subscription);
  }

  const onConfirm = useCallback(async () => {
    setInProgress(true);

    await ProfileAPI.addSubscription({
      packageId: selectedSubscription.packageId
    })

    setInProgress(false);
    onClose();
  }, [onClose, selectedSubscription]);

  const onSelectGroup = useCallback((group) => {
    setSelectedGroup(group);
    setSelectedSubscription(null);
  }, []);

  const onClickAdd = useCallback(() => {
    setSelectedGroup(null);
    setSelectedSubscription(null);
  }, []);

  const onBack = useCallback(() => {
    setSelectedGroup(null);
    setSelectedSubscription(null);
  }, []);


  const plans = useMemo(() => {
    if (selectedGroup) {
      return chunk(selectedGroup.plans, 2);
    }
    return [];
  }, [selectedGroup]);


  const handleClose = useCallback(() => {
    setSelectedGroup(null);
    setSelectedSubscription(null);
    onClose(false);
  }, [onClose]);

  return (
    <BaseModal isOpen={isOpen} onClose={handleClose} content={{width: selectedGroup ? "600px" : "400px", transition: "width 0.3s ease"}}>
      <section className="modal-body">
        <Header name="Choose subscription" onClose={handleClose}/>
        <div className="modal-content">
          <div className={cx({list: !selectedGroup})}>
            {
              selectedGroup ? (
                plans.map((subscriptions) => (
                  <div className="subscription-group">
                    <div className="subscription-group-plans">
                      {subscriptions.map((subscription) => (
                        <SubscriptionItem
                          key={subscription.id}
                          item={subscription}
                          add
                          isSelected={selectedSubscription && selectedSubscription.id === subscription.id}
                          onSelectSubscription={() => onSelectSubscription(subscription)}/>
                      ))}
                    </div>
                    <Collapse show={selectedSubscription && subscriptions.map((item) => item.id).includes(selectedSubscription.id)}>
                      <SubscriptionPreview addon key={selectedSubscription?.id} item={selectedSubscription || {}}/>
                    </Collapse>
                  </div>
                ))
              ) : (
                subscriptionGroups.map((group, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`subscription-btn subscription-group-btn ${selectedGroup && selectedGroup.id === group.id ? "selected" : ""}`}
                      role="button"
                      onClick={() => onSelectGroup(group)}
                    >
                      <p className="subscription-btn-group-name">{group.name}</p>
                    </div>
                  );
                })
              )
            }
          </div>
        </div>
        <div className="modal-buttons">
          <Button
            onClick={selectedGroup ? onBack : handleClose}
            title={selectedGroup ? "Back" : "Close"}
            color="outline"
          />
          <Button
            loader={inProgress}
            disabled={inProgress || !selectedSubscription}
            onClick={onConfirm}
            title={"Confirm"}
            color="grey"
          />
        </div>
      </section>
    </BaseModal>
  );
};

export default NewSubscription;
