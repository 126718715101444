import "./HeaderSettings.scss";
import React from "react";
import ButtonLg from "../../../../components/buttons/ButtonLg";

const Header = ({setAddGuestAccount, loading, name = "Guest Cameras"}) => {
  return (
    <div className="page-header">
      <div className="page-title">{loading ? "Loading..." : name}</div>
      {setAddGuestAccount ?
        <div className="page-header-controls">
          <ButtonLg onClick={() => setAddGuestAccount(true)} title={"Add"} icon={"key"} color="grey"/>
        </div>
        : null
      }
    </div>
  );
};

export default Header;
