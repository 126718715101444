import React from "react";
import "./dnd.scss";

import {Container} from "./Container";

const Dnd = ({row, cameras, onChangeOrder, onPin}) => {

  return (
    <>
      {cameras?.length > 0 && (
        <section className={`dnd-block${row ? " row" : ""}`}>
          <Container onChangeOrder={onChangeOrder} camerasPin={cameras} onPin={onPin} row={row}/>
        </section>
      )}
    </>
  );
};

export default Dnd;
