import React from "react";
import {getBatteryIcon, getSignalIcon} from "../../pages/CameraSettings/utils";

export const RowStats = ({stats = {}}) => {
    const signalIcon = getSignalIcon(stats?.signal);
    const batteryIcon = getBatteryIcon(stats?.battery);

    return (
        <div className="camera-item-stats-row">
            <div className="camera-item-stats-item-row">
                <div className="camera-item-stats-battery-percent">
                    {batteryIcon && <img src={batteryIcon} alt="Battery" />}
                    <div className="camera-item-stats-txt">{stats?.battery ? `${stats?.battery}%` : "N/A"}</div>
                </div>
            </div>
            <div className="camera-item-stats-item-row">
                <div className="camera-item-stats-wifi-signal">
                    {signalIcon && <img src={signalIcon} alt="Signal" />}
                </div>
                <div className="camera-item-stats-txt">
                    {stats?.signal !== undefined && stats?.signal !== null ? `${stats?.signal}%` : "N/A"}
                </div>
            </div>
            <div className="camera-item-stats-item-row">
                <div className="camera-item-stats-card" />
                <div className="camera-item-stats-txt">
                    {stats?.sdCard?.freeSpace ? `${stats?.sdCard?.freeSpace}${stats?.sdCard?.uom}` : "N/A"}
                </div>
            </div>
        </div>
    );
};
