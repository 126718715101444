import axios from "axios";
import {toast} from "react-toastify";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    config.headers = {
      ...config.headers,
        ...(token ? {['Authorization']: `Bearer ${token}`} : {})
    };
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.localStorage.removeItem('AUTH_TOKEN');
    window.location.href = '/app/start';
  }
  if (error.response.status === 404) {
    toast.error("Something not found");
  }
  if (error.response.status === 400 && error.response.data && error.response.data.message) {
    toast.error(error.response.data.message || "Something went wrong");
  }
  if (error.response.status === 500) {
    toast.error(error.response?.data?.message || "Something went wrong. Try again later.");
  }

  return Promise.reject(error);
});

export default instance;
