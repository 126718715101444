import React from "react";
import {default as BaseNav} from "../../../components/Nav/Nav";
import {Tooltip} from "react-tooltip";

const Nav = ({camera, current}) => {

  const subscription = camera?.usage?.subscriptions;
  const showReplenish = subscription && (subscription?.closeOverLimit || subscription?.overPhotosLimit);

  const cameraNav = [
    {name: "Info", alias: "info"},
    {name: "Settings", alias: "settings"},
    {name: "Specs", alias: "specs"},
    {name: "Map", alias: "map"},
    {name: "Commands", alias: "commands"},
    {name: "Linked cameras", alias: "linked", disabled: camera.modelName !== "lora"},
    {name: "Notifications", alias: "notifications"},
    {name: "Billing", alias: "billing"},
    {name: "Usage", alias: "usage"},
    {name: "Reports", alias: "reports", sub: "general/total"},
    {name: "Debug LOGs", alias: "debug", disabled: !camera.isDebug},
  ];

  const list = cameraNav
    .filter(item => !item.disabled)
    .map(item => {
    if (item.alias === 'billing' && showReplenish) {
      return {
        ...item,
        ...{
          icon: "warning", tooltip: {
            id: "camera-nav-tooltip",
            content: "Replenish required"
          }
        }
      };
    }

    return item;
  });

  return (
    <>
      <BaseNav list={list} basePath={`/camera/${camera.id}`} current={current}/>
      <Tooltip id={"camera-nav-tooltip"}/>
    </>
  )
};

export default Nav;
