import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Link, useSearchParams} from "react-router-dom";
import {
  CameraName,
  CameraImei,
  PinCamera
} from "./common";
import "./cameras.scss";

import {ItemTypes} from "./ItemTypes";
import Stats from "../Stats/Stats";
import SubscriptionStatus from "../cameras/SubscriptionStatus";
import iconRight from "../../images/iconSVG/icon-right.svg"
import iconLeft from "../../images/iconSVG/icon-left.svg"
import status from "../../helpers/status";
import cx from "classnames";
import Weather from "../Weather";

export const DraggableCardCamera = ({index, onDrop, camera, onPin, moveCamera}) => {
  const ref = useRef(null);
  const [page, onChangePage] = useState(0);

  const [pinProcessing, setPinProcessing] = useState(false);
  const [showLinked, onChangeShowLinked] = useState(false);

  const [searchParams] = useSearchParams();
  const search = searchParams.get("search") || "";
  const {latestFirmware, usage, lastSeen} = camera;
  const isOutdatedFirmware = latestFirmware && latestFirmware.currentState === "outdated";

  const [, drop] = useDrop(
    {
      accept: ItemTypes.CARD,
      hover(item, monitor) {
        if (!ref.current) {
          return;
        }

        const dragIndex = item.index;
        const hoverIndex = index;

        if (dragIndex === hoverIndex) {
          return;
        }

        moveCamera(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
      drop: async (item, monitor) => {
        if (!ref.current) {
          return;
        }
        if (onDrop) await onDrop();
      }
    },
    [index, onDrop, index, moveCamera],
  );

  let id = camera?.id;

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes.CARD,
    item: {id: camera?.id, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const onPinClick = async () => {
    setPinProcessing(true);
    await onPin(camera);
    setPinProcessing(false);
  };

  const handleClickShowLinked = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    onChangeShowLinked(!showLinked);
  }, [showLinked]);

  const handleOutsideClick = (event) => {
    const inElem = event.target?.closest(`.camera-item-${camera.id}`);
    if (showLinked && !inElem) {
      onChangeShowLinked(false);
    }
  };

  const pages = [
    <div className="camera-card-info">
      <CameraName value={camera?.name || ""} search={search}/>
      <div className="camera-item-info">
        <span className="camera-item-info-txt">{camera?.model}</span>
        <span className="camera-item-info-txt">IMEI:</span>
        <CameraImei value={camera?.imei} search={search}/>
      </div>
      <Stats stats={usage?.stats || {}}/>
      <div className={cx("camera-item-time", {"hidden-last-seen": !lastSeen})}>
        <div className="camera-item-time-icon"/>
        <p className="camera-item-stats-txt">{lastSeen || "never"}</p>
      </div>
    </div>,
    <Weather className="camera-card-weather" weather={camera?.weather} />
  ];

  const handleChangePage = useCallback((direction) => {
    let newPage = page + direction;
    if (newPage >= pages.length) {
      newPage = 0;
    }
    if (newPage < 0) {
      newPage = pages.length - 1;
    }
    onChangePage(newPage);
  }, [page, pages]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const suspended = camera.status === status.STATUS_SUSPEND;

  return (
    <section
      className={cx(`camera-item`, { isDraggingCard: isDragging, suspended, "camera-card-with-weather": !!camera?.weather })}
      draggable={Boolean(onDrop)}
      ref={Boolean(onDrop) ? ref : null}
    >
      {!isDragging && (
        <>
          {camera.onxEnabled && <div className="card-item-onx-logo"/>}
          {isOutdatedFirmware &&
            <div className="card-item-firmware-icon" data-tooltip-id={`camera-outdated-firmware-list`}
                 data-tooltip-content={"New firmware available"}/>
          }
          <div className="camera-item-header">
            <Link to={`/cameras/${id}/photos`} className="camera-photo-link">
              <img className="camera-image" src={camera?.image} alt="camera"/>
              {suspended ? <span className="suspended-label">Suspended</span> : null}
            </Link>
          </div>
          <div className="camera-item-controls">
            <PinCamera isPinned={camera.pinned} pinProcessing={pinProcessing} onClick={onPinClick}/>
            <Link to={`/camera/${id}/info`}>
              <div className="camera-item-controls-setting"/>
            </Link>
            {camera.linkedCameras && camera.linkedCameras.length > 0 && (
              <div className={cx("camera-item-linked-list", {showLinked: showLinked})}>
                <div role="button" className="camera-item-cameras-icon trigger" onClick={handleClickShowLinked}>
                  <span>{camera.linkedCameras.length}</span>
                </div>
                <div className="camera-item-linked-list-wrapper">
                  <ul>
                    {camera.linkedCameras.map((linkedCamera) => (
                      <li key={linkedCamera.id}>
                        <Link to={`/cameras/${camera.id}/photos?filters[linked][0]=${linkedCamera.id}`}>
                          {linkedCamera.slot}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          <SubscriptionStatus closeOverLimit={camera.closeOverLimit} overPhotosLimit={camera.overPhotosLimit}/>
          <div className="camera-card-item-info-wrapper">
            <Link to={`cameras/${id}/photos`} className="camera-name-link">
              {pages.map((item, idx) => (
                <div className={cx("camera-card-page", {"camera-card-active-page": page === idx})}>
                  {item}
                </div>
              ))}
            </Link>
            {!!camera?.weather && (
              <>
                <button className="camera-card-nav right" onClick={() => handleChangePage(1)}>
                  <img src={iconRight} alt="right" draggable={false}/>
                </button>
                <button className="camera-card-nav left" onClick={() => handleChangePage(-1)}>
                  <img src={iconLeft} alt="left" draggable={false}/>
                </button>
              </>
            )}
          </div>
        </>
      )}
    </section>
  );
};

export default DraggableCardCamera;
