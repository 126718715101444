import "./cameraSettingsInput.scss";
import React  from "react";

const Select = ({ name, options, rangeOptions, value, onChange, disabled = false }) => {

  return (
    <select className="select" name={name} value={value} onChange={onChange} disabled={disabled}>
      {getOptions(options, rangeOptions)}
    </select>
  );
};

const getOptions = (options) => {

  if (options) {
    if (Array.isArray(options)) {
      return options.map((option, index) => {
        return <option key={`${option.label}-${index}`} value={option.value}>{option.label}</option>
      });
    }

    return Object.keys(options).map(key => {
      const option = options[key];

      return <option key={`${key}`} value={key}>{option}</option>
    })
  }

  return <option>Empty</option>
}

export default Select;
