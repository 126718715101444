import "./ActionsDropdown.scss";
import React, {useEffect, useState} from "react";

const ActionsDropdown = ({options, disabled, onOptionClick}) => {
    const [isOpen, setIsOpen] = useState(false);

  const handleOutsideClick = (event) => {
    const inElem = event.target?.closest(".actions-dropdown");
    if (isOpen && !inElem) {
      setIsOpen(false);
    }
  };


  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [handleOutsideClick]);

    const handleOpen = () => {
      if (disabled) return;
      setIsOpen(!isOpen);
    };

    return (
        <div className="dropdown actions-dropdown">
            <button onClick={handleOpen} disabled={disabled} className="btn btn-grey lg">
                <span>Actions</span>
                <span className="btn-icon icon-dropdown-alt"/>
            </button>
            <ul className={`menu ${isOpen ? "open" : ""}`}>
                {Object.keys(options).map((alias, index) => (
                    <li key={index} className="menu-item" onClick={() => {
                        setIsOpen(false);
                        onOptionClick(alias);
                    }}>
                        {options[alias].label}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ActionsDropdown;
