import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true
});

instance.interceptors.request.use(
  (config) => {
    const token = window.localStorage.getItem('AUTH_TOKEN');
    config.headers = {
      ...config.headers,
        ...(token ? {['Authorization']: `Bearer ${token}`} : {})
    };
    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    window.localStorage.removeItem('AUTH_TOKEN');
    window.location.href = '/start';
  }

  return Promise.reject(error);
});

export default instance;
