import React from "react";
import Modal from "react-modal";
import { Bars } from "react-loader-spinner";

const Loader = () => {
  return (
    <Modal
      isOpen={true}
      ariaHideApp={false}
      style={{
        overlay: {
          backgroundColor: "rgba(15, 23, 42, 0.45)",
        },
        content: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          background: "none",
        },
      }}
    >
      <Bars
        height="80"
        width="100"
        color="#E42F31"
        ariaLabel="line-wave"
        visible={true}
      />
    </Modal>
  );
};

export default Loader;
