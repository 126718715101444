import React, { useContext, useRef, useState } from "react";
import BaseModal from "../components/Modal/BaseModal";
import Button from "../components/buttons/Button";
import Header from "../components/Modal/Header";

const useModalShow = () => {
  const [ show, setShow ] = useState(false);

  const handleOnHide = () => {
    setShow(false);
  };

  return {
    show,
    setShow,
    onHide: handleOnHide,
  }
};

const ConfirmationModalContext = React.createContext({});

const ConfirmationModalContextProvider = (props) => {
  const { setShow, show, onHide } = useModalShow();
  const [ content, setContent ] = useState({ message: "Are you sure?" });
  const resolver = useRef();

  const handleShow = (message, red, onConfirm) => {
    if (message) {
      setContent({
        message,
        onConfirm,
        red,
      });
    }
    setShow(true);
    return new Promise(function (resolve) {
      resolver.current = resolve;
    });
  };

  const modalContext = {
    showConfirmation: handleShow
  };

  const handleOk = () => {
    if (content.onConfirm) {
      content.onConfirm();
    }
    resolver.current && resolver.current(true);
    onHide();
  };

  const handleCancel = () => {
    resolver.current && resolver.current(false);

    onHide();
  };

  return (
    <ConfirmationModalContext.Provider value={modalContext}>
      {props.children}
      <BaseModal isOpen={show} overlayStyle={{ zIndex: 100 }} content={{ width: 300 }} size="size-sm">
        <Header name="Confirmation"/>
        <div className="modal-content">{content && content.message || ""}</div>
        <div className="modal-footer">
          <Button title="Cancel" color="none" onClick={handleCancel}/>
          <Button title="Confirm" color={content.red ? "red": "grey"} onClick={handleOk}/>
        </div>
      </BaseModal>
    </ConfirmationModalContext.Provider>
  )
};

const useConfirmationModalContext = () => useContext(ConfirmationModalContext);

export {
  useModalShow,
  useConfirmationModalContext,
}

export default ConfirmationModalContextProvider;
