import React, {useLayoutEffect, useRef, useState} from "react";
import cx from "classnames";
import "./collapse.scss";

const Collapse = ({ children, extra = 0, className, show = false }) => {
  const [height, onChangeHeight] = useState(0);
  const content = useRef();

  useLayoutEffect(() => {
    if (content.current) {
      const contentSize = content.current?.getBoundingClientRect();
      if (show) {
        onChangeHeight((contentSize?.height || 0) + extra);
        return;
      }
      onChangeHeight(0);
    }
  }, [content, show]);

  return (
    <div className={cx("collapse-content", className, { shown: show })} style={{ height: show ? height : 0 }}>
      <div ref={content}>
        {children}
      </div>
    </div>
  )
}

export default Collapse;
