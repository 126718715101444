import React, {useCallback, useMemo, useState} from "react";
import cx from "classnames";
import "./components.scss";
import {format} from "date-fns";
import { isEqual } from "lodash";
import {MultiSelect} from "../../../../components";
import Button from "../../../../components/buttons/Button";
import DatePicker from "../../../../components/DatePicker";

const Filters = ({setFilter, cameras = [], recognitions, oneCamera, defaultFilter, filter = {}}) => {
  const [localFilters, onChangeLocalFilters] = useState(filter);

  const activeIcons = useMemo(() => {
    const paramsLabels = localFilters?.labels || [];
    if (paramsLabels.length < 1) return recognitions.map((item) => String(item.id));
    return paramsLabels.map(item => String(item));
  }, [localFilters, recognitions]);

  const handleClickRecognition = useCallback((id) => {
    if (activeIcons.includes(id) && activeIcons.length < 2) return;
    const newLabels = activeIcons.includes(id) ? activeIcons.filter((item) => item !== id) : [...activeIcons, id];
    onChangeLocalFilters({
      ...localFilters,
      labels: newLabels
    });
  }, [localFilters, activeIcons]);

  const date = useMemo(() => {
    return [localFilters.date_from ? new Date(localFilters.date_from) : null, localFilters.date_to ? new Date(localFilters.date_to) : null]
  }, [localFilters]);


  const onChangeCalendarHandler = useCallback((date) => {
    onChangeLocalFilters({
      ...localFilters,
      date_from: date[0] ? format(date[0], "yyyy-MM-dd") : "",
      date_to: date[1] ? format(date[1], "yyyy-MM-dd") : "",
    });
  }, [localFilters]);

  const onCamerasChange = useCallback((_name, values) => {
    const filterCameras = values.map((item) => item.value);
    onChangeLocalFilters({
      ...localFilters,
      cameras: filterCameras,
    });
  }, [localFilters]);

  const camerasValue = useMemo(() => {
    if (localFilters.cameras) {
      return cameras.filter((item) => localFilters.cameras.includes(item.value));
    }
    return [];
  }, [cameras, localFilters]);

  const handleApplyFilter = useCallback(() => {
    setFilter(localFilters);
  }, [setFilter, localFilters]);

  const resetFilter = useCallback(() => {
    setFilter(defaultFilter.filter);
    onChangeLocalFilters(defaultFilter.filter);
  }, [setFilter, defaultFilter]);

  const disabledReset = useMemo(() => {
    return isEqual(defaultFilter.filter, localFilters);
  }, [defaultFilter, localFilters]);

  return (
    <div className="reports-filters-wrapper">
      <div className="reports-filters-line">
        <div className="reports-filters-recognitions">
          {recognitions.map((icon) => (
            <span key={icon.id} onClick={() => handleClickRecognition(`${icon.id}`)} role="button"
                  className="reports-filters-recognition">
            <span className={cx("recognition-icon dark-icon", {
              "active-recognition-icon": activeIcons.includes(String(icon.id))
            }, `icon-${icon.label}`)}
            />
              {icon.name}
          </span>
          ))}
        </div>
        {oneCamera ? null : <DatePicker className="reports-datepicker" range value={date} onChange={onChangeCalendarHandler} />}
      </div>
      <div className="reports-filters-line">
        {oneCamera ? <DatePicker className="reports-datepicker" range value={date} onChange={onChangeCalendarHandler} /> : null}
        <div className="reports-filters-btns">
          <Button color="outline" size="lg" disabled={disabledReset} title="Reset" onClick={resetFilter} />
          <Button color="grey" size="lg" title="Apply filters" onClick={handleApplyFilter} />
        </div>
      </div>
    </div>
  )
}

export default Filters;
