import "./Profile.scss";
import React, {useEffect, useRef, useState} from "react";
import {ProfileAPI} from "../../api/profile";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import Cookies from "js-cookie";

const PaymentInfo = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [sessionInfo, setSessionInfo] = useState({});
    const revXContainer = useRef(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const {data} = await ProfileAPI.paymentWidget();
        setSessionInfo(data);
        setIsLoading(false);

        if (!data.error) {
            if (!Cookies.remove('PHPSESSID')) {
                Cookies.remove('PHPSESSID', {
                    'path': '/',
                    'domain': '.covert-wireless.com',
                });

                Cookies.remove('PHPSESSID', {
                    'path': '',
                    'domain': '.covert-wireless.com',
                });
            }

            Cookies.set('PHPSESSID', data.session_id, {
                'path': '/',
                'domain': '.covert-wireless.com',
                'expires': 1/80
            });

            revXContainer.current.innerHTML = '<object type="text/html" data="' + data.url + '" style="width: 100%; height: 800px;">';
        }
    };

    const {error} = sessionInfo;

    return (
      <section className="content-section">
          <div className="page-sub-title">Payment settings</div>
          {isLoading ? (<LineWaveLoader/>) : (
            <div>
                {error && (<p>Error occurred during authorization to billing system.</p>)}
            </div>
          )}
          <div id={"revxContent"} ref={revXContainer}/>
      </section>
    );
};

export default PaymentInfo;
