import axios from "./http-api-client";

export const SubscriptionsAPI = {
    subscriptions: async () => {
        return axios.get(`/subscriptions`);
    },

    addons: async () => {
        return axios.get(`/subscriptions/addons`);
    },

    special: async () => {
        return axios.get(`/subscriptions/special`);
    }
};
