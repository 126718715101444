import React, {useEffect, useRef, useState} from "react";

import "./MediaDropdown.scss";

const MediaDropdown = ({options, value, modal}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectOptionsRef = useRef(null);
    const dropdownBtnRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                isOpen &&
                selectOptionsRef.current &&
                !selectOptionsRef.current.contains(event.target) &&
                !dropdownBtnRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [isOpen]);

    const handleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`custom-dropdown ${isOpen ? "open" : ""}`}>
            <div className={`btn btn-green lg ${modal ? "modal" : ""}`} onClick={handleDropdown} ref={dropdownBtnRef}>
                <span>Download</span>
                <span className="btn-icon icon-dropdown-alt" />
            </div>
            {isOpen && (
                <ul className="dropdown-options" ref={selectOptionsRef}>
                    {options.map((option) => (
                        <div
                            className={`options-link-block ${value === option.label ? "selected" : ""}`}
                            key={option.label}
                        >
                            <a className={`option-link`} onClick={() => setIsOpen(false)} href={option?.url}>
                                {option.label}
                            </a>
                        </div>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default MediaDropdown;
