import {isArray, uniq, get, omit, some, isObject} from "lodash";
import colors from "../../helpers/colors";

export const prepareDataForGraph = (data = {datasets: {}, label: ""}) => {
  if (isArray(data)) {
    return {
      labels: undefined,
      graphData: [],
      y: "Photos",
      graphType: "AreaGraphic",
      yy: []
    }
  }
  let y = "Photos";
  let yy = undefined;
  let labels = undefined;
  let type = "AreaGraphic"; //BarGraphic
  const values = Object.values((data.datasets));
  const keys = Object.keys((data.datasets));
  const dataTypeIsObject = some(values, isObject);
  const dataTypeIsArray = some(values, isArray);

  let graphData = keys.map((key) => {
    const value = data.datasets[key] ?? 0;
    const valueIsObject = isObject(data.datasets[key]);
    const valueIsArray = isArray(data.datasets[key]);

    if (valueIsArray) {
      const data = value.reduce((result, item) => ({
        ...result,
        [item.id]: Number(item.value) ?? 0,
      }), {});
      const labels = value.reduce((result, item) => ({
        ...result,
        [item.id]: item.label,
      }), {});
      return {
        label: key,
        ...data,
        labels
      };
    }

    if (valueIsObject) {
      return {
        label: key,
        ...value
      };
    }

    return {
      label: key,
      [y]: value ?? 0
    };
  });

  if (dataTypeIsObject) {
    yy = uniq(graphData.reduce((res, item) => {
      return [...res, ...Object.keys(omit(item, ["label", y]))]
    }, []))
      .map((key, index) => ({
        key,
        color: colors[index]
      }));
    type = "BarGraphic";
  }

  if (dataTypeIsArray) {
    yy = uniq(graphData.reduce((res, item) => {
      return [...res, ...Object.keys(omit(item, ["label", "labels", y]))]
    }, []))
      .map((key, index) => ({
        key,
        color: colors[index]
      }));
    labels = graphData.reduce((result, item) => {
      return {
        ...result,
        ...get(item, "labels", {})
      }
    }, {});
    graphData = graphData.map((item) => omit(item, "labels"));
    type = "BarGraphic";
  }

  return {
    labels,
    graphData,
    y,
    graphType: type,
    yy
  }
}
