import "./Modal.scss";
import React from "react";
import Modal from "react-modal";
import Header from "./Header";

Modal.setAppElement("#root");

const defaultContent = {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    border: "none",
    background: "#FFFFFF",
    WebkitOverflowScrolling: "touch",
    borderRadius: "8px",
    outline: "none",
    padding: "28px",
    width: "656px",
    overflow: "hidden",
};

export default function ModalComponent({children, header = true, onClose, isOpen = false, content = {}, title = ""}) {
    return (
        <Modal
            isOpen={isOpen}
            closeTimeoutMS={200}
            ariaHideApp={false}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={true}
            style={{
                overlay: {
                    backgroundColor: "rgba(15, 23, 42, 0.45)",
                    zIndex: 1000,
                },
                content: {
                    ...defaultContent,
                    ...content,
                },
            }}
        >
            <section className="modal-body">
                {header ? <Header name={title} onClose={onClose} /> : null}
                {children}
            </section>
        </Modal>
    );
}
