import React, {useEffect, useState} from "react";
import { Stream } from "@cloudflare/stream-react";
import LineWaveLoader from "../../components/Loader/LineWaveLoader";
import "./stream.scss";
import {useParams} from "react-router-dom";
import { AccountCameraAPI } from "../../api/account-camera";

const CameraStream = () => {
  const { id } = useParams();
  const [src, onChangeSrc] = useState(undefined);
  const [error, onChangeError] = useState(false);
  const [loading, onChangeLoading] = useState(false);

  const startStream = async () => {
    try {
      onChangeLoading(true);
      const { data = {} } = await AccountCameraAPI.startStream(id);
      onChangeLoading(false);
      onChangeSrc(data.uid);
    } catch (e) {
      onChangeLoading(false);
      onChangeError(true);
    }
  }

  useEffect(() => {
    startStream();
    return () => {
      onChangeLoading(false);
      onChangeSrc(undefined);
      onChangeError(false);
      AccountCameraAPI.stopStream(id);
    }
  }, [id]);

  const handleError = (error) => {
    onChangeError(true);
  }

  return (
    <section className="content-section">
      <div className="stream-wrapper">
        {src && (
          <Stream
            controls
            autoplay
            height="500px"
            width="100%"
            onError={handleError}
            src={src}
            primaryColor="#E42F31"
          />
        )}
        {error && (<div className="stream-placeholder">Something went wrong. Try again later</div>)}
        {loading && (<div className="stream-loader-wrapper"><LineWaveLoader/></div>)}
      </div>
    </section>
  )
}

export default CameraStream;
