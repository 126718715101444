import "./Button.scss";
import React from "react";

const Button = ({title, onClick, className = "", color = 'grey', icon = '', size = '', disabled = false, loader = false, type = "button"}) => {
    return (
        <button type={type} onClick={onClick} disabled={disabled} className={`btn btn-${color} ${className} ${size ? size : ''}`}>
            <Content title={title} icon={icon} loader={loader}/>
        </button>
    );
};

export default Button;

const Content = ({icon, title = "", loader = false}) => {
    return (
        <>
            <div className="btn-spinner" style={{display: loader ? "inline-block" : "none"}}/>
            {icon && <span style={{visibility: loader ? "hidden" : "visible"}} className={`btn-icon icon-${icon}`}/>}
            {(title && title.length > 0) && <span className="button-text-controller" style={{visibility: loader ? "hidden" : "visible"}}>{title}</span>}
        </>
    )
}
