import React from "react";
import cx from "classnames";
import "./tags.scss";
import {Link} from "react-router-dom";

const Tags = ({ tags = [], className }) => {
  if (tags.length < 1) return null;
  return (
    <div className={cx("render-tags-wrapper", className)}>
      <h4>Tags: </h4>
      {tags.map((tag) => (
        <Link className="render-tag" key={tag.id} to={`/gallery?filters[tags][0]=${tag.tag_id}`}>{tag.name}</Link>
      ))}
    </div>
  );
}

export default Tags;
